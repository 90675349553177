const shadowLighter = [
  [173, 1139],
  [440, 1170],
  [429, 1022],
  [421, 936],
  [417, 857],
  [418, 853],
  [403, 848],
  [151, 848],
  [173, 1139],
].map(([px, py]) => ({ px, py }));

const background = [
  [388, 839],
  [421, 1157],
  [416, 1158],
  [416, 1167],
  [171, 1139],
  [120, 837],
  [125, 832],
  [128, 827],
  [388, 838],
].map(([px, py]) => ({ px, py }));

const shadowDarker = [
  [182, 1132],
  [428, 1157],
  [402, 837],
  [396, 836],
  [395, 833],
  [137, 831],
  [182, 1132],
].map(([px, py]) => ({ px, py }));

const bgInner = [
  [167, 871],
  [201, 1102],
  [377, 1122],
  [348, 878],
  [167, 871],
].map(([px, py]) => ({ px, py }));

const frameBorder0 = [
  [129, 828],
  [127, 831],
  [177, 1130],
  [421, 1158],
  [388, 835],
  [129, 828],
].map(([px, py]) => ({ px, py }));

const frameBorder1 = [
  [121, 836],
  [169, 1135],
  [415, 1166],
  [379, 845],
  [121, 836],
].map(([px, py]) => ({ px, py }));

const frameBorder2 = [
  [148, 855],
  [187, 1117],
  [391, 1138],
  [362, 864],
  [148, 855],
].map(([px, py]) => ({ px, py }));

const frameBorder3 = [
  [156, 865],
  [194, 1110],
  [384, 1130],
  [354, 872],
  [156, 865],
].map(([px, py]) => ({ px, py }));

const frameBorder4 = [
  [160, 869],
  [198, 1106],
  [380, 1126],
  [350, 876],
  [160, 869],
].map(([px, py]) => ({ px, py }));

const frameLight = [
  [201, 1103],
  [169, 1135],
  [416, 1166],
  [379, 845],
  [362, 865],
  [391, 1138],
  [377, 1123],
].map(([px, py]) => ({ px, py }));

const frameLightTop = [
  [156, 866],
  [160, 869],
  [350, 876],
  [355, 872],
  [156, 866],
].map(([px, py]) => ({ px, py }));

const frameShadowTop = [
  [127, 834],
  [127, 839],
  [376, 849],
  [382, 843],
].map(([px, py]) => ({ px, py }));

const frameLightTopDetail = [
  [128, 829],
  [126, 833],
  [128, 841],
  [375, 849],
  [387, 836],
  [128, 829],
].map(([px, py]) => ({ px, py }));

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "clipped-valparaiso non-stk",

      backgroundColor: "rgba(4, 244, 4, 0.2)",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "clipped-valparaiso non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.4)",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
  // background: {
  //   points: background,
  //   configs: {
  //     class: "valparaiso",
  //     backgroundColor: "transparent",
  //     strokeWidth: "1",
  //     filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
  //   },
  // },
  frameBorder0: {
    points: frameBorder0,
    configs: {
      backgroundColor: "rgb(107 83 48)",
    },
  },
  frameLightTopDetail: {
    points: frameLightTopDetail,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
  },
  frameShadowTop: {
    points: frameShadowTop,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(71 57 37)",
    },
  },
  frameBorder1: {
    points: frameBorder1,
    configs: {
      backgroundColor: "rgb(161 120 59)",
    },
  },
  frameBorder2: {
    points: frameBorder2,
    configs: {
      backgroundColor: "rgb(125 93 46)",
    },
  },
  frameBorder3: {
    points: frameBorder3,
    configs: {
      backgroundColor: "rgb(161 120 59)",
    },
  },
  frameBorder4: {
    points: frameBorder4,
    configs: {
      backgroundColor: "rgb(107 80 39)",
    },
  },
  bgInner: {
    points: bgInner,
    configs: {
      class: "valparaiso",
      backgroundColor: "rgba(4, 244, 4, 0.4)",
      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
  frameLight: {
    points: frameLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
  },

  frameLightTop: {
    points: frameLightTop,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
  },
};
