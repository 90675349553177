export const IPAD_SCREEN = [
  [7, 853],
  [7, 1071],
  [12, 1074.5],
  [299, 1023],
  [301.5, 1018.5],
  [280, 825],
  [7, 853],
].map(([px, py]) => ({ px, py }));

export const IPAD_TOP_PART = [
  [0, 850],
  [0, 1080],
  [5.5, 1085],
  [51.25, 1078.5],
  [176.125, 1055.25],
  [301, 1032],
  [309, 1027],
  [311, 1016],
  [287, 823],
  [278, 817],
  [8, 845],
  [0, 850],
].map(([px, py]) => ({ px, py }));

export const IPAD_BOTTOM_PART = [
  [5.5, 1085],
  [0, 1092],
  [0, 1200],
  [60, 1200],
  [349, 1137],
  [356, 1134],
  [358, 1127],
  [356.5, 1122.5],
  [301, 1032],
  [176.125, 1055.25],
  [51.25, 1078.5],
  [5.5, 1085],
].map(([px, py]) => ({ px, py }));

export const APPLE_PENCIL = [
  [46, 831],
  [42, 836],
  [43, 838],
  [46, 839],
  [205, 825],
  [214, 821],
  [201, 818],
  [46, 831],
].map(([px, py]) => ({ px, py }));

export const IPAD_KEYBOARD = [
  [3, 1112],
  [1, 1167],
  [336, 1100],
  [309, 1055],
  [3, 1112],
].map(([px, py]) => ({ px, py }));

export const IPAD_TRACKPAD = [
  [115, 1148],
  [119, 1179],
  [253, 1152],
  [239, 1123],
  [115, 1148],
].map(([px, py]) => ({ px, py }));

const IPAD_CONFIG = {
  paddingLeft: 20,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 22,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 20,
      color: "white",
      heightLine: 22,
    },
  },
  backgroundColor: "black",
  p1: IPAD_SCREEN[0], // Top left corner
  p2: IPAD_SCREEN[1], // Bottom left corner
  p3: IPAD_SCREEN[IPAD_SCREEN.length - 2], // Top right corner
  p4: IPAD_SCREEN[IPAD_SCREEN.length - 3], // Bottom right corner
};

const DATA = {
  ipadTopPart: {
    points: IPAD_TOP_PART,
    configs: { backgroundColor: "#020202" },
  },
  applePencil: {
    points: APPLE_PENCIL,
    configs: { backgroundColor: "#fffffd" },
  },
  ipadBottomPart: {
    points: IPAD_BOTTOM_PART,
    configs: { backgroundColor: "#2d2d2d" },
  },
  ipad: {
    points: IPAD_SCREEN,
    configs: IPAD_CONFIG,
  },
  ipadKeyboard: {
    points: IPAD_KEYBOARD,
    configs: { backgroundColor: "#1b1b1b" },
  },
  ipadTrackpad: {
    points: IPAD_TRACKPAD,
    configs: { backgroundColor: "#161616" },
  },
};

export default DATA;
