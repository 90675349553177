import React, { useState, useEffect, useContext } from "react";
import { Conversation, AttachMessageType, ConvoHistory, ConversationData } from "./types";
import { RenderChatAction } from "./Actions";
import { showImageMesage, ChatConvoService } from "./helpers";
import { ThreeDotsLoading } from "./ThreeDotsLoading";
import { Icon } from "semantic-ui-react";
import { ChatMessage } from "./Message";
import * as Animations from "./animations";
import "./style.scss";
import { ChatAPI } from "api";
import { AppContext } from "AppContext";
import ChatImg from "assets/characters/me_creation.png";
import { SVGOnline } from "./SVGs";
import { useChatData } from "./useChatData";
import MyAnalytics from "services/Analytics";

type ChatContainerProps = {
  onClose: () => void;
  startAgain: () => void;
  opened: boolean;
};

type ConvoProps = {
  data: ConversationData;
  previousAnswers: ConvoHistory;
};

type ChatProps = ChatContainerProps & ConvoProps;

const EMPTY_CONVERSATION: ConversationData = {
  keyWord: "",
  messages: [],
  action: undefined,
};

const Chat: React.FC<ChatProps> = ({ data, previousAnswers, startAgain }) => {
  const [state, setState] = useContext(AppContext) as any;
  const { EMAIL_DETECTED_CONVERSATION } = useChatData();
  const [conversation, setConversation] = useState<Conversation>(EMPTY_CONVERSATION as any);
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const chatBody = React.createRef() as React.RefObject<HTMLInputElement>;

  const initChat = async () => {
    const userHAsChatBefore = previousAnswers.length >= 1;
    if (userHAsChatBefore) {
      await attachNewConversation(data, true);
      await Animations.reproduceLastConvo(data, previousAnswers, handleAnswer);
      setInitialized(true);
    } else {
      await attachNewConversation(data);
      setInitialized(true);
    }
  };

  useEffect(() => {
    initChat();
  }, []);

  const attachMessage = ({ text, position = "left", onClick }: AttachMessageType) => {
    setConversation((e) => ({ ...e, messages: [...e.messages, { text, position, onClick }], action: undefined }));
  };

  const attachNewConversation = async (conversation: ConversationData, asReproducingLast?: boolean) => {
    const { messages, action, keyWord } = conversation;

    await Animations.botWritting(messages, setShowThreeDots, attachMessage, asReproducingLast);
    setConversation((e) => ({ ...e, keyWord, action }));
  };

  const handleAnswer = async (
    msgToAdd: string,
    newConv: ConversationData,
    currentConven: ConversationData,
    asReproducingLast?: boolean
  ) => {
    const backToMessage = async () => {
      // await Animations.moveBotImgToMsg(target);
      setConversation(currentConven);
    };

    attachMessage({ text: msgToAdd, position: "right", onClick: backToMessage });

    const fallbackRedirectNewConversation = async () => {
      /**
       * In case the action is get the email, could be case user already entered it..
       * So this function assign the proper conversation branch to follow
       */

      if (asReproducingLast) return false;

      const isActionTypeEmail = newConv.action?.type === "input-email";
      if (!isActionTypeEmail) return false;

      const userEmailStored = state.user?.email;
      if (!userEmailStored) return false;

      if (newConv.action?.type === "input-email") {
        await attachNewConversation(
          EMAIL_DETECTED_CONVERSATION(userEmailStored, newConv, newConv.action.conversation),
          asReproducingLast
        );
      }
      return true;
    };

    const wasRedirected = await fallbackRedirectNewConversation();

    !wasRedirected && (await attachNewConversation(newConv, asReproducingLast));
  };

  useEffect(() => {
    chatBody.current && Animations.updateScrollToBottom(chatBody.current);
  }, [conversation, chatBody]);

  const { messages } = conversation;

  const callbackBackToPreview = () => {
    // const rightMessage = conversation.messages.reverse().find((e) => e.position === "right");
    // if (rightMessage && rightMessage.onClick) {
    //   console.log("GOOOOING TOOOO", rightMessage, data, previousAnswers);
    //   rightMessage.onClick();
    // }
    // setConversation(data);
    MyAnalytics.capture("clicked_chat_start_again", { from: conversation.keyWord });
    startAgain();
  };

  // console.log("conversation", conversation);

  return (
    <>
      {messages.map((msg, index) => (
        <ChatMessage key={`msg-${index}`} {...msg} showImage={showImageMesage(index, messages) && !showThreeDots} />
      ))}
      <RenderChatAction
        currentConversation={conversation}
        handleAnswer={handleAnswer}
        startAgain={callbackBackToPreview}
        showButtonBack={initialized && conversation.keyWord !== "welcome"}
      />
      {showThreeDots && <ThreeDotsLoading />}
    </>
  );
};

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const [convoData, setConvoData] = useState<ConvoProps | undefined>();

  //I have to change the convoData passing new CHAT_DATA if it changes

  const { CHAT_DATA } = useChatData();

  const fetchChatConvo = async () => {
    const answers = await ChatAPI.get();
    console.log("BACKEND HISTORY answers:", answers);
    const { chatData, lastConversationHistory } = new ChatConvoService(answers, CHAT_DATA);
    setConvoData({ data: chatData, previousAnswers: lastConversationHistory() });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchChatConvo();
    // setConvoData({ data: CHAT_DATA, previousAnswers: [] });
    // setLoading(false);
  }, [CHAT_DATA]);

  // useEffect(() => {
  //   console.log("CHAT_DATA", CHAT_DATA);
  //   if (convoData) {
  //     console.log("convoData", convoData);
  //   }
  // }, [CHAT_DATA]);

  const _startAgain = () => {
    setLoading(true);
    function myFunction() {
      setConvoData({ data: CHAT_DATA, previousAnswers: [] });
      setLoading(false);
    }
    const timeoutId = setTimeout(myFunction, 500);
  };
  // if (!convoData) return <></>;

  return (
    <div className={`bubble-chat__chat ${props.opened ? "opened" : "hidden"}`}>
      <div className="chat__header">
        {/* <FollowingTheCursor imgs={ARRAY_FINJAS_FOTOS} initAngleImge={-0.0625} width={"55px"} /> */}
        <div className="cursor_image">
          <img className="img-pdf" src={ChatImg} alt="" />
        </div>
        <span className="profile_description">
          <b>Ig.Ruiz</b>
          <br /> We can just chat, I´ll do my best..
        </span>
        <span className="wrapper-online">
          <SVGOnline />
          online
        </span>
        <span className="chat__header_close" onClick={() => props.onClose()}>
          <Icon disabled name="close" size="large" />
        </span>
      </div>

      {/* <div ref={chatBody} className="chat__body"> */}
      <div className="chat__body">
        {!loading && (
          <Chat
            {...props}
            data={(convoData as any).data}
            previousAnswers={(convoData as any).previousAnswers}
            startAgain={_startAgain}
          />
        )}
      </div>

      {/* <InputText currentConversation={conversation} attachMessage={attachMessage} setShowThreeDots={setShowThreeDots} /> */}
    </div>
  );
};

export default ChatContainer;
