import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Logo, LogoLoading, SwapArrow } from "components/Logo";
import { useLanguage } from "services/translate";
import { ImpSections } from "layout/instagram/post/services/sections";
import Img from "assets/characters/me_creation.png";

type Props = { renderValue: number }; // number 0 - 1.

const InstagramStory: React.FC<Props> = ({ renderValue }) => {
  const { language } = useLanguage();
  const [clickIt, setClickIt] = useState(false);
  // const [init, setInit] = useState(false);

  const goToFirstSection = React.useCallback(async () => {
    const SectionsServ = new ImpSections([]);
    await SectionsServ.runLoadingSection();
    setClickIt(false);
  }, []);

  // const callbackInit = React.useCallback(async () => {
  //   setInit(false);
  // }, []);

  useEffect(() => {
    renderValue === 1 && setClickIt(true);
    // renderValue === 0 && setInit(true);
  }, [renderValue]);

  return (
    <>
      <svg
        id="ws__svg__ignacio-front"
        className="ignacio-front"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox={"0 0 900 1200"}
      >
        <image className="character-intro" href={Img} />
      </svg>
      <Logo
        width={500}
        renderValue={renderValue}
        onClick={goToFirstSection}
        clickIt={clickIt}
        // init={init}
        // callbackInit={callbackInit}
      />
      <LogoLoading className="inner-logo" renderValue={renderValue} lg={language} />
      <SwapArrow className="swap-triangle" onClick={goToFirstSection} />
    </>
  );
};

export default InstagramStory;
