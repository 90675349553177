import React, { useState, useEffect } from "react";
import * as Helpers from "./helpers";
import { useLogo } from "./useLogo";
import "./styles.scss";
import { sleep } from "utils";

type Props = {
  className?: string;
  onCLick?: () => void;
  renderValue?: number; // for the animation, value between 0-1 where 1 is full viewed.
  lg: string;
};

const CONFIGS = {
  width: 300, //180, //500
};

// const heightInnerlogo = (2 * CONFIGS.width) / (2 * Math.sqrt(3));
const heightInnerlogo = CONFIGS.width * 1.3;

const getWord = (lg: string) => Helpers.loadingPoints(CONFIGS.width, lg === "es" ? "cargando" : "loading");
const isO = (index: number, lg: string) => {
  if (lg === "es") {
    return index === 6;
  } else {
    return 1;
  }
};

export const LogoLoading: React.FC<Props> = ({ className = "", onCLick = () => {}, renderValue = 1, lg }) => {
  const [elems, setElems] = useState(Helpers.innerSquarePoints(CONFIGS.width));
  const [loadingElements, setLoadingElements] = useState(getWord(lg));
  const [indexRender, setIndexRender] = useState(1);
  const [elementRendered, setElementRendered] = useState(loadingElements[1]);

  useEffect(() => {
    if (renderValue == 1) {
      setIndexRender(loadingElements.length - 1);
      setElementRendered(loadingElements[loadingElements.length - 1]);
      return;
    }
    let render = renderValue % 1;

    const numSteps = loadingElements.length - 1;
    const currentIndex = Math.round(numSteps * render);
    setIndexRender(currentIndex === 0 ? currentIndex + 1 : currentIndex);
    // setElementRendered(loadingElements[currentIndex === 0 ? currentIndex + 1 : currentIndex]);
    setElementRendered(lg === "es" ? loadingElements[7] : loadingElements[1]);
  }, [renderValue]);

  useEffect(() => {
    setLoadingElements(getWord(lg));
  }, [lg]);

  return (
    <svg
      className={["loading-letters"].join(" ")}
      width={Math.floor(CONFIGS.width)}
      viewBox={`0 0 ${CONFIGS.width * 6} ${CONFIGS.width * 1.2}`}
    >
      <g
        className={"inner-logo"}
        style={{
          transform: `translate(${elementRendered.translations.x}px, ${elementRendered.translations.y}px) rotate(${
            elementRendered.translations.rotation + 360
          }deg)`,
        }}
      >
        {elems.map(({ className, points }, i) => {
          // const isG: boolean = indexRender === 6;
          // const isDot: boolean = [7, 8, 9].includes(indexRender);

          // let pointsToUse = points;
          let pointsToUse = Helpers.scalate(0.5, points);

          // if (!isO) {
          //   pointsToUse = Helpers.scalate(0.5, elems[1].points);
          // }

          // if (isO(indexRender, lg)) {
          //   pointsToUse = Helpers.scalate(0.5, points);
          // }
          // else if (isG) {
          //   // pointsToUse = Helpers.scalate(1.5, points);
          // } else if (isDot) {
          //   // pointsToUse = Helpers.scalate(0.2, points);
          // }
          return (
            <polyline
              key={className + " element " + i}
              className={className}
              points={pointsToUse.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
            />
          );
        })}
      </g>

      {loadingElements.map(({ className, points, translations }, i) => {
        const isDot: boolean = className === "loading dot";
        let pointsToUse = points;
        const { x, y, rotation } = translations;
        const transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`;

        return (
          <g key={className + i + "g"} className="loading__letter">
            <polyline
              key={className + i + "pl"}
              className={[className, i === indexRender ? "active" : ""].join(" ")}
              points={pointsToUse.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
              style={{ transform }}
            />
            {!isDot &&
              pointsToUse.map(([px, py], j) => {
                return (
                  <circle
                    key={[className, "cid", j].join("-")}
                    className="inner dot "
                    r="1px"
                    cx={px}
                    cy={py}
                    style={{ transform }}
                  ></circle>
                );
              })}
            <polyline
              key={className + i}
              className={[className, "inner", i === indexRender ? "active" : ""].join(" ")}
              points={pointsToUse.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
              style={{ transform }}
            />
            {!isDot &&
              pointsToUse.map(([px, py], j) => {
                return (
                  <circle
                    key={[className, "circle-inner ", j].join("-")}
                    className="inner"
                    r="1px"
                    cx={px}
                    cy={py}
                    style={{ transform }}
                  ></circle>
                );
              })}
          </g>
        );
      })}
    </svg>
  );
};
