const LEATHER = [
  [0, 847],
  [0, 1200],
  [900, 1200],
  [900, 877],
].map(([px, py]) => ({ px, py }));

export default {
  leather: {
    points: LEATHER,
    configs: { backgroundColor: "#532413", filter: "drop-shadow(-3px -69px 0 rgba(255,255,255,0.05))  blur(1px)" },
  },
};
