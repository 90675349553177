import React from "react";
import LayoutClient from "./client";
import LayoutInstagram from "./instagram";
import LayoutBooks from "./books";
import { useLocation } from "react-router-dom";
import { isItInstagramRoute, isItClaudeRoute } from "routes";
import LayoutAdmin from "./admin";

const Layout = () => {
  const { pathname } = useLocation();
  // return <LayoutAdmin />;
  // if (isItAdminRoute(pathname)) return <LayoutAdmin />;
  // return <LayoutBooks />;
  if (isItInstagramRoute(pathname)) return <LayoutInstagram />;
  if (isItClaudeRoute(pathname)) return <LayoutClient story="claude" />;
  return <LayoutClient story="portfolio" />;
};

export default Layout;
