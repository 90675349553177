import { api } from "./configs/axiosConfigs";
import { CHAT_API_FXS } from "./Fixtures";
import * as Types from "./types";

export const ChatAPI = {
  get: async () => {
    const response: Types.RequestBotChatAnswers = await api.get("/chat");
    return response.data;
  },
  answerConversation: async (conversationId: string, answerType: Types.ConversationAnswer["type"], value: string) => {
    const params = {
      [conversationId]: {
        type: answerType,
        value: value,
      },
    };
    await api.post("/chat/answer", { ...params });
  },
  contactForm: async (email: string, content: string) => {
    const params = {
      email: email,
      content: content,
    };
    await api.post("/chat/contact-form", { ...params });
  },
  askQuestion: (question: string, currentConvoOLD: Types.ConversationType): Promise<Types.PayloadAskQuestion> => {
    const currentConvo = CHAT_API_FXS.COLABORATION_THREAD;
    const content =
      "You are Ignacio software engineer, and you are showing him your Portafolio. Regarding to collaborations, you have participate in successfully startups as a principal engineer. Answer shortly";

    const systemMenssage = {
      role: "system" as const,
      content: content,
    };

    currentConvo.unshift(systemMenssage);

    const chatHistory = currentConvo;

    const params = {
      text: question,
      inputData: {
        chatHistory: chatHistory,
      },
    };

    return api.post("/chat/question", { ...params });
  },
  askAQuestionNew: (question: string): Promise<Types.PayloadAskQuestion> => {
    // return ChatAPI.askQuestion(question, []);
    return api.post("/chat/questionNew", { text: question });
  },
};
