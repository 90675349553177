export const COMPUTER = [
  [492, 837],
  [488, 1023],
  [793, 1048],
  [783, 849],
  [643.5, 843],
  [642, 844],
  [639, 845],
  [623, 844],
  [620, 843],
  [619, 842],
  [492, 837],
].map(([px, py]) => ({ px, py }));

export const COMPLETE_TOP = [
  [492, 825],
  [485, 830],
  [479, 1029],
  [480, 1037],
  [800, 1066],
  [802, 1058],
  [802, 1051],
  [792, 843],
  [788, 836.5],
  [783, 834],
  [492, 825],
].map(([px, py]) => ({ px, py }));

export const COMPLETE_BOTTOM = [
  [480, 1037],
  [476, 1041.5],
  [430, 1146],
  [433, 1155],
  [439.5, 1158],
  [635.75, 1179.5],
  [832, 1197],
  [837, 1195],
  [839.5, 1190],
  [840, 1185],
  [839, 1181],
  [804, 1068],
  [800, 1066],
  [480, 1037],
].map(([px, py]) => ({ px, py }));

export const KEYBOARD = [
  [488, 1044],
  [469, 1096],
  [807, 1128],
  [791, 1071],
  [488, 1044],
].map(([px, py]) => ({ px, py }));

export const TRACKPAD = [
  [550, 1107],
  [538, 1157],
  [719, 1176],
  [714, 1123],
  [550, 1107],
].map(([px, py]) => ({ px, py }));

export const DETAIL_BOTTOM = [
  [430, 1146],
  [433, 1155],
  [439.5, 1158],
  [635.75, 1179.5],
  [832, 1197],
  [837, 1195],
  [839.5, 1190],
  [840, 1185],
  [837, 1187],
  [834, 1189],
  [818, 1189],
  [658, 1174],
  [657, 1172],
  [596, 1166],
  [593, 1166],
  [592, 1167],
  [592, 1167],
  [439, 1150],
  [433, 1148],
  [430, 1146],
].map(([px, py]) => ({ px, py }));

export const DETAIL_BOTTOM_OPENER = [
  [592, 1167],
  [592, 1171],
  [597, 1173],
  [604, 1174],
  [656, 1179],
  [659, 1177],
  [659, 1174],
  [657, 1172],
  [596, 1166],
  [593, 1166],
].map(([px, py]) => ({ px, py }));

export const DETAIL_TOP_CAMARA = [
  [623, 830],
  [621, 831],
  [618, 834],
  [617, 837],
  [617, 839],
  [618, 841],
  [619, 842],
  [620, 843],
  [623, 844],
  [639, 845],
  [642, 844],
  [643.5, 843],
  [645, 842],
  [647, 839],
  [647, 836],
  [645, 833],
  [641, 831],
  [623, 830],
].map(([px, py]) => ({ px, py }));

const COMPUTER_CONFIG = {
  paddingLeft: 10,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 22,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 16,
      color: "white",
      heightLine: 20,
    },
  },
  backgroundColor: "black",
  filter: "filter: drop-shadow(0px 1px 0px rgba(255,255,255,.4))",
  p1: COMPUTER[0], // Top left corner
  p2: COMPUTER[1], // Bottom left corner
  p3: COMPUTER[3], // Top right corner
  p4: COMPUTER[2], // Bottom right corner
};

const DATA = {
  computerCompleteTop: {
    points: COMPLETE_TOP,
    configs: { backgroundColor: "rgb(48 48 48)" },
  },
  computerCompleteBottom: {
    points: COMPLETE_BOTTOM,
    configs: { backgroundColor: "rgb(107 97 94)" },
  },
  computerKeyboard: {
    points: KEYBOARD,
    configs: { backgroundColor: "rgb(2 1 10)" },
  },
  computerTrackpad: {
    points: TRACKPAD,
    configs: { backgroundColor: "rgb(84 73 76)" },
  },
  computerDetailBottom: {
    points: DETAIL_BOTTOM,
    configs: { backgroundColor: "rgb(121 111 108)" },
  },
  computerDetailOpener: {
    points: DETAIL_BOTTOM_OPENER,
    configs: { backgroundColor: "#a49c97" },
  },
  computer: {
    points: COMPUTER,
    configs: COMPUTER_CONFIG,
    // childElements: {
    //   monitor: {
    //     points: COMPUTER,
    //   },
    // },
  },
  computerDetailCamara: {
    points: DETAIL_TOP_CAMARA,
    configs: { backgroundColor: "rgb(69 69 69)" },
  },
};

export default DATA;
