import * as d3 from "d3";
import BackgroundClient from "services/BackgroundClient";

const substitleConfigs = {
  reel: {
    paddingLeft: 20,
    paddingTop: 1,
    fonts: {
      h1: {
        characterPerLine: 20, //26, //44,
        fontSize: 14,
        heightLine: 38, //45 // 80, //40
      },
      p: {
        characterPerLine: 38,
        fontSize: 17,
        heightLine: 22,
      },
    },
  },
  post: {
    paddingLeft: 20,
    paddingTop: 1,
    fonts: {
      h1: {
        characterPerLine: 20, //26, //44,
        fontSize: 20,
        heightLine: 38, //45 // 80, //40
      },
      p: {
        characterPerLine: 40,
        fontSize: 22,
        heightLine: 32,
      },
    },
  },
  desktop: {
    paddingLeft: 20,
    paddingTop: 1,
    fonts: {
      h1: {
        characterPerLine: 20, //26, //44,
        fontSize: 20,
        heightLine: 38, //45 // 80, //40
      },
      p: {
        characterPerLine: 40,
        fontSize: 22,
        heightLine: 32,
      },
    },
  },
};

type TData = {
  textElements: string | number | symbol;
  objectsDefined: string | number | symbol;
  svgObjects: string | number | symbol;
  // svgObjects: {
  //   viewer: {
  //     border: {
  //       points: { px: number; py: number }[];
  //       configs: { backgroundColor: string; strokeWidth: string | number };
  //     };
  //     display: {
  //       points: { px: number; py: number }[];
  //       configs: { backgroundColor: string; strokeWidth: string | number };
  //     };
  //   };
  // };
};

type TRenderTextText = { header: string[] | undefined; paragraph: string[] | undefined };

interface ISubtitle {
  init: () => void; // it builds the subtitle element, turning it on, ready to be used and consume.
  terminate: () => void; // it removes the svg Subtitle element.
  renderText: (text: TRenderTextText, renderValue: number) => void;
}

type BgClient = BackgroundClient<TData["objectsDefined"], TData["svgObjects"], TData["textElements"]>;

class Subtitle implements ISubtitle {
  static idSvg: string = "svg-subtitle";
  private svg: any;
  public bgClient: BgClient;

  constructor() {
    this._setHtmlSvg();
    const data = this._buildData();
    this.bgClient = new BackgroundClient(data as any, Subtitle.idSvg);
  }

  public init = () => {
    this._build();
  };

  public renderText = (text: TRenderTextText, renderValue: number) => {
    this.bgClient.textElements.display.renderTextRefactored(text, renderValue);
  };

  public terminate = () => {
    this.svg.remove();
  };

  private _build = () => {
    // this.svg.append("rect").attr("class", "outter").attr("x", 0).attr("y", 0);
    // this.svg.append("rect").attr("class", "inner").attr("x", 5).attr("y", 5);
    this.bgClient.useElements(["viewer"], []);
    // this.bgClient.toComic();
  };

  private _buildData = () => {
    const [xo, yo] = [0, 0];
    const bW = 10; // BorderWidth: px.
    const vH = 100; // ViewerHeight: px.
    // const vW = 300; // ViewerWidth: px.
    const vW = this.svg.node().getBoundingClientRect().width; // ViewerHeight: px.

    const [p1, p2, p3, p4] = [
      [xo, yo],
      [xo, yo + vH],
      [xo + vW, yo + vH],
      [xo + vW, yo],
    ];
    const [p5, p6, p7, p8] = [
      [p1[0] + bW, p1[1] + bW],
      [p2[0] + bW, p2[1] - bW],
      [p3[0] - bW, p3[1] - bW],
      [p4[0] - bW, p4[1] + bW],
    ];

    const getSubtitleConfigBasedOnWidth = (renderedOn: "reel" | "post" | "desktop") => {
      switch (renderedOn) {
        case "post":
          break;

        default:
          break;
      }
    };

    const display = {
      points: [p5, p6, p7, p8].map(([px, py]) => ({ px, py })),
      configs: {
        ...substitleConfigs.reel,
        p1: { px: p5[0], py: p5[1] },
        p2: { px: p6[0], py: p6[1] },
        p3: { px: p8[0], py: p8[1] },
        p4: { px: p7[0], py: p7[1] },
      },
    };

    const DATA = {
      textElements: { display },
      objectsDefined: {},
      svgObjects: {
        viewer: {
          border: {
            points: [p1, p2, p3, p4].map(([px, py]) => ({ px, py })),
            configs: { backgroundColor: "#3f3532" },
          },
          display: display,
        },
      },
    };

    return DATA;
  };

  private _setHtmlSvg = () => {
    this.svg = d3
      .select(".welcome-section")
      .append("svg")
      .attr("id", Subtitle.idSvg)
      .attr("class", Subtitle.idSvg)
      .attr("width", "100%")
      .attr("height", 100);
  };
}

export default Subtitle;
