import Img from "assets/characters/mirando_welcome_reduced.png";

import caraData from "./cara";
import bocaData from "./boca";
import camisaData from "./camisa";
import lentesData from "./lentes";
import ojosData from "./ojos";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */

const TITLE_CONFIG = {
  paddingLeft: 30,
  paddingTop: 10,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 62,
      color: "#f5c51f",
      heightLine: 80, // 80, //40
    },
    p: {
      characterPerLine: 25,
      fontSize: 32,
      color: "white",
      heightLine: 62,
    },
  },
  backgroundColor: "#311408",
  // p1: TITULO[0], // Top left corner
  // p2: TITULO[1], // Bottom left corner
  // p3: TITULO[TITULO.length - 2], // Top right corner
  // p4: TITULO[TITULO.length - 3], // Bottom right corner
};

const OBJECTS_DEFINED = {
  ...caraData,
  ...camisaData,
  ...ojosData,
  ...lentesData,
};

// const {
//   monitorMain,
//   monitorTabGoogle,
//   monitorTabMiro,
//   monitorTabNotion,
//   monitor,
//   title,
//   overComputer,
//   titleWelcome,
// } = OBJECTS_DEFINED;

const DATA = {
  image: Img,
  textElements: {},
  objectsDefined: OBJECTS_DEFINED,
  svgObjects: {
    camisa: {
      ...camisaData,
    },
    cara: {
      ...caraData,
    },
    boca: {
      ...bocaData,
    },
    lentes: {
      ...lentesData,
    },
    ojos: {
      ...ojosData,
    },
  },
};

export default DATA;
