import React, { useEffect } from "react";
import "./styles.scss";
import { Logo, LogoLoading, SwapArrow } from "components/Logo";
import { sleep } from "utils";
import { useLanguage } from "services/translate";
import { ImpSections } from "layout/client/LayoutScroll/services/sections";

type Props = { renderValue: number }; // number 0 - 1.

const goToFirstSection = async () => {
  const SectionsServ = new ImpSections([]);
  await SectionsServ.runLoadingSection();
};

const LoadingSection: React.FC<Props> = ({ renderValue }) => {
  const { language } = useLanguage();
  return (
    <>
      <Logo width={500} renderValue={renderValue} onClick={goToFirstSection} />
      <LogoLoading className="inner-logo" renderValue={renderValue} lg={language} />
      <SwapArrow className="swap-triangle" onClick={goToFirstSection} />
    </>
  );
};

export default LoadingSection;
