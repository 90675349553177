import * as Helpers from "../helpers";
import * as d3 from "d3";
import { sleep } from "utils";

class AnimateLogo {
  readonly svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private data: ReturnType<typeof Helpers.logoPoints>;
  // private edges: ReturnType<typeof Helpers.edges>;
  constructor(svg: SVGSVGElement, private width: number) {
    this.svg = d3.select(svg);
    this.data = Helpers.logoPoints(width);
  }

  public init = () => {
    const polylineGroup = this.svg
      .append("g")
      .attr("style", `transform: translate(${this.width / 2}px, ${this.width / Math.sqrt(3)}px)`);

    // const tDelta = 100; //100; // 200;
    const tDelta = 100; //100; // 200;
    const tDurationLines = 100; //400;
    const tDelayFill = (this.data.length - 1) * tDelta + tDurationLines;
    // const tDelayShadow = tDelayFill + tDurationFill;
    const tDelayShadow = 0;

    const polylines = polylineGroup
      .selectAll("polyline")
      .data(this.data)
      .enter()
      .append("polyline")
      .attr("fill", "transparent")
      .attr("points", ({ points }) => points.join(" "))
      .attr("stroke-width", 0.3);

    polylines
      .transition()
      .duration(tDurationLines)
      .delay((d, i) => i * tDelta)
      .ease(d3.easeLinear)
      .attrTween("stroke-dasharray", function () {
        const length = (this as any).getTotalLength();
        return d3.interpolate(`0,${length}`, `${length},${length}`);
      })
      .attr("stroke-width", (d, i) => (i < 3 ? 0 : 2))
      .attr("stroke", "saddlebrown");

    polylines
      .transition()
      .delay(tDelayFill)
      .attr("class", (d) => d.className);

    this.svg
      .transition()
      .delay(tDelayShadow)
      .on("start", function () {
        d3.select(this).classed("shadow", true); // Add the class after the delay
      });
  };

  public play = async () => {
    await this.shake();
    this.svg.classed("played", true);
  };

  public stop = async () => {
    this.svg.classed("played", false);
  };

  private shakeAbit = () => {
    this.data = Helpers.logoPoints(180, true);
    this.svg
      .selectAll("polyline")
      .data(this.data)
      .transition()
      .duration(100)
      .attr("points", ({ points }) => points.join(" "));
  };

  public rotate = () => {
    this.svg.classed("rotate-90", true);
  };

  private shake = async () => {
    const deltaT = 100;
    const amountShakes = 10;
    const durationEachShake = 300;

    for (let index = 0; index < amountShakes; index++) {
      this.data = Helpers.logoPoints(0.8 * this.width, true);
      this.svg
        .selectAll("polyline")
        .data(this.data)
        .transition()
        .duration(durationEachShake)
        .attr("points", ({ points }) => points.join(" "));
      await sleep(deltaT);
    }

    this.data = Helpers.logoPoints(this.width, false);
    this.svg
      .selectAll("polyline")
      .data(this.data)
      .transition()
      .duration(durationEachShake)
      .attr("points", ({ points }) => points.join(" "));
  };
}

export default AnimateLogo;
