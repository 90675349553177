import React from "react";
// import { ARRAY_FINJAS_FOTOS } from "../../../assets/finja_fotos";
import { MessageCorner } from "./Message/SVGs";

export const ThreeDotsLoading: React.FC = () => {
  return (
    <div className={`chat__msg-wrapper left`}>
      <MessageCorner />
      {/* <img className="msg-img" alt="img-chat-bot" src={ARRAY_FINJAS_FOTOS[0]} /> */}
      <div className={`chat__msg left`} style={{ width: "66px", height: "40px" }}>
        <div className="dot-typing" style={{ marginLeft: "10px", marginTop: "10px" }} />
      </div>
    </div>
  );
};
