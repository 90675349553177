import Foto11 from "assets/stories/claude/character.png";
import Foto12 from "assets/stories/claude/back.png";
import Foto13 from "assets/stories/claude/diagram.png";
import Foto14 from "assets/stories/claude/table.png";
import Foto15 from "assets/stories/claude/vision.png";
import Foto16 from "assets/stories/claude/idea.png";
import Foto17 from "assets/stories/claude/idea_template.png";
import Foto18 from "assets/stories/claude/clap.png";

export default {
  ["claude_character"]: Foto11,
  ["claude_back"]: Foto12,
  ["claude_diagram"]: Foto13,
  ["claude_table"]: Foto14,
  ["claude_vision"]: Foto15,
  ["claude_idea"]: Foto16,
  ["claude_idea_template"]: Foto17,
  ["claude_clap"]: Foto18,
};
