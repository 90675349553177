const PUZZLE = [
  [440, 166],
  [442, 402],
  [469, 399.5],
  [470.5, 396.25],
  [511.25, 393.625],
  [513.125, 396.8125],
  [713, 381],
  [713, 402],
  [529, 415.5],
  [529, 420.25],
  [839, 396],
  [839, 375],
  [836, 129],
  [441, 167],
].map(([px, py]) => ({ px, py }));

const PUZZLE_CONFIGS = {
  paddingLeft: 30,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 20,
      fontSize: 36,
      color: "black",
      heightLine: 42,
    },
  },
  backgroundColor: "#fce9c1",
  p1: PUZZLE[0], // Top left corner
  p2: PUZZLE[1], // Bottom left corner
  p3: PUZZLE[PUZZLE.length - 2], // Top right corner
  p4: PUZZLE[PUZZLE.length - 3], // Top right corner
};

const DATA = {
  puzzle: {
    points: PUZZLE,
    configs: PUZZLE_CONFIGS,
  },
};

export default DATA;
