import React from "react";
import "./styles.scss";

type Props = { renderValue: number; width: number | string }; // number 0 - 100.

const padding = 40;
const AnimationProgressBar: React.FC<Props> = ({ renderValue, width }) => {
  if (renderValue === 0) return <></>;
  return (
    <div className="animation-progress-bar" style={{ width: Number(width) - padding }}>
      <div className="animation-progress" style={{ width: `${renderValue * 100}%` }} />
    </div>
  );
};

export default AnimationProgressBar;
