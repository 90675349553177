import * as Helpers from "../helpers";

const TRAMO_CAMISA_IZQ_CUELLO_OUT = [
  [405, 710],
  [388, 688],
  [390, 689],
  [378, 703],
  [363, 712],
  [351, 720],
  [341, 723],
  [303, 737],
  [279, 744],
  [242, 749],
  [217, 757],
  [177, 772],
  [154, 784],
  [138, 801],
  [120, 812],
  [110, 826],
  [91, 837],
  [70, 857],
  [58, 867],
  [39, 886],
  [28, 898],
  [21, 911],
  [16, 917],
  [23, 967],
  [29, 980],
  [35, 987],
];
export const TRAMO_CAMISA_IZQ_CUELLO = [
  [523, 987], //
  [511, 957],
  [505.5, 944.5],
  [498, 934],
  [475.5, 913],
  [453, 896],
  [440.5, 888.5],
  [428, 883],
  [400, 836],
  [385, 790],
  [381.5, 772],
  [382, 750],
  [382, 743],
  [387, 731.5],
  [388, 723],
  [391, 719.5],
  [394, 718],
  [398, 716],
  [406, 715], //
];

const CUELLO_IZQ = [
  [387, 682],
  [376, 702],
  [366, 729],
  [362, 764],
  [365, 804],
  [364, 836],
  [364, 855],
  [370, 864],
  [377, 869],
  [380, 873],
  [381, 885],
  [386, 896],
  [388, 900],
  [394, 893],
  [397, 888],
  [399, 882],
  [395, 877],
  [389, 874],
  [379, 867],
  [371, 856],
  [370, 851],
  [373, 845],
  [381, 845],
  [386, 847],
  [389, 860],
  [395, 870],
  [406, 876],
  [415, 888],
  [421, 896],
  [425, 900],
  [427, 905],
  [442, 906],
  [449, 904],
  [455, 904],
  [463, 910],
  [473, 919],
  [478, 923],
  [486, 931],
  [493, 936],
  [498, 942],
  [503, 949],
  [509, 966],
  [516, 982],
  [518, 987],
  ...TRAMO_CAMISA_IZQ_CUELLO,
].map(([px, py]) => ({ px, py }));

const CAMISA_IZQUIERDA = [...TRAMO_CAMISA_IZQ_CUELLO, ...TRAMO_CAMISA_IZQ_CUELLO_OUT].map(([px, py]) => ({ px, py }));

export const TRAMO_CUELLO_CAMISA_DER = [
  [628, 711], //
  [629.5, 714.25],
  [631, 759.5],
  [633, 780],
  [632, 808],
  [627.5, 826.5],
  [624.875, 840.125],
  [622.25, 847.75],
  [621, 862],
  [615, 880],
  [601.75, 900.25],
  [593.5, 916.5],
  [577, 951],
  [560, 987], //
];

const CUELLO_DER = [
  ...TRAMO_CUELLO_CAMISA_DER,
  [594, 986],
  [603, 979],
  [612, 969],
  [620, 962],
  [626, 947],
  [630, 924],
  [631, 910],
  [636, 900],
  [651, 889],
  [654, 885],
  [666, 878],
  [673, 869],
  [679, 859],
  [678, 850],
  [676, 845],
  [680, 841],
  [684, 833],
  [682, 825],
  [679, 810],
  [669, 779],
  [659, 753],
  [648, 725],
  [642, 714],
  [633, 703],
].map(([px, py]) => ({ px, py }));

const CAMISA_DERECHA = [
  [637, 700],
  [633, 704],
  ...TRAMO_CUELLO_CAMISA_DER,
  [589, 986],
  [888, 987],
  [890, 956],
  [891, 948],
  [890, 923],
  [897, 908],
  [895, 901],
  [891, 894],
  [890, 859],
  [883, 848],
  [869, 816],
  [852, 777.75],
  [826.5, 753.625],
  [809.75, 743.5625],
  [793, 735.5],
  [774.5, 728.875],
  [750, 722.25],
  [719, 715],
  [702.5, 709.5],
  [686, 705],
  [663.5, 704],
  [643, 700],
  [640, 699],
  [637, 700],
].map(([px, py]) => ({ px, py }));

const SHADOW_LEFT_CUELLO = [
  [400, 687],
  [389, 694],
  [383, 703],
  [378, 719],
  [376, 739],
  [376, 764],
  [375, 789],
  [379, 815],
  [383, 828],
  [389, 836],
  [396, 846],
  [395, 855],
  [399, 864],
  [405, 872],
  [416, 882],
  [420, 886],
  [424, 890],
  [428, 898],
  [433, 903],
  [440, 901],
  [448, 900],
  [455, 903],
  [459, 905],
  [463, 903],
  [446, 882],
  [430, 881],
  [426, 883],
  [419, 880],
  [411, 873],
  [406, 866],
  [403, 851],
  [403, 846],
  [400, 843],
  [395, 834],
  [392, 819],
  [387, 799],
  [383, 786],
  [382, 774],
  [380, 754],
  [383, 740],
  [389, 723],
  [391, 717],
  [397, 717],
  [403, 714],
  [408, 711],
].map(([px, py]) => ({ px, py }));

const SHADOW_DETAIL_CUELLO_LEFT2 = [
  [383, 845],
  [378, 845],
  [372, 846],
  [371, 851],
  [375, 856],
  [382, 860],
  [386, 867],
  [393, 874],
  [400, 883],
  [399, 889],
  [395, 897],
  [409, 895],
  [416, 900],
  [421, 902],
  [424, 903],
  [422, 895],
  [415, 888],
  [408, 882],
  [399, 875],
  [392, 868],
  [388, 861],
  [387, 856],
  [386, 852],
].map(([px, py]) => ({ px, py }));

const BORDE_BUTTON_LEFT = [
  [432, 906],
  [440, 916],
  [452, 929],
  [458, 943],
  [467, 967],
  [472, 982],
  [473, 985],
  [519, 986],
  [514, 975],
  [509, 961],
  [504, 953],
  [501, 946],
  [494, 940],
  [489, 934],
  [484, 929],
  [477, 922],
  [471, 916],
  [462, 910],
  [455, 907],
  [449, 904],
  [436, 905],
].map(([px, py]) => ({ px, py }));

const BUTTON = [
  [458, 926],
  [457, 930],
  [459, 935],
  [462, 939],
  [466, 942],
  [472, 942],
  [476, 942],
  [480, 939],
  [481, 936],
  [481, 932],
  [476, 928],
  [470, 926],
  [464, 925],
  [460, 925],
].map(([px, py]) => ({ px, py }));

const BUTTON_COSTURE = [
  [469, 933],
  [469, 934],
  [466, 934],
  [466, 937],
  [468, 937],
  [469, 940],
  [471, 940],
  [472, 938],
  [474, 938],
  [474, 936],
  [471, 935],
  [471, 933],
].map(([px, py]) => ({ px, py }));

const BUTTON_LIGHT = [
  [459, 926],
  [460, 930],
  [463, 929],
  [468, 930],
  [472, 931],
  [476, 933],
  [477, 936],
  [479, 934],
  [478, 931],
  [472, 928],
  [466, 927],
  [461, 926],
].map(([px, py]) => ({ px, py }));

const TSHIRT_LINES = [
  [153, 784],
  [145, 801],
  [143, 818],
  [143, 838],
  [148, 868],
  [154, 896],
  [165, 930],
  [173, 948],
  [177, 966],
  [177, 978],
  [177, 984],
  [195, 986],
  [193, 963],
  [186, 940],
  [177, 918],
  [172, 905],
  [167, 877],
  [163, 854],
  [161, 833],
  [161, 824],
  [161, 818],
  [176, 807],
  [187, 800],
  [200, 798],
  [211, 801],
  [221, 798],
  [232, 795],
  [239, 790],
  [248, 786],
  [256, 785],
  [260, 780],
  [266, 777],
  [275, 774],
  [283, 772],
  [288, 773],
  [294, 770],
  [306, 764],
  [314, 758],
  [323, 752],
  [330, 747],
  [337, 744],
  [343, 744],
  [353, 744],
  [362, 744],
  [364, 734],
  [355, 733],
  [344, 736],
  [336, 736],
  [326, 741],
  [319, 743],
  [305, 748],
  [294, 757],
  [283, 760],
  [268, 765],
  [255, 771],
  [243, 775],
  [235, 780],
  [222, 784],
  [208, 786],
  [201, 786],
  [191, 786],
  [187, 788],
  [178, 792],
  [169, 796],
  [163, 799],
  [166, 790],
  [168, 784],
  [169, 778],
  [161, 779],
  [155, 781],
].map(([px, py]) => ({ px, py }));

const TSHIRT_LINES_RIGHT = [
  [638, 706.5],
  [645, 722.5],
  [648, 726.5],
  [654, 732.5],
  [658, 731.5],
  [666, 728.5],
  [674, 728.5],
  [689, 735.5],
  [711, 744.5],
  [727, 751.5],
  [747, 758.5],
  [765, 763.5],
  [788, 768.5],
  [804, 772.5],
  [819, 774.5],
  [824, 779.5],
  [829, 796.5],
  [834, 837.5],
  [834, 856.5],
  [829, 878.5],
  [821, 891.5],
  [811, 908.5],
  [804, 922.5],
  [803, 951.5],
  [803, 962.5],
  [803, 967.5],
  [802, 983.5],
  [815, 986.5],
  [815, 971.5],
  [815, 951.5],
  [816, 938.5],
  [818, 924.5],
  [821, 909.5],
  [830, 894.5],
  [838, 879.5],
  [841, 861.5],
  [844, 840.5],
  [842, 819.5],
  [840, 801.5],
  [836, 782.5],
  [835, 771.5],
  [831, 764.5],
  [829, 758.5],
  [827, 755.5],
  [821, 752.5],
  [817, 750.5],
  [822, 766.5],
  [811, 762.5],
  [799, 761.5],
  [785, 756.5],
  [772, 753.5],
  [764, 750.5],
  [751, 749.5],
  [740, 745.5],
  [734, 740.5],
  [720, 735.5],
  [700, 726.5],
  [690, 724.5],
  [675, 721.5],
  [668, 720.5],
  [662, 717.5],
  [654, 715.5],
  [647, 711.5],
  [644, 709.5],
].map(([px, py]) => ({ px, py }));

const SHADOW_TSHIRT_RIGHT = [
  [632, 705],
  [629, 712],
  [631, 729],
  [631, 756],
  [634, 774],
  [634, 794],
  [632, 816],
  [626, 835],
  [622, 856],
  [617, 874],
  [612, 890],
  [603, 902],
  [595, 918],
  [586, 933],
  [578, 948],
  [578, 954],
  [589, 940],
  [597, 925],
  [605, 916],
  [609, 916],
  [616, 911],
  [619, 907],
  [621, 916],
  [621, 930],
  [618, 948],
  [623, 943],
  [625, 924],
  [625, 908],
  [626, 900],
  [629, 889],
  [629, 877],
  [635, 861],
  [640, 846],
  [644, 835],
  [648, 823],
  [651, 834],
  [652, 844],
  [659, 850],
  [662, 855],
  [660, 864],
  [655, 868],
  [651, 874],
  [653, 878],
  [659, 875],
  [664, 869],
  [670, 864],
  [672, 857],
  [669, 845],
  [663, 839],
  [661, 831],
  [662, 828],
  [667, 827],
  [672, 824],
  [668, 812],
  [662, 806],
  [663, 802],
  [669, 797],
  [665, 784],
  [653, 757],
  [647, 742],
  [636, 715],
  [650, 723],
  [655, 726],
  [668, 726],
  [673, 725],
  [674, 721],
  [666, 710],
  [670, 710],
  [675, 719],
  [680, 722],
  [694, 726],
  [708, 732],
  [719, 735],
  [728, 740],
  [737, 743],
  [747, 747],
  [761, 749],
  [776, 750],
  [784, 750],
  [792, 755],
  [805, 758],
  [816, 762],
  [826, 766],
  [834, 771],
  [838, 784],
  [844, 800],
  [850, 811],
  [856, 814],
  [859, 814],
  [859, 804],
  [858, 800],
  [865, 814],
  [869, 826],
  [872, 834],
  [878, 842],
  [879, 852],
  [877, 866],
  [870, 883],
  [859, 895],
  [846, 912],
  [831, 917],
  [829, 926],
  [827, 932],
  [819, 940],
  [828, 942],
  [836, 940],
  [849, 927],
  [858, 918],
  [868, 908],
  [877, 891],
  [882, 873],
  [883, 856],
  [889, 863],
  [889, 870],
  [889, 881],
  [889, 894],
  [889, 903],
  [883, 913],
  [874, 920],
  [863, 933],
  [852, 944],
  [839, 959],
  [831, 970],
  [822, 976],
  [826, 979],
  [836, 985],
  [845, 985],
  [888, 983],
  [890, 957],
  [891, 935],
  [891, 922],
  [892, 917],
  [898, 908],
  [894, 895],
  [892, 870],
  [891, 861],
  [884, 847],
  [876, 831],
  [866, 811],
  [856, 788],
  [850, 774],
  [834, 758],
  [822, 747],
  [801, 736],
  [773, 727],
  [733, 718],
  [707, 712],
  [690, 705],
  [664, 704],
  [649, 704],
  [637, 699],
].map(([px, py]) => ({ px, py }));

const SHADOW_TSHIRT_LEFT = [
  [369, 716],
  [348, 723],
  [337, 726],
  [321, 730],
  [303, 739],
  [284, 743],
  [270, 746],
  [256, 747],
  [240, 748],
  [215, 760],
  [186, 769],
  [175, 773],
  [168, 779],
  [154, 780],
  [151, 790],
  [143, 799],
  [134, 806],
  [125, 808],
  [115, 818],
  [108, 829],
  [92, 838],
  [78, 846],
  [68, 858],
  [63, 865],
  [46, 880],
  [30, 899],
  [23, 909],
  [19, 915],
  [15, 921],
  [15, 940],
  [21, 960],
  [26, 974],
  [31, 981],
  [34, 986],
  [109, 985],
  [99, 979],
  [87, 968],
  [73, 948],
  [64, 930],
  [64, 913],
  [62, 891],
  [63, 880],
  [70, 866],
  [81, 850],
  [79, 863],
  [76, 877],
  [76, 888],
  [77, 912],
  [83, 928],
  [99, 942],
  [113, 951],
  [119, 955],
  [106, 937],
  [97, 920],
  [90, 897],
  [91, 897],
  [92, 897],
  [98, 908],
  [106, 924],
  [120, 942],
  [127, 949],
  [129, 958],
  [135, 968],
  [136, 977],
  [139, 986],
  [174, 984],
  [164, 963],
  [152, 945],
  [138, 929],
  [123, 909],
  [120, 896],
  [132, 913],
  [141, 919],
  [150, 927],
  [136, 908],
  [128, 893],
  [123, 873],
  [119, 856],
  [121, 848],
  [126, 854],
  [126, 863],
  [131, 882],
  [136, 896],
  [143, 906],
  [147, 910],
  [140, 889],
  [135, 868],
  [136, 852],
  [140, 845],
  [143, 839],
  [143, 816],
  [143, 807],
  [143, 803],
  [151, 796],
  [165, 789],
  [166, 797],
  [165, 811],
  [179, 795],
  [181, 787],
  [187, 791],
  [198, 788],
  [205, 781],
  [219, 775],
  [218, 787],
  [225, 788],
  [234, 786],
  [256, 773],
  [275, 768],
  [286, 759],
  [295, 758],
  [314, 748],
  [332, 737],
  [339, 735],
  [347, 735],
  [359, 730],
  [366, 726],
].map(([px, py]) => ({ px, py }));

const LIGHT_TSHIRT_LEFT = [
  [357, 748.5],
  [348, 745.5],
  [346, 754.5],
  [340, 748.5],
  [329, 753.5],
  [322, 758.5],
  [307, 779.5],
  [304, 790.5],
  [302, 806.5],
  [296, 816.5],
  [285, 810.5],
  [282, 798.5],
  [279, 787.5],
  [277, 778.5],
  [275, 777.5],
  [265, 778.5],
  [260, 786.5],
  [259, 801.5],
  [256, 800.5],
  [243, 800.5],
  [235, 799.5],
  [220, 800.5],
  [204, 806.5],
  [199, 816.5],
  [200, 834.5],
  [213, 863.5],
  [201, 849.5],
  [190, 828.5],
  [187, 814.5],
  [186, 806.5],
  [182, 808.5],
  [181, 808.5],
  [175, 817.5],
  [175, 842.5],
  [175, 858.5],
  [182, 877.5],
  [177, 888.5],
  [178, 896.5],
  [188, 914.5],
  [192, 929.5],
  [202, 936.5],
  [208, 933.5],
  [222, 944.5],
  [238, 961.5],
  [259, 973.5],
  [271, 979.5],
  [256, 948.5],
  [242, 942.5],
  [272, 954.5],
  [283, 951.5],
  [272, 936.5],
  [255, 925.5],
  [239, 905.5],
  [231, 897.5],
  [227, 884.5],
  [242, 897.5],
  [249, 907.5],
  [260, 917.5],
  [265, 925.5],
  [277, 935.5],
  [286, 943.5],
  [295, 950.5],
  [311, 958.5],
  [295, 944.5],
  [273, 929.5],
  [265, 911.5],
  [259, 899.5],
  [256, 886.5],
  [274, 904.5],
  [291, 914.5],
  [303, 925.5],
  [320, 932.5],
  [338, 938.5],
  [348, 947.5],
  [358, 953.5],
  [349, 926.5],
  [343, 892.5],
  [336, 866.5],
  [347, 878.5],
  [348, 892.5],
  [353, 901.5],
  [356, 912.5],
  [358, 926.5],
  [364, 952.5],
  [376, 964.5],
  [386, 964.5],
  [402, 968.5],
  [406, 975.5],
  [411, 984.5],
  [438, 985.5],
  [438, 979.5],
  [426, 964.5],
  [413, 951.5],
  [404, 939.5],
  [399, 931.5],
  [390, 916.5],
  [387, 902.5],
  [381, 900.5],
  [373, 904.5],
  [375, 912.5],
  [373, 914.5],
  [373, 914.5],
  [364, 909.5],
  [357, 896.5],
  [356, 872.5],
  [361, 857.5],
  [362, 846.5],
  [361, 820.5],
  [353, 810.5],
  [350, 792.5],
  [350, 779.5],
  [353, 765.5],
  [355, 756.5],
].map(([px, py]) => ({ px, py }));

const TSHIRT_LIGHT_RIGHT = [
  [669, 733.25],
  [675, 741.25],
  [680, 757.25],
  [683, 780.25],
  [683, 801.25],
  [684, 815.25],
  [685, 835.25],
  [684, 842.25],
  [684, 855.25],
  [687, 872.25],
  [686, 887.25],
  [686, 902.25],
  [681, 913.25],
  [672, 922.25],
  [662, 932.25],
  [657, 931.25],
  [662, 920.25],
  [665, 905.25],
  [665, 892.25],
  [665, 885.25],
  [665, 880.25],
  [657, 884.25],
  [654, 888.25],
  [649, 905.25],
  [646, 925.25],
  [641, 930.25],
  [638, 935.25],
  [633, 940.25],
  [627, 947.25],
  [625, 960.25],
  [620, 967.25],
  [612, 973.25],
  [605, 979.25],
  [602, 984.25],
  [644, 985.25],
  [651, 975.25],
  [656, 959.25],
  [670, 944.25],
  [678, 935.25],
  [679, 948.25],
  [677, 957.25],
  [683, 957.25],
  [697, 945.25],
  [702, 934.25],
  [716, 923.25],
  [725, 923.25],
  [730, 927.25],
  [746, 914.25],
  [757, 901.25],
  [771, 893.25],
  [785, 885.25],
  [783, 892.25],
  [780, 895.25],
  [784, 900.25],
  [781, 905.25],
  [774, 910.25],
  [769, 914.25],
  [756, 921.25],
  [750, 927.25],
  [743, 927.25],
  [750, 926.25],
  [761, 924.25],
  [780, 915.25],
  [797, 903.25],
  [774, 925.25],
  [767, 931.25],
  [760, 936.25],
  [754, 932.25],
  [750, 934.25],
  [742, 938.25],
  [737, 945.25],
  [730, 952.25],
  [722, 954.25],
  [714, 963.25],
  [709, 967.25],
  [699, 974.25],
  [694, 976.25],
  [690, 981.25],
  [704, 983.25],
  [711, 983.25],
  [724, 974.25],
  [736, 963.25],
  [750, 954.25],
  [764, 948.25],
  [775, 942.25],
  [782, 941.25],
  [794, 933.25],
  [800, 925.25],
  [804, 909.25],
  [813, 898.25],
  [822, 885.25],
  [827, 877.25],
  [829, 863.25],
  [830, 851.25],
  [830, 835.25],
  [829, 822.25],
  [819, 821.25],
  [805, 813.25],
  [797, 810.25],
  [805, 802.25],
  [807, 795.25],
  [820, 789.25],
  [824, 788.25],
  [816, 786.25],
  [806, 786.25],
  [794, 786.25],
  [783, 786.25],
  [772, 792.25],
  [764, 794.25],
  [758, 794.25],
  [758, 795.25],
  [751, 799.25],
  [751, 811.25],
  [752, 820.25],
  [754, 819.25],
  [752, 831.25],
  [748, 833.25],
  [734, 837.25],
  [721, 845.25],
  [712, 849.25],
  [711, 841.25],
  [714, 833.25],
  [706, 828.25],
  [709, 815.25],
  [720, 809.25],
  [725, 804.25],
  [738, 799.25],
  [738, 798.25],
  [725, 797.25],
  [725, 788.25],
  [735, 782.25],
  [737, 778.25],
  [727, 781.25],
  [714, 781.25],
  [719, 772.25],
  [722, 766.25],
  [717, 763.25],
  [716, 757.25],
  [725, 753.25],
  [711, 748.25],
  [695, 742.25],
  [684, 737.25],
].map(([px, py]) => ({ px, py }));

const TSHIRT_LIGHT_RIGHT2 = [
  [861, 817.75],
  [855, 825.75],
  [844, 820.75],
  [851, 847.75],
  [846, 870.75],
  [838, 889.75],
  [832, 905.75],
  [825, 904.75],
  [820, 920.75],
  [818, 938.75],
  [820, 957.75],
  [819, 972.75],
  [819, 980.75],
  [828, 981.75],
  [820, 969.75],
  [827, 966.75],
  [845, 947.75],
  [865, 924.75],
  [880, 906.75],
  [884, 901.75],
  [886, 881.75],
  [887, 866.75],
  [881, 888.75],
  [872, 908.75],
  [858, 925.75],
  [844, 939.75],
  [835, 945.75],
  [823, 944.75],
  [822, 935.75],
  [828, 919.75],
  [837, 911.75],
  [854, 881.75],
  [870, 866.75],
  [874, 848.75],
].map(([px, py]) => ({ px, py }));

export default {
  camisaIzquierda: {
    points: CAMISA_IZQUIERDA,
    configs: { backgroundColor: "rgb(222 216 214)", strokeWidth: "0" },
  },
  camisaCuelloIzq: {
    points: CUELLO_IZQ,
    configs: { backgroundColor: "rgb(237 230 228)", strokeWidth: "0" },
  },
  camisaDerecha: {
    points: CAMISA_DERECHA,
    configs: { backgroundColor: "rgb(222 216 214)", strokeWidth: "0" },
  },
  camisaCuelloDer: {
    points: CUELLO_DER,
    configs: { backgroundColor: "rgb(237 230 228)", strokeWidth: "0" },
  },
  shadowCuelloIzq: {
    points: SHADOW_LEFT_CUELLO,
    configs: { backgroundColor: "rgb(204 198 197)", strokeWidth: "0" },
  },
  shadowCuelloIzq2: {
    points: SHADOW_DETAIL_CUELLO_LEFT2,
    configs: { backgroundColor: "rgb(204 198 197)", strokeWidth: "0" },
  },
  bordButtonLeft: {
    points: BORDE_BUTTON_LEFT,
    configs: { backgroundColor: "rgb(219 212 211)", strokeWidth: "0" },
  },
  tShirtLines: {
    points: TSHIRT_LINES,
    configs: { backgroundColor: "rgb(232 229 228)", strokeWidth: "0" },
  },
  tShirtLinesRight: {
    points: TSHIRT_LINES_RIGHT,
    configs: { backgroundColor: "rgb(232 229 228)", strokeWidth: "0" },
  },
  tShirtShadowRight: {
    points: SHADOW_TSHIRT_RIGHT,
    configs: { backgroundColor: "rgb(204 198 197)", strokeWidth: "0" },
  },
  tShirtShadowLeft: {
    points: SHADOW_TSHIRT_LEFT,
    configs: { backgroundColor: "rgb(204 198 197)", strokeWidth: "0" },
  },
  tShirtLightLeft: {
    points: LIGHT_TSHIRT_LEFT,
    configs: { backgroundColor: "rgb(232 229 228)", strokeWidth: "0" },
  },
  tShirtLightRight: {
    points: TSHIRT_LIGHT_RIGHT,
    configs: { backgroundColor: "rgb(232 229 228)", strokeWidth: "0" },
  },
  tShirtLightRight2: {
    points: TSHIRT_LIGHT_RIGHT2,
    configs: { backgroundColor: "rgb(232 229 228)", strokeWidth: "0" },
  },
  // button: {
  //   points: BUTTON,
  //   configs: { backgroundColor: "rgb(204 198 197)", strokeWidth: "0" },
  // },
  // buttonLight: {
  //   points: BUTTON_LIGHT,
  //   configs: { backgroundColor: "rgb(222 216 214)", strokeWidth: "0" },
  // },
  // buttonCosture: {
  //   points: BUTTON_COSTURE,
  //   configs: { backgroundColor: "rgb(237 230 228)", strokeWidth: "0" },
  // },
};
