import * as d3 from "d3";
import BgClient from "./index";
import * as Helpers from "./helpers";
import * as Types from "./types";
import { CharacterProfileService } from "components/CharacterProfile";

export interface Element {
  refId: string;
  points: Types.Point[];
  configs: Types.ImageElementConfigs;
  childElements?: Element[];
}

export class ImageElement implements Element {
  readonly svgPath: string;
  readonly refId: string;
  readonly points: Types.Point[];
  readonly configs: Types.ImageElementConfigs;
  readonly children: ImageElement[] | undefined;
  readonly parent: ImageElement | undefined;

  constructor(
    protected client: BgClient<any, any, any>,
    refId: string,
    objectData: Types.ElementData,
    parent?: ImageElement
  ) {
    const { points, configs, childElements } = objectData;
    this.svgPath = Helpers.hpPointsToPath(points);
    this.refId = refId;
    this.points = points;
    this.configs = this._buildConfig(configs);
    this.parent = parent;
    if (childElements) {
      this.children = Object.entries(childElements).map(
        ([key, element]) => new ImageElement(client, "some child identifier", element as any, this)
      );
    } else {
      this.children = undefined;
    }
  }

  public drawBorders = (effect: "by-line" | "by-path", color: "gold" | "silver" = "gold") => {
    effect === "by-line" && this._drawBordersByLine(color);
    effect === "by-path" && this._drawBordersByPath(color);
  };

  public removeBorders = () => {
    d3.selectAll(`.image-element__border`).filter(`.${this.refId}`).remove();
  };

  public remove = () => {
    this.client.svg.selectAll(`.${this.refId}`).remove();
  };

  public onClick = () => {
    const className = "image-element__inner";

    const onClickFunction = () => {
      const getMinMaxPoints = () => {
        const { minX, maxX, minY, maxY } = this.points.reduce(
          (acc, current) => ({
            minX: Math.min(acc.minX, current.px),
            maxX: Math.max(acc.maxX, current.px),
            minY: Math.min(acc.minY, current.py),
            maxY: Math.max(acc.maxY, current.py),
          }),
          { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
        );

        return { minX, maxX, minY, maxY };
      };

      const getElementWidth = () => {
        const { minX, maxX } = getMinMaxPoints();
        return maxX - minX;
      };

      const getElementHeight = () => {
        const { minY, maxY } = getMinMaxPoints();
        return maxY - minY;
      };

      const { minX, minY } = getMinMaxPoints();

      const b = getElementWidth();
      // const padding = { horizontal: 20, vertical: 100 };
      const padding = { horizontal: 0, vertical: 0 };
      const WIDTH = 900; //TODO, calculate this value base on the client viewport from the viewport that should be the same that the viewport used for the data definition

      const b_p = WIDTH - 2 * padding.horizontal;
      const y2 = minY - padding.vertical;
      const x2 = minX - padding.horizontal;

      const scaleFactor = b_p / b;

      const isZoomInActivated = this.client.svg.select("g:first-child").classed("zoomed-in");

      if (isZoomInActivated) {
        this.client.ZoomOut();
      } else {
        this.client.ZoomIn(-1 * x2, -1 * y2, scaleFactor);
      }
    };

    this.client.svg
      .select("g:first-child")
      .append("path")
      .attr("class", className)
      .attr("d", this.svgPath)
      .on("click", () => {
        onClickFunction();
      });
  };

  public assignHover = () => {
    const className = "image-element__inner";

    this.client.svg
      .select("g:first-child")
      .append("path")
      .attr("class", className)
      .attr("d", this.svgPath)
      .on("mouseover", () => {
        if (this.parent) {
          // has a parent => is a Child.
          this.parent.client.drawOpacityLayer(this.parent.svgPath);
          this.parent.drawBorders("by-path", "silver");
          this.drawBorders("by-path", "gold");
        } else {
          this.client.drawOpacityLayer(this.svgPath);
          this.drawBorders("by-path");
        }
      });
  };

  public assignClick = (call: () => void | undefined) => {
    const element = this.client.svg.select(`path.${this.refId}`);
    element.on("click", () => {
      call();
    });
  };

  public classed = (className: string, includeIt: boolean) => {
    const existingElement = this.client.svg.select(`.g-${this.refId}`);
    !existingElement.empty() && existingElement.classed(className, includeIt);
  };

  public paintBackground = (opacity: number = 1, color?: string) => {
    // this.client.svg.select("#" + this.refId).remove();
    // const existingElement = this.client.svg.select(`#${this.refId}`);

    const existingElement = this.client.svg.select(`.g-${this.refId}`);
    !existingElement.empty() && existingElement.attr("opacity", opacity);
    if (!existingElement.empty()) return;

    const { backgroundColor, strokeWidth, filter } = this.configs;
    const theGroup = this.client.svg.append("g");
    const className = "svg-image-element";
    theGroup.attr("class", `${className} ${String(this.refId)}`);

    theGroup
      .append("g")
      .attr("class", `g-${this.refId}`)
      // .select("." + this.refId)
      .select("g:first-child")
      .append("path")
      .attr("class", this.refId)
      .attr("id", this.refId)
      .attr("d", `${this.svgPath}`)
      .attr("fill", color || backgroundColor)
      .attr("stroke-width", strokeWidth || 1)
      .attr("stroke", "rgba(0,0,0,0.3)")
      .attr("filter", filter || "none")
      .attr("opacity", opacity);
  };

  public renderCharacter = (deleteIt: boolean = false, scaleFactor: number = 1, opacity: number = 1) => {
    const existingCharacter = this.client.svg.select(`.${this.refId}.character-image`);

    if (deleteIt) {
      existingCharacter.remove();
      return;
    }

    !existingCharacter.empty() && existingCharacter.attr("opacity", opacity);
    if (!existingCharacter.empty()) return;
    // if (opacity === 1) {
    //   existingCharacter.classed("visible", true);
    // }

    const characterFoto = CharacterProfileService.getFoto("mirando-frente-welcome");
    const { elementHeight, thetaBottom } = this.configs.texts.calculatedValues;
    const { p1, p2, p4 } = this.configs.texts.calculatedValues.refPoints;

    const [x0, y0] = [p1.px, p1.py];
    const width = p4.px - p2.px;
    const transformString = `translate(${p1.px + width / 2}px, ${
      p1.py + elementHeight / 2
    }px) rotate(${thetaBottom}deg) translate(-${p1.px + width / 2}px, -${
      p1.py + elementHeight / 2
    }px) scale(${scaleFactor});`;

    existingCharacter.empty() &&
      this.client.svg
        .select("g:first-child")
        .append("image")
        .attr("class", `${this.refId} character-image`)
        .attr("xlink:href", characterFoto)
        .attr("x", x0)
        .attr("y", y0)
        // .attr("width", p4.px - p2.px)
        .attr("height", elementHeight)
        .attr("style", `width: ${p4.px - p2.px}px; transform: ${transformString};`);
  };

  public renderImgRefactored = (imageHref: string, considerPadding?: boolean) => {
    const textConfig = this.configs.texts;
    const { elementHeight, thetaBottom, refPoints } = textConfig.calculatedValues;
    const { p1, p2, p4 } = refPoints;
    // const padding = considerPadding ? textConfig.padding : { left: 0, top: 0 };
    const padding = { left: -5, top: 2 };
    const [x0, y0] = [p1.px + padding.left, p1.py + padding.top];
    const width = p4.px - p2.px - 2 * padding.left;

    const transformString = `translate(${p1.px}px, ${p1.py}px) rotate(${thetaBottom}deg) translate(-${p1.px}px, -${
      p1.py
    }px) scale(${1});`;

    const group = this.client.svg.select(`g.g-` + this.refId);
    const existingImage = group.select("image");

    !existingImage.empty() &&
      group &&
      existingImage
        .attr("class", `${this.refId}`)
        .attr("xlink:href", imageHref)
        .attr("x", x0)
        .attr("y", y0)
        .attr("height", elementHeight)
        .attr("style", `width: ${width}px; transform: ${transformString};`);

    existingImage.empty() &&
      group &&
      group
        .append("image")
        .attr("class", `${this.refId}`)
        .attr("xlink:href", imageHref)
        .attr("x", x0)
        .attr("y", y0)
        // .attr("width", p4.px - p2.px)
        .attr("height", elementHeight)
        .attr("style", `width: ${width}px; transform: ${transformString};`);
  };

  public renderImage = (imageHref: string, deleteIt: boolean = false, className: string, considerPadding?: boolean) => {
    const existingImage = this.client.svg.select(`.${this.refId}.${className}`);

    if (deleteIt) {
      existingImage.remove();
      return;
    }

    if (!existingImage.empty()) return;

    const textConfig = this.configs.texts;
    const { elementHeight, thetaBottom, refPoints } = textConfig.calculatedValues;
    const { p1, p2, p4 } = refPoints;

    const padding = considerPadding ? textConfig.padding : { left: 0, top: 0 };

    const [x0, y0] = [p1.px + padding.left, p1.py + padding.top];
    const width = p4.px - p2.px - 2 * padding.left;
    // const transformString = `rotate(${thetaBottom}deg) scale(${scaleFactor});`;
    // const transformString = `translate(${p1.px + width / 2}px, ${
    //   p1.py + elementHeight / 2
    // }px) rotate(${thetaBottom}deg) translate(-${p1.px + width / 2}px, -${
    //   p1.py + elementHeight / 2
    // }px) scale(${scaleFactor});`;
    const transformString = `translate(${p1.px}px, ${p1.py}px) rotate(${thetaBottom}deg) translate(-${p1.px}px, -${
      p1.py
    }px) scale(${1});`;

    const group = this.client.svg.select(`g.g-` + this.refId);

    existingImage.empty() &&
      group &&
      group
        .append("image")
        .attr("class", `${this.refId} ${className}`)
        .attr("xlink:href", imageHref)
        .attr("x", x0)
        .attr("y", y0)
        // .attr("width", p4.px - p2.px)
        .attr("height", elementHeight)
        .attr("style", `width: ${width}px; transform: ${transformString};`);
  };

  public rotate = (point: Types.Point, angle: number) => {
    // console.log("publicrotate -> point", point, this.client.svg.select(`.${this.refId}`));
    this.client.svg
      .select(`.${this.refId}`)
      .attr(
        "style",
        `transform: translate(${point.px}px, ${
          point.py
        }px) rotate(${angle}deg) translate(${-point.px}px, ${-point.py}px);`
      );
  };

  private _drawBordersByLine = (color: "gold" | "silver") => {
    const className = "image-element__border";
    for (let i = 0; i < this.points.length - 1; i += 1) {
      const [p1, p2] = this.points.slice(i, i + 2);
      this.client.svg
        .select("g:first-child")
        .append("line")
        .attr("class", `${className} ${this.refId} ${color}`)
        .attr("x1", p1.px)
        .attr("y1", p1.py)
        .attr("x2", p2.px)
        .attr("y2", p2.py)
        .transition()
        .duration(1500)
        .ease(d3.easeLinear)
        .attrTween("stroke-dasharray", function () {
          const length = this.getTotalLength();
          return d3.interpolate(`0,${length}`, `${length},${length}`);
        });
    }
  };

  private _drawBordersByPath = (color: "gold" | "silver") => {
    const className = "image-element__border";
    this.client.svg
      .select("g:first-child")
      .append("path")
      .attr("class", `${className} ${this.refId} ${color}`)
      .attr("d", this.svgPath)
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attrTween("stroke-dasharray", function () {
        const length = this.getTotalLength();
        return d3.interpolate(`0,${length}`, `${length},${length}`);
      });
  };

  private _buildConfig = ({
    paddingLeft,
    paddingTop,
    fonts,
    backgroundColor,
    filter,
    strokeWidth,
    p1, // Top left corner
    p2, // Bottom left corner
    p3, // Top Right corner
    p4, // Bottom Right corner
  }: Types.ImageDataConfigs = DEFAULT_CONFIG) => {
    let texts = undefined;
    if (fonts && p1 && p2 && p3 && p4 && paddingLeft && paddingTop) {
      texts = {
        padding: {
          left: paddingLeft,
          top: paddingTop,
        },
        fonts,
        calculatedValues: {
          dx: (fonts.p.heightLine * (p2.px - p1.px)) / (p2.py - p1.py),
          thetaTop: (Math.atan2(p3.py - p1.py, p3.px - p1.px) * 180) / Math.PI,
          thetaBottom: (Math.atan2(p4.py - p2.py, p4.px - p2.px) * 180) / Math.PI,
          refPoints: { p1, p2, p3, p4 },
          elementHeight: p2.py - p1.py,
        },
      };
    } else {
      const { paddingLeft, paddingTop, fonts, p1, p2, p3, p4 } = DEFAULT_CONFIG;
      texts = {
        padding: {
          left: paddingLeft,
          top: paddingTop,
        },
        fonts,
        calculatedValues: {
          dx: (fonts.p.heightLine * (p2.px - p1.px)) / (p2.py - p1.py),
          thetaTop: (Math.atan2(p3.py - p1.py, p3.px - p1.px) * 180) / Math.PI,
          thetaBottom: (Math.atan2(p4.py - p2.py, p4.px - p2.px) * 180) / Math.PI,
          refPoints: { p1, p2, p3, p4 },
          elementHeight: p2.py - p1.py,
        },
      };
    }

    return {
      filter: filter || DEFAULT_CONFIG.strokeWidth,
      strokeWidth: strokeWidth || DEFAULT_CONFIG.strokeWidth,
      backgroundColor: backgroundColor || DEFAULT_CONFIG.backgroundColor,
      texts: texts,
    };
  };
}

const DEFAULT_CONFIG = {
  paddingLeft: 30,
  paddingTop: 80,
  fonts: {
    h1: {
      characterPerLine: 40, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 40, // 80, //40
    },
    p: {
      characterPerLine: 53,
      fontSize: 26,
      color: "black",
      heightLine: 40,
    },
  },
  backgroundColor: "black",
  filter: "none",
  strokeWidth: "1",
  p1: { px: 0, py: 0 }, // Top left corner
  p2: { px: 0, py: 0 }, // Bottom left corner
  p3: { px: 0, py: 0 }, // Top right corner
  p4: { px: 0, py: 0 }, // Bottom right corner
};
