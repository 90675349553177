import * as Types from "./types";
const pathEle =
  "111,829.5454406738281 104,833.5454406738281 99,842.5454406738281 111,996.5454406738281 0,1020.5454406738281 2,1196.5454406738281 864,1195.5454406738281 789,964.5454406738281 636,971.5454406738281 611,909.5454406738281 596,898.5454406738281 580,893.5454406738281 485,911.5454406738281 474,805.5454406738281 471,799.5454406738281 460,793.5454406738281 112,830.5454406738281";

const getPointsFromPath = () => {
  const points = pathEle.split(" ").map((parPointsValue) => {
    const [px, py] = parPointsValue.split(",");
    return { px: parseInt(px), py: parseInt(py) };
  });

  console.log("points", points);
};

export const extractPoints = (points: Types.TPoint[], point1: Types.TPoint, point2: Types.TPoint) => {
  const indexPoint1 = points.findIndex(({ px, py }) => px === point1.px && py === point1.py);
  const indexPoint2 = points.findIndex(({ px, py }) => px === point2.px && py === point2.py);

  if (indexPoint1 === -1 || indexPoint2 === -1) return [];

  if (indexPoint2 > indexPoint1) {
    return points.slice(indexPoint1, indexPoint2);
  } else {
    return points.slice(indexPoint2, indexPoint1).reverse();
  }
};
