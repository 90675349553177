import { Language, VALID_LANGUAGES } from "./translate/types";

type User = {
  ref: string;
  email: string;
  lovesIt: boolean;
};

export class LocalStorage {
  lg: Language;
  user: User | undefined;
  constructor() {
    this.lg = this._getLang();
    this.user = this._getUser();
  }

  public update = (appState: { language: Language; user: User | undefined }) => {
    const { language, user } = appState;
    if (language !== this.lg) this.storeLanguage(language);
    if (user !== this.user && user) this.storeUser(user);
  };

  private storeLanguage = (lang: Language) => {
    localStorage.setItem("language", lang);
    this.lg = lang;
  };

  private storeUser = (user: User) => {
    localStorage.setItem("user", JSON.stringify(user));
    this.user = user;
  };

  private _getLang = () => {
    const defaultLang = "en";
    const storeLang = localStorage.getItem("language");
    if (!storeLang) return defaultLang;
    if (VALID_LANGUAGES.includes(storeLang as any)) return storeLang as Language;
    return defaultLang;
  };

  private _getUser = () => {
    const user = localStorage.getItem("user");
    if (!user) return undefined;
    return JSON.parse(user) as User;
  };
}
