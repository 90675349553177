import * as Types from "./types";
import { FOTOS_DATA } from "./data";

const getFoto = (type: Types.CharactersTypes) => {
  switch (type) {
    case "mirando-frente-serio":
      return FOTOS_DATA.frente.pensando;
    case "pensando":
      return FOTOS_DATA.abajo.pensando.diagonalAbajo;
    case "observando-izquierda":
      return FOTOS_DATA.izquierda.mirando;
    case "mirando-frente-sonriendo":
      return FOTOS_DATA.frente.mirando;
    case "mirando-frente-welcome":
      return FOTOS_DATA.frente.welcome;
    case "story-img":
      return FOTOS_DATA.stories.portfolio.startingProfesional;
    case "story-portfolio-miro-idea-show-content":
      return FOTOS_DATA.stories.portfolio.miroIdeaShowContent;
    default:
      return FOTOS_DATA.frente.mirando;
  }
};

export const CharacterProfileService = {
  getFoto,
};
