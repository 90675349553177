export type StoryViewportsClasses = "reel" | "post" | "desktop";

interface SocialMediaFrame {
  viewportClosestRatio: Ratio;
  renderAs: StoryViewportsClasses;
  smWidth: number | string;
  smHeight: number | string;
  refreshValues: (viewport: { width: number; height: number }, renderAs?: StoryViewportsClasses) => void;
}

const ratios: Ratio[] = [
  ["reel", 9 / 16],
  ["post", 1],
  ["desktop", 8 / 5],
];

const FRAMES = {
  reel: 9 / 16,
  post: 1,
  desktop: 8 / 5,
};

type Ratio = [StoryViewportsClasses, number];

class SocialMediaFrame implements SocialMediaFrame {
  private viewport: { width: number; height: number; ratio: number };
  public viewportClosestRatio: Ratio;
  public renderAs: StoryViewportsClasses;
  public smWidth: number | string;
  public smHeight: number | string;

  constructor(viewport: { width: number; height: number }, renderAs?: StoryViewportsClasses) {
    this.viewport = { ...viewport, ratio: viewport.width / viewport.height };
    this.viewportClosestRatio = this._findClosestRatio();
    this.renderAs = renderAs || this.viewportClosestRatio[0];

    const { width, height } = this._setDimention(Boolean(renderAs));
    this.smWidth = width;
    this.smHeight = height;
  }

  public refreshValues = (viewport: { width: number; height: number }, renderAs?: StoryViewportsClasses) => {
    this.viewport = { ...viewport, ratio: viewport.width / viewport.height };
    this.viewportClosestRatio = this._findClosestRatio();
    this.renderAs = renderAs || this.viewportClosestRatio[0];

    const { width, height } = this._setDimention(Boolean(renderAs));
    this.smWidth = width;
    this.smHeight = height;
  };

  private _setDimention = (consideringRenderAs: boolean) => {
    const ratioToUse = consideringRenderAs
      ? FRAMES[this.renderAs as StoryViewportsClasses]
      : this.viewportClosestRatio[1];

    const leadWidth = this.viewport.ratio <= ratioToUse;
    const [widthSmFrame, heightSocialMedia] = [
      leadWidth ? this.viewport.width : this.viewport.height * ratioToUse,
      leadWidth ? this.viewport.width / ratioToUse : this.viewport.height,
    ];

    return { width: Math.round(widthSmFrame), height: Math.round(heightSocialMedia) };
  };

  private _findClosestRatio = () => {
    let closestRatio = ratios[0];
    let minDifference: number | null = null;
    for (const [name, ratio] of ratios) {
      const difference = Math.abs(this.viewport.ratio - ratio);

      if (minDifference === null || difference < minDifference) {
        minDifference = difference;
        closestRatio = [name, ratio];
      }
    }
    return closestRatio;
  };
}

export default SocialMediaFrame;
