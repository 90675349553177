import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import Configs from "configs";

// Retrieve userSession from localStorage or generate a unique hash
const userSession = localStorage.getItem("usersession") || generateUniqueHash();

function generateUniqueHash() {
  const session = new Date().getTime().toString(16);
  localStorage.setItem("usersession", session);
  return session;
}

export const api = axios.create({
  baseURL: Configs.apiEndpoint,
  headers: {
    // userSession: userSession,
  },
  withCredentials: true,
});

// Custom response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle errors
    return errorHandler(error);
  }
);

// Custom error handler
const errorHandler = (error: any) => {
  console.log("errorHandler -> error", error);
  const statusCode = error.response?.status;
  console.log("errorHandler -> statusCode", statusCode);

  if (error.code === "ERR_CANCELED") {
    return Promise.resolve();
  }

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// Registering the custom error handler
// api.interceptors.response.use(undefined, (error) => errorHandler(error));
