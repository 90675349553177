import Img from "assets/defined_fotos/books_corner_996x1327.png";
import ImgObjects from "assets/defined_fotos/books_objects_hq.png";
// import ImgComic from "assets/defined_fotos/books_comic_1200x1606.png";
import ImgComic from "assets/defined_fotos/book_comics_hq.png";
// import ImgComic from "assets/defined_fotos/books_comic_1200x1606_min.jpeg";

import BooksWall from "./wall";
import Vinozeros from "./vinozeros";
import Valparaiso from "./valparaiso";
import Titanium from "./titanium";
import BottomLevel from "./bottom_level";
import MidLevel from "./mid_level";
import TopLevel from "./top_level";
import Max from "./max";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */

const OVER_COMPUTER = [
  [30, 628],
  [30, 628],
  [30, 628],
].map(([px, py]) => ({ px, py }));

const TITULO = [
  [37, 56],
  [37, 56],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const TITULO_WELCOME = [
  [37, 56],
  [37, 56],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const TEXT_WELCOME = [
  [37, 56],
  [37, 56],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const OVER_COMPUTER_CONFIG = {
  paddingLeft: 0,
  paddingTop: 0,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 32,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 20,
      fontSize: 22,
      color: "white",
      heightLine: 32,
    },
  },
  backgroundColor: "#210503",
  p1: OVER_COMPUTER[0], // Top left corner
  p2: OVER_COMPUTER[1], // Bottom left corner
  p3: OVER_COMPUTER[OVER_COMPUTER.length - 2], // Top right corner
  p4: OVER_COMPUTER[OVER_COMPUTER.length - 3], // Bottom right corner
};

const TITLE_CONFIG = {
  paddingLeft: 30,
  paddingTop: 10,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 62,
      color: "#f5c51f",
      heightLine: 80, // 80, //40
    },
    p: {
      characterPerLine: 25,
      fontSize: 32,
      color: "white",
      heightLine: 62,
    },
  },
  backgroundColor: "#311408",
  p1: TITULO[0], // Top left corner
  p2: TITULO[1], // Bottom left corner
  p3: TITULO[TITULO.length - 2], // Top right corner
  p4: TITULO[TITULO.length - 3], // Bottom right corner
};

const TITLE_WELCOME_CONFIG = {
  paddingLeft: 30,
  paddingTop: 40,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 72,
      color: "#f5c51f",
      heightLine: 20, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 44,
      color: "white",
      heightLine: 60,
    },
  },
  backgroundColor: "#311408",
  p1: TITULO_WELCOME[0], // Top left corner
  p2: TITULO_WELCOME[1], // Bottom left corner
  p3: TITULO_WELCOME[TITULO_WELCOME.length - 2], // Top right corner
  p4: TITULO_WELCOME[TITULO_WELCOME.length - 3], // Bottom right corner
};

const TEXT_WELCOME_CONFIG = {
  paddingLeft: 30,
  paddingTop: 40,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 62,
      color: "#f5c51f",
      heightLine: 0, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 34,
      color: "white",
      heightLine: 50,
    },
  },
  backgroundColor: "#311408",
  p1: TEXT_WELCOME[0], // Top left corner
  p2: TEXT_WELCOME[1], // Bottom left corner
  p3: TEXT_WELCOME[TEXT_WELCOME.length - 2], // Top right corner
  p4: TEXT_WELCOME[TEXT_WELCOME.length - 3], // Bottom right corner
};

const OBJECTS_DEFINED = {
  overComputer: {
    points: OVER_COMPUTER,
    configs: OVER_COMPUTER_CONFIG,
  },
  title: {
    points: TITULO,
    configs: { ...TITLE_CONFIG, strokeWidth: "0" },
  },
  titleWelcome: {
    points: TITULO_WELCOME,
    configs: { ...TITLE_WELCOME_CONFIG, strokeWidth: "0" },
  },
  textWelcome: {
    points: TEXT_WELCOME,
    configs: { ...TEXT_WELCOME_CONFIG, strokeWidth: "0" },
  },
  // libro: {
  //   points: LIBRO,
  //   configs: LIBRO_CONFIGS,
  // },
};

const { title, overComputer, titleWelcome, textWelcome } = OBJECTS_DEFINED;

const TEXT_ELEMENTS = {
  title,
  overComputer,
  titleWelcome,
  textWelcome,
};

const DATA = {
  image: Img,
  imageComic: ImgComic,
  imageObjects: ImgObjects,
  textElements: TEXT_ELEMENTS,
  objectsDefined: OBJECTS_DEFINED,
  svgObjects: {
    wall: {
      ...BooksWall,
    },
    titanium: { ...Titanium },
    max: { ...Max },
    topLevel: { ...TopLevel },
    vinozeros: { ...Vinozeros }, // TODO: ADDED ONLY TO SHOW THE TEXT ELEMENTS ON EDIT MODE.
    midLevel: { ...MidLevel },
    bottomLevel: { ...BottomLevel },
    valparaiso: { ...Valparaiso },
  },
};

export default DATA;
