const shadowLighter = [
  [554, 773.5],
  [834, 766.5],
  [812, 413.5],
  [799, 410.5],
  [515, 448.5],
].map(([px, py]) => ({ px, py }));

const shadowDarker = [
  [555, 771],
  [821, 758],
  [799, 407],
  [516, 447],
].map(([px, py]) => ({ px, py }));

const background = [
  [514, 448],
  [789, 411],
  [814, 762],
  [552, 773],
  [514, 448],
].map(([px, py]) => ({ px, py }));

const bgInner = [
  [524, 457],
  [560, 765],
  [804, 756],
  [781, 421],
  [524, 457],
].map(([px, py]) => ({ px, py }));

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(222 217 209)", // "rgba(4, 244, 4, 0.2)",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(215 209 202)",
    },
  },
  background: {
    points: background,
    configs: {
      backgroundColor: "rgb(9 2 0)",
    },
  },
  bgInner: {
    points: bgInner,
    configs: {
      backgroundColor: "rgb(224 212 194)",
    },
  },
};
