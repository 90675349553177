import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";
import { LocalJson, Language } from "./types";
import LanguagesDropdown from "./LanguagesDropdown";
import { LanguageContext } from "../../AppContext";
export { LanguagesDropdown };

export const useLocal = (localJson: LocalJson, attr: string) => {
  const [state, setState] = useContext(AppContext) as any;
  const languageSelected = state.language as Language;
  return localJson[languageSelected] === undefined ? localJson["en"][attr] : localJson[languageSelected][attr];
};
// export const useLanguage = () => {
//   const [state, setState] = useContext(AppContext) as any;
//   const [language, setLanguage] = useState<Language>(state.language);

//   useEffect(() => {
//     console.log("useLanguage -> useEffect", language);
//     setState((e: any) => ({ ...e, language: language }));
//   }, [language]);

//   return [language, setLanguage] as const;
// };

// Define the custom hook to access language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }

  return context;
};
