import React, { useState, useEffect } from "react";
import * as Helpers from "./helpers";
import { useLogo } from "./useLogo";
import "./styles.scss";

type Props = {
  className?: string;
  onCLick?: () => void;
};

const CONFIGS = {
  width: 150,
};

// const heightInnerlogo = (2 * CONFIGS.width) / (2 * Math.sqrt(3));
const heightInnerlogo = CONFIGS.width / 2;

export const LogoInnerSmallTriangle: React.FC<Props> = ({ className = "", onCLick = () => {} }) => {
  const [elems, setElems] = useState(Helpers.innerSmallTrianglePoints(CONFIGS.width));
  const [rotation, rotate] = useLogo();

  useEffect(() => {
    // console.log("rotation", rotation);
  }, [rotation]);

  return (
    <>
      <svg
        className={["inner-small-triagle", className].join(" ")}
        width={CONFIGS.width * 0.2}
        viewBox={`0 0 ${CONFIGS.width * 0.3} ${CONFIGS.width * 0.33}`}
        onClick={onCLick}
      >
        <g
          style={{
            transform: `translate(${CONFIGS.width / 8}px, ${heightInnerlogo / 3}px) rotate(90deg)`,
          }}
        >
          {elems.map((elem, i) => {
            return (
              <polyline
                key={elem.className + " " + i}
                className={elem.className}
                points={elem.points.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
              />
            );
          })}
        </g>
      </svg>
    </>
  );
};
