export default {
  threeDots: {
    delayToAppear: {
      normal: 300,
      fast: 100,
    },
    duration: {
      normal: 300,
      fast: 100,
    },
  },
};
