import Img from "assets/defined_fotos/logo_creation_min.jpg";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */
import monitorData from "./monitor";
import computerData from "./computer";
import logoData from "./logo";
import papersData from "./papeles";
import deskData from "./desk";
import leatherData from "./leather";
import fotosData from "./foto";
import cupData from "./cup";
import penDAta from "./pen";

const OBJECTS_DEFINED = {
  ...monitorData,
  ...deskData,
  ...leatherData,
  ...fotosData,
  ...computerData,
  ...cupData,
  ...papersData,
  ...logoData,
  ...penDAta,
};

const { computerScreen, monitor, papersPage3, papersPage1 } = OBJECTS_DEFINED;

const TEXT_ELEMENTS = {
  computerScreen,
  monitor,
  papersPage3,
  papersPage1,
};

export default {
  image: Img,
  objectsDefined: OBJECTS_DEFINED,
  textElements: TEXT_ELEMENTS,
  svgObjects: {
    monitor: {
      ...monitorData,
    },
    papers: {
      ...papersData,
    },
  },
};
