const [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12] = [
  [515, 976],
  [710, 989],
  [609, 891],
  [621, 983],
  [655, 938],
  [561, 932],
  [589, 956],
  [636, 960],
  [611, 935],
  [561, 963],
  [661, 970],
  [610, 924],
];

const T1 = [p10, p1, p4].map(([px, py]) => ({ px, py }));
const T2 = [p11, p4, p2].map(([px, py]) => ({ px, py }));
const T3 = [p11, p2, p5].map(([px, py]) => ({ px, py }));
const T4 = [p12, p5, p3].map(([px, py]) => ({ px, py }));
const T5 = [p12, p3, p6].map(([px, py]) => ({ px, py }));
const T6 = [p10, p6, p1].map(([px, py]) => ({ px, py }));
const T7 = [p7, p6, p10].map(([px, py]) => ({ px, py }));
const T8 = [p7, p10, p4].map(([px, py]) => ({ px, py }));
const T9 = [p8, p4, p11].map(([px, py]) => ({ px, py }));
const T10 = [p8, p11, p5].map(([px, py]) => ({ px, py }));
const T11 = [p9, p5, p12].map(([px, py]) => ({ px, py }));
const T12 = [p9, p12, p6].map(([px, py]) => ({ px, py }));
const T13 = [p9, p6, p7].map(([px, py]) => ({ px, py }));
const T14 = [p7, p4, p8].map(([px, py]) => ({ px, py }));
const T15 = [p8, p5, p9].map(([px, py]) => ({ px, py }));
const T16 = [p9, p7, p8, p9].map(([px, py]) => ({ px, py }));

export default {
  t1: {
    points: T1,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t2: {
    points: T2,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t3: {
    points: T3,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t4: {
    points: T4,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t5: {
    points: T5,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t6: {
    points: T6,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t7: {
    points: T7,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t8: {
    points: T8,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t9: {
    points: T9,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t10: {
    points: T10,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t11: {
    points: T11,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t12: {
    points: T12,
    configs: { backgroundColor: "rgba(168,108,12,.6705882353)" },
  },
  t13: {
    points: T13,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t14: {
    points: T14,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t15: {
    points: T15,
    configs: { backgroundColor: "rgba(252,188,16,.5411764706)" },
  },
  t16: {
    points: T16,
    configs: { backgroundColor: "rgba(253,210,43,.631372549)" },
  },
};
