import RockefellerData from "./rockefeller";
import DesktopData from "./my_desk";
import Layout3Screens from "./layout_3_screens";
import ConDiego from "./con_diego_selfie";
import OriginalIdea from "./original_idea";
import LogoIdea from "./logo_idea";
import IgnacioFront from "./ignacio_front";
import Books from "./books";

import * as Types from "./types";

export const FOTOS_DATA = {
  rockefeller: RockefellerData as Types.TFotoData<keyof typeof RockefellerData.objectsDefined>,
  desktop: Layout3Screens,
  oldDesktop: DesktopData as Types.TFotoData<keyof typeof DesktopData.objectsDefined>,
  // conDiego: ConDiego as Types.TFotoData<keyof typeof ConDiego.objectsDefined>,
  conDiego: ConDiego,
  originalIdea: OriginalIdea,
  logoIdea: LogoIdea,
  ignacioFront: IgnacioFront,
  books: Books,
};
