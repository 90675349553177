import introduccionData from "./1-introduction.json";
import introduccionData2 from "./1-introduction copy.json";
import aboutMeData from "./2-about-me.json";
import aboutMeData2 from "./2-about-me copy.json";
import mySkillsData from "./3-my-skills.json";
import mySkillsData2 from "./3-my-skills copy.json";
import contactData from "./4-contact.json";
import * as Types from "./types";
export { Types as PortfolioStoryTextsTypes };

const PortfolioStoryTexts: Types.StoryScene[] = [
  {
    name: "introduction",
    content: introduccionData as unknown as Types.StoryScene["content"],
  },
  {
    name: "about-me",
    content: aboutMeData as unknown as Types.StoryScene["content"],
  },
  {
    name: "my-skills",
    content: mySkillsData as unknown as Types.StoryScene["content"],
  },
  {
    name: "contact",
    content: contactData as unknown as Types.StoryScene["content"],
  },
];

export const ClaudeStoryContent: Types.StoryScene[] = [
  {
    name: "introduction",
    content: introduccionData2 as unknown as Types.StoryScene["content"],
  },
  {
    name: "about-me",
    content: aboutMeData2 as unknown as Types.StoryScene["content"],
  },
  {
    name: "my-skills",
    content: mySkillsData2 as unknown as Types.StoryScene["content"],
  },
  {
    name: "contact",
    content: contactData as unknown as Types.StoryScene["content"],
  },
];
export default PortfolioStoryTexts;
