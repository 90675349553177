/**
 * StoryHighOrderData
 * it defines the data needed for the data structure, in order to be able to render the story.
 * all content, animations and styles use this data.
 */

export const STORY_STRUCTURE_DATA = {
  scenes: ["introduction", "mid-section", "culture-map"] as const,
  storyMoments: ["rendering-intro", "rendering-mid-section", "rendering-culture-map"] as const,
  textElements: ["books-mid-0", "top-frame", "middle-frame", "bottom-frame"] as const,
};
