import BackgroundClient, { TextElement } from "services/BackgroundClient";
import { FOTOS_DATA } from "components/FotoCatalog/Data";
import StoryData, { PortfolioStoryTextsTypes, ClaudeStoryContent } from "./texts";
import Subtitle from "./subtitle";
import * as d3 from "d3";
import * as Services from "layout/client/LayoutScroll/services/sections";
import { ChatAPI } from "api";
import Configs from "configs";
import Fotos from "./fotos";
import { STORY_STRUCTURE_DATA } from "./configs";
import MyAnalytics from "services/Analytics";

type PortafolioScenes = PortfolioStoryTextsTypes.PortafolioScenes;
type TextElements = PortfolioStoryTextsTypes.TextElements;
type StoryMoments = PortfolioStoryTextsTypes.StoryMoments;

const ID_ORIGINAL = FOTOS_DATA.originalIdea;
const ID_THREE_SCREENS = FOTOS_DATA.desktop;
const ID_NACHO_FRONT = FOTOS_DATA.ignacioFront;
const ID_THREE_SCREEN_IPAD = FOTOS_DATA.desktop;

type TtextFormatted = {
  h1?: string[];
  p?: string[];
};

type BgClientThreeScreens = BackgroundClient<
  keyof typeof ID_THREE_SCREENS.objectsDefined,
  keyof typeof ID_THREE_SCREENS.svgObjects,
  keyof typeof ID_THREE_SCREENS.textElements
>;

type BgClientOriginal = BackgroundClient<
  keyof typeof ID_ORIGINAL.objectsDefined,
  keyof typeof ID_ORIGINAL.svgObjects,
  keyof typeof ID_ORIGINAL.textElements
>;

type BgClientNachoFront = BackgroundClient<
  keyof typeof ID_NACHO_FRONT.objectsDefined,
  keyof typeof ID_NACHO_FRONT.svgObjects,
  any
>;

interface StoryRender {
  goToScene: (sceneIndex: number) => void;
}

export class PortfolioStory implements StoryRender {
  public static STORY_TEXTS_CALCULATED: Record<
    "es" | "en" | "de",
    Record<PortafolioScenes, Record<TextElements, TtextFormatted>[][]>
  >;
  public static StoryTexts: Record<PortafolioScenes, Record<TextElements, TtextFormatted>[][]>;
  public static scenes = STORY_STRUCTURE_DATA.scenes;
  private renderedAsPortfolio: boolean;
  private bgClientTitle: BgClientOriginal;
  private bgClientMonitor: BgClientOriginal;
  private bgClientComputer: BgClientThreeScreens;
  private bgClientNachoFront: BgClientNachoFront;
  private bgClientIpad: BgClientThreeScreens;
  private subtitle: Subtitle;
  private textElements: Record<TextElements, TextElement>;

  private storyFotos: typeof Fotos;
  private renderValue: number;
  public currentScene: PortafolioScenes;
  private SectionsServ: Services.ImpSections;

  constructor(
    private lg: string,
    renderAs: "portfolio" | "claude-3",
    idSvgContainerTitle: string,
    idSvgContainerMonitor: string,
    idSvgContainerComputer: string,
    idSvgContainerMeFront: string,
    idSvgContainerIpad: string
  ) {
    this.renderedAsPortfolio = renderAs === "portfolio";
    this.renderValue = 0;
    this.bgClientTitle = new BackgroundClient(ID_ORIGINAL as any, idSvgContainerTitle);
    this.bgClientMonitor = new BackgroundClient(ID_ORIGINAL as any, idSvgContainerMonitor);
    this.bgClientComputer = new BackgroundClient(ID_THREE_SCREENS as any, idSvgContainerComputer);
    this.bgClientNachoFront = new BackgroundClient(ID_NACHO_FRONT as any, idSvgContainerMeFront);
    this.bgClientIpad = new BackgroundClient(ID_THREE_SCREEN_IPAD as any, idSvgContainerIpad);

    this.subtitle = new Subtitle();
    this.subtitle.init();
    this.subtitle.terminate();

    this.textElements = {
      background: this.bgClientTitle.textElements.textWelcome,
      "monitor-screen": this.bgClientMonitor.textElements.monitor,
      "monitor-tab-main": this.bgClientMonitor.textElements.monitorMain,
      "monitor-tab-left": this.bgClientMonitor.textElements.monitorTabMiro,
      "monitor-tab-middle": this.bgClientMonitor.textElements.monitorTabNotion,
      "monitor-tab-right": this.bgClientMonitor.textElements.monitorTabGoogle,
      ipad: this.bgClientIpad.textElements.ipad,
      computer: this.bgClientComputer.textElements.computer,
      "monitor-tab-right-small": this.bgClientMonitor.textElements.monitorTabMusic,
    };
    this.addTextClipDefs();
    // this.storyTexts = (PortfolioStoryTextsFormatted as Record<string, any>)[lg];
    PortfolioStory.STORY_TEXTS_CALCULATED = {
      es: this._setTexts("es") as any,
      en: this._setTexts("en") as any,
      de: this._setTexts("de") as any,
    };

    PortfolioStory.StoryTexts =
      PortfolioStory.STORY_TEXTS_CALCULATED[lg as keyof typeof PortfolioStory.STORY_TEXTS_CALCULATED];

    this.storyFotos = Fotos;
    this.currentScene = PortfolioStory.scenes[0];

    const SECTIONS_DATA = PortfolioStory.scenes.map((e) => ({
      name: e,
      scrollConsumed: Math.round(10000 / PortfolioStory.scenes.length),
    }));

    const SectionsServ = new Services.ImpSections(SECTIONS_DATA);
    this.SectionsServ = SectionsServ;

    // Write storyTexts to .storedData.json
  }

  private addTextClipDefs = () => {
    const clippedExceptions = ["textWelcome", "titleWelcome"];
    Object.values(this.textElements).forEach((e) => {
      if (clippedExceptions.includes(e.refId)) return;
      // Check if 'defs' element exists
      let defs = e.client.svg.select("defs");
      if (defs.empty()) {
        defs = e.client.svg.append("defs") as any;
      }
      defs
        .append("clipPath")
        .attr("id", "clip-" + e.refId)
        .append("path")
        .attr("d", e.svgPath);
    });
  };

  public renderContactForm = () => {
    // Select the SVG container
    const groupContainer = this.textElements["monitor-tab-main"].group;
    if (!groupContainer.select("g.contact-form").empty()) return;

    const { px, py } = this.textElements["monitor-tab-main"].points[0];
    const group = groupContainer.append("g").attr("class", "contact-form").attr("transform", `translate(${px}, ${py})`);

    const submitForm = async () => {
      const fromInput = document.getElementById("fromInput") as HTMLInputElement;
      const contentInput = document.getElementById("contentInput") as HTMLTextAreaElement;

      // Simple email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(fromInput.value)) {
        alert("Invalid email address");
        return;
      }

      const payload = {
        email: fromInput.value,
        content: contentInput.value,
      };

      await ChatAPI.contactForm(payload.email, payload.content);

      fromInput.value = "";
      contentInput.value = "";

      MyAnalytics.capture("contact_form_sent", payload);

      alert("Email sent successfully! I'll reply you as soon as possible.");

      //Construct the mailto URL
      // const mailtoUrl = `mailto:ig.ruiz@proton.me?subject=Contact&body=${encodeURIComponent(payload.content)}`;

      // Open the default email client
      // window.location.href = mailtoUrl;
    };

    group
      .append("foreignObject")
      .attr("x", 10)
      .attr("y", 10)
      .attr("width", 320)
      .attr("height", 40)
      .html('<input type="text" id="fromInput" name="from" placeholder="Your email">');

    group
      .append("foreignObject")
      .attr("class", "text-area-content")
      .attr("x", 10)
      .attr("y", 60)
      .attr("width", 320)
      .attr("height", 100)
      .html(
        '<textarea id="contentInput" name="content" placeholder="Hey I liked this, I would like to..."></textarea>'
      );

    group
      .append("foreignObject")
      .attr("x", 10)
      .attr("y", 170)
      .attr("width", 200) // Adjust the width of the foreignObject to fit the SVG
      .attr("height", 80)
      .html(
        `
      <div xmlns="http://www.w3.org/1999/xhtml">
        <svg
          class="btn-send"
          id="btn-send"
          width="100%"
          height="100%"
          viewBox="0 0 500 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect class="outter" x="2" y="2" width="495" height="96" rx="10" ry="10"></rect>
          <rect class="inner" x="15" y="15" width="470" height="70" rx="8" ry="8"></rect>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="16">
            Send
          </text>
        </svg>
      </div>
    `
      );

    const button = document.getElementById("btn-send");
    if (button) {
      button.addEventListener("click", submitForm);
    }
  };

  public goToScene = (sceneIndex: number) => {
    this.currentScene = PortfolioStory.scenes[sceneIndex];
    const isContactScene = this.currentScene === "contact";
    this.SectionsServ.goToSection(sceneIndex, isContactScene ? "bottom" : "top");
  };

  public renderSvg = () => {
    // return this.bgClient.renderSvgObject("monitor");
    this.bgClientTitle.useElements([], ["titleWelcome", "textWelcome"], false);
    this.bgClientMonitor.useElements(
      ["monitor", "lamp"],
      [
        "monitorTabGoogle",
        "monitorTabNotion",
        "monitorTabMiro",
        "monitorTabMiroDetailTop",
        "monitorTabMusic",
        "monitorMain",
        "ventiladorWinds",
        "ventiladorPerilla",
        "ventiladorDot",
      ],
      true
    );
    this.bgClientComputer.useElements(["computer"], ["computer"], true);
    this.bgClientIpad.useElements(["ipad"], ["ipad"], true);
    // this.bgClientNachoFront.useElements(["camisa", "cara", "lentes", "ojos", "boca"], []);
    // this.bgClientNachoFront.useElements(["camisa", "cara", "lentes", "ojos", "boca"], []);
    // this.addClips();

    Object.values(this.textElements).forEach((e) => {
      e.setGroup();
    });
  };

  public updateTexts = (lg: string) => {
    PortfolioStory.StoryTexts =
      PortfolioStory.STORY_TEXTS_CALCULATED[lg as keyof typeof PortfolioStory.STORY_TEXTS_CALCULATED];
    this.webhookScrolling(this.renderValue);
  };

  public webhookScrolling = (renderValue: number) => {
    this.renderValue = renderValue;
    let momentRenderValue = 0;
    this.rotateVentilator(renderValue);

    const progresses = this.SectionsServ.progressesRefactored(renderValue * 10000);
    const currentScene = PortfolioStory.scenes[this.SectionsServ.current];
    const [isStep1, isStep2, isStep3, isStep4] = PortfolioStory.scenes.map((e) => e === currentScene);
    const [renderVal1, renderVal2, renderVal3, renderVal4] = progresses;

    this.currentScene = currentScene;

    switch (true) {
      case isStep1:
        this.bgClientMonitor.elements.monitorMain.classed("visible", false);
        this.handlerClassName("introduction", "rendering-main-words");
        this.handlerTexts("introduction", "rendering-main-words", renderVal1);
        !this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["claude_table"], false);

        break;

      case isStep2: {
        const newNumAnimations = Object.keys(PortfolioStory.StoryTexts["about-me"]).length;
        const newCostPerAnimation = 1 / newNumAnimations; // this is assuming each animation cost same.
        momentRenderValue = renderVal2 / newCostPerAnimation;
        this.bgClientMonitor.elements.monitorMain.paintBackground(1);
        this.bgClientMonitor.elements.monitorMain.classed("visible", true);

        this.handlerClassName("about-me", "rendering-monitor");
        this.handlerTexts("about-me", "rendering-monitor", momentRenderValue);
        this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["miro-idea"], true);
        !this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["claude_vision"], false);

        if (renderVal2 < newCostPerAnimation) break;
        momentRenderValue = (renderVal2 - newCostPerAnimation) / newCostPerAnimation;
        this.handlerTexts("about-me", "rendering-text-and-monitor", momentRenderValue);
        this.handlerClassName("about-me", "rendering-text-and-monitor");
        !this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["claude_idea"], false);

        if (renderVal2 < 2 * newCostPerAnimation) break;
        momentRenderValue = (renderVal2 - 2 * newCostPerAnimation) / newCostPerAnimation;
        this.handlerTexts("about-me", "rendering-monitor-2", momentRenderValue);
        this.handlerClassName("about-me", "rendering-monitor");
        !this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["claude_idea_template"], false);
        break;
      }

      case isStep3: {
        this.bgClientMonitor.elements.monitorMain.classed("visible", !this.renderedAsPortfolio);
        const newNumAnimations = Object.keys(PortfolioStory.StoryTexts["my-skills"]).length;
        const newCostPerAnimation = 1 / newNumAnimations;
        momentRenderValue = renderVal3 / newCostPerAnimation;
        this.handlerClassName("my-skills", "rendering-main-words");
        this.handlerTexts("my-skills", "rendering-main-words", momentRenderValue);

        this.renderedAsPortfolio && this.handlerRenderImageDesk(momentRenderValue);
        !this.renderedAsPortfolio &&
          this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["claude_clap"], true);

        if (renderVal3 < newCostPerAnimation) break;
        momentRenderValue = Math.max((renderVal3 - newCostPerAnimation) / newCostPerAnimation, 0.18);
        this.handlerTexts("my-skills", "rendering-monitor", momentRenderValue);
        this.handlerClassName("my-skills", "rendering-monitor");
        this.bgClientMonitor.elements.monitorMain.classed("visible", !this.renderedAsPortfolio);

        this._stateStep3End();

        break;
      }

      case isStep4:
        {
          this._stateStep3End();

          this.handlerClassName("contact", "rendering-end-intro");
          const newNumAnimations = Object.keys(PortfolioStory.StoryTexts["contact"]).length;
          const newCostPerAnimation = 1 / newNumAnimations;
          momentRenderValue = Math.max(renderVal4 / newCostPerAnimation, 0.22);
          this.handlerTexts("contact", "rendering-end-intro", momentRenderValue);

          if (renderVal4 < newCostPerAnimation) break;
          this.handlerClassName("contact", "rendering-end");
          this.textElements["ipad"].renderImgRefactored(this.storyFotos["me_real"], false);
          momentRenderValue = (renderVal4 - newCostPerAnimation) / newCostPerAnimation;
          this.handlerTexts("contact", "rendering-end", momentRenderValue);
          this.renderContactForm();
          // this.assignClick();
        }
        break;

      default: {
        console.log("Number is outside the defined ranges");
        this.handlerClassName("contact", "rendering-end");
        this.textElements["ipad"].renderImgRefactored(this.storyFotos["me_real"], false);
        this.handlerTexts("contact", "rendering-end", 0.99);
        this.renderContactForm();
      }
    }
  };

  private _stateStep3End = () => {
    this.textElements["monitor-tab-right-small"].renderImgRefactored(this.storyFotos["my_stack_google"], false);
    this.textElements["monitor-tab-right"].renderImgRefactored(this.storyFotos["my_stack_typescript"], false);
    this.renderedAsPortfolio && this.textElements["ipad"].renderImgRefactored(this.storyFotos["my_stack_miro"], false);
    this.renderedAsPortfolio &&
      this.textElements["computer"].renderImgRefactored(this.storyFotos["my_stack_node"], false);
    !this.renderedAsPortfolio &&
      this.textElements["monitor-tab-middle"].renderImgRefactored(this.storyFotos["claude_back"], false);
    !this.renderedAsPortfolio &&
      this.textElements["ipad"].renderImgRefactored(this.storyFotos["claude_character"], false);
    !this.renderedAsPortfolio &&
      this.textElements["computer"].renderImgRefactored(this.storyFotos["claude_diagram"], false);
  };

  private handlerClassName = (currentScene: PortafolioScenes, currentRenderMoment: StoryMoments) => {
    const sceneClass = "scene-" + currentScene;
    const classesToAdd = [sceneClass, currentRenderMoment].join(" ");

    const classToDelete = [
      STORY_STRUCTURE_DATA.storyMoments.filter((e) => e !== currentRenderMoment).join(" "),
      STORY_STRUCTURE_DATA.scenes
        .filter((e) => e !== currentScene)
        .map((e) => "scene-" + e)
        .join(" "),
    ].join(" ");

    this.bgClientTitle.svg.classed(classToDelete, false);
    this.bgClientNachoFront.svg.classed(classToDelete, false);
    this.bgClientMonitor.svg.classed(classToDelete, false);
    this.bgClientComputer.svg.classed(classToDelete, false);
    this.bgClientIpad.svg.classed(classToDelete, false);

    this.bgClientMonitor.svg.classed(classesToAdd, true);
    this.bgClientTitle.svg.classed(classesToAdd, true);
    this.bgClientNachoFront.svg.classed(classesToAdd, true);
    this.bgClientComputer.svg.classed(classesToAdd, true);
    this.bgClientIpad.svg.classed(classesToAdd, true);
  };

  private handlerTexts = (currentScene: PortafolioScenes, currentMoment: StoryMoments, renderValue: number) => {
    const isAboutMe = currentScene === "about-me";
    const isContact = currentScene === "contact";

    Object.entries(PortfolioStory.StoryTexts[currentScene][currentMoment as any]).forEach(([objectName, data]) => {
      const { h1, p } = data as any;

      const textElement = this.textElements[objectName as TextElements];

      switch (objectName) {
        case "background": {
          if (isAboutMe) {
            const isOnRenderinMonitor = currentMoment === "rendering-monitor"; // this has to be handle by the data.
            const isOnRenderingInterfaces = currentMoment === "rendering-text-and-monitor"; // this has to be handle by the data.
            if (isOnRenderinMonitor) {
              textElement.renderTextRefactored({ header: h1, paragraph: p }, 0.99, "section", objectName);
              return;
            }
            if (isOnRenderingInterfaces) {
              textElement.renderTextRefactored(
                { header: h1, paragraph: p },
                Math.min(5 * renderValue, 0.99),
                "section",
                "",
                currentScene,
                currentMoment,
                objectName
              );
              return;
            }
          }
          textElement.renderTextRefactored(
            { header: h1, paragraph: p },
            renderValue,
            "moving-up",
            "",
            currentScene,
            currentMoment,
            objectName
          );
          return;
        }
        case "monitor-tab-main": {
          if (isAboutMe) {
            textElement.renderTextRefactored(
              { header: h1, paragraph: p },
              renderValue,
              "moving-up",
              "",
              currentScene,
              currentMoment,
              objectName
            );
            return;
          }
          if (isContact) {
            const isOnRenderingEnd = currentMoment === "rendering-end";

            if (isOnRenderingEnd) {
              // textElement.renderTextRefactored({ header: h1, paragraph: p }, 0.99, "section");

              textElement.renderTextRefactored(
                { header: h1, paragraph: p },
                0.99,
                "section",
                "",
                currentScene,
                currentMoment,
                objectName
              );
              return;
            }

            textElement.renderTextRefactored(
              { header: h1, paragraph: p },
              renderValue,
              "moving-up",
              "",
              currentScene,
              currentMoment,
              objectName
            );
            return;
          }
          break;
        }
        case "monitor-tab-middle": {
          if (isContact) {
            textElement.renderTextRefactored(
              { header: h1, paragraph: p },
              0.99,
              "section",
              "",
              currentScene,
              currentMoment,
              objectName
            );
            return;
          }
        }
        default:
          break;
        // return;
      }
      textElement.renderTextRefactored(
        { header: h1, paragraph: p },
        renderValue,
        "moving-up",
        "",
        currentScene,
        currentMoment,
        objectName
      );
    });

    const renderOnSubtitle = () => {
      // const { h1, p } = this.textsFormatted.subtitle;
      const [h1, p] = [[""], [""]]; // = this.storyTexts["introduction"];

      // this.subtitle.renderText({ header: h1, paragraph: p }, renderValue);
      // this.subtitle.renderTextRefactored({ header: h1, paragraph: p }, renderValue);
    };
    //renderOnSubtitle();
  };

  private assignClick = () => {
    const onClick = () => {
      console.log("I have clickled it, the notion Tab.");

      this._activateSubtitle();

      const element = this.bgClientMonitor.svg.select(`path.${this.bgClientMonitor.elements.monitorTabNotion.refId}`);

      element
        .transition()
        .duration(1000) // Transition duration in milliseconds
        .attrTween("d", function () {
          // Use d3.interpolatePath to interpolate between the initial and target paths
          var interpolate = d3.interpolateString(
            "M459,234 488,504 728,469 728,469 706,195z",
            "M385,234 417,515 615,485 869,450 850,162 693,191 500,222z"
          );
          return function (t) {
            return interpolate(t);
          };
        });
    };
    this.bgClientMonitor.elements.monitorTabNotion.assignClick(onClick);
  };

  private rotateVentilator = (renderValue: number) => {
    this.bgClientMonitor.elements.ventiladorWinds.rotate({ px: 89, py: 506 }, renderValue * 7800);
  };

  private _activateSubtitle = () => {
    this.subtitle.init();
  };

  private _setTexts = (lg: string) => {
    // console.log("🚀 ~ PortfolioStory ~ lg:", lg);
    const dataToUse = this.renderedAsPortfolio ? StoryData : ClaudeStoryContent;
    const storyTexts: [string, any][] = dataToUse.reduce((acc, { name, content }) => {
      return {
        ...acc,
        [name]: content[lg as "es" | "en" | "de"].reduce((acc, { moment, texts }) => {
          return {
            ...acc,
            [moment]: Object.entries(texts).reduce((acc, [object, data]) => {
              return {
                ...acc,
                [object]: this.textElements[object as TextElements].formatText(data as any, name, moment),
              };
            }, {} as { [key: string]: any }),
          };
        }, {}),
      };
    }, {} as { [key: string]: any }) as any;

    return storyTexts;
  };

  private handlerRenderImageDesk = (renderValue: number) => {
    if (renderValue < 0.33) {
      this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["my_desk_original"], true);
    } else if (renderValue < 0.66) {
      this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["my_desk_elements_chroma"], true);
    } else {
      this.textElements["monitor-tab-left"].renderImgRefactored(this.storyFotos["my_desk_elements"], true);
    }
  };
}

// const calculateSubtitleLg = (lg: keyof typeof TextsData) => {
//   switch (lg) {
//     case "es":
//       return "en";
//     case "en":
//       return "es";
//     default:
//       return "en";
//   }
// };
