import React, { useEffect, useState, useCallback, useContext } from "react";
import AnimationProgressBar from "./components/AnimationProgressBar";
import { LanguagesDropdown } from "services/translate";
import * as Sections from "sections";
import * as Services from "./services/sections";
import SocialMediaFrame from "./services/social_media";
import * as Configs from "./configs";
import * as Types from "./types";
import "./styles.scss";
import { AppContext } from "AppContext";
import MyAnalytics from "services/Analytics";

const renderAs = "post";
const SocialMediaFrameServ = new SocialMediaFrame({ width: window.innerWidth, height: window.innerHeight }, renderAs);
const SectionsServ = new Services.ImpSections(Configs.sectionsData);

const PostLayout: React.FC = () => {
  const [state, setState] = useContext(AppContext) as any;
  const [progresses, setProgresses] = useState<Types.Progresses>({
    renderValues: Configs.sectionsData.map(() => 0),
    currentSeccion: 1,
  });

  const [show, setShow] = useState({
    chat: false,
    btnLoveClicked: state.user?.lovesInstagramPost1,
    furtherActions: false,
  });

  const [smFrame, setSmFrame] = useState({
    smServ: SocialMediaFrameServ,
    innerHeight: window.innerHeight,
  });

  const scrollHandler = useCallback(() => {
    const progresses = SectionsServ.progressesRefactored(window.scrollY);
    // const bottomScrollReached = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    // console.log("scrollHandler -> bottomScrollReached", bottomScrollReached);
    // bottomScrollReached && console.log("trigger event");

    setProgresses({ renderValues: progresses, currentSeccion: SectionsServ.current });
  }, [SectionsServ]);

  useEffect(() => {
    const handleViewportHeightChange = () => {
      const currentViewportHeight = window.innerHeight;

      if (currentViewportHeight > smFrame.innerHeight) {
        smFrame.smServ.refreshValues({ width: window.innerWidth, height: window.innerHeight }, smFrame.smServ.renderAs);
        console.log("Frame Changed to: " + smFrame.smServ.smHeight + "px");
        setSmFrame((e) => ({ ...e, smServ: smFrame.smServ }));
      }
    };

    window.addEventListener("resize", handleViewportHeightChange);
    window.addEventListener("scroll", scrollHandler);

    scrollHandler();

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("resize", handleViewportHeightChange);
    };
  }, [scrollHandler]);

  const clickLove = () => {
    setState({ ...state, user: { ...state.user, lovesIt: !show.btnLoveClicked } });

    MyAnalytics.capture("clicked_love", { value: !show.btnLoveClicked });

    setShow((e) => ({ ...e, btnLoveClicked: !e.btnLoveClicked }));
  };

  return (
    <div
      className={"lyt-scroll social-media-wrapper instagram " + smFrame.smServ.renderAs}
      style={{ height: `${SectionsServ.totalScrollConsumed}px` }}
    >
      <div
        className={"sections-wrapper"}
        style={{ height: `${smFrame.smServ.smHeight}px`, width: `${smFrame.smServ.smWidth}px` }}
      >
        <div
          className={"s-" + Configs.sectionsData[0].name + " " + (progresses.currentSeccion === 1 ? "active" : "")}
          style={{ zIndex: 2 }}
        >
          <Sections.InstagramStory renderValue={progresses.renderValues[0]} />
          <AnimationProgressBar renderValue={progresses.renderValues[0]} width={smFrame.smServ.smWidth} />
        </div>

        <LanguagesDropdown />
        {show.furtherActions && <FurtherActions clickLove={clickLove} btnLoveClicked={show.btnLoveClicked} />}
      </div>
    </div>
  );
};

type Props = {
  clickLove: () => void;
  btnLoveClicked: boolean;
};

const FurtherActions: React.FC<Props> = ({ clickLove, btnLoveClicked }) => {
  const [clicked, setClicked] = useState({ love: btnLoveClicked });

  const handlerClickLove = () => {
    setClicked((e) => ({ ...e, love: !e.love }));
    clickLove();
  };

  return (
    <div className="further-action">
      <p> {clicked.love ? "Love it 🫶!" : "Left me a like"}</p>

      <svg
        className={"btn-love " + (clicked.love ? "clicked" : "")}
        onClick={handlerClickLove}
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        viewBox="0 0 100 100"
      >
        <path
          className="outter"
          d="M 2,11 C 2,11 2,2 12,2 l 75,0 c 0,0 11,0 11,11 l 0,75 c 0,0 0,10 -11,10 L 12,98 C 12,98 2,99 2,88 z"
        ></path>

        <path
          className="inner"
          d="m 6.2,15 c 0,0 0,-9 8.8,-9 l 64,0 c 0,0 11,0 11,11 l 0,63 c 0,0 1,10 -9,10 l -66,0 c 0,0 -8.8,1 -8.8,-10 z"
        />

        <g transform="translate(13, 13) scale(0.7)">
          <svg className={"heart"} viewBox="0 0 1500 1500" width="100" height="100">
            <path
              className="st0"
              d="M541.7 1092.6H376.6c-13 0-23.6-10.6-23.6-23.6V689.9c0-13 10.6-23.6 23.6-23.6h165.1c13 0 23.6 10.6 23.6 23.6V1069c-.1 13-10.7 23.6-23.6 23.6zM622.9 1003.5V731.9c0-66.3 18.9-132.9 54.1-189.2 21.5-34.4 69.7-89.5 96.7-118 6-6.4 27.8-25.2 27.8-35.5 0-13.2 1.5-34.5 2-74.2.3-25.2 20.8-45.9 46-45.7h1.1c44.1.8 58.2 41.6 58.2 41.6s37.7 74.4 2.5 165.4c-29.7 76.9-35.7 83.1-35.7 83.1s-9.6 13.9 20.8 13.3c0 0 185.6-.8 192-.8 13.7 0 57.4 12.5 54.9 68.2-1.8 41.2-27.4 55.6-40.5 60.3-2.6.9-2.9 4.5-.5 5.9 13.4 7.8 40.8 27.5 40.2 57.7-.8 36.6-15.5 50.1-46.1 58.5-2.8.8-3.3 4.5-.8 5.9 11.6 6.6 31.5 22.7 30.3 55.3-1.2 33.2-25.2 44.9-38.3 48.9-2.6.8-3.1 4.2-.8 5.8 8.3 5.7 20.6 18.6 20 45.1-.3 14-5 24.2-10.9 31.5-9.3 11.5-23.9 17.5-38.7 17.6l-411.8.8c-.1.1-22.5.1-22.5-29.9z"
              style={{ transform: "translate(50%,50%) scale(0.1) translate(-50%, -50%)" }}
            ></path>
            <ellipse className={"st1 "} cx="748.9" cy="750" rx="748.9" ry="750"></ellipse>
            <path
              className="st0"
              d="M748.9 541.9C715.4 338.7 318.4 323.2 318.4 628c0 270.1 430.5 519.1 430.5 519.1s430.5-252.3 430.5-519.1c0-304.8-397-289.3-430.5-86.1z"
            ></path>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default PostLayout;
