const MONITOR = [
  [148, 445],
  [181, 743],
  [256, 741.875],
  [331, 740.75],
  [418.5, 741.125],
  [506, 743.5],
  [640, 752],
  [690, 756],
  [900, 778],
  [900, 392],
  [883, 394],
  [839, 396],
  [529, 420.25],
  [480.5, 425],
  [299, 435],
  [146, 444],
].map(([px, py]) => ({ px, py }));

const CORNER_BOTTOM_RIGHT = [
  [528, 556],
  [524.5, 728],
  [626, 734],
  [808, 750],
  [900, 759],
  [900, 524],
  [528, 556],
].map(([px, py]) => ({ px, py }));

const TWO_LINES_TOP_LEFT = [
  [274, 489],
  [281, 601],
  [655, 580],
  [645, 470],
  [274, 489],
].map(([px, py]) => ({ px, py }));

const LAMP = [
  [529, 415.5],
  [713, 402],
  [713, 381],
  [513.125, 396.8125],
  [511.25, 393.625],
  [470.5, 396.25],
  [469, 399.5],
  [442, 402],
  [408.5, 403.5],
  [297, 411],
  [294, 418],
  [294, 425],
  [297.625, 430],
  [475.25, 419],
].map(([px, py]) => ({ px, py }));

const SCREEN = [
  [222, 447],
  [252, 726],
  [412, 725],
  [524.5, 728],
  [626, 734],
  [808, 750],
  [810, 409],
  [222, 447],
].map(([px, py]) => ({ px, py }));

const DETAIL_BOTTOM = [
  [180, 731],
  [181, 743],
  [256, 741.875],
  [331, 740.75],
  [418.5, 741.125],
  [506, 743.5],
  [640, 752],
  [690, 756],
  [900, 778],
  [900, 763],
  [825, 756],
  [729, 746],
  [626, 737],
  [500, 730],
  [411, 728],
  [330.5, 728],
  [250, 730],
  [180, 731],
].map(([px, py]) => ({ px, py }));

const DETAIL_LAMP_TOP = [
  [470.5, 396.25],
  [469, 399.5],
  [468, 403],
  [468, 407],
  [468, 411],
  [468, 415],
  [469, 416],
  [508, 413],
  [507, 408],
  [507, 402],
  [508, 399],
  [509, 397],
  [510, 396],
  [511, 396],
  [513, 397],
  [511, 394],
].map(([px, py]) => ({ px, py }));

const DETAIL_LAMP_BOTTOM = [
  [475.25, 419],
  [480.5, 425],
  [481, 429],
  [514, 427],
  [514, 421],
  [523, 418],
  [523, 421],
  [529, 420.25],
  [529, 415.5],
  [475.25, 419],
].map(([px, py]) => ({ px, py }));

const DETAIL_ARM_RIGHT = [
  [640, 752],
  [605, 828],
  [637, 829],
  [669, 754],
].map(([px, py]) => ({ px, py }));

const DETAIL_ARM_LEFT = [
  [669, 754],
  [637, 829],
  [655, 830],
  [690, 756],
].map(([px, py]) => ({
  px,
  py,
}));

const MONITOR_CONFIG = {
  paddingLeft: 30,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 36,
      color: "black",
      heightLine: 44,
    },
  },
  backgroundColor: "white",
  p1: SCREEN[0], // Top left corner
  p2: SCREEN[1], // Bottom left corner
  p3: SCREEN[SCREEN.length - 2], // Top right corner
  p4: SCREEN[SCREEN.length - 3], // Bottom right corner
};

const TWO_LINES_TOP_LEFT_CONFIG = {
  paddingLeft: 0,
  paddingTop: 0,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 30,
      fontSize: 26,
      color: "black",
      heightLine: 44,
    },
  },
  backgroundColor: "white",
  p1: TWO_LINES_TOP_LEFT[0], // Top left corner
  p2: TWO_LINES_TOP_LEFT[1], // Bottom left corner
  p3: TWO_LINES_TOP_LEFT[TWO_LINES_TOP_LEFT.length - 2], // Top right corner
  p4: TWO_LINES_TOP_LEFT[TWO_LINES_TOP_LEFT.length - 3], // Bottom right corner
};

const CORNER_BOTTOM_RIGHT_CONFIG = {
  paddingLeft: 30,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 36,
      color: "black",
      heightLine: 44,
    },
  },
  backgroundColor: "white",
  p1: CORNER_BOTTOM_RIGHT[0], // Top left corner
  p2: CORNER_BOTTOM_RIGHT[1], // Bottom left corner
  p3: CORNER_BOTTOM_RIGHT[CORNER_BOTTOM_RIGHT.length - 2], // Top right corner
  p4: CORNER_BOTTOM_RIGHT[CORNER_BOTTOM_RIGHT.length - 3], // Bottom right corner
};

const DATA = {
  monitorComplete: {
    points: MONITOR,
    configs: { backgroundColor: "#161616" },
    // configs: { backgroundColor: "black" },
    // childElements: {
    //   monitorFoto: {
    //     points: MONITOR_CHILDREN.foto,
    //   },
    //   monitorBody: {
    //     points: MONITOR_CHILDREN.body,
    //   },
    // },
  },
  monitorCornerBottomRight: {
    points: CORNER_BOTTOM_RIGHT,
    configs: CORNER_BOTTOM_RIGHT_CONFIG,
  },
  twoLinesTopLeft: {
    points: TWO_LINES_TOP_LEFT,
    configs: TWO_LINES_TOP_LEFT_CONFIG,
  },
  monitorLamp: {
    points: LAMP,
    configs: { backgroundColor: "#a49c97" },
  },
  monitor: {
    points: SCREEN,
    configs: MONITOR_CONFIG,
  },
  monitorDetailLampTop: {
    points: DETAIL_LAMP_TOP,
    configs: { backgroundColor: "#6b615e" },
  },
  monitorDetailLampBottom: {
    points: DETAIL_LAMP_BOTTOM,
    configs: { backgroundColor: "#3f4038" },
  },
  monitorDetailBottom: {
    points: DETAIL_BOTTOM,
    configs: { backgroundColor: "#a49c97" },
  },
  monitorDetailArmRight: {
    points: DETAIL_ARM_RIGHT,
    configs: { backgroundColor: "#030303" },
  },
  monitorDetailArmLeft: {
    points: DETAIL_ARM_LEFT,
    configs: { backgroundColor: "#3d3d3d" },
  },
};

export default DATA;
