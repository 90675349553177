const LENTES_BORDE = [
  [515, 493],
  [505, 493],
  [497.5, 495.5],
  [494, 500],
  [485, 490],
  [472.5, 485],
  [460, 482],
  [444, 479.5],
  [435, 479.25],
  [421, 480],
  [407, 484],
  [398.5, 489.5],
  [393, 494],
  [389, 499],
  [370, 497],
  [370, 505],
  [380.5, 505.5],
  [381.75, 503.75],
  [386.375, 504.875],
  [385, 514],
  [385.5, 520],
  [386, 528],
  [395, 553],
  [411, 570],
  [430, 577],
  [454, 576],
  [473, 567],
  [482.5, 558],
  [488, 549],
  [494.5, 536.5],
  [497, 526],
  [498.5, 519],
  [498, 512],
  [498, 503],
  [501, 499],
  [507.5, 497],
  [514, 497],
  [530, 498],
  [536, 503],
  [536, 511],
  [534, 519],
  [535, 527],
  [535, 530],
  [537, 540],
  [539, 547],
  [541, 552],
  [551, 567],
  [560, 576],
  [572, 582],
  [584, 585],
  [598, 586],
  [616, 583],
  [634, 570],
  [642.5, 558],
  [648, 544],
  [649.5, 534.5],
  [650.25, 528.75],
  [650, 525],
  [649, 521],
  [667, 522],
  [677, 523],
  [676, 519.5],
  [668, 515],
  [647, 512],
  [644, 508],
  [638, 502],
  [629.25, 496.25],
  [620.5, 492.5],
  [611.75, 489.75],
  [601, 488],
  [589, 487.5],
  [577, 488],
  [567, 489],
  [558, 491],
  [548, 496],
  [543.5, 500],
  [541, 502],
  [538, 496],
  [531.5, 492.5],
  [524, 492],
].map(([px, py]) => ({ px, py }));

const LENTE_LEFT = [
  [445, 482],
  [435, 481],
  [422, 483],
  [408, 487],
  [400, 491],
  [395, 496],
  [392, 501],
  [389, 505],
  [388, 516],
  [389, 529],
  [391, 538],
  [397, 550],
  [404, 560],
  [413, 568],
  [430, 573],
  [452, 573],
  [470, 565],
  [480, 556],
  [486, 547],
  [491, 535],
  [494, 525],
  [495, 518],
  [495, 512],
  [491, 502],
  [483, 493],
  [472, 487],
  [459, 484],
].map(([px, py]) => ({ px, py }));

const LENTE_RIGHT = [
  [588, 490],
  [577, 490],
  [567, 491],
  [559, 493],
  [549, 499],
  [541, 507],
  [539, 512],
  [537, 520],
  [537, 526],
  [538, 529],
  [540, 539],
  [541, 546],
  [543, 551],
  [552, 564],
  [560, 573],
  [572, 579],
  [584, 582],
  [598, 583],
  [616, 580],
  [632, 568],
  [641, 555],
  [645, 542],
  [646, 534],
  [647, 525],
  [643, 513],
  [641, 509],
  [636, 504],
  [627, 498],
  [619, 494],
  [610, 492],
  [601, 490],
].map(([px, py]) => ({ px, py }));

export default {
  lentesBorde: {
    points: LENTES_BORDE,
    configs: { backgroundColor: "black", strokeWidth: "1", filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));" },
  },
  lentesLenteLeft: {
    points: LENTE_LEFT,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "1" },
  },
  lentesLenteRight: {
    points: LENTE_RIGHT,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "1" },
  },
};
