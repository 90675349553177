import React, { useState, useEffect, createContext, ReactNode } from "react";
import { LocalStorage } from "services/LocalStorage";
import { Language } from "services/translate/types";

// Define the context
interface LanguageContextProps {
  language: Language;
  setLanguage: (language: Language) => void;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

// Define a provider component that will wrap your app
interface LanguageProviderProps {
  children: ReactNode;
}

const locStorage = new LocalStorage();

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const { lg, user } = locStorage;

  const [language, setLanguage] = useState<Language>(lg);

  const contextValue: LanguageContextProps = {
    language,
    setLanguage,
  };

  useEffect(() => {
    locStorage.update({ language, user });
  }, [language]);

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

const AppContext = createContext([{}, () => {}]);

const AppContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { lg, user } = locStorage;
  // console.log("user", "language", user, language);
  const [state, setState] = useState({ language: lg, user });

  useEffect(() => {
    locStorage.update(state);
  }, [state]);

  return <AppContext.Provider value={[state, setState]}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
