import enData from './en';
import deData from './de';
import esData from './es';

const chatData = {
  en: enData,
  de: deData,
  es: esData,
};

export default chatData;
