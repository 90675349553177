import React from "react";
import * as Sections from "sections";
// const oneViewScroll = 813; // This value calibrate the speed of the animation tied to the scroll.
const oneViewScroll = 1000; // This value calibrate the speed of the animation tied to the scroll.
const T = oneViewScroll;

const portfolio = [
  {
    name: "loading",
    scrollConsumed: 0.2 * T,
    section: Sections.Loading as React.FC,
  },
  {
    name: "portfolio",
    scrollConsumed: 5 * T,
    section: Sections.Portfolio as React.FC,
  },
];

const claude = [
  {
    name: "loading",
    scrollConsumed: 0.2 * T,
    section: Sections.Loading as React.FC,
  },
  {
    name: "claude",
    scrollConsumed: 5 * T,
    section: Sections.ClaudeStory as React.FC,
  },
];

export const sectionsData = {
  portfolio,
  claude,
};

export const showStates: Record<keyof typeof sectionsData, { chat: boolean; furtherActions: boolean }> = {
  portfolio: {
    chat: true,
    furtherActions: true,
  },
  claude: { chat: false, furtherActions: true },
};
