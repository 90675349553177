import { sleep } from "utils";

// Assuming T is a string or number type
type ProgressesFormatted<T extends string | number> = (scrollValue: number) => { [key in T]: number };
interface ISections<T extends string | number> {
  // progresses: (scrollValue: number) => number[];
  progressesFormmatted: ProgressesFormatted<T>;
  current: number;
  runLoadingSection: (sectionIndex: number) => void;
}

type SectionData = {
  name: string;
  scrollConsumed: number;
  section?: React.FC;
};

type Section = {
  name: string;
  scrollConsumed: number;
  range: {
    min: number;
    max: number;
  };
};

function generateDataWanted(sectionsData: SectionData[]): (number | number[])[][][] {
  let dataWanted: number[][][] = [];

  let scrollAccumulator = 0;
  sectionsData.forEach((section) => {
    const sectionData: number[][] = [];

    for (let i = 0; i <= section.scrollConsumed; i++) {
      sectionData.push([scrollAccumulator + i, i / section.scrollConsumed]);
    }

    scrollAccumulator += section.scrollConsumed;
    dataWanted.push(sectionData);
  });

  return dataWanted.map((section, sectionIndex) =>
    section.map(([key, val], i) => {
      const progress = dataWanted.map((e, i) => {
        if (i < sectionIndex) return 1;
        if (i > sectionIndex) return 0;
        return val;
      });

      return [key, progress, sectionIndex];
    })
  );
}

export class ImpSections implements ISections<string> {
  static scrollLayoutData: SectionData[];
  private readonly data: Section[];
  public readonly totalScrollConsumed: number;
  public current: number;
  public progressCalculated: any; //Array<[number, number[]]>;

  constructor(sectionData: SectionData[]) {
    this.totalScrollConsumed = sectionData.reduce((acc, { scrollConsumed }) => acc + scrollConsumed, 0);
    if (ImpSections.scrollLayoutData === undefined) {
      this.progressCalculated = generateDataWanted(
        sectionData.map((e) => ({
          ...e,
          scrollConsumed:
            (e.scrollConsumed * (this.totalScrollConsumed - window.innerHeight)) / this.totalScrollConsumed,
        }))
      ).flat();
    } else {
      this.progressCalculated = generateDataWanted(sectionData).flat();
    }

    if (ImpSections.scrollLayoutData === undefined) {
      this.setStaticScrollLayoutData(sectionData);
    }
    this.data = this._setData(sectionData);
    this.current = 0;
  }

  public setStaticScrollLayoutData = (sectionData: SectionData[]) => {
    const TC = sectionData.reduce((acc, { scrollConsumed }) => acc + scrollConsumed, 0);
    ImpSections.scrollLayoutData = sectionData.map((e) => ({
      ...e,
      // scrollConsumed: (e.scrollConsumed * (TC - window.innerHeight)) / TC,
      scrollConsumed: (e.scrollConsumed * (TC - window.innerHeight)) / TC,
    })); // the method depends on the layout to do the calculations, of all children sections.;
  };

  public progressesRefactored = (scrollValue: number) => {
    const data = this.progressCalculated[Math.round(scrollValue)] || [
      scrollValue,
      this.data.map((e) => 1),
      this.data.length - 1,
    ];

    const progress = data[1] as number[];
    // this.current = Math.min(data[2] + 1, this.data.length);
    this.current = data[2];
    return progress;
  };

  private progresses = (scrollValue: number) => {
    return this.data.map(({ scrollConsumed, range }) => {
      if (scrollValue > range.max) {
        return 1;
      } else if (scrollValue < range.min) {
        return 0;
      } else {
        return (scrollValue - range.min) / scrollConsumed;
      }
    });
  };

  public progressesFormmatted = (scrollValue: number) => {
    return this.progresses(scrollValue).reduce((acc, progress, i) => {
      return { ...acc, [this.data[i].name]: progress };
    }, {});
  };

  public runLoadingSection = async () => {
    const deltaScroll = 50;
    const deltaTime = 30; //ms

    const toConsume =
      ImpSections.scrollLayoutData[0].scrollConsumed + 0.084 * ImpSections.scrollLayoutData[1].scrollConsumed;
    const scrollEvent = new Event("scroll");

    for (let val = 0; val <= toConsume; val = val + deltaScroll) {
      // const aux = () => {
      // window.scrollBy(0, deltaScroll);
      window.scrollTo(0, val + deltaScroll);
      window.dispatchEvent(scrollEvent);
      // };
      // requestAnimationFrame(aux);
      await sleep(deltaTime);
    }
  };

  public goToSection = (sectionIndex: number, option: "bottom" | "top" = "top") => {
    const groundVAlue = ImpSections.scrollLayoutData[0].scrollConsumed;
    const totalValue = ImpSections.scrollLayoutData[1].scrollConsumed;
    const renderValue = this.data[sectionIndex].range[option === "bottom" ? "max" : "min"] / 10000;
    const scrollToSet = groundVAlue + renderValue * totalValue;

    if (sectionIndex === 0) {
      window.scrollTo(0, 0);
    } else {
      const valueToASureGetIntoSection = option === "bottom" ? 0 : 90;
      window.scrollTo(0, scrollToSet + valueToASureGetIntoSection);
    }
  };

  private _setData = (dataInput: SectionData[]) => {
    let acc = 0;
    const response = dataInput.map((sectionData) => {
      const range = {
        min: acc,
        max: acc + sectionData.scrollConsumed,
      };
      acc = acc + sectionData.scrollConsumed;

      return {
        ...sectionData,
        range,
      };
    });
    return response;
  };
}
