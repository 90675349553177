import Foto1 from "assets/stories/portfolio/me_homeoffice_starting_profesional.jpeg";
import Foto2 from "assets/stories/portfolio/miro_idea_show_content_max.png";
import Foto3 from "assets/stories/portfolio/my_stack_google.jpg";
import Foto4 from "assets/stories/portfolio/my_stack_miro.png";
import Foto5 from "assets/stories/portfolio/my_stack_node.jpg";
import Foto6 from "assets/stories/portfolio/my_stack_typescript.png";
import Foto7 from "assets/stories/portfolio/my_desk_original.jpg";
import Foto8 from "assets/stories/portfolio/my_desk_elements_chroma.jpg";
import Foto9 from "assets/stories/portfolio/my_desk_elements.jpg";
import Foto10 from "assets/stories/portfolio/me_real.jpg";

export default {
  ["starting_profesional"]: Foto1,
  ["miro-idea"]: Foto2,
  ["my_stack_google"]: Foto3,
  ["my_stack_miro"]: Foto4,
  ["my_stack_node"]: Foto5,
  ["my_stack_typescript"]: Foto6,
  ["my_desk_original"]: Foto7,
  ["my_desk_elements_chroma"]: Foto8,
  ["my_desk_elements"]: Foto9,
  ["me_real"]: Foto10,
};
