import React from "react";
import { Logo, LogoInnerSmallTriangle } from "components/Logo";
import * as Types from "../types";
import "./styles.scss";

type Props = {
  selectedIndex: number;
  onItemClick: (path: string, index: number) => void;
  items: Types.NavbarItem[];
};

type PropsNavbarItem = {
  keY: string;
  item: Types.NavbarItem;
  onItemClick: () => void;
  selected: boolean;
};

const NavbarItem: React.FC<PropsNavbarItem> = ({ item, onItemClick, selected, keY }) => {
  return (
    <div className={selected ? "navbar__item selected" : "navbar__item"} onClick={() => onItemClick()}>
      <LogoInnerSmallTriangle />
      <p className="item text">{item.name}</p>
    </div>
  );
};

const Navbar: React.FC<Props> = ({ onItemClick, items, selectedIndex }) => {
  const [leftItems, rightItem] = [items.slice(0, -1), items.slice(-1)[0]];
  return (
    <div className="navbar">
      <div className="left-items">
        {leftItems.map((item, i) => (
          <NavbarItem
            key={item.name + " " + i}
            keY={item.name + " " + i}
            item={item}
            onItemClick={() => onItemClick(item.path, i)}
            selected={selectedIndex === i}
          />
        ))}
      </div>
      <div className="right-items">
        <NavbarItem
          keY={rightItem.name + " " + (items.length - 1)}
          item={rightItem}
          onItemClick={() => onItemClick(rightItem.path, items.length - 1)}
          selected={selectedIndex === items.length - 1}
        />
      </div>
    </div>
  );
};

export default Navbar;
