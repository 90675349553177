import React, { useEffect, useState, useMemo } from "react";
import "./styles.scss";
import { PortfolioStory } from "./Story";
import { useLanguage } from "services/translate";
import Img from "assets/characters/me_creation.png";
import ImgComputer from "assets/stories/portfolio/computer.png";
import ImgMonitor from "assets/stories/portfolio/monitor.png";
import ImgIpad from "assets/stories/portfolio/ipad.png";
import StorySidebar from "./components/StorySidebar";
import { useNavigate } from "react-router-dom";
import MyAnalytics from "services/Analytics";

const getCurrentSceneIndex = (current: string | undefined) => {
  if (!current) return 0;
  const response = PortfolioStory.scenes.findIndex((e) => e === current);
  return response;
};

type SidebarNames = Record<
  (typeof PortfolioStory.scenes)[number],
  {
    es: string;
    de: string;
    en: string;
  }
>;

const langs: SidebarNames = {
  introduction: {
    es: "Intro",
    de: "Intro",
    en: "Intro",
  },
  "about-me": {
    es: "Sobre Mí",
    de: "Über Mich",
    en: "About Me",
  },
  "my-skills": {
    es: "Mis Skills",
    de: "Meine Skills",
    en: "My Skills",
  },
  contact: {
    es: "Contacto",
    de: "Kontakt",
    en: "Contact",
  },
};

type Props = {
  renderValue: number;
  renderAs?: "portfolio" | "claude-3";
};

const PorfolioStory: React.FC<Props> = ({ renderValue, renderAs = "portfolio" }) => {
  const [storyImp, seStoryImp] = useState<PortfolioStory | undefined>(undefined);
  const navigate = useNavigate();
  const { language } = useLanguage();

  const changePath = useMemo(
    () => (newPath: string) => {
      renderAs === "portfolio" && navigate(language + "/" + newPath);
      renderAs !== "portfolio" && navigate(language + "/" + renderAs);
      MyAnalytics.capture("$page_view");
    },
    [language, navigate]
  );

  useEffect(() => {
    const bgServ = new PortfolioStory(
      language,
      renderAs,
      "ws__svg__original__title",
      "ws__svg__original__monitor",
      "ws__svg__three-screens__computer",
      "ws__svg__ignacio-front",
      "ws__svg__three-screens__ipad"
    );

    seStoryImp(bgServ);
    bgServ.renderSvg();
  }, []);

  useEffect(() => {
    if (renderAs !== "portfolio") return;
    storyImp && changePath(storyImp.currentScene);
  }, [storyImp?.currentScene]);

  useEffect(() => {
    storyImp && storyImp.updateTexts(language);
    storyImp && changePath(storyImp.currentScene);
  }, [language]);

  useEffect(() => {
    storyImp && storyImp.webhookScrolling(renderValue);
  }, [renderValue]);

  const _onClickSidebarItem = useMemo(
    () => (path: string, index: number) => {
      storyImp && storyImp.goToScene(index);
      MyAnalytics.capture("clicked_sidebar_item", { path: path });
    },
    [storyImp]
  );

  const currentSceneIndex = useMemo(() => getCurrentSceneIndex(storyImp?.currentScene), [storyImp?.currentScene]);

  return (
    <>
      {renderAs === "portfolio" && (
        <StorySidebar
          itemNames={langs}
          currentScene={currentSceneIndex}
          _onItemClick={_onClickSidebarItem}
          lg={language}
        />
      )}
      <div className="welcome-section">
        <div className="title-wrapper">
          <svg
            id="ws__svg__original__title"
            xmlns="http://www.w3.org/2000/svg"
            className="original__title"
            preserveAspectRatio="none"
          ></svg>
        </div>

        <svg
          id="ws__svg__original__monitor"
          xmlns="http://www.w3.org/2000/svg"
          className="original__monitor"
          preserveAspectRatio="none"
        >
          <image className="monitor" href={ImgMonitor} width="900" />
        </svg>

        <svg
          id="ws__svg__three-screens__computer"
          className="three-screens__computer"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox={"0 0 900 1200"}
        >
          <image className="computer" href={ImgComputer} width="900" />
        </svg>

        <svg
          id="ws__svg__three-screens__ipad"
          className="three-screens__ipad"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox={"0 0 900 1200"}
        >
          <image className="ipad" href={ImgIpad} width="900" />
        </svg>

        <svg
          id="ws__svg__ignacio-front"
          className="ignacio-front"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox={"0 0 900 1200"}
        >
          <image className="character-intro" href={Img} />
        </svg>
      </div>
    </>
  );
};

export default PorfolioStory;

/** OLD CODE FOR THE EYES */

/**
 * 
 *   // const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  // const [eyesCentroide, setEyesCentroide] = useState({ left: 0, top: 0 });
 * 
 *   // useEffect(() => {
  //   const { left, top } = eyesCentroide;
  //   // const selectedPath = d3.select("path.pupilaIzquierda");
  //   // const { left, top, width, height } = (selectedPath as any).node().getBoundingClientRect();

  //   const { x, y } = clickPosition;
  //   const deltaX = left - x;
  //   const deltaY = top - y;

  //   const getAngleBetweenPoints = () => {
  //     var angleRadians = Math.atan2(deltaY, deltaX);
  //     if (angleRadians < 0) {
  //       angleRadians += 2 * Math.PI;
  //     }
  //     return angleRadians;
  //   };
  //   const angleRad = getAngleBetweenPoints();
  //   const [maxDeltaX, maxDeltaY] = [10, 5];
  //   const [factorX, factorY] = [Math.abs(deltaX) / window.innerWidth, Math.abs(deltaY) / window.innerHeight];
  //   const [deltaXp, deltaYp] = [factorX * maxDeltaX * Math.cos(angleRad), factorY * maxDeltaY * Math.sin(angleRad)];

  //   bgService && bgService.animateEyes(-deltaXp, -deltaYp);
  // }, [clickPosition]);

  // const handleClick = (event: React.MouseEvent) => {
  //   // const mouseX = event.clientX;
  //   // const mouseY = event.clientY;
  //   // setClickPosition({ x: mouseX, y: mouseY });
  // };
 * 
 * 
 */
