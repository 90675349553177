import React from "react";
import { ChatMessageProps } from "./types";
// import { ARRAY_FINJAS_FOTOS } from "assets/finja_fotos";
import { MessageCorner } from "./SVGs";
import * as MessageTypes from "./types";
export { MessageTypes };




export const ChatMessage: React.FC<ChatMessageProps> = ({ text, position = "left", showImage, onClick = () => {} }) => {
  return (
    <div className={`chat__msg-wrapper ${position}`}>
      {showImage && (
        <>
          {/* <img id="msg-img" className="msg-img" alt="img-chat-bot" /> */}
          <div style={{ width: "40px", height: "40px" }}></div>
          <MessageCorner />
        </>
      )}
      {!showImage && <div style={{ width: "40px" }} />}
      <div className={`chat__msg ${position}`} onClick={onClick as any}>
        <p>{text}</p>
      </div>
    </div>
  );
};
