import Img from "assets/defined_fotos/original_idea_3024x4032.jpg";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */
import monitorData from "./monitor";
import lampData from "./lamp";
import computerData from "./computer";
import deskData from "./desk";
import leatherData from "./leather";
import wallData from "./wall";
import mouseData from "./mouse";
import keyboard from "./keyboard";
import fotoData from "./foto";

const OVER_COMPUTER = [
  [30, 628],
  [29, 847],
  [331, 767],
  [329, 575],
  [30, 628],
].map(([px, py]) => ({ px, py }));

const LIBRO = [
  [681, 576],
  [687, 668],
  [716, 660],
  [729, 662],
  [749, 657],
  [758, 652],
  [768, 652],
  [781, 656],
  [769, 670],
  [773, 678],
  [763, 679],
  [753, 684],
  [778, 686],
  [791, 687],
  [805, 692],
  [813, 695],
  [800, 585],
  [748, 578],
  [702, 576],
  [681, 576],
].map(([px, py]) => ({ px, py }));

const TITULO = [
  [37, 56],
  [71, 279],
  [684, 225],
  [683, 10],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const TITULO_WELCOME = [
  [37, 56],
  [71, 279],
  // [71, 450],
  [889, 225],
  // [889, 450],
  [871, 0],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const TEXT_WELCOME = [
  [37, 56],
  [71, 500],
  [889, 500],
  [871, 50],
  [37, 56],
].map(([px, py]) => ({ px, py }));

const LIBRO_CONFIGS = {
  backgroundColor: "white",
};

const OVER_COMPUTER_CONFIG = {
  paddingLeft: 0,
  paddingTop: 0,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 32,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 20,
      fontSize: 22,
      color: "white",
      heightLine: 32,
    },
  },
  backgroundColor: "#210503",
  p1: OVER_COMPUTER[0], // Top left corner
  p2: OVER_COMPUTER[1], // Bottom left corner
  p3: OVER_COMPUTER[OVER_COMPUTER.length - 2], // Top right corner
  p4: OVER_COMPUTER[OVER_COMPUTER.length - 3], // Bottom right corner
};

const TITLE_CONFIG = {
  paddingLeft: 30,
  paddingTop: 10,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 62,
      color: "#f5c51f",
      heightLine: 80, // 80, //40
    },
    p: {
      characterPerLine: 25,
      fontSize: 32,
      color: "white",
      heightLine: 62,
    },
  },
  backgroundColor: "#311408",
  p1: TITULO[0], // Top left corner
  p2: TITULO[1], // Bottom left corner
  p3: TITULO[TITULO.length - 2], // Top right corner
  p4: TITULO[TITULO.length - 3], // Bottom right corner
};

const TITLE_WELCOME_CONFIG = {
  paddingLeft: 30,
  paddingTop: 40,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 72,
      color: "#f5c51f",
      heightLine: 20, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 44,
      color: "white",
      heightLine: 60,
    },
  },
  backgroundColor: "#311408",
  p1: TITULO_WELCOME[0], // Top left corner
  p2: TITULO_WELCOME[1], // Bottom left corner
  p3: TITULO_WELCOME[TITULO_WELCOME.length - 2], // Top right corner
  p4: TITULO_WELCOME[TITULO_WELCOME.length - 3], // Bottom right corner
};

const TEXT_WELCOME_CONFIG = {
  paddingLeft: 30,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 52,
      color: "#f5c51f",
      heightLine: 60, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 32,
      color: "white",
      heightLine: 45,
    },
  },
  backgroundColor: "#311408",
  p1: TEXT_WELCOME[0], // Top left corner
  p2: TEXT_WELCOME[1], // Bottom left corner
  p3: TEXT_WELCOME[TEXT_WELCOME.length - 2], // Top right corner
  p4: TEXT_WELCOME[TEXT_WELCOME.length - 3], // Bottom right corner
};

const OBJECTS_DEFINED = {
  ...wallData,
  ...deskData,
  ...leatherData,
  ...keyboard,
  ...mouseData,
  ...computerData,
  ...monitorData,
  ...fotoData,
  ...lampData,
  overComputer: {
    points: OVER_COMPUTER,
    configs: OVER_COMPUTER_CONFIG,
  },
  title: {
    points: TITULO,
    configs: { ...TITLE_CONFIG, strokeWidth: "0" },
  },
  titleWelcome: {
    points: TITULO_WELCOME,
    configs: { ...TITLE_WELCOME_CONFIG, strokeWidth: "0" },
  },
  textWelcome: {
    points: TEXT_WELCOME,
    configs: { ...TEXT_WELCOME_CONFIG, strokeWidth: "0" },
  },
  // libro: {
  //   points: LIBRO,
  //   configs: LIBRO_CONFIGS,
  // },
};

const {
  monitorMain,
  monitorTabGoogle,
  monitorTabMiro,
  monitorTabNotion,
  monitorTabMusic,
  monitor,
  title,
  overComputer,
  titleWelcome,
  textWelcome,
} = OBJECTS_DEFINED;

const TEXT_ELEMENTS = {
  monitorMain,
  monitorTabGoogle,
  monitorTabMiro,
  monitorTabNotion,
  monitorTabMusic,
  monitor,
  title,
  overComputer,
  titleWelcome,
  textWelcome,
};

const { monitorFOTO, ...svgMonitorData } = monitorData;

const DATA = {
  image: Img,
  textElements: TEXT_ELEMENTS,
  objectsDefined: OBJECTS_DEFINED,
  svgObjects: {
    texts: TEXT_ELEMENTS, // TODO: ADDED ONLY TO SHOW THE TEXT ELEMENTS ON EDIT MODE.

    monitor: {
      ...svgMonitorData,
      // ...fotoData,
    },
    lamp: {
      ...lampData,
    },
    desk: {
      ...deskData,
      ...leatherData,
      ...computerData,
      ...keyboard,
      ...mouseData,
    },
    wall: {
      ...wallData,
    },
  },
};

export default DATA;
