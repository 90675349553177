const TAP = [
  [38, 881],
  [111, 940],
  [118, 943],
  [128, 942],
  [444, 834],
  [448, 832],
  [448, 830],
  [334, 795],
  [327, 795],
  [321, 797],
  [43, 874],
  [38, 877],
  [37, 880],
  [38, 881],
].map(([px, py]) => ({ px, py }));

const DETAIL_LATERAL = [
  [38, 881],
  [42, 890],
  [110, 947],
  [114, 949.5],
  [119, 950.75],
  [449, 838],
  [448, 832],
  [444, 834],
  [128, 942],
  [118, 943],
  [111, 940],
  [38, 881],
].map(([px, py]) => ({ px, py }));

const LOGOS_FACTORY_BG = [
  [113, 919],
  [130, 933],
  [259, 887],
  [240, 876],
  [113, 919],
].map(([px, py]) => ({ px, py }));

const LOGOS_FACTORY_LETTERS = [
  [240, 889],
  [231, 883],
  [227, 885],
  [230, 887],
  [225, 889],
  [226, 890],
  [232, 888],
  [234, 890],
  [227, 893],
  [227, 895],
  [240, 890],
  [226, 894],
  [225, 891],
  [221, 891],
  [220, 893],
  [216, 893],
  [222, 889],
  [217, 888],
  [214, 888],
  [210, 890],
  [208, 890],
  [205, 891],
  [209, 894],
  [213, 895],
  [218, 895],
  [223, 893],
  [209, 897],
  [208, 895],
  [204, 894],
  [200, 894],
  [196, 894],
  [194, 895],
  [192, 897],
  [191, 898],
  [191, 899],
  [195, 898],
  [196, 896],
  [199, 895],
  [203, 895],
  [204, 897],
  [203, 898],
  [200, 900],
  [197, 900],
  [196, 899],
  [194, 900],
  [198, 901],
  [203, 900],
  [208, 897],
  [195, 901],
  [194, 902],
  [192, 902],
  [185, 898],
  [182, 899],
  [179, 900],
  [179, 902],
  [184, 901],
  [187, 903],
  [184, 905],
  [186, 906],
  [189, 904],
  [191, 906],
  [195, 904],
  [193, 904],
  [195, 903],
  [181, 906],
  [181, 903],
  [176, 902],
  [172.5, 903.5],
  [168, 905],
  [166, 906],
  [164, 908],
  [168, 910],
  [173, 910],
  [178, 908.5],
  [181, 907],
  [166, 911],
  [160, 907],
  [157, 908],
  [159, 910],
  [160, 912],
  [157, 914],
  [161, 913],
  [166, 913],
  [165, 914],
  [157, 915],
  [145, 913],
  [145, 911],
  [147, 911],
  [146, 909],
  [139, 913],
  [140, 923],
  [145, 920],
  [143, 915],
  [153, 917],
  [157, 915],
].map(([px, py]) => ({ px, py }));

const LOGOS_ALLES_BG = [
  [234, 872],
  [207, 858],
  [164, 871],
  [189, 887],
  [234, 872],
].map(([px, py]) => ({ px, py }));

const LOGOS_ALLES_LETTERS = [].map(([px, py]) => ({ px, py }));

const LOGOS_LOCALYZE_BG = [
  [349, 828],
  [330, 829],
  [317, 831],
  [302, 834],
  [290, 839],
  [275, 847],
  [272, 851.5],
  [272, 856],
  [273.5, 858.5],
  [278, 861],
  [282, 862],
  [291, 864],
  [315, 865],
  [335, 863],
  [353, 859],
  [362, 856],
  [371, 850.5],
  [376, 846],
  [378, 839],
  [376, 835],
  [370, 832],
  [364, 830],
  [356, 828],
].map(([px, py]) => ({ px, py }));

const LOGOS_LOCALYZE_IG = [
  [354, 832],
  [340, 831],
  [326, 832],
  [314, 834],
  [301, 838],
  [292, 841],
  [287, 845],
  [284, 847.5],
  [283, 850],
  [284, 853],
  [286, 856],
  [289, 857],
  [292, 859],
  [296, 858],
  [299, 857],
  [300, 856],
  [294, 856],
  [301, 853],
  [293, 853],
  [294, 849],
  [292, 847],
  [292, 844],
  [293, 842],
  [295, 841],
  [299, 840],
  [303, 840],
  [306, 841],
  [308, 841],
  [310, 843],
  [315, 844],
  [320, 844],
  [328, 842],
  [334, 840],
  [340, 840],
  [343, 841],
  [343, 845],
  [343, 845],
  [338, 848],
  [332, 850],
  [326, 850],
  [320, 852],
  [315, 854],
  [312, 854],
  [317, 855],
  [315, 858],
  [320, 858],
  [323, 857],
  [323, 855],
  [325, 854],
  [332, 854],
  [335, 853],
  [345, 847],
  [346, 849],
  [348, 850],
  [339, 855],
  [343, 856],
  [349, 856],
  [359, 851],
  [363, 848],
  [366, 842],
  [365, 837],
  [359, 834],
  [354, 832],
].map(([px, py]) => ({ px, py }));

const LOGOS_BERLIN_BG = [
  [308, 825],
  [307, 825],
  [304, 822],
  [299, 822],
  [279, 814],
  [135, 855],
  [157, 868],
  [207, 853],
  [210, 855],
  [214, 855],
  [224, 855],
  [230, 853],
  [235, 852],
  [238, 853],
  [239, 854],
  [239, 856],
  [238, 856],
  [236, 856],
  [239, 857],
  [243, 856],
  [247, 855],
  [249, 853],
  [251, 852],
  [254, 851],
  [257, 851],
  [260, 851],
  [264, 850],
  [266, 847],
  [264, 845],
  [261, 844],
  [259, 843],
  [264, 841],
  [266, 841],
  [273, 838],
  [275, 838],
  [272, 840],
  [272, 841],
  [276, 842],
  [281, 841],
  [304, 834],
  [302, 832],
  [305, 829],
  [308, 827],
].map(([px, py]) => ({ px, py }));

const LOGOS_BERLIN_LETTERS = [
  [225, 872.75],
  [190, 883.75],
  [171, 871.75],
  [202, 861.75],
].map(([px, py]) => ({ px, py }));

const MOIN = [
  [52, 871],
  [55, 874],
  [69, 874],
  [77, 873],
  [74, 878],
  [74, 880],
  [80, 877],
  [85, 878],
  [108, 870],
  [111, 868],
  [115, 866],
  [106, 865],
  [115, 860],
  [114, 859],
  [115, 856],
  [112, 855],
].map(([px, py]) => ({ px, py }));

export default {
  tap: {
    points: TAP,
    configs: { backgroundColor: "#a49c97", filter: "" },
  },
  tapDetailLat: {
    points: DETAIL_LATERAL,
    configs: { backgroundColor: "rgb(56 21 8)", filter: "" },
  },
  logosFactoryBg: {
    points: LOGOS_FACTORY_BG,
    configs: { backgroundColor: "rgb(235 218 188)", filter: "" },
  },
  logosFactoryLetters: {
    points: LOGOS_FACTORY_LETTERS,
    configs: { backgroundColor: "rgb(50 16 12)", filter: "", strokeWidth: "0" },
  },
  logosAllesBg: {
    points: LOGOS_ALLES_BG,
    configs: { backgroundColor: "rgb(35 6 3)", filter: "" },
  },
  // logosAllesLetters: {
  //   points: LOGOS_ALLES_LETTERS,
  //   configs: { backgroundColor: "rgb(249 227 172)", filter: "", strokeWidth: "0" },
  // },
  logosLocalyzeBg: {
    points: LOGOS_LOCALYZE_BG,
    configs: { backgroundColor: "rgb(158 163 116)", filter: "", strokeWidth: "1", stroke: "rgb(147 152 105)" },
  },
  logosLocalyzeIg: {
    points: LOGOS_LOCALYZE_IG,
    configs: { backgroundColor: "rgb(235 218 188)", filter: "", strokeWidth: "0" },
  },
  logosBerlinBg: {
    points: LOGOS_BERLIN_BG,
    configs: { backgroundColor: "rgb(235 218 188)", filter: "" },
  },
  logosBerlinLetters: {
    points: LOGOS_BERLIN_LETTERS,
    configs: { backgroundColor: "transparent", filter: "" },
  },
  moin: {
    points: MOIN,
    configs: { backgroundColor: "rgb(105 69 61)", filter: "" },
  },
};
