const BORDER_TOP = [
  [0, 574],
  [0, 835],
  [336, 858],
  [348, 591],
  [347, 584],
  [342, 580],
  [336, 578],
  [0, 574],
].map(([px, py]) => ({ px, py }));

const BORDER_BOTTOM = [
  [0, 839],
  [0, 1063],
  [168, 1090],
  [156, 1028],
  [158.5, 1010.5],
  [164.75, 997.75],
  [170.875, 989.375],
  [179, 981],
  [189.5, 973],
  [205.75, 963],
  [219, 956],
  [241, 949],
  [252, 946.5],
  [270, 945],
  [279, 945],
  [288, 946],
  [338, 868],
  [336, 858],
  [0, 835],
].map(([px, py]) => ({ px, py }));

const SCREEN = [
  [0, 588],
  [0, 822],
  [329, 843],
  [337, 593],
  [0, 588],
].map(([px, py]) => ({ px, py }));

const KEYBOARD = [
  [0, 849],
  [0, 945],
  [197, 968],
  [222, 955],
  [262, 948],
  [314, 875],
  [0, 849],
].map(([px, py]) => ({ px, py }));

const TRACKPAD = [
  [0, 955],
  [0, 1056],
  [43, 1063],
  [50, 1065],
  [54, 1062],
  [131, 970],
  [0, 955],
].map(([px, py]) => ({ px, py }));

const DETAILBORDER = [].map(([px, py]) => ({ px, py }));

const SCREEN_CONFIG = {
  paddingLeft: 20,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 32,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 20,
      color: "white",
      heightLine: 26,
    },
  },
  backgroundColor: "black",
  filter: "filter: drop-shadow(0px 1px 0px rgba(255,255,255,.4))",
  p1: SCREEN[0], // Top left corner
  p2: SCREEN[1], // Bottom left corner
  p3: SCREEN[3], // Top right corner
  p4: SCREEN[2], // Bottom right corner
};

export default {
  computerBorderTop: {
    points: BORDER_TOP,
    configs: { backgroundColor: "rgb(48 48 48)", filter: "drop-shadow(3px 0px 0 rgb(48, 48, 48))" },
  },
  computerBorderBottom: {
    points: BORDER_BOTTOM,
    configs: { backgroundColor: "rgb(107 97 94)", filter: "drop-shadow(1px 12px 0 rgba(0,0,0,.3))" },
  },
  computerScreen: {
    points: SCREEN,
    configs: { ...SCREEN_CONFIG, backgroundColor: "black", filter: "drop-shadow(-1px 2px 0 rgba(255,255,255,.3))" },
  },
  computerKeyboard: {
    points: KEYBOARD,
    configs: { backgroundColor: "rgb(2 1 10)", filter: "filter: drop-shadow(3px -4px 0 rgba(0,0,0,.3))" },
  },
  computerTrackpad: {
    points: TRACKPAD,
    configs: { backgroundColor: "rgb(84 73 76)", filter: "drop-shadow(2px -3px 0 rgba(0,0,0,.3))" },
  },
};
