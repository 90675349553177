import { ActionOptions } from "../Actions/types";
import { ConversationData } from "../types";

const finishConversation = (hearAbout: string = "you"): ConversationData => ({
  keyWord: "ending-branch",
  messages: [
    { text: "Perfect❗️" },
    { text: "I'll contact you as soon as possible." },
    { text: `I am already looking forward to hear more about ${hearAbout}` },
    { text: "I invite you now to keep browsing my website 🙂." },
  ],
  action: undefined,
});

const emailActionDefault = (hearAbout: string) => ({
  type: "input-email" as const,
  conversation: finishConversation(hearAbout),
});

const MISSING_CONVERSATION = {
  keyWord: "missing-conversation",
  messages: [
    { text: "I'm sorry, I have not yet complete this content." },
    { text: "I'm gonna notify Ignacio you´re selecting this option." },
    { text: "Would you like to provide me your email, so we can contact you❓" },
  ],
  action: emailActionDefault("the oportunity to colaborate 💪"),
};

const EMAIL_DETECTED_CONVERSATION = (
  email: string,
  emailQuestionConversation: ConversationData,
  conversationForward: ConversationData
): ConversationData => ({
  keyWord: "email-detected",
  messages: [
    ...emailQuestionConversation.messages,
    { text: "Oh, but wait." },
    { text: "I see you already entered your email 😅." },
    { text: `Should I contact you to ${email}❓` },
  ],
  action: {
    type: "confirm-email-action",
    options: [
      {
        keyWord: "confirm-email",
        text: "Yes!",
        conversation: conversationForward,
      },
      {
        keyWord: "reject-confirm-email",
        text: "No, I would like to enter an other one.",
        conversation: {
          keyWord: "reject-confirm-email",
          messages: [
            { text: "All good, that's no problem 🙂." },
            { text: "Here we go, just enter it:" },
          ],
          action: {
            type: "input-email-reentered",
            conversation: conversationForward,
          },
        },
      },
    ],
  },
});

const BACK_TO_MESSAGE_MESSAGES = [
  { text: "I see, that's no problem, we can come back to this later 🙂." },
];

const CHAT_DATA_FAKE = {
  keyWord: "welcome",
  messages: [
    { text: "Hi there 👋, good to have you❗️" },
    { text: "This is a basic chat I did in order to communicate with you." },
    { text: "What do you have interest for❓" },
  ],
};

const ActionOptionCollaboration: ActionOptions = {
  keyWord: "collaboration",
  text: "I'm looking for collaboration.",
  conversation: {
    keyWord: "collaboration",
    messages: [
      { text: "That's great❗️" },
      { text: "May I ask you which type of colaboration are you looking for❓" },
    ],
    action: {
      type: "options",
      options: [
        {
          keyWord: "unicorn",
          text: "I have the next unicorn idea 🦄.",
          conversation: {
            keyWord: "unicorn",
            messages: [
              { text: "Oh wow. That sounds amazing." },
              { text: "I would be glad to hear more about it." },
              { text: "Would you mind to leave me your email❓" },
              { text: "So then I can reach you out." },
            ],
            action: emailActionDefault("the 🦄💡❗️"),
          },
        },
        {
          keyWord: "services",
          text: "I think you'd be interested on my services 📈.",
          conversation: {
            keyWord: "services",
            messages: [
              { text: "😮" },
              { text: "Happy to hear that, you are likely right❗️" },
              { text: "I'm wondering which kind of services you're offering.. 🤔" },
              { text: "Is it one of these ones❓" },
            ],
            action: {
              type: "options",
              options: [
                {
                  keyWord: "marketing",
                  text: "Marketing",
                  conversation: {
                    keyWord: "marketing",
                    messages: [
                      { text: "Marketing is way important for any business." },
                      { text: "I'm curious, what kind of marketing do you offer❓" },
                    ],
                    action: {
                      type: "options",
                      options: [
                        {
                          keyWord: "marketing-digital",
                          text: "Digital marketing",
                          conversation: {
                            keyWord: "marketing-digital",
                            messages: [
                              { text: "Awesome, there's a lot of potential in digital marketing ideas around." },
                              { text: "I would be glad to hear more about it." },
                            ],
                            action: emailActionDefault("the digital marketing idea 💡"),
                          },
                        },
                        {
                          keyWord: "marketing-traditional",
                          text: "Traditional marketing",
                          conversation: {
                            keyWord: "marketing-traditional",
                            messages: [
                              { text: "Interesting choice." },
                              { text: "Would you mind to tell me more about it❓" },
                            ],
                            action: emailActionDefault("the traditional marketing idea 💡"),
                          },
                        },
                        {
                          keyWord: "marketing-other",
                          text: "Other one",
                          conversation: {
                            keyWord: "marketing-other",
                            messages: [
                              { text: "I would be happy to hear which kind it would be." },
                              { text: "I like these most, because it would improve this 🤖 in future upgrades 👌." },
                              { text: "How do you want to colaborate❓" },
                            ],
                            action: emailActionDefault("the oportunity to colaborate 💪"),
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  keyWord: "creator",
                  text: "Content creator",
                  conversation: {
                    keyWord: "creator",
                    messages: [
                      { text: "Content creation nowadays rocks 🚀." },
                      { text: "Do you see any potencial in this❓" },
                      { text: "I would be glad to hear more about it." },
                      { text: "Would you like to provide me your email❓" },
                    ],
                    action: emailActionDefault("the content creation idea 💡"),
                  },
                },
                {
                  keyWord: "outsourcing",
                  text: "Outsourcing",
                  conversation: {
                    keyWord: "outsourcing",
                    messages: [
                      { text: "Outsourcing is a great way to improve productivity." },
                      { text: "And it's a great way to colaborate with others." },
                      { text: "Do you think we could colaborate❓" },
                      { text: "I would be glad to jump into a call to discuss it." },
                      { text: "Would you like to provide me your email❓" },
                    ],
                    action: emailActionDefault("the oportunity to colaborate 💪"),
                  },
                },
              ],
            },
          },
        },
        {
          keyWord: "colaboration-other",
          text: "Other type of colaboration",
          conversation: {
            keyWord: "colaboration-other",
            messages: [
              { text: "I would be happy to hear which kind it would be." },
              { text: "I like these most, because it would improve this 🤖 in future upgrades 👌." },
              { text: "How do you want to colaborate❓" },
            ],
            action: {
              type: "input-text",
              conversation: {
                keyWord: "colaboration-other-input-text",
                messages: [
                  { text: "Thanks❗️, noted 🗒." },
                  { text: "I'm gonna notify Ignacio about it." },
                  { text: "Would you like to provide me your email, so we can contact you❓" },
                  { text: "Otherwise once Ignacio replies, it would appear in this chat." },
                ],
                action: emailActionDefault("the oportunity to colaborate 💪"),
              },
            },
          },
        },
      ],
    },
  },
};

const ActionOptionAboutMe: ActionOptions = {
  keyWord: "more-about-me",
  text: "More about you",
  conversation: {
    keyWord: "more-about-me",
    messages: [
      { text: "I'm glad you're interested in knowing more about me." },
      { text: "I'm a software developer, I'm also a passionate about technology." },
      { text: "I'm always looking for new challenges and opportunities." },
      { text: "I invite you to keep browsing my website." },
    ],
    action: undefined,
  },
};

const ActionOptionFeedback: ActionOptions = {
  keyWord: "feedback",
  text: "I want to give you some feedback.",
  conversation: {
    keyWord: "feedback",
    messages: [
      { text: "I'm glad to hear that." },
      { text: "I'm always looking for feedback to improve." },
      { text: "Which kind of feedback do you want to give me❓" },
    ],
    action: {
      type: "options",
      options: [
        {
          keyWord: "feedback-bug",
          text: "I found a bug",
          conversation: {
            keyWord: "feedback-bug",
            messages: [
              { text: "I'm sorry to hear that." },
              { text: "I'm gonna notify Ignacio about it." },
              { text: "Would you like to provide me your email, so we can contact you❓" },
              { text: "Otherwise once Ignacio replies, it would appear in this chat." },
            ],
            action: emailActionDefault("the bug 🐞"),
          },
        },
        {
          keyWord: "feedback-improvement",
          text: "I have an improvement suggestion",
          conversation: {
            keyWord: "feedback-improvement",
            messages: [
              { text: "I'm glad to hear that." },
              { text: "I'm always looking for feedback to improve." },
              { text: "I would be glad to hear more about it." },
              { text: "Would you mind to leave me your email❓" },
              { text: "So then I can reach you out." },
            ],
            action: emailActionDefault("the improvement suggestion 💡"),
          },
        },
        {
          keyWord: "feedback-other",
          text: "Other one",
          conversation: {
            keyWord: "feedback-other",
            messages: [
              { text: "I would be happy to hear which kind it would be." },
              { text: "I like these most, because it would improve this 🤖 in future upgrades 👌." },
              { text: "How do you want to colaborate❓" },
            ],
            action: emailActionDefault("the oportunity to colaborate 💪"),
          },
        },
      ],
    },
  },
};

const ActionOptionHireMe: ActionOptions = {
  keyWord: "hire-me",
  text: "I want to work with you",
  conversation: {
    keyWord: "hire-me",
    messages: [
      { text: "That's awesome❗️, I'm glad to hear that." },
      { text: "I'm always looking for new challenges and opportunities." },
      { text: "I would be glad to hear more about it." },
      { text: "Would you mind to leave me your email❓" },
      { text: "So then I can reach you out." },
    ],
    action: emailActionDefault("the oportunity to colaborate 💪"),
  },
};

const CHAT_DATA: ConversationData = {
  keyWord: "welcome",
  messages: [
    { text: "Hi there 👋, good to have you❗️" },
    { text: "This is a basic chat I did in order to communicate with you." },
    { text: "What do you have interest for❓" },
  ],
  action: {
    type: "options",
    options: [
      ActionOptionCollaboration,
      ActionOptionAboutMe,
      ActionOptionFeedback,
      ActionOptionHireMe,
    ],
  },
};

const englishData = {
  CHAT_DATA,
  CHAT_DATA_FAKE,
  EMAIL_DETECTED_CONVERSATION,
  BACK_TO_MESSAGE_MESSAGES,
};

export default englishData;

