import { ConversationData, ConvoHistory } from "../types";

export const reproduceLastConvo = async (
  initConversation: ConversationData,
  previousAnswers: ConvoHistory,
  handleAnswer: (
    msgToAdd: string,
    newConv: ConversationData,
    currentConven: ConversationData,
    asReproducingLast?: boolean
  ) => void
) => {
  const selectOption = async (conversation: ConversationData, answerIndex: number) => {
    const { action } = conversation;
    if (!action) return;
    if (answerIndex >= previousAnswers.length) return;

    const valueSelected = Object.values(previousAnswers[answerIndex])[0].value;

    switch (action.type) {
      case "options": {
        const optionSelected = action.options.find((option) => option.keyWord === valueSelected);
        if (optionSelected) {
          await handleAnswer(optionSelected.text, optionSelected.conversation, conversation, true);
          await selectOption(optionSelected.conversation, answerIndex + 1);
        }
        break;
      }
      case "input-text":
      case "input-email": {
        await handleAnswer(valueSelected, action.conversation, conversation, true);
        await selectOption(action.conversation, answerIndex + 1);
      }
    }
  };

  await selectOption(initConversation, 0);
};
