import React, { useEffect, useState } from "react";
import "./styles.scss";
import FotoCatalog, { ImageFrameData } from "components/FotoCatalog";
import { AnimateAboutMeOD, AnimateAboutMe, AnimateAboutMeLogoIdea } from "./animations";

type Props = { renderValue: number }; // number 0 - 1.

const SVG_ID = "svg-aboutme";

const AboutMeSection: React.FC<Props> = ({ renderValue }) => {
  const [currentFoto, setCurrentFoto] = useState(0);
  const [bgService, setBgService] = useState<AnimateAboutMe | AnimateAboutMeOD | AnimateAboutMeLogoIdea | undefined>(
    undefined
  );

  // useEffect(() => {
  //   // const bgServ = new AnimateAboutMe();
  //   // setBgService(bgServ);
  // }, []);

  useEffect(() => {
    // collectionFotoChange(currentFoto);
  }, [currentFoto]);

  const collectionFotoChange = (indexCurrentFoto: number) => {
    setCurrentFoto(indexCurrentFoto);

    // try {
    //   // Assuming you have an SVG with id="svg-aboutme"
    //   const svgId = "svg-aboutme";

    //   // Get the SVG element by its ID
    //   const svgElement = document.getElementById(svgId);

    //   // Remove all children elements
    //   if (svgElement) {
    //     // while (svgElement.firstChild) {
    //     //   svgElement.removeChild(svgElement.firstChild);
    //     // }

    //     Array.from(svgElement.childNodes).forEach(function (child) {
    //       svgElement.removeChild(child);
    //     });
    //   }

    //   // try {
    //   //   var svgId = "#svg-aboutme";
    //   //   console.log("Before removal:", d3.select(svgId).node());

    //   //   // Select the SVG element by its ID and remove all its children
    //   //   d3.select(svgId).selectAll("*").remove();

    //   //   console.log("After removal:", d3.select(svgId).node());
    //   // } catch (error) {
    //   //   console.log("BgClient<T -> publicremoveSvgHtmlElements -> error", error);
    //   // }
    // } catch (error) {}

    switch (indexCurrentFoto) {
      case 0:
        setBgService(new AnimateAboutMe(SVG_ID));
        break;
      case 1:
        // try {
        //   // Get the SVG element by its ID
        //   const svgElement = document.getElementById("svg-aboutme");

        //   // Remove all children elements
        //   if (svgElement) {
        //     // while (svgElement.firstChild) {
        //     //   svgElement.removeChild(svgElement.firstChild);
        //     // }

        //     Array.from(svgElement.childNodes).forEach(function (child) {
        //       svgElement.removeChild(child);
        //       console.log("collectionFotoChange -> child", child);
        //     });

        //     // Array.from(svgElement?.childNodes || []).forEach(function (child) {
        //     //   if (child.nodeType === 1 && (child as Element).tagName.toLowerCase() !== "img") {
        //     //     svgElement?.removeChild(child);
        //     //   }
        //     // });
        //   }
        // } catch (error) {
        //   console.log("BgClient<T -> publicremoveSvgHtmlElements -> error", error);
        // }
        setBgService(new AnimateAboutMeOD(SVG_ID));
        break;
      case 2:
        setBgService(new AnimateAboutMeLogoIdea(SVG_ID));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    bgService && bgService.webhookScrolling(renderValue);
  }, [renderValue]);

  const _onItemClick = (path: string, index: number) => {
    console.log("_onItemClick -> path", path);
    window.history.replaceState(null, "", "/" + path);
    setCurrentFoto(index);
  };

  return (
    <>
      <div className={SVG_ID}>
        <FotoCatalog
          collection={ImageFrameData.collection}
          callbackChangeFoto={collectionFotoChange}
          svgId="svg-aboutme"
          currentFoto={currentFoto}
        />
      </div>
    </>
  );
};

export default AboutMeSection;
