const PEN = [
  [778, 845.25],
  [791, 947.25],
  [793, 958.25],
  [794, 952.25],
  [797, 949.25],
  [801, 948.25],
  [805, 949.25],
  [808, 951.25],
  [809, 954.25],
  [810, 957.25],
  [810, 952.25],
  [794, 845.25],
  [790, 842.25],
  [787, 841.25],
  [782, 842.25],
  [778, 846.25],
].map(([px, py]) => ({ px, py }));

const PUNTA = [
  [793, 958.25],
  [802, 977],
  [803, 975],
  [805, 975],
  [806, 977],
  [810, 957.25],
  [809, 954.25],
  [808, 951.25],
  [805, 949.25],
  [801, 948.25],
  [797, 949.25],
  [794, 952.25],
  [793, 958.25],
].map(([px, py]) => ({ px, py }));

const PUNTA_DETAIL = [
  [802, 978],
  [803, 978],
  [804, 981],
  [805, 981],
  [805, 978],
  [805, 977],
  [806, 977],
].map(([px, py]) => ({ px, py }));

const PUNTA_DETAIL_LIGHT = [
  [798, 954],
  [802, 972],
  [804, 972],
  [804, 954],
  [802, 953],
  [799, 953.5],
  [798, 954],
].map(([px, py]) => ({ px, py }));

const SUJETADOR = [
  [772, 833],
  [772, 839],
  [778, 885],
  [781, 885],
  [781, 883],
  [782, 882],
  [781.5, 878.5],
  [780.25, 877.75],
  [777, 847],
  [779, 845.25],
  [782, 842.25],
  [786, 841.25],
  [790, 842.25],
  [794, 845],
  [792, 834],
  [786, 831],
  [779, 831.5],
  [777, 834],
  [772, 833],
].map(([px, py]) => ({ px, py }));

const TAPA = [
  [787, 831],
  [779, 831.5],
  [777, 821],
  [778.5, 819],
  [784, 820],
  [785.5, 825.5],
  [787, 831],
].map(([px, py]) => ({ px, py }));

export default {
  pen: {
    points: PEN,
    configs: { backgroundColor: "rgb(69 72 56)", filter: "drop-shadow(-1px 7px 0 rgba(0,0,0,.3))" },
  },
  penPunta: {
    points: PUNTA,
    configs: { backgroundColor: "rgb(117 113 104)", filter: "drop-shadow(-1px 8px 0 rgba(0,0,0,.3))" },
  },
  penPuntaDetail: {
    points: PUNTA_DETAIL,
    configs: { backgroundColor: "#827469", strokeWidth: "0", filter: "drop-shadow(-1px 8px 0 rgba(0,0,0,.3))" },
  },
  penPuntaDetailLight: {
    points: PUNTA_DETAIL_LIGHT,
    configs: {
      backgroundColor: "rgb(133 126 109)",
      strokeWidth: "0",
      filter: "drop-shadow(2px 0px 0 rgba(255,255,255,.2))",
    },
  },
  penSujetador: {
    points: SUJETADOR,
    configs: { backgroundColor: "#7f7b74", strokeWidth: "1", filter: "drop-shadow(0px 6px 0 rgba(0,0,0,.3))" },
  },
  penTapa: {
    points: TAPA,
    configs: { backgroundColor: "#6e6860", strokeWidth: "1" },
  },
};

// transform: translate(806px, 991px) rotate3d(-15, 69, 29, 40deg)translate(-920px, -965px)
