import React from "react";
import "./styles.scss";
import PortfolioStory from "sections/1-PortfolioStory";

type Props = { renderValue: number }; // number 0 - 1.

const ClaudeStory: React.FC<Props> = ({ renderValue }) => {
  return <PortfolioStory renderValue={renderValue} renderAs="claude-3" />;
};

export default ClaudeStory;
