const background = [
  [0, 168], //
  [244, 119],
  [249, 124],
  [259, 121],
  [267, 128],
  [269, 139],
  [252, 146],
  [296, 467],
  [313, 466],
  [315, 481],
  [297, 485],
  [304, 531],
  [32, 555],
  [25, 512],
  [21, 513],
  [18, 497],
  [23, 496],
  [17, 470],
  [0, 472], //
  [0, 168], //
].map(([px, py]) => ({ px, py }));

const shadowLighter = [
  [73, 554],
  [74, 564],
  [106, 563],
  [133, 560],
  [161, 558],
  [235, 546],
  [269, 542],
  [294, 540],
  [312, 537],
  [324, 536],
  [320, 486],
  [328, 485],
  [326, 470],
  [317, 467],
  [298, 303],
  [275, 149],
  [284, 146],
  [281, 126],
  [277, 124],
  [271, 115],
  [248, 118],
  [73, 554],
].map(([px, py]) => ({ px, py }));

const shadowDarker = [
  [73, 550],
  [315, 531],
  [308, 482],
  [319, 479],
  [316, 463],
  [307, 463],
  [264, 142],
  [275, 138],
  [269, 113],
  [240, 118],
  [73, 550],
].map(([px, py]) => ({ px, py }));

const shelfFront = [
  [17, 496],
  [20, 511],
  [307, 480],
  [307, 464],
  [17, 496],
].map(([px, py]) => ({ px, py }));

const shelfFrontTop = [
  [0, 177],
  [0, 195],
  [261, 136],
  [260, 119],
  [0, 177],
].map(([px, py]) => ({ px, py }));

const shelfSide = [
  [296, 466],
  [307, 480],
  [315, 480],
  [312, 462],
  [303, 463],
  [296, 466],
].map(([px, py]) => ({ px, py }));

const shelfTopBottom = [
  [0, 194],
  [0, 202],
  [268, 140],
  [261, 136],
  [0, 194],
].map(([px, py]) => ({ px, py }));

const shelfTopSide = [
  [260, 121],
  [260, 137],
  [268, 141],
  [266, 125],
  [260, 121],
].map(([px, py]) => ({ px, py }));

const bg = [
  [0, 168],
  [0, 381],
  [32, 555],
  [172, 544],
  [223, 540],
  [256, 538],
  [304, 533],
  [246, 116],
  [0, 168],
].map(([px, py]) => ({ px, py }));

// const pinBottomLeft = [
//   [37, 499],
//   [37, 497],
//   [35, 496],
//   [34, 497],
//   [34, 499],
//   [36, 500],
// ].map(([px, py]) => ({ px, py }));

// const pinBottomRight = [
//   [271, 473],
//   [270, 475],
//   [271, 476],
//   [272, 476],
//   [273, 474],
// ].map(([px, py]) => ({ px, py }));

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.2)",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.4)",
    },
  },
  shelfSide: {
    points: shelfSide,
    configs: {
      backgroundColor: "rgb(0 0 0)",
    },
  },
  shelfFront: {
    points: shelfFront,
    configs: {
      backgroundColor: "rgb(0 0 0)",
    },
  },
  shelfTopSide: {
    points: shelfTopSide,
    configs: {
      backgroundColor: "rgb(0 0 0)",
    },
  },
  shelfTopBottom: {
    points: shelfTopBottom,
    configs: {
      backgroundColor: "rgb(0 0 0)",
    },
  },
  shelfFrontTop: {
    points: shelfFrontTop,
    configs: {
      backgroundColor: "rgb(0 0 0)",
    },
  },
  // background: {
  //   points: background,
  //   configs: {
  //     class: "titanium",
  //     backgroundColor: "rgba(4, 244, 4, 1)",
  //   },
  // },
  bg: {
    points: bg,
    configs: {
      backgroundColor: "rgb(219 212 201)",
    },
  },
  // pinBottomLeft: {
  //   points: pinBottomLeft,
  //   configs: {
  //     class: "non-stk",
  //     backgroundColor: "rgb(0 0 0)",
  //   },
  // },
  // pinBottomRight: {
  //   points: pinBottomRight,
  //   configs: {
  //     class: "non-stk",
  //     backgroundColor: "rgb(0 0 0)",
  //   },
  // },
};
