const COMPLETE = [
  [548, 832],
  [551, 835],
  [565, 847],
  [570, 850], //
  [571.5, 855],
  [571, 858],
  [570, 861],
  [568, 864],
  [566, 867],
  [566, 871],
  [575, 876],
  [592, 885],
  [596, 886],
  [601, 886],
  [604, 886],
  [607, 886],
  [614, 885],
  [621, 883],
  [632, 880],
  [643, 876],
  [655, 872],
  [668, 867],
  [676, 864],
  [679, 863],
  [681, 863],
  [683, 865],
  [679, 857],
  [670, 844],
  [662, 834],
  [654, 825],
  [646, 819],
  [638, 815],
  [620, 810],
  [611, 809.5],
  [602, 810],
  [595, 810.5],
  [588, 812],
  [577, 816],
  [565, 821],
  [557, 825],
  [551, 828],
  [548, 830],
].map(([px, py]) => ({ px, py }));

const DETAIL_SCROLL_BOARDER = [
  [548, 832],
  [551, 835],
  [565, 847],
  [570, 850],
  [570, 848],
  [568, 842],
  [569, 838.5],
  [570.5, 835.75],
  [579.25, 833.375],
  [600, 831],
  [602.5, 829.5],
  [603.75, 825.75],
  [604.375, 821.875],
  [603, 818],
  [598.5, 816],
  [587.375, 816],
  [577.8125, 818],
  [573.03125, 819],
  [568.25, 821],
  [561.125, 824],
  [548, 832],
].map(([px, py]) => ({ px, py }));

const DETAIL_SCROLL_RUEDA = [
  [578, 821],
  [578, 825],
  [578.5, 829.5],
  [581, 833],
  [592, 832],
  [590, 829],
  [589, 826],
  [589, 823],
  [590, 821],
  [591, 819],
  [578, 821],
].map(([px, py]) => ({ px, py }));

const DETAIL_BUTTON_LEFT = [
  [576, 838],
  [576, 839],
  [577, 840],
  [578, 841],
  [585, 840],
  [584, 839],
  [584, 838],
  [584, 837],
  [576, 838],
].map(([px, py]) => ({ px, py }));

const DETAIL_BUTTON_RIGHT = [
  [586, 837],
  [586, 838],
  [588, 840],
  [597, 839],
  [597, 837],
  [595, 836],
  [586, 837],
].map(([px, py]) => ({ px, py }));

const DETAIL_BUTTON_LIGHT = [
  [597, 824],
  [597, 825],
  [600, 825],
  [600, 823],
  [597, 823],
  [597, 824],
].map(([px, py]) => ({ px, py }));

const DETAIL_SCROLL_RUEDA_SHADOW = [
  [591, 819],
  [590, 821],
  [589, 823],
  [589, 826],
  [590, 829],
  [592, 832],
  [592, 832],
  [594, 827],
  [594, 822],
  [591, 819],
].map(([px, py]) => ({ px, py }));

const DETAIL_BASE = [
  [592, 885],
  [596, 886],
  [601, 886],
  [604, 886],
  [607, 886],
  [614, 885],
  [621, 883],
  [632, 880],
  [643, 876],
  [655, 872],
  [668, 867],
  [676, 864],
  [679, 863],
  [681, 863],
  [683, 865],
  [679, 859],
  [675, 858],
  [669, 859],
  [660, 862],
  [649, 867],
  [638, 872],
  [628, 876],
  [617, 879],
  [610, 881],
  [602, 882],
  [596, 883],
  [592, 885],
].map(([px, py]) => ({ px, py }));

const DETAIL_SCHADOW_BODY = [
  [606, 824],
  [604, 831],
  [602, 833],
  [597, 834],
  [588, 835],
  [580, 836],
  [573, 837],
  [570, 838],
  [570.5, 835.75],
  [569, 838.5],
  [568, 842],
  [570, 848],
  [570, 850],
  [572, 850],
  [577, 853],
  [579, 854],
  [585, 861],
  [592, 862],
  [597, 865],
  [613, 868],
  [619, 876],
  [625, 872],
  [629, 860],
  [622, 837],
  [606, 824],
].map(([px, py]) => ({ px, py }));

const DETAIL_SHADOW_BODY_2 = [
  [604, 818],

  [606, 824],
  [604, 831],
  [602, 833],
  [597, 834],
  [588, 835],
  [580, 836],
  [573, 837],
  [570, 838],
  [570.5, 835.75],
  [569, 838.5],
  [568, 842],
  [570, 848],
  [570, 850],
  [572, 850],

  [570, 850], //
  [571.5, 855],
  [571, 858],

  [573, 861],
  [578, 862],
  [582, 862],
  [584, 864],
  [589, 866],
  [593, 867],
  [607, 869],
  [610, 872],
  [615, 875],
  [621, 875],
  [627, 871],
  [629, 869],
  [633, 867],
  [642, 864],
  [644, 863],
  [645, 859],
  [646, 856],
  [647, 851],
  [647, 848],
  [643, 843],
  [638, 840],
  [632, 837],
  [628, 833],
  [630, 830],
  [631, 826],
  [628, 825],
  [626, 822],
  [616, 820],
  [608, 819],
].map(([px, py]) => ({ px, py }));

export default {
  mouseComplete: {
    points: COMPLETE,
    configs: { backgroundColor: "rgb(197 148 79)", filter: "drop-shadow(1px 3px 0px rgb(30 2 0))" },
  },
  mouseScrollSurface: {
    points: DETAIL_SCROLL_BOARDER,
    configs: { backgroundColor: "rgb(107 79 39)" },
  },
  detailsScrollRueda: {
    points: DETAIL_SCROLL_RUEDA,
    configs: {
      backgroundColor: "rgb(110 104 101)",
      filter: "drop-shadow(1px 0px 0px rgba(27 9 5 / 0.3))",
      strokeWidth: "0",
    },
  },
  detailsScrollRuedaShadow: {
    points: DETAIL_SCROLL_RUEDA_SHADOW,
    configs: {
      backgroundColor: "rgb(56 53 51)",
      filter: "drop-shadow(1px 0px 0px rgba(27 9 5 / 0.3))",
      strokeWidth: "0",
    },
  },
  detailsButtonLight: {
    points: DETAIL_BUTTON_LIGHT,
    configs: { backgroundColor: "rgb(21 182 21)", strokeWidth: "0", filter: "blur(0.2px)" },
  },
  detailShadowBodyDarker: {
    points: DETAIL_SHADOW_BODY_2,
    configs: { backgroundColor: "rgb(161 118 58)", strokeWidth: "0" },
  },
  detailShadowBody: {
    points: DETAIL_SCHADOW_BODY,
    configs: {
      backgroundColor: "rgb(125 92 45)",
      strokeWidth: "0",
      filter: "drop-shadow(-3px -7px 0px rgba(27 9 5 / 0.1))",
    },
  },
  detailsButtonLeft: {
    points: DETAIL_BUTTON_LEFT,
    configs: {
      backgroundColor: "rgb(84 75 72)",
      strokeWidth: "0",
      filter: "drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1))",
    },
  },
  detailsButtonRight: {
    points: DETAIL_BUTTON_RIGHT,
    configs: {
      backgroundColor: "rgb(84 75 72)",
      strokeWidth: "0",
      filter: "drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1))",
    },
  },
  detailBase: {
    points: DETAIL_BASE,
    configs: { backgroundColor: "rgb(179 129 82)", filter: "drop-shadow(2px 4px 0px rgb(30 2 0))", strokeWidth: "0" },
  },
};
