import { Conversation, ConvoHistory } from "./types";

export const showImageMesage = (msgIndex: number, msgs: Conversation["messages"]) => {
  const msg = msgs[msgIndex];
  /** Logic to show the image next to the first message */
  /* 
  const previousMsg = msgs[msgIndex - 1];
  const isFirstMessage = msgIndex === 0 || previousMsg.position === "right";
    return isLeft && isFirstMessage;
  */

  const isLeft = msg.position !== "right";
  const isLastMessage = msgIndex === msgs.length - 1;
  return isLastMessage && isLeft;
};

type ActionOptions = {
  keyWord: string;
  text: string;
  conversation: Conversation;
};

export class ChatConvoService {
  chatData: Conversation;
  WELCOME_CONVERSATION_KEYWORD: string;

  constructor(public convoHistory: ConvoHistory, chatData: Conversation) {
    this.chatData = chatData;
    this.WELCOME_CONVERSATION_KEYWORD = chatData.keyWord;
  }

  public pathToConversation = () => this._findPathIds(this._getLastConversationId());

  /** lastConversationHistory:
   * It returns the conversation keywords to reach the last conversation user had.
   * It is required bc the userPreviousAnswers is the history, that considers user could had back to a a question, breaking the path.
   */
  public lastConversationHistory = () => {
    const cleanArray = [];
    const arrayKeywords = this.convoHistory.map((e) => Object.keys(e)[0]);
    for (let i = 0; i < this.convoHistory.length; i++) {
      const lastIndexOf = arrayKeywords.lastIndexOf(arrayKeywords[i]);
      if (lastIndexOf > i) i = lastIndexOf;
      cleanArray.push(this.convoHistory[i]);
    }

    // we filter the rejection history, not really needed.
    return cleanArray.filter((e) => Object.keys(e)[0] !== "reject-confirm-email");
  };

  /** lastConversationPathIds:
    It goes through all the three branches, finding the path to get until the last conversationKeyword.
   */
  private _findPathIds = (lastConversationId: string) => {
    const findConversationIdOnOptions = (options: ActionOptions[], path: string[]) => {
      const conversationIds = options.map((e) => e.conversation.keyWord);

      if (conversationIds.includes(lastConversationId)) return [...path, lastConversationId];
      return options.map((e) => findConversationInAction(e.conversation.action, e.conversation.keyWord, path));
    };

    const findConversationIdInInput = (conversation: Conversation, path: string[]) => {
      if (conversation.keyWord === lastConversationId) return [...path, lastConversationId];
    };

    const findConversationInAction = (action: Conversation["action"], keyWord: string, path: string[]): any => {
      if (!action) return;
      if (action.type === "options") return findConversationIdOnOptions(action.options, [...path, keyWord]);
      if (action.type === "input-email") return findConversationIdInInput(action.conversation, [...path, keyWord]);
      if (action.type === "input-text") return findConversationIdInInput(action.conversation, [...path, keyWord]);
    };

    if (lastConversationId === this.WELCOME_CONVERSATION_KEYWORD) return [this.WELCOME_CONVERSATION_KEYWORD];
    return findConversationInAction(this.chatData.action, this.WELCOME_CONVERSATION_KEYWORD, []);
  };

  private _getLastConversationId = () => {
    const convoLength = this.convoHistory.length;
    if (convoLength === 0) return this.WELCOME_CONVERSATION_KEYWORD;
    const lastAnswer = this.convoHistory[convoLength - 1];
    return Object.keys(lastAnswer)[0];
  };
}
