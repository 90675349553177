import React, { useContext, Dispatch, SetStateAction, useState } from "react";
import { ChatActionOptions } from "./Options";
import { ChatActionInput } from "./Input";
import { Conversation } from "../types";
import { ChatAction } from "./types";
import { ChatAPI } from "api";
import { AppContext } from "AppContext";

type ChatActionProps = {
  currentConversation: Conversation;
  startAgain: () => void;
  showButtonBack: boolean;
  handleAnswer: (msgToAdd: string, newConv: Conversation, currentConven: Conversation) => void;
};

export const RenderChatAction: React.FC<ChatActionProps> = ({
  currentConversation,
  handleAnswer,
  startAgain,
  showButtonBack,
}) => {
  const [state, setState] = useContext(AppContext) as any;
  const { action } = currentConversation;
  const [clicked, setClicked] = useState(false);

  const handleSubmitAnswer = async (
    actionType: ChatAction["type"],
    text: string,
    value: string,
    conversation: Conversation
  ) => {
    switch (actionType) {
      case "input-text":
      case "options":
        ChatAPI.answerConversation(currentConversation.keyWord, actionType, value);
        break;
      case "confirm-email-action":
        switch (value) {
          case "confirm-email":
            ChatAPI.answerConversation(conversation.keyWord, "input-email", state.user.email);
            break;
          case "reject-confirm-email":
            await setState({ ...state, user: { ...state.user, email: undefined } });
            break;
        }
        break;
      case "input-email":
      case "input-email-reentered":
        setState({ ...state, user: { ...state.user, email: value } });
        ChatAPI.answerConversation(currentConversation.keyWord, actionType, value);
        break;
      default:
        break;
    }
    handleAnswer(text, conversation, currentConversation);
  };

  const getAction = () => {
    switch (action?.type) {
      case "options":
      case "confirm-email-action":
        return <ChatActionOptions {...action} handleSelectOption={handleSubmitAnswer} />;
      case "input-email":
      case "input-text":
      case "input-email-reentered":
        return <ChatActionInput {...action} handleSubmit={handleSubmitAnswer} />;
      default:
        break;
    }
    return <></>;
  };

  const questionToModelAction = {
    type: "input-text" as const,
    conversation: EMPTY_CONVERSATION,
  };

  const handleBackTo = () => {
    setClicked(true);
    function myFunction() {
      startAgain();
      setClicked(false);
    }
    const timeoutId = setTimeout(myFunction, 500);
  };

  return (
    <>
      <div className="chat__action-container">
        {showButtonBack && (
          <svg
            className={"btn-back " + (clicked ? "clicked" : "")}
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            viewBox="0 0 100 100"
            onClick={handleBackTo}
          >
            <path
              className="outter"
              d="M 2,11 C 2,11 2,2 12,2 l 75,0 c 0,0 11,0 11,11 l 0,75 c 0,0 0,10 -11,10 L 12,98 C 12,98 2,99 2,88 z"
            />
            <path
              className="inner"
              d="m 6.2,15 c 0,0 0,-9 8.8,-9 l 64,0 c 0,0 11,0 11,11 l 0,63 c 0,0 1,10 -9,10 l -66,0 c 0,0 -8.8,1 -8.8,-10 z"
            />

            <path className="arrow-1" d="m 78,17 -24,0 0,30 9,0 c 6,0 13,-7 15,-30 z" />
            <path
              className="arrow-2"
              d="m 78,17 0,39 c 0,0 0,8 -6,8 -14,0 -32,0 -32,0 L 40,78 16,51 40,25 40,40 c 0,0 23,0 23,0 11,0 15,-20 15,-23 z"
            />
          </svg>
        )}

        <div className="chat__action-wrapper">{getAction()}</div>
      </div>
    </>
  );
};

const EMPTY_CONVERSATION: Conversation = {
  keyWord: "",
  messages: [
    {
      text: "string",
      position: "right",
    },
  ],
};
