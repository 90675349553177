const WALL = [
  [144, 0],
  [157, 16],
  [239, 63],
  [171, 32],
  [205, 90],
  [180.5, 54],
  [169.25, 39],
  [158, 26],
  [131, 12],
  [134, 24],
  [156.5, 53.5],
  [181, 89],
  [198, 127],
  [200, 135],
  [211, 161],
  [210, 159],
  [185, 109],
  [167, 87.5],
  [137, 57],
  [136, 87],
  [186, 156],
  [191, 164],
  [149, 111],
  [128, 82],
  [131, 97],
  [131, 115],
  [144, 184],
  [132, 152],
  [126, 140],
  [105, 112],
  [85, 88],
  [88, 106.5],
  [88.5, 124.25],
  [95, 138],
  [110, 205],
  [111, 234],
  [103, 193],
  [91, 142],
  [88, 134.5],
  [93, 180],
  [80, 128],
  [71.5, 117.5],
  [69.25, 130.25],
  [81.125, 158.625],
  [84.0625, 187.8125],
  [77, 217],
  [70, 228],
  [52, 245],
  [65, 254],
  [108, 278],
  [104.5, 289],
  [105, 302],
  [106, 719],
  [106.5, 739],
  [108.25, 738],
  [109.125, 724.5],
  [111.5625, 719.75],
  [112, 717],
  [113, 710.5],
  [114.5, 711.25],
  [114.25, 718.625],
  [117.125, 724.3125],
  [118, 726],
  [118, 735.5],
  [123, 736.25],
  [125.5, 732.625],
  [125.75, 727.8125],
  [128, 723],
  [127, 743],
  [130, 751],
  [134, 757],
  [136.5, 760.5],
  [136.75, 768.25],
  [138.875, 776.125],
  [141, 776],
  [379, 779],
  [384, 771],
  [395, 768],
  [400.5, 771.5],
  [401.25, 768.25],
  [396.625, 764.625],
  [396, 759],
  [401, 757],
  [405, 759],
  [406, 764.75],
  [403.5, 766.625],
  [403.25, 772.5625],
  [403.125, 776.53125],
  [406.0625, 778.515625],
  [411, 778.5],
  [440, 779],
  [440, 800],
  [419, 815],
  [416, 854],
  [380, 888],
  [354, 889],
  [354, 824],
  [289, 832],
  [312, 1018],
  [479, 1028],
  [485, 829],
  [490.5, 825],
  [605, 828],
  [640, 752],
  [506, 743.5],
  [418.5, 741.125],
  [331, 740.75],
  [256, 741.875],
  [181, 743],
  [146, 444],
  [299, 435],
  [480.5, 425],
  [475.25, 419],
  [297.625, 430],
  [294, 425],
  [294, 418],
  [297, 411],
  [408.5, 403.5],
  [404, 131],
  [876, 84],
  [883, 394],
  [900, 392],
  [900, 0],
  [149, 0],
].map(([px, py]) => ({ px, py }));

const WALL_CORNER = [
  [690, 756],
  [655, 830],
  [782, 836],
  [787, 838],
  [792, 843],
  [803, 1052],
  [900, 1057],
  [900, 778],
  [690, 756],
].map(([px, py]) => ({ px, py }));

const WALL_CONFIG = {
  backgroundColor: "#b7b19d",
};

const DATA = {
  wall: {
    points: WALL,
    configs: WALL_CONFIG,
  },
  wallCorner: {
    points: WALL_CORNER,
    configs: WALL_CONFIG,
  },
};

export default DATA;
