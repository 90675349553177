import React, { useMemo } from "react";
import Navbar from "./Navbar";
import * as Types from "./types";

const buildSidebarName: Types.IBuildSidebarName = (lg, itemsName) => {
  return Object.entries(itemsName).map(([name, langs]) => ({
    path: lg + "/" + name,
    name: langs[lg],
  }));
};

const StorySidebar: React.FC<Types.Props> = React.memo(({ currentScene, _onItemClick, lg, itemNames }) => {
  const sidebarItems = useMemo(() => buildSidebarName(lg, itemNames), [lg, itemNames]);
  return (
    <div className={"sidebar"}>
      <div className="sidebar__logo">{/* <Logo width={100} renderValue={1} /> */}</div>
      <Navbar onItemClick={_onItemClick} items={sidebarItems} selectedIndex={currentScene} />
    </div>
  );
});

export default StorySidebar;
