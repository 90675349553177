import Foto1 from "assets/characters/mirando_arriba_reduced.png";
import Foto2 from "assets/characters/mirando_derecha_reduced.png";
import Foto6 from "assets/characters/mirando_izquierda_reduced.png";
import Foto3 from "assets/characters/mirando_frente_reduced.png";
import Foto4 from "assets/characters/pensativo_diagonal_abajo_reduced.png";
import Foto5 from "assets/characters/pensativo_frente_reduced.png";
import Foto7 from "assets/characters/mirando_welcome_reduced.png";
import StoryImg from "assets/stories/portfolio/me_homeoffice_starting_profesional.jpeg";
import MiroIdeaShowContent from "assets/stories/portfolio/miro_idea_show_content_max.png";

export const FOTOS_DATA = {
  frente: {
    mirando: Foto3,
    pensando: Foto5,
    welcome: Foto7,
  },
  arriba: {
    mirando: Foto1,
  },
  derecha: {
    mirando: Foto2,
  },
  izquierda: {
    mirando: Foto6,
  },
  abajo: {
    pensando: {
      diagonalAbajo: Foto4,
    },
  },
  stories: {
    portfolio: {
      startingProfesional: StoryImg,
      miroIdeaShowContent: MiroIdeaShowContent,
    },
  },
};
