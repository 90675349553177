import React, { useState, ChangeEvent } from "react";
import { ChatActionInputProps } from "./types";
import { Input } from "semantic-ui-react";
import "./style.scss";
import { ActionInputHelpers } from "./helpers";

const { validateInputValue, placeholders } = ActionInputHelpers;

export const ChatActionInput: React.FC<ChatActionInputProps> = ({ type, handleSubmit, conversation }) => {
  const [inputData, setInputData] = useState({ value: "", valid: false });

  const _onSubmit = () => {
    const { value, valid } = inputData;
    console.log("_onSubmit -> { value, valid }", { value, valid });
    valid && handleSubmit(type, value, value, conversation);
  };

  const _onChange = (e: ChangeEvent, d: { value: string }) =>
    setInputData({ value: d.value.trim(), valid: validateInputValue(d.value, type) });

  return (
    <div className={`chat__action input`}>
      <Input
        action={{
          color: "brown",
          labelPosition: "right",
          icon: "send",
          content: "",
          onClick: _onSubmit,
          disabled: !inputData.valid,
        }}
        placeholder={placeholders(type)}
        onChange={_onChange}
      />
    </div>
  );
};
