import AnimationConfigs from "./configs";
import { sleep } from "utils";

/** botWritting:
 * It renders the messages one to another and set show/hide the three dots bot writting.
 **/

type Props = {
  text: string;
  position?: "right" | "left";
  onClick?: () => Promise<void>;
};

export const botWritting = async (
  msgs: {text: string}[],
  handleShowThreeDots: (show: boolean) => void,
  handleShowMsg: (props: Props) => void,
  asReproducingLast?: boolean
) => {
  const { threeDots: THREE_DOTS_CONFIGS } = AnimationConfigs;

  const duration = asReproducingLast ? THREE_DOTS_CONFIGS.duration.fast : THREE_DOTS_CONFIGS.duration.normal;
  const delay = asReproducingLast ? THREE_DOTS_CONFIGS.delayToAppear.fast : THREE_DOTS_CONFIGS.delayToAppear.normal;

  for (let i = 0; i < msgs.length; i++) {
    handleShowThreeDots(true);
    await sleep(duration).then(() => {
      handleShowThreeDots(false);
      handleShowMsg({ text: msgs[i].text });
    });
    await sleep(delay);
  }
};
