import React, { useState } from "react";
import { ChatActionOptionsProps } from "./types";
import { effectHideNotSelectedOptions, ID_IDENTIFIER } from "./helpers";
import "./style.scss";

export const ChatActionOptions: React.FC<ChatActionOptionsProps> = ({ type, options, handleSelectOption }) => {
  const [selected, setSelected] = useState<ChatActionOptionsProps["options"][0] | undefined>(undefined);

  const _onClick = async (option: ChatActionOptionsProps["options"][0], indexSelected: number) => {
    setSelected(option);
    await effectHideNotSelectedOptions(indexSelected, options);
    handleSelectOption(type, option.text, option.keyWord, option.conversation);
  };

  return (
    <>
      <br />
      {options.map((option, i) => {
        const shouldBeHidden = selected && option !== selected;
        return (
          <div
            id={`${ID_IDENTIFIER}${option.keyWord}`}
            key={`${option.keyWord}-${i}`}
            className={`chat__action option ${selected === option && "selected"} ${shouldBeHidden && "hidden"}`}
            onClick={() => _onClick(option, i)}
          >
            <p>{option.text} →</p>
          </div>
        );
      })}
    </>
  );
};
