import BackgroundClient, { TextElement } from "services/BackgroundClient";
import { FOTOS_DATA } from "components/FotoCatalog/Data";
import StoryData, { BookShelfStoryTextsTypes } from "./texts";
import * as d3 from "d3";
import * as Services from "layout/client/LayoutScroll/services/sections";
import Configs from "configs";
import Fotos from "./fotos";
import { STORY_STRUCTURE_DATA } from "./configs";

type StoryScenesName = BookShelfStoryTextsTypes.StoryScenesName;
type TextElements = BookShelfStoryTextsTypes.TextElements;
type StoryMoments = BookShelfStoryTextsTypes.StoryMoments;

const ID_ORIGINAL = FOTOS_DATA.books;

type TtextFormatted = {
  h1?: string[];
  p?: string[];
};

type BgClientOriginal = BackgroundClient<
  keyof typeof ID_ORIGINAL.objectsDefined,
  keyof typeof ID_ORIGINAL.svgObjects,
  keyof typeof ID_ORIGINAL.textElements
>;

interface StoryRender {}

export class Story implements StoryRender {
  // public static scenes: typeof TextsData = storyScenes;
  // public static scenes: typeof TextsData = storyScenes;
  public static STORY_TEXTS_CALCULATED: Record<
    "es" | "en" | "de",
    Record<StoryScenesName, Record<TextElements, TtextFormatted>[][]>
  >;
  public static StoryTexts: Record<StoryScenesName, Record<TextElements, TtextFormatted>[][]>;
  public static scenes = STORY_STRUCTURE_DATA.scenes;
  private bgClientTitle: BgClientOriginal;
  private textElements: Record<TextElements, TextElement>;

  private storyFotos: typeof Fotos;
  private renderValue: number;
  public currentScene: StoryScenesName;
  private SectionsServ: Services.ImpSections;

  constructor(private lg: string, idSvgContainerTitle: string) {
    this.renderValue = 0;
    console.log("🚀 ~ Story ~ constructor ~ ID_ORIGINAL:", ID_ORIGINAL);
    this.bgClientTitle = new BackgroundClient(ID_ORIGINAL as any, idSvgContainerTitle);

    this.textElements = {
      "middle-frame": this.bgClientTitle.textElements.textWelcome,
      "top-frame": this.bgClientTitle.textElements.textWelcome,
      "books-mid-0": this.bgClientTitle.textElements.textWelcome,
      "bottom-frame": this.bgClientTitle.textElements.textWelcome,
      // "monitor-screen": this.bgClientMonitor.textElements.monitor,
    };

    Story.STORY_TEXTS_CALCULATED = {
      es: this._setTexts("es") as any,
      en: this._setTexts("en") as any,
      de: this._setTexts("de") as any,
    };

    console.log("🚀 ~ Story ~ constructor ~  Story.STORY_TEXTS_CALCULATED:", Story.STORY_TEXTS_CALCULATED);
    Story.StoryTexts = Story.STORY_TEXTS_CALCULATED[lg as keyof typeof Story.STORY_TEXTS_CALCULATED];

    this.storyFotos = Fotos;
    this.currentScene = Story.scenes[0];

    const SECTIONS_DATA = Story.scenes.map((e) => ({
      name: e,
      scrollConsumed: Math.round(10000 / Story.scenes.length),
    }));

    const SectionsServ = new Services.ImpSections(SECTIONS_DATA);
    this.SectionsServ = SectionsServ;

    // Write storyTexts to .storedData.json
  }

  public updateTexts = (lg: "en" | "es" | "de") => {
    Story.StoryTexts = Story.STORY_TEXTS_CALCULATED[lg as keyof typeof Story.STORY_TEXTS_CALCULATED];
  };

  public webhookScrolling = (renderValue: number) => {
    this.renderValue = renderValue;
  };

  public renderSvg = () => {
    // return this.bgClient.renderSvgObject("monitor");
    this.bgClientTitle.useElements([], ["titleWelcome", "textWelcome"], false);
    Object.values(this.textElements).forEach((e) => {
      e.setGroup();
    });
  };

  /** PRIVATE METHODS */

  private _setTexts = (lg: string) => {
    console.log("🚀 ~ Story ~ lg:", lg);
    const storyTexts: [string, any][] = StoryData.reduce((acc, { name, content }) => {
      return {
        ...acc,
        [name]: content[lg as "es" | "en" | "de"].reduce((acc, { moment, texts }) => {
          return {
            ...acc,
            [moment]: Object.entries(texts).reduce((acc, [object, data]) => {
              return {
                ...acc,
                [object]: this.textElements[object as TextElements].formatText(data as any, name, moment),
              };
            }, {} as { [key: string]: any }),
          };
        }, {}),
      };
    }, {} as { [key: string]: any }) as any;

    return storyTexts;
  };
}
