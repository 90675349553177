import * as Helpers from "../helpers";

const PELO = [
  [138, 335],
  [140, 341],
  [140, 345],
  [139, 349],
  [142, 354],
  [147, 358],
  [152, 358],
  [146, 359],
  [142, 364],
  [144, 377],
  [146, 378],
  [146, 383],
  [149, 386],
  [152, 387],
  [148, 383],
  [148, 378],
  [149, 378],
  [151, 382],
  [150, 378],
  [152, 377],
  [155, 377],
  [157, 376],
  [158, 373],
  [159, 369],
  [156, 374],
  [154, 374],
  [153, 373],
  [153, 370],
  [157, 366],
  [157, 367],
  [158, 368],
  [158, 365],
  [162, 357],
  [162, 353],
  [168, 350],
  [175, 349],
  [177, 350],
  [180, 352],
  [178, 355],
  [177, 359],
  [177, 360],
  [180, 355],
  [178, 361],
  [179, 364],
  [179, 361],
  [183, 353],
  [185, 350],
  [186, 349],
  [198, 354],
  [200, 355],
  [198, 356],
  [190, 353],
  [188, 353],
  [190, 355],
  [193, 356],
  [198, 357],
  [198, 359],
  [198, 361],
  [200, 366],
  [202, 372],
  [199, 363],
  [199, 361],
  [203, 362],
  [206, 363],
  [206, 365],
  [206, 367],
  [207, 370],
  [210, 374],
  [212, 379],
  [214, 385],
  [216, 390],
  [216, 386],
  [216, 383],
  [216, 380],
  [217, 378],
  [219, 377],
  [221, 377],
  [223, 378],
  [222, 370],
  [221, 364],
  [221, 358],
  [218, 352],
  [216, 348],
  [212, 344],
  [208, 340],
  [209, 339],
  [208, 337],
  [207, 336],
  [205, 336],
  [204, 336],
  [200, 333],
  [197, 330],
  [194, 329],
  [190, 328],
  [187, 327],
  [183, 326],
  [179, 325],
  [177, 326],
  [174, 327],
  [172, 327],
  [168, 326],
  [165, 327],
  [165, 327],
  [165, 330],
  [163, 331],
  [162, 331],
  [159, 331],
  [159, 329],
  [157, 330],
  [156, 332],
  [155, 335],
  [155, 337],
  [153, 338],
  [151, 340],
  [147, 339],
  [144, 338],
  [144, 338],
  [141, 335],
  [139, 334],
].map(([px, py]) => ({ px, py }));

const NACHO_CARA = [
  [152, 387],
  [154, 388],
  [155, 392],
  [156, 396],
  [157, 400],
  [158, 405],
  [161, 412],
  [167, 419],
  [168, 423],
  [169, 425],
  [171, 427],
  [173, 430],
  [176, 432],
  [178, 434],
  [180, 436],
  [185, 436],
  [190, 434],
  [196, 432],
  [198, 432],
  [200, 433],
  [201, 434],
  [202, 434],
  [205, 433],
  [205, 430],
  [205, 424],
  [205, 420],
  [207, 416],
  [210, 411],
  [212, 407],
  [215, 403],
  [217, 400],
  [219, 397],
  [221, 395],
  [223, 388],
  [224, 383],
  [224, 381],
  [223, 379],
  [223, 377],
  [223, 378],
  [221, 377],
  [219, 377],
  [217, 378],
  [216, 380],
  [216, 380],
  [216, 383],
  [216, 386],
  [216, 390],
  [214, 385],
  [212, 379],
  [210, 374],
  [207, 370],
  [206, 367],
  [206, 365],
  [206, 363],
  [203, 362],
  [199, 361],
  [199, 363],
  [202, 372],
  [200, 366],
  [198, 361],
  [198, 359],
  [198, 357],
  [193, 356],
  [190, 355],
  [188, 353],
  [190, 353],
  [198, 356],
  [200, 355],
  [198, 354],
  [186, 349],
  [185, 350],
  [183, 353],
  [179, 361],
  [179, 364],
  [178, 361],
  [180, 355],
  [177, 360],
  [177, 359],
  [178, 355],
  [180, 352],
  [177, 350],
  [175, 349],
  [168, 350],
  [162, 353],
  [162, 357],
  [158, 365],
  [158, 368],
  [157, 367],
  [157, 366],
  [153, 370],
  [153, 373],
  [154, 374],
  [156, 374],
  [159, 369],
  [158, 373],
  [157, 376],
  [155, 377],
  [152, 377],
].map(([px, py]) => ({ px, py }));

const CARA_IZQ = [
  [152, 387],
  [154, 388],
  [155, 392],
  [156, 396],
  [157, 400],
  [158, 405],
  [161, 412],
  [167, 419],
  [166, 419],
  [168, 415],
  [169, 414],
  [171, 413],
  [172, 413],
  [173, 413],
  [174, 413],
  [174, 409],
  [175, 405],
  [175, 401],
  [175, 396],
  [175, 393],
  [173, 391],
  [170, 389],
  [167, 387],
  [164, 387],
  [161, 386],
  [157, 386],
  [155, 386],
].map(([px, py]) => ({ px, py }));

const CEJA_IZQ = [
  [155, 386],
  [157, 386],
  [161, 386],
  [164, 387],
  [167, 387],

  [171, 388],
  [170, 389],
  [169, 385],
  [164, 384],
  [161, 383],
  [159, 383],
  [155, 382],
  [153, 383],
  [152, 384],
].map(([px, py]) => ({ px, py }));

const CEJA_DER = [
  [176, 386],
  [180, 388],
  [182, 387],
  [186, 385],
  [188, 385],
  [190, 385],
  [192, 384],
  [196, 384],
  [199, 384],
  [202, 384],
  [204, 384],
  [206, 385],
  [202, 381],
  [197, 379],
  [192, 379],
  [189, 380],
  [185, 380],
  [183, 380],
  [182, 381],
  [182, 382],
  [181, 381],
  [177, 384],
].map(([px, py]) => ({ px, py }));

const SOMBRA_DERECHA = [
  [181, 390],
  [182, 392],
  [184, 391],
  [185, 391],
  [186, 392],
  [188, 393],
  [187, 393],
  [185, 394],
  [188, 396],
  [191, 396],
  [192, 397],
  [195, 397],
  [192, 399],
  [190, 400],
  [190, 402],
  [190, 404],
  [188, 404],
  [186, 403],
  [185, 404],
  [184, 406],
  [184, 407],
  [185, 408],
  [187, 408],
  [187, 407],
  [187, 406],
  [189, 405],
  [190, 406],
  [191, 407],
  [193, 407],
  [195, 409],
  [198, 411],
  [200, 411],
  [198, 409],
  [200, 407],
  [201, 405],
  [199, 402],
  [199, 400],
  [198, 398],
  [200, 395],
  [198, 395],
  [195, 395],
  [191, 394],
  [189, 393],
  [187, 391],
  [187, 389],
  [192, 387],
  [196, 387],
  [200, 388],
  [199, 386],
  [194, 386],
  [189, 387],
  [186, 388],
  [183, 389],
].map(([px, py]) => ({ px, py }));

const BARBA = [
  [218, 397],
  [216, 394],
  [216, 391],
  [214, 392],
  [215, 396],
  [212, 400],
  [207, 407],
  [206, 410],
  [205, 413],
  [204, 415],
  [203, 416],
  [202, 416],
  [201, 414],
  [199, 412],
  [196, 411],
  [193, 411],
  [191, 412],
  [189, 410],
  [188, 411],
  [186, 412],
  [185, 413],
  [183, 414],
  [181, 415],
  [179, 416],
  [176, 415],
  [174, 414],
  [174, 413],
  [173, 413],
  [172, 413],
  [171, 413],
  [169, 414],
  [168, 415],
  [166, 419],
  [167, 419],
  [168, 423],
  [169, 425],
  [171, 427],
  [173, 430],
  [176, 432],
  [178, 434],
  [180, 436],
  [185, 436],
  [190, 434],
  [196, 432],
  [198, 432],
  [200, 433],
  [201, 434],
  [202, 434],
  [205, 433],
  [205, 430],
  [205, 424],
  [205, 420],
  [207, 416],
  [210, 411],
  [212, 407],
  [215, 403],
  [217, 400],
  [219, 397],
].map(([px, py]) => ({ px, py }));

const BOCA = [
  [174, 419],
  [174, 422],
  [175, 424],
  [177, 426],
  [178, 427],
  [180, 428],
  [180, 427],
  [178, 425],
  [178, 424],
  [181, 425],
  [182, 425],
  [186, 425],
  [189, 425],
  [189, 424],
  [191, 426],
  [192, 427],
  [194, 425],
  [196, 423],
  [198, 421],
  [198, 420],
  [198, 419],
  [196, 417],
  [193, 417],
  [191, 417],
  [187, 418],
  [185, 419],
  [183, 419],
  [180, 420],
  [177, 419],
  [175, 418],
].map(([px, py]) => ({ px, py }));

const LABIO = [
  [174, 420],
  [176, 422],
  [178, 424],
  [180, 425],
  [181, 425],
  [184, 425],
  [185, 425],
  [188, 425],
  [190, 424],
  [192, 422],
  [195, 419],
  [196, 417],
  [192, 419],
  [189, 420],
  [186, 420],
  [184, 421],
  [181, 421],
  [178, 421],
  [174, 420],
].map(([px, py]) => ({ px, py }));

const OJO_IZQ_BG = [
  [159, 393],
  [161, 395],
  [163, 395],
  [165, 395],
  [168, 394],
  [170, 394],
  [171, 394],
  [171, 393],
  [171, 392],
  [168, 391],
  [165, 391],
  [162, 391],
  [160, 392],
  [159, 393],
].map(([px, py]) => ({ px, py }));

const OJO_IZQ = [
  [163, 391],
  [163, 393],
  [164, 394],
  [166, 394],
  [168, 394],
  [169, 392],
  [168, 391],
  [163, 391],
].map(([px, py]) => ({ px, py }));

const OJO_DER_BG = [
  [187, 392],
  [190, 393],
  [193, 393],
  [196, 393],
  [198, 391],
  [199, 390],
  [198, 389],
  [195, 388],
  [193, 388],
  [191, 389],
  [189, 390],
  [187, 392],
].map(([px, py]) => ({ px, py }));

const OJO_DER = [
  [191, 389],
  [191, 391],
  [193, 392],
  [195, 392],
  [196, 391],
  [196, 389],
  [195, 388],
  [191, 389],
].map(([px, py]) => ({ px, py }));

const NACHO_DATA = {
  fotoPelo: {
    points: PELO,
    configs: { backgroundColor: "rgb(108 72 52)", strokeWidth: "1" },
  },
  fotoNachoCara: {
    points: NACHO_CARA,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
  fotoNachoCaraIzq: {
    points: CARA_IZQ,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  fotoNachoCaraCejaIzq: {
    points: CEJA_IZQ,
    configs: { backgroundColor: "rgb(114 63 36)", strokeWidth: "0" },
  },
  fotoNachoCaraCejaDer: {
    points: CEJA_DER,
    configs: { backgroundColor: "rgb(114 63 36)", strokeWidth: "0" },
  },
  fotoNachoCaraSombraDer: {
    points: SOMBRA_DERECHA,
    configs: { backgroundColor: "rgb(226 165 118)", strokeWidth: "0" },
  },
  fotoNachoCaraBarba: {
    points: BARBA,
    configs: { backgroundColor: "rgb(83 31 14)", strokeWidth: "0" },
  },
  fotoNachoCaraBoca: {
    points: BOCA,
    configs: { backgroundColor: "rgb(209 135 99)", strokeWidth: "0" },
  },
  fotoNachoCaraLabio: {
    points: LABIO,
    configs: { backgroundColor: "rgb(222 123 114)", strokeWidth: "1", stroke: "rgb(186 103 95)" },
  },
  fotoNachoCaraOjoIzqBg: {
    points: OJO_IZQ_BG,
    configs: { backgroundColor: "rgb(254 220 194)", strokeWidth: "1" },
  },
  fotoNachoCaraOjoIzq: {
    points: OJO_IZQ,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
  fotoNachoCaraOjoDerBg: {
    points: OJO_DER_BG,
    configs: { backgroundColor: "rgb(254 220 194)", strokeWidth: "1" },
  },
  fotoNachoCaraOjoDer: {
    points: OJO_DER,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
};

const MAX_BORDER = [
  [256, 475],
  [259, 471],
  [256, 468],
  [258, 465],
  [261, 464],
  [262, 461],
  [261, 456],
  [265.5, 454],
  [270, 450],
  [274, 449],
  [276, 451],
  [280, 446],
  [280, 436],
  [281, 434],
  [285, 431],
  [287, 427],
  [287, 425],
  [283, 424],
  [285, 426],
  [285, 427],
  [281, 425],
  [281, 423],
  [276, 422],
  [276, 420],
  [276, 417],
  [277, 418],
  [278, 417],
  [277, 415],
  [272, 411],
  [269, 410],
  [267, 410],
  [265, 409],
  [262, 405],
  [260, 405],
  [259, 403],
  [257, 400],
  [257, 398],
  [254, 397],
  [252, 396],
  [250, 395],
  [247, 394],
  [245, 394],
  [240, 393],
  [236, 393],
  [231, 393],
  [228, 393],
  [224, 393],
  [221, 394],
  [197, 432],
  [256, 475],
].map(([px, py]) => ({ px, py }));

const POLERON = [
  [154, 434],
  [166, 503],
  [266, 490],
  [257, 477],
  [245, 462],
  [232, 449],
  [232, 446],
  [222, 441.5],
  [215, 441.25],
  [206.5, 438.125],
  [198, 433],
  [182, 438],
  [167, 429],
].map(([px, py]) => ({ px, py }));

const MAX_PELO = [].map(([px, py]) => ({ px, py }));
const MAX_NONO_BG = [].map(([px, py]) => ({ px, py }));
const MAX_NONO_MANGO = [].map(([px, py]) => ({ px, py }));
const MAX_FACE_SHADOW = [].map(([px, py]) => ({ px, py }));
const MAX_CEJA = [].map(([px, py]) => ({ px, py }));
const MAX_OREJA = [].map(([px, py]) => ({ px, py }));
const MAX_OREJA_SHADOW = [].map(([px, py]) => ({ px, py }));
const MAX_DETAIL_NOSE_HOLE = [].map(([px, py]) => ({ px, py }));
const MAX_DETAIL_NOSE_SHADOW = [].map(([px, py]) => ({ px, py }));
const MAX_OJO_BG = [].map(([px, py]) => ({ px, py }));
const MAX_OJO = [].map(([px, py]) => ({ px, py }));

export default {
  ...NACHO_DATA,
  fotoPoleron: {
    points: POLERON,
    // configs: { backgroundColor: "transparent", strokeWidth: "1" },
    configs: { backgroundColor: "rgb(222 211 174)", strokeWidth: "0" },
  },
  fotoMaxBorde: {
    points: MAX_BORDER,
    // configs: { backgroundColor: "transparent", strokeWidth: "1" },
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
};

const point1 = { px: 198, py: 433 };
const point2 = { px: 256, py: 475 };

const pointsExtracted = Helpers.extractPoints(POLERON, point1, point2);
