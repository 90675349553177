const LEATHER = [
  [0, 969],
  [0, 1200],
  [281, 1200],
  [529, 1044],
  [580, 1009],
  [599, 996],
  [853, 835],
  [856, 831],
  [855.5, 825],
  [851, 820],
  [841, 815],
  [832, 813],
  [585, 763],
  [574, 763],
  [565, 764],
  [554, 767],
  [0, 969],
].map(([px, py]) => ({ px, py }));

export default {
  leather: {
    points: LEATHER,
    configs: { backgroundColor: "rgb(116 53 24)" },
  },
};
