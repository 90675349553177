import React from "react";
import "./styles.scss";

type Props = {};

export const SVGOnline: React.FC<Props> = () => {
  return (
    <div className="svg-online-wrapper">
      <svg version="1.0" width="15" height="15" viewBox="0 0 128 128" id="svg2">
        <defs id="defs5">
          <linearGradient id="linearGradient2816">
            <stop style={{ stopColor: "#00b000", stopOpacity: 1 }} offset="0" id="stop2818" />
            <stop style={{ stopColor: "#ffffff", stopOpacity: 1 }} offset="1" id="stop2820" />
          </linearGradient>
          <linearGradient id="linearGradient2794">
            <stop style={{ stopColor: "#545454", stopOpacity: 1 }} offset="0" id="stop2796" />
            <stop style={{ stopColor: "#545454", stopOpacity: 1 }} offset="0.9464286" id="stop2804" />
            <stop style={{ stopColor: "#c5c5c5", stopOpacity: 0 }} offset="1" id="stop2798" />
          </linearGradient>
          <linearGradient id="linearGradient2760">
            <stop style={{ stopColor: "#00ff00", stopOpacity: 1 }} offset="0" id="stop2762" />
            <stop style={{ stopColor: "#00a200", stopOpacity: 1 }} offset="0.61160713" id="stop2768" />
            <stop style={{ stopColor: "#66ff66", stopOpacity: 1 }} offset="1" id="stop2764" />
          </linearGradient>
          <linearGradient
            x1="6.9292336"
            y1="121.07077"
            x2="6.9292336"
            y2="8.9609833"
            id="linearGradient2766"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient2760"
          />
          <radialGradient
            cx="62.984127"
            cy="65.015877"
            r="54.179893"
            fx="62.984127"
            fy="65.015877"
            id="radialGradient2812"
            xlinkHref="#linearGradient2794"
            gradientUnits="userSpaceOnUse"
          />
          <linearGradient
            x1="31.5"
            y1="63"
            x2="31.5"
            y2="12"
            id="linearGradient2822"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient2816"
          />
        </defs>
        <path
          d="M 117.16402,65.015877 C 117.16402,94.938606 92.906856,119.19577 62.984127,119.19577 C 33.061398,119.19577 8.8042336,94.938606 8.8042336,65.015877 C 8.8042336,35.093148 33.061398,10.835984 62.984127,10.835984 C 92.906856,10.835984 117.16402,35.093148 117.16402,65.015877 z "
          transform="translate(5.724864,4.031744)"
          style={{
            opacity: 1,
            fill: "url(#radialGradient2812)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 3.75,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
          id="path2790"
        />
        <path
          d="M 117.16402,65.015877 C 117.16402,94.938606 92.906856,119.19577 62.984127,119.19577 C 33.061398,119.19577 8.8042336,94.938606 8.8042336,65.015877 C 8.8042336,35.093148 33.061398,10.835984 62.984127,10.835984 C 92.906856,10.835984 117.16402,35.093148 117.16402,65.015877 z "
          transform="translate(1.69312,0)"
          style={{
            opacity: 1,
            fill: "url(#linearGradient2766)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "#007400",
            strokeWidth: 3.75,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
          id="path1873"
        />
        <path
          d="M 95.5,37.5 C 95.5,51.583261 81.173112,63 63.5,63 C 45.826888,63 31.5,51.583261 31.5,37.5 C 31.5,23.416739 45.826888,12 63.5,12 C 81.173112,12 95.5,23.416739 95.5,37.5 z "
          transform="translate(1,2)"
          style={{
            opacity: 1,
            fill: "url(#linearGradient2822)",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 3.75,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
          id="path2814"
        />
      </svg>
    </div>
  );
};
