import dataScene1 from "./1-introduction.json";
import dataScene2 from "./2-mid-section.json";
import dataScene3 from "./3-culture-map.json";
import * as Types from "./types";
export { Types as BookShelfStoryTextsTypes };

const BookShelfStoryTexts: Types.StoryScene[] = [
  {
    name: "introduction",
    content: dataScene1 as unknown as Types.StoryScene["content"],
  },
  {
    name: "mid-section",
    content: dataScene2 as unknown as Types.StoryScene["content"],
  },
  {
    name: "culture-map",
    content: dataScene3 as unknown as Types.StoryScene["content"],
  },
];

export default BookShelfStoryTexts;
