import { useState, useEffect } from "react";
import { sleep } from "utils";

export const useLogo = () => {
  const [logoStates, setLogoStates] = useState([]);
  const [rotation, setRotation] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const initAnimation = async () => {
    await rotate();
  };

  const rotate = async () => {
    const TEMPO = 80;

    for (let index = 0; index < 16; index++) {
      // setRotation([0, index >= 8 ? 15 * (index - 7) : 0, index >= 8 ? 0 : 15 * index]);
      // await sleep(TEMPO);

      // setRotation([0, index >= 8 ? 15 * (index - 7) : 0, 15 * index]);

      const smallTriangule = 15 * index;
      const middleTriangule = index >= 8 ? 15 * (index - 7) : 0;
      setRotation([0, 0, 0, 0, middleTriangule, middleTriangule, middleTriangule, middleTriangule, smallTriangule]);

      // setRotation([0, 15 * index, 15 * index]);
      await sleep(TEMPO);
    }
  };

  const setCustomRotation = () => {
    rotate();
  };

  useEffect(() => {
    // sleep(4000);
    // initAnimation();
  }, []);
  useEffect(() => {
    // sleep(2000);
    // initAnimation();
  }, [rotation]);

  return [rotation, setCustomRotation];
};

interface ILogoAnimation {
  runAnimation(): () => void;
  rotate(): () => void;
}

class LogoAnimation {
  constructor(private svgLogo: HTMLElement) {}
}
