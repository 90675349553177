const BACKGROUND = [
  [0, 0],
  [0, 1200],
  [900, 1200],
  [900, 0],
].map(([px, py]) => ({ px, py }));

const BORDER = [
  [0, 0],
  [0, 168], //
  [244, 119],
  [249, 124],
  [259, 121],
  [267, 128],
  [269, 139],
  [252, 146],
  [296, 467],
  [313, 466],
  [315, 481],
  [297, 485],
  [304, 531],
  [32, 555],
  [25, 512],
  [21, 513],
  [18, 497],
  [23, 496],
  [17, 470],
  [0, 472], //
  [0, 1200],
  [900, 1200],
  [900, 1112], // bottom - level
  [439, 1073],
  [436, 1055],
  [439, 1047],
  [447, 1048],
  [449, 1036],
  [459, 891],
  [548, 901],
  [558, 903],
  [569, 907],
  [569, 866],
  [570, 859],
  [590, 855],
  [696, 863],
  [703, 874],
  [715, 877],
  [804, 879],
  [841, 880],
  [862, 882],
  [872, 883],
  [882, 888],
  [884, 894],
  [900, 1044],
  [900, 1048], // bottom - level
  [875, 364], // top level
  [472, 411],
  [390, 420],
  [401, 584], // mid - level
  [461, 581],
  [473, 589],
  [472, 741],
  [453, 743],
  [448, 759],
  [388, 839],
  [421, 1157],
  [416, 1158],
  [416, 1167],
  [171, 1139],
  [120, 837],
  [125, 832],
  [128, 827],
  [388, 838],
  [446, 760],
  [57, 768],
  [52, 751],
  [49, 752],
  [59, 596],
  [158, 590],
  [172, 603],
  [254, 593],
  [274, 593],
  [278, 589],
  [366, 582],
  [375, 614],
  [377, 588],
  [399, 584], // mid - level
  [388, 424],
  [387, 411],
  [359, 411],
  [340, 224],
  [420, 199],
  [424, 204],
  [433, 199],
  [447, 203],
  [452, 208],
  [464, 203],
  [573, 180],
  [578, 186],
  [601, 180],
  [603, 190],
  [708, 169],
  [714, 173],
  [714, 138],
  [793, 120],
  [834, 107],
  [858, 95],
  [863, 100],
  [868, 105],
  [880, 99],
  [886, 130], // top level
  [900, 303],
  [888, 353],
  [889, 362],
  [900, 363],
  [900, 0],
].map(([px, py]) => ({ px, py }));

type Point = {
  px: number;
  py: number;
};

export default {
  background: {
    points: BACKGROUND,
    configs: {
      // backgroundColor: "rgba(4, 244, 4, 0.2)",
      backgroundColor: "transparent",
      // backgroundColor: "white",

      strokeWidth: "1",
      filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
    },
  },
  // books_border: {
  //   points: BORDER,
  //   configs: {
  //     backgroundColor: "transparent",
  //     // backgroundColor: "white",
  //     strokeWidth: "1",
  //     filter: "drop-shadow(-2px 5px 2px rgb(208, 161, 124));",
  //   },
  // },
};

const validatePoints = (points: Point[]) => {
  return points.reduce((acc: any, point) => {
    const { px, py } = point;

    if (px < 10) {
      return [...acc, { ...point, px: 0 }];
    } else if (px > 890) {
      return [...acc, { ...point, px: 900 }];
    } else if (py < 10) {
      return [...acc, { ...point, py: 0 }];
    } else if (py > 1190) {
      return [...acc, { ...point, py: 1200 }];
    } else {
      return [...acc, { ...point }];
    }
  }, []) as Point[];
};

const test = () => {
  // const validatedPoints = validatePoints(BORDER);
  // console.log(
  //   "validatedPoints",
  //   validatedPoints.map(({ px, py }) => [px, py])
  // );
};

test();
