import * as Helpers from "../helpers";

const OJO_DERECHO = [
  [561, 528],
  [560.5, 530.5],
  [562, 533], //
  [575, 536],
  [593, 538], //
  [607, 536],
  [613, 533],
  [610, 528],
  [603.5, 521.5],
  [595, 518],
  [587, 516],
  [578, 517],
  [571, 520],
  [564, 525],
].map(([px, py]) => ({ px, py }));

const OJO_IZQUIERDO = [
  [470, 526],
  [467, 520],
  [457, 513.5],
  [447, 509],
  [437, 509],
  [423, 515],
  [421, 520],
  [422, 525],
  [443, 530],
  [461, 529],
  [468, 529],
].map(([px, py]) => ({ px, py }));

const CEJA_DERECHA = [
  [523, 479],
  [532, 486],
  [539, 499],
  [556, 490],
  [574, 486],
  [597, 487],
  [624, 494],
  [644, 509],
  [643, 500],
  [626, 476],
  [614, 472],
  [604, 466],
  [596, 468],
  [570, 464],
  [562, 464],
  [555, 460],
  [554, 465],
  [549, 465],
  [549, 470],
  [543, 468],
].map(([px, py]) => ({ px, py }));

const CEJA_IZQUIERDA = [
  [413, 460],
  [398, 472],
  [391, 484],
  [391, 496],
  [403, 487],
  [419, 480],
  [426, 475],
  [439, 478],
  [456, 480],
  [474, 487],
  [488, 494],
  [493, 492],
  [488, 475],
  [475, 466],
  [460, 460],
  [435, 457],
  [421, 458],
].map(([px, py]) => ({ px, py }));

const PUPILA_DERECHA = [
  [572, 521],
  [571, 527],
  [572, 533],
  [575.5, 536],
  [585, 537],
  [591, 537.5],
  [597, 536],
  [599.5, 531.5],
  [600, 527],
  [598.5, 522.5],
  [595, 518],
  [587, 516],
  [578, 517],
].map(([px, py]) => ({ px, py }));

const PUPILA_DERECHA_IN = [
  [584, 523],
  [581, 525],
  [579, 529],
  [582, 532],
  [587, 533],
  [592, 529],
  [589, 525],
  [586, 523],
].map(([px, py]) => ({ px, py }));

const PUPILA_IZQUIERDA_IN = [
  [445, 515],
  [441, 518],
  [440, 523],
  [443, 527],
  [447, 527],
  [451, 525],
  [451, 519],
  [448, 515],
].map(([px, py]) => ({ px, py }));

const PUPILA_IZQUIERDA = [
  [437, 509],
  [433, 514.5],
  [432, 521],
  [435, 527],
  [440, 529],
  [448, 529.5],
  [455, 529],
  [458.5, 525.5],
  [459, 522],
  [458, 517],
  [456, 513],
  [448, 509.5],
  [444, 509],
].map(([px, py]) => ({ px, py }));

const SHADOW_EYE_RIGHT_TOP = [
  [548, 522],
  [554, 522],
  [568, 512],
  [585, 508],
  [600, 511],
  [609, 514],
  [614, 520],
  [619, 528],
  [625, 532],
  [623, 527],
  [612, 515],
  [599, 506],
  [585, 505],
  [572, 506],
  [564, 508],
  [553, 516],
].map(([px, py]) => ({ px, py }));

const SHADOW_EYE_LEFT_TOP = [
  [410, 511],
  [417, 507],
  [423, 502],
  [432, 499],
  [442, 497],
  [452, 499],
  [462, 504],
  [467, 508],
  [471, 512],
  [474, 515],
  [477, 515],
  [477, 511],
  [470, 505],
  [454, 498],
  [441, 495],
  [429, 497],
  [421, 499],
  [416, 502],
  [411, 507],
].map(([px, py]) => ({ px, py }));

const SHADOW_EYE_RIGHT_BOTTOM = [
  [546, 540],
  [549, 547],
  [563, 555],
  [576, 560],
  [588, 566],
  [596, 565],
  [607, 563],
  [618, 565],
  [621, 562],
  [595, 556],
  [578, 553],
  [565, 546],
  [553, 541],
].map(([px, py]) => ({ px, py }));

const SHADOW_EYE_LEFT_BOTTOM = [
  [481, 535],
  [455, 535],
  [449, 537],
  [460, 539],
  [445, 543],
  [426, 543],
  [423, 541],
  [416, 541],
  [411, 546],
  [405, 549],
  [420, 550],
  [434, 554],
  [455, 551],
  [465, 548],
  [475, 542],
].map(([px, py]) => ({ px, py }));

const DETAIL_EYE_RIGHT = [
  [595, 538],
  [606, 538],
  [613, 539],
  [620, 536],
  [622, 535],
  [617, 530],
  [617, 526],
  [613, 525],
  [603, 516],
  [594, 514],
  [585, 513],
  [578, 514],
  [571, 516],
  [567, 518],
  [564, 520],
  [561, 522],
  [558, 525],
  [556, 526],
  [552, 527],
  [550, 528],
  [548, 529],
  [546, 531],
  [547, 532],
  [552, 533],
  [556, 533],
  [559, 533],
  [562, 533],
].map(([px, py]) => ({ px, py }));

const DETAIL_EYE_LEFT = [
  [410, 523],
  [419, 527],
  [431, 530],
  [438, 532],
  [444, 531],
  [478, 528],
  [478, 524],
  [474, 521],
  [468, 517],
  [465, 515],
  [464, 515],
  [462, 512],
  [461, 511],
  [459, 510],
  [457, 510],
  [456, 509],
  [453, 507],
  [452, 508],
  [446, 505],
  [440, 505],
  [433, 506],
  [430, 507],
  [428, 505],
  [428, 507],
  [424, 508],
  [421, 509],
  [418, 508],
  [418, 511],
  [415, 512],
  [408, 514],
  [412, 517],
  [408, 517],
  [413, 519],
  [411, 521],
].map(([px, py]) => ({ px, py }));

const LIGHT_RIGHT_EYE = [
  [581, 525],
  [585, 526],
  [586, 523],
  [582, 523],
].map(([px, py]) => ({ px, py }));

const LIGHT_LEFT_EYE = [
  [443, 518],
  [448, 518],
  [448, 516],
  [443, 516],
].map(([px, py]) => ({ px, py }));

export default {
  ojoDerechoDetail: {
    points: DETAIL_EYE_RIGHT,
    configs: { backgroundColor: "rgb(135 93 60)", strokeWidth: "0" },
  },
  ojoIzquierdoDetail: {
    points: DETAIL_EYE_LEFT,
    configs: { backgroundColor: "rgb(135 93 60)", strokeWidth: "0" },
  },
  ojoDerecho: {
    points: OJO_DERECHO,
    configs: { backgroundColor: "white", strokeWidth: "0" }, //rgb(254 220 194)
  },
  ojoIzquierdo: {
    points: OJO_IZQUIERDO,
    configs: { backgroundColor: "white", strokeWidth: "0" },
  },
  cejaIzquierda: {
    points: CEJA_IZQUIERDA,
    configs: { backgroundColor: "rgb(114 63 36)", strokeWidth: "0" },
  },
  cejaDerecha: {
    points: CEJA_DERECHA,
    configs: { backgroundColor: "rgb(114 63 36)", strokeWidth: "0" },
  },
  pupilaDerecha: {
    points: PUPILA_DERECHA,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
  pupilaIzquierda: {
    points: PUPILA_IZQUIERDA,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
  pupilaDerechaIn: {
    points: PUPILA_DERECHA_IN,
    configs: { backgroundColor: "rgb(60 23 11)", strokeWidth: "0" },
  },
  pupilaIzquierdaIn: {
    points: PUPILA_IZQUIERDA_IN,
    configs: { backgroundColor: "rgb(60 23 11)", strokeWidth: "0" },
  },
  shadowEyeRightTop: {
    points: SHADOW_EYE_RIGHT_TOP,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
  shadowEyeLeftTop: {
    points: SHADOW_EYE_LEFT_TOP,
    configs: { backgroundColor: "rgb(79 32 26)", strokeWidth: "0" },
  },
  shadowEyeRightBottom: {
    points: SHADOW_EYE_RIGHT_BOTTOM,
    configs: { backgroundColor: "rgb(208 161 124)", strokeWidth: "0" },
  },
  shadowEyeLeftBottom: {
    points: SHADOW_EYE_LEFT_BOTTOM,
    configs: { backgroundColor: "rgb(208 161 124)", strokeWidth: "0" },
  },
  lightRightEye: {
    points: LIGHT_RIGHT_EYE,
    configs: { backgroundColor: "white", strokeWidth: "0" },
  },
  lightLeftEye: {
    points: LIGHT_LEFT_EYE,
    configs: { backgroundColor: "white", strokeWidth: "0" },
  },
};
