const shadowLighter = [
  [390, 429],
  [483, 422],
  [559, 415],
  [560, 413],
  [643, 404],
  [685, 398],
  [739, 391],
  [789, 389],
  [840, 386],
  [872, 376],
  [876, 365],
  [879, 355],
  [886, 349],
  [886, 349],
  [887, 344],
  [885, 141],
  [731, 206],
  [584, 260],
  [391, 302],
  [390, 429],
].map(([px, py]) => ({ px, py }));

const background = [
  [875, 364], // top level
  [472, 411],

  [387, 411],
  [359, 411],
  [340, 224],
  [420, 199],
  [424, 204],
  [433, 199],
  [447, 203],
  [452, 208],
  [464, 203],
  [573, 180],
  [578, 186],
  [601, 180],
  [603, 190],
  [708, 169],
  [714, 173],
  [714, 138],
  [793, 120],
  [834, 107],
  [858, 95],
  [863, 100],
  [868, 105],
  [880, 99],
  [886, 130], // top level
  [875, 364], // top level
].map(([px, py]) => ({ px, py }));

const shadowDarker = [
  [388, 416],
  [875, 360],
  [879, 347],
  [881, 342],
  [881, 335],
  [886, 335],
  [881, 180],
  [878, 144],
  [878, 117],
  [878, 103],
  [869, 111],
  [866, 114],
  [713, 194],
  [410, 274],
  [388, 416],
].map(([px, py]) => ({ px, py }));

const bookYouDoYou = [
  [341, 224],
  [357, 409],
  [452, 394],
  [451, 340],
  [444, 277],
  [436, 231],
  [430, 212],
  [428, 209],
  [422, 203],
  [420, 198],
  [341, 224],
]
  // .reverse()
  .map(([px, py]) => ({ px, py }));

const bookYouDoYouPageOne = [
  [419, 207],
  [382, 405],
  [454, 396],
  [456, 393],
  [455, 352],
  [449, 284],
  [443, 231],
  [438, 208],
  [436, 202],
  [434, 198],
  [419, 207],
].map(([px, py]) => ({ px, py }));

const bookYouDoYouPages = [
  [434, 200],
  [439, 217],
  [443, 231],
  [456, 355],
  [456, 393],
  [454, 396],
  [466, 396],
  [456, 265],
  [450, 208],
  [447, 203],
  [434, 200],
].map(([px, py]) => ({ px, py }));

const bookYouDoYouLightShadow = [
  [452, 215],
  [466, 396],
  [474, 395],
  [474, 394],
  [495, 391],
  [491, 348],
  [493, 282],
  [497, 247],
  [500, 219],
  [497, 215],
  [490, 215],
  [487, 212],
  [478, 208],
  [470, 207],
  [470, 202],
  [462, 203],
  [451, 207],
  [452, 215],
].map(([px, py]) => ({ px, py }));

const bookYouDoYouShadowSemiDark = [
  [451, 214],
  [465, 396],
  [474, 395],
  [473, 393],
  [478, 389],
  [470, 209],
  [462, 206],
  [461, 207],
  [460, 213],
  [451, 214],
].map(([px, py]) => ({ px, py }));

const bookYouDoYouShadowDark = [
  [455, 248],
  [465, 395],
  [474, 395],
  [463, 246],
  [455, 248],
].map(([px, py]) => ({ px, py }));

const bookDesignYourLife = [
  [461, 204],
  [473, 395],
  [587, 381],
  [574, 182],
  [573, 180],
  [461, 204],
].map(([px, py]) => ({ px, py }));

const bookDesignYourLifePages = [
  [551, 328],
  [574, 180],
  [587, 380],
  [589, 378],
  [578, 187],
  [551, 328],
].map(([px, py]) => ({ px, py }));

const bookDesignYourLifeShadowLight = [
  [578, 186],
  [587, 380],
  [600, 379],
  [595, 193],
  [596, 192],
  [603, 190],
  [601, 180],
].map(([px, py]) => ({ px, py }));

const bookDesignYourLifeShadowDark = [
  [578, 194],
  [588, 379],
  [598, 378],
  [595, 194],
  [596, 192],
  [596, 191],
  [578, 194],
].map(([px, py]) => ({ px, py }));

const bookMoney = [
  [596, 193],
  [595, 194],
  [599, 377],
  [607, 376],
  [672, 368],
  [693, 364],
  [707, 361],
  [713, 360],
  [713, 346],
  [714, 317],
  [715, 306],
  [714, 299],
  [716, 273],
  [717, 255],
  [715, 236],
  [711, 176],
  [711, 173],
  [708, 170],
  [598, 192],
  [596, 193],
]
  .reverse()
  .map(([px, py]) => ({ px, py }));

const bookMoneyPagesBottom = [
  [599, 377],
  [600, 379],
  [718, 366],
  [713, 360],
  [677, 367],
  [658, 370],
  [638, 372],
  [599, 377],
].map(([px, py]) => ({ px, py }));

const bookMoneyPagesSide = [
  [711, 173],
  [717, 255],
  [715, 302],
  [715, 306],
  [713, 324],
  [714, 342],
  [713, 359],
  [719, 366],
  [716, 176],
  [711, 173],
].map(([px, py]) => ({ px, py }));
const bookMoneyShadowLight = [
  [713, 177],
  [706, 178],
  [708, 359],
  [713, 359],
  [719, 366],
  [754, 360],
  [752, 324],
  [749, 291],
  [746, 270],
  [746, 263],
  [760, 178],
  [713, 177],
].map(([px, py]) => ({ px, py }));

const bookMoneyShadowDark = [
  [714, 172],
  [714, 175],
  [703, 175],
  [706, 360],
  [713, 360],
  [720, 366],
  [735, 362],
  [734, 358],
  [738, 358],
  [732, 259],
  [737, 169],
  [714, 172],
].map(([px, py]) => ({ px, py }));

const bookVoice = [
  [714, 138],
  [719, 365],
  [760, 357],
  [804, 348],
  [831, 343],
  [844, 340],
  [855, 337],
  [860, 336],
  [863, 250],
  [865, 176],
  [863, 134],
  [861, 107],
  [859, 96],
  [858, 95],
  [834, 107],
  [810, 115],
  [793, 120],
  [761, 127],
  [731, 134],
  [714, 138],
].map(([px, py]) => ({ px, py }));

const bookVoiceFirstPage = [
  [860, 105],
  [720, 365],
  [869, 346],
  [873, 343],
  [873, 262],
  [873, 205],
  [871, 152],
  [870, 118],
  [868, 104],
  [860, 105],
].map(([px, py]) => ({ px, py }));

const bookVoiceShadowDark = [
  [870, 114],
  [856, 110],
  [724, 363],
  [809, 349],
  [872, 337],
  [874, 347],
  [879, 345],
  [882, 341],
  [881, 335],
  [890, 331],
  [888, 288],
  [877, 106],
  [875, 107],
  [870, 114],
].map(([px, py]) => ({ px, py }));

const shelfFront = [
  [387, 406],
  [387, 417],
  [875, 357],
  [875, 346],
  [387, 406],
].map(([px, py]) => ({ px, py }));

const shelfBaseSide = [
  [386, 417],
  [466, 412],
  [875, 363],
  [880, 336],
  [874, 345],
  [875, 357],
  [386, 417],
].map(([px, py]) => ({ px, py }));

const shelfBg = [
  [377, 269],
  [389, 412],
  [876, 349],
  [873, 145],
  [377, 269],
].map(([px, py]) => ({ px, py }));

const bookYouDoYouPagesBottom = [
  [357, 409],
  [383, 408],
  [466, 396],
  [454, 396],
  [386, 405],
  [357, 409],
].map(([px, py]) => ({ px, py }));

export const def = {
  bookVoice: {
    points: bookVoice,
    configs: {
      backgroundColor: "rgb(244 201 29)",
    },
  },
  bookMoney: {
    points: bookMoney,
    configs: {
      backgroundColor: "rgb(255 248 223)",
    },
  },
  bookDesignYourLife: {
    points: bookDesignYourLife,
    configs: {
      backgroundColor: "rgb(255 232 210)",
    },
  },

  bookYouDoYou: {
    points: bookYouDoYou,
    configs: {
      backgroundColor: "rgb(231 208 181)",
    },
  },
};

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "clipped-top-level non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.2)",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "clipped-top-level non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.2)",
    },
  },

  background: {
    points: background,
    configs: {
      class: "top-level",
      // backgroundColor: "rgba(4, 244, 4, 0.2)",
      backgroundColor: "transparent",
    },
  },
  shelfBg: {
    points: shelfBg,
    configs: {
      class: "clipped-top-level",
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
  },
  bookVoiceFirstPage: {
    points: bookVoiceFirstPage,
    configs: {
      class: "clipped-top-level",
      backgroundColor: "rgb(188 154 112)",
    },
  },
  bookVoiceShadowDark: {
    points: bookVoiceShadowDark,
    configs: {
      class: "clipped-top-level non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
  bookVoice: {
    points: bookVoice,
    configs: {
      backgroundColor: "rgb(244 201 29)",
    },
  },
  bookMoneyShadowLight: {
    points: bookMoneyShadowLight,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
  },
  bookMoneyShadowDark: {
    points: bookMoneyShadowDark,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  bookMoneyPagesSide: {
    points: bookMoneyPagesSide,
    configs: {
      backgroundColor: "rgb(151 131 109)",
    },
  },
  bookMoneyPagesBottom: {
    points: bookMoneyPagesBottom,
    configs: {
      backgroundColor: "rgb(188 154 112)",
    },
  },
  bookMoney: {
    points: bookMoney,
    configs: {
      backgroundColor: "rgb(255 248 223)",
    },
  },
  bookDesignYourLifeShadowLight: {
    points: bookDesignYourLifeShadowLight,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
  },
  bookDesignYourLifeShadowDark: {
    points: bookDesignYourLifeShadowDark,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  bookDesignYourLifePages: {
    points: bookDesignYourLifePages,
    configs: {
      backgroundColor: "rgb(151 131 109)",
    },
  },
  bookDesignYourLife: {
    points: bookDesignYourLife,
    configs: {
      backgroundColor: "rgb(255 232 210)",
    },
  },

  bookYouDoYouLightShadow: {
    points: bookYouDoYouLightShadow,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
  },
  bookYouDoYouShadowSemiDark: {
    points: bookYouDoYouShadowSemiDark,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  bookYouDoYouShadowDark: {
    points: bookYouDoYouShadowDark,
    configs: {
      class: "clipped-top-level  non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
  bookYouDoYouPages: {
    points: bookYouDoYouPages,
    configs: {
      backgroundColor: "rgb(151 131 109)",
    },
  },

  bookYouDoYouPageOne: {
    points: bookYouDoYouPageOne,
    configs: {
      backgroundColor: "rgb(188 154 112)",
    },
  },
  shelfBaseSide: {
    points: shelfBaseSide,
    configs: {
      backgroundColor: "rgb(54 52 52)",
    },
  },
  shelfFront: {
    points: shelfFront,
    configs: {
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
  },
  bookYouDoYouPagesBottom: {
    points: bookYouDoYouPagesBottom,
    configs: {
      backgroundColor: "rgb(151 131 109)",
    },
  },
  bookYouDoYou: {
    points: bookYouDoYou,
    configs: {
      backgroundColor: "rgb(231 208 181)",
    },
  },
};
