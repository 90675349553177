import * as d3 from "d3";
import BgClient from "./index";
import * as Helpers from "./helpers";
import * as Types from "./types";
import { ImageElement } from "./ImageElement";
import { PortfolioStory } from "sections/1-PortfolioStory/Story";

export interface ITextElement {
  // readonly textConfigs: Types.TextConfig;
  readonly textBottomPath: string;
  readonly textTopPath: string;
}

type textPreCalculatedData = {
  linePosition: { x: number; y: number };
}[];

type PreCalculatedData = Array<textPreCalculatedData>;

export class TextElement extends ImageElement implements ITextElement {
  // readonly textConfigs: Types.TextConfig;
  public client: BgClient<any, any, any>;
  public group: d3.Selection<d3.BaseType, unknown, null, undefined>;
  private existingPath: d3.Selection<d3.BaseType, unknown, null, undefined>;
  readonly textBottomPath: string;
  readonly textTopPath: string;

  readonly calculatedValuesFormmated: {
    numSections: number;
  } = { numSections: 0 };
  readonly textsFormatted = PortfolioStory.STORY_TEXTS_CALCULATED;
  textFormatted: any; // Record<string, { h1: string[]; p: string[]; calculated: PreCalculatedData }> = {};

  constructor(client: BgClient<any, any, any>, refId: string, objectData: Types.ElementData) {
    super(client, refId, objectData);
    const { configs } = objectData;

    const setTextBottomPath = () => {
      const p2 = configs?.p2 || { px: 0, py: 0 };
      const p4 = configs?.p4 || { px: 0, py: 0 };

      const paddingLeft = configs?.paddingLeft || 0;

      return [
        { px: p2.px + paddingLeft, py: p2.py },
        { px: p4.px - paddingLeft, py: p4.py },
      ];
    };

    const setTextTopPath = () => {
      const p1 = configs?.p1 || { px: 0, py: 0 };
      const p3 = configs?.p3 || { px: 0, py: 0 };

      const paddingLeft = configs?.paddingLeft || 0;

      return [
        { px: p1.px + paddingLeft, py: p1.py },
        { px: p3.px - paddingLeft, py: p3.py },
      ];
    };
    this.client = client;
    this.textBottomPath = Helpers.hpPointsToPath(setTextBottomPath());
    this.textTopPath = Helpers.hpPointsToPath(setTextTopPath());

    // this.calculatedValuesFormmated = this.calculateValues()
    this.group = this.client.svg.select(`g.g-` + this.refId);
    this.existingPath = this.client.svg.select("#bottomCurvedPath-" + this.refId);
  }

  public setGroup = () => {
    this.group = this.client.svg.select(`g.g-` + this.refId);
  };

  public renderTextRefactored = (
    content: { header?: string[]; paragraph?: string[] },
    readerValue: number,
    effect: "section" | "moving-up" = "section",
    textClass: string = "",
    currentScene: string | undefined = undefined,
    moment: string | undefined = undefined,
    objectText: string | undefined = undefined
  ) => {
    // this.refId === "display" && console.log("TextElement -> content COOONTENT", content);

    if (!content.paragraph) return;

    const { fonts } = this.configs.texts;
    const { heightLine } = fonts.h1;
    const headerHeight = content.header?.length || 0;

    switch (effect) {
      case "section":
        this._handleRenderParagraph(content.paragraph, readerValue, textClass, headerHeight * heightLine);
        break;

      case "moving-up":
        this.renderTextNew(currentScene as string, readerValue, moment as string, objectText as string);
        break;

      default:
        break;
    }
    this.renderH1(content.header, readerValue);
  };

  public renderH1 = (texts: string[] = [""], readerValue: number) => {
    const { padding, fonts, calculatedValues } = this.configs.texts;
    const { fontSize, heightLine } = fonts.h1;
    const { dx, thetaTop, refPoints } = calculatedValues;
    const x0 = refPoints.p1.px + padding.left;
    const y0 = refPoints.p1.py + padding.top;

    this.client.svg.selectAll(`.element-text.header.${this.refId}`).remove();

    const numLin = texts.length;
    texts.flat().forEach((e: string, index: number) => {
      const y = index * heightLine;

      const linePosition = {
        x: x0 + index * dx - numLin * readerValue * dx,
        y: y0 + y,
      };

      this.group
        .append("text")
        .attr("class", "element-text header inner " + this.refId)
        .attr(
          "style",
          `font-size: ${fontSize}px; transform: translate(${linePosition.x}px, ${linePosition.y}px) rotate(${thetaTop}deg);`
        )
        .text(e);
    });
  };

  public renderTextNew = (sectionName: string, readerValue: number, moment: string, objectText: string) => {
    if (readerValue >= 1) return;

    // console.log(
    //   "🚀 ~ TextElement ~ dataCalculated:",
    //   (PortfolioStory.StoryTexts as any)[sectionName as any][moment][objectText]
    // );

    const { p, calculated } = (PortfolioStory.StoryTexts as any)[sectionName as any][moment][objectText];

    this.removeExistingText();

    this.createOrUpdateCurvedPath(this.textBottomPath);

    p.forEach((textLine: string, index: number) => {
      const lineData = calculated["moving-up"][Math.round(readerValue * 10000)][index];
      if (!lineData) {
        return;
      }

      const { linePosition, addOpacity, fontSize, color } = lineData;
      this.addTextElement(textLine, linePosition, fontSize, color, addOpacity);
    });
  };

  private removeExistingText = () => {
    this.client.svg.selectAll(`.element-text.p.${this.refId}`).remove();
  };

  private createOrUpdateCurvedPath = (pathData: string) => {
    if (!this.existingPath.empty()) {
      this.existingPath.attr("d", pathData);
    } else {
      this.client.svg
        .append("path")
        .attr("d", pathData)
        .attr("id", "bottomCurvedPath-" + this.refId);

      this.existingPath = this.client.svg.select("#bottomCurvedPath-" + this.refId);
    }
  };

  private addTextElement = (
    textLine: string,
    linePosition: { x: number; y: number },
    fontSize: number,
    color: string,
    addOpacity: boolean
  ) => {
    this.group
      .append("text")
      .attr("style", `font-size: ${fontSize}px; transform: translate(${linePosition.x}px, ${linePosition.y}px)`)
      .attr("class", "element-text p " + this.refId)
      .append("textPath")
      .attr("xlink:href", "#bottomCurvedPath-" + this.refId)
      .text(textLine === "EMPTY_LINE" ? " " : textLine)
      .attr("fill", color)
      .attr("opacity", addOpacity ? 0.2 : 1);
  };

  public renderTextSection = (
    textT: string[] = [""],
    readerValue: number,
    selectedPath: "Bottom" | "Top" | "Mixed",
    textClass: string = "",
    headerHeight: number = 0
  ) => {
    const { padding, fonts, calculatedValues } = this.configs.texts;
    const { fontSize, heightLine, color } = fonts.p;
    const { dx, refPoints, elementHeight } = calculatedValues;

    this.client.svg.selectAll(`.element-text.p.${this.refId}`).remove();

    // LIGIC to curve the text based on the curvatura as the bottom on the computer.
    const pathDataTop = this.textTopPath;
    const pathDataBottom = this.textBottomPath;

    const y0 = padding.top + headerHeight;

    const refIdBottomPath = `${this.refId}-bottomPath`;
    const refIdTopPath = `${this.refId}-topPath`;

    const existingPathBottom = this.existingPath;

    existingPathBottom.empty() && this.client.svg.append("path").attr("d", pathDataBottom).attr("id", refIdBottomPath);
    !existingPathBottom.empty() && existingPathBottom.attr("d", pathDataBottom);

    const existingPathTop = this.client.svg.select(`#${refIdTopPath}`);

    existingPathTop.empty() && this.client.svg.append("path").attr("d", pathDataTop).attr("id", refIdTopPath);
    !existingPathTop.empty() && existingPathTop.attr("d", pathDataTop);

    const textGroup = this.client.svg.select(`.g-${this.refId}`);

    textT.flat().forEach((e: string, index: number) => {
      const y = (index + 1) * heightLine;
      const currentLine = Math.floor(readerValue * textT.length);

      if (selectedPath === "Mixed") {
        selectedPath = index < textT.length / 2 ? "Top" : "Bottom";
      }

      const H = selectedPath === "Top" ? 0 : elementHeight;

      const linePosition = {
        x: index * dx,
        y: y0 + y - H,
      };

      if (currentLine < index) return;

      const widthRenderValue =
        (readerValue * textT.length) % Math.floor(readerValue * textT.length) ||
        readerValue * textT.length - Math.floor(readerValue * textT.length);

      const textToRender = currentLine === index ? this._getPercentageOfString(e, widthRenderValue) : e;
      const idPath = selectedPath === "Top" ? refIdTopPath : refIdBottomPath;

      textGroup
        .append("text")
        .attr(
          "style",
          `font-size: ${fontSize}px; transform: translate(${linePosition.x}px, ${linePosition.y}px); width: 10px;`
        )
        .attr("class", "element-text p " + this.refId)
        .append("textPath")
        .attr("xlink:href", "#" + idPath)
        .text(e === "EMPTY_LINE" ? " " : textToRender)
        .attr("fill", color);
    });
  };

  public removeText = () => {
    this.client.svg.selectAll(`.element-text.${this.refId}`).remove();
    this.client.svg.selectAll(`.${this.refId}.mask-text-line`).remove();
  };

  public formatText = (texts: { p?: string[]; h1?: string[] }, sceneName?: string, moment?: string) => {
    const newText = Object.entries(texts).map(([key, value]) => {
      const characterPerLine =
        key === "p" ? this.configs.texts.fonts.p.characterPerLine : this.configs.texts.fonts.h1.characterPerLine;
      if (value) {
        return [
          key,
          value.reduce((acc: any, text) => [...acc, Helpers.hpSplitTextIntoLines(text, characterPerLine)], []).flat(),
        ];
      }
      return [key, value];
    });

    if (sceneName) {
      const [h1, p] = [newText[0][1], newText[1][1]];
      const { padding, calculatedValues, fonts } = this.configs.texts;

      const headerHeight = h1?.length || 0;
      const considerHeaderHeight = fonts.h1.heightLine * headerHeight;
      const { heightLine, fontSize, color } = fonts.p;
      const { dx, refPoints } = calculatedValues;
      const [x0, y0] = [padding.left, padding.top + considerHeaderHeight];

      let calculatedValue = [];

      for (let index = 0; index < 10000; index++) {
        const temp = p.map((e: string, i: number) => {
          const y = i * heightLine;
          // const y = 0;

          const renderValue = index / 10000;
          const linePosition = {
            x: x0 + i * dx - p.length * renderValue * dx,
            y: y0 + y - p.length * heightLine * renderValue,
          };

          const paragraphTopLimit = refPoints.p1.py + heightLine + considerHeaderHeight - refPoints.p2.py;

          if (linePosition.y < paragraphTopLimit) return;
          if (linePosition.y < y0 + heightLine - refPoints.p2.py) return;
          if (linePosition.y > refPoints.p2.py - heightLine / 2 - refPoints.p2.py) return;

          const addOpacity = linePosition.y < paragraphTopLimit + 0.2 * heightLine;
          // const addOpacity = false;

          return { linePosition, addOpacity: addOpacity, fontSize, color };
        });
        calculatedValue.push(temp);
      }

      // this.textFormatted = {
      //   ...this.textFormatted,
      //   [sceneName + "_" + moment]: {
      //     h1: h1,
      //     p: p,
      //     calculated: { "moving-up": calculatedValue },
      //   },
      // };

      return {
        h1: h1,
        p: p,
        calculated: { "moving-up": calculatedValue },
      };
    }

    // return Object.fromEntries(newText);
  };

  private _handleRenderParagraph = (
    texts: string[],
    readerValue: number,
    textClass: string = "",
    headerHeight: number = 0
  ) => {
    const { fonts, calculatedValues, padding } = this.configs.texts;
    const { heightLine } = fonts.p;
    const { refPoints } = calculatedValues;
    const { p1, p2 } = refPoints;

    const heightElement = p2.py - p1.py - 2 * padding.top - headerHeight;
    const renderLines = Math.floor(heightElement / heightLine);
    const totalLines = texts.length;

    const numSections = Math.ceil(totalLines / renderLines);

    const currentSection = Math.floor(readerValue * numSections);

    const renderValueCurrentSection =
      (readerValue * numSections) % currentSection || readerValue * numSections - currentSection;

    const sectionInitIndex = currentSection * renderLines;
    const sectionLastIndex = sectionInitIndex + (renderLines - 1);

    const sectionText = texts.slice(sectionInitIndex, sectionLastIndex + 1);
    this.renderTextSection(sectionText, renderValueCurrentSection, "Top", textClass, headerHeight);
  };

  private _getPercentageOfString(inputString: string, percentage: number): string {
    if (percentage < 0 || percentage > 1) {
      throw new Error("Percentage should be between 0 and 1");
    }
    if (percentage > 0.9) {
      return inputString;
    }

    const length = inputString.length;
    const startIndex = 0;
    const endIndex = Math.floor(length * percentage);

    return inputString.slice(startIndex, endIndex);
  }
}
