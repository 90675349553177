import * as Helpers from "../helpers";

import { TRAMO_CAMISA_IZQ_CUELLO, TRAMO_CUELLO_CAMISA_DER } from "./camisa";

const TRAMO_CUELLO_BARBA = [
  [405, 710], //
  [430, 736],
  [438, 744.5],
  [446, 754],
  [466, 768],
  [476.5, 773],
  [487, 776],
  [501, 779],
  [515, 780],
  [531.5, 777.5],
  [548, 773],
  [562.5, 764.5],
  [575, 756],
  [596, 740],
  [616, 723],
  [624, 715],
];

const SECTION_CABEZA_BARBA_INFERIOR = [
  [373, 612], //pointOrejaIzqInferior
  [376, 662],
  [380, 675],
  [390, 689],
  ...TRAMO_CUELLO_BARBA,
  [633, 704],
  [648, 678],
  [655, 662],
  [655, 652], // pointOrejaDerInferior
];

const SECTION_CARA_SUPERIOR = [
  [655, 652], // pointOrejaDerInferior
  [664, 623.5],
  [668, 595.5],
  [669, 578.5],
  [671, 559.5],
  [671, 528.5],
  [671, 526.5],
  [676, 527.5],
  [678, 521.5],
  [668, 512.5],
  [668, 492.5],
  [656, 468.5],
  [658, 451.5],
  [648, 443.5],
  [643, 430.5],
  [640, 426.5],
  [659, 438.5],
  [657, 433.5],
  [654, 424.5],
  [642, 407.5],
  [633, 399.5],
  [606, 377.5],
  [585, 367.5],
  [569, 367.5],
  [547, 363.5],
  [522, 368.5],
  [499, 368.5],
  [478, 371.5],
  [471, 371.5],
  [455, 388.5],
  [434, 399.5],
  [421, 400.5],
  [416, 400.5],
  [406, 409.5],
  [397, 422.5],
  [389, 443.5],
  [387, 453.5],
  [382, 462.5],
  [378, 479.5],
  [377, 490.5],
  [375, 513.5],
  [372, 538.5],
  [372, 567.5],
  [372, 592.5],
  [373, 612], //pointOrejaIzqInferior
];

const TRAMO_BORDE_DERECHO_OREJA_DER = [
  [658, 655],
  [663, 655.5],
  [668, 652],
  [672.5, 648.5],
  [675, 641],
  [682, 617],
  [686, 608.5],
  [688, 600],
  [693.5, 582],
  [696, 564],
  [697, 553.5],
  [694, 547],
  [691, 543], //oreja derecha top
];

const TRAMO_OREJA_IZQ_BORDE_LEFT = [
  [372, 521], //oreja izquierda top
  [368, 524],
  [362, 524],
  [358, 537],
  [359, 552],
  [361, 567],
  [364, 587],
  [365, 606],
  [369, 612],
];

const SECTION_CABEZA_OREJA_IZQ_TO_OREJA_DER_INFERIOR = [
  ...TRAMO_OREJA_IZQ_BORDE_LEFT,
  ...SECTION_CABEZA_BARBA_INFERIOR,
  ...TRAMO_BORDE_DERECHO_OREJA_DER,
];

const SECTION_CABEZA_OREJA_DER_TO_OREJA_IZQ_SUPERIOR = [
  [693.5, 524.5],
  [697.75, 522.25],
  [696.875, 518.125],
  [699.4375, 511.0625],
  [700, 502],
  [706, 488],
  [705.5, 480.5],
  [708.25, 475.75],
  [715.625, 469.375],
  [711.3125, 470.1875],
  [704.15625, 475.59375],
  [699, 475],
  [701, 462],
  [701, 450.5],
  [702, 440.75],
  [696, 427],
  [691, 410],
  [684.5, 396],
  [676, 386],
  [670, 381.5],
  [670, 374.25],
  [666, 367],
  [658.5, 357],
  [653.75, 350],
  [647, 344],
  [641, 334],
  [633, 326],
  [629, 323],
  [622, 318],
  [631.5, 330],
  [639.25, 337],
  [642.125, 344.5],
  [633, 338],
  [621, 325],
  [616, 306],
  [610, 294],
  [605.5, 287],
  [601, 284],
  [605, 291],
  [607, 299],
  [599.5, 286],
  [593, 280],
  [584, 276],
  [577, 273.5],
  [571.5, 273.25],
  [566.75, 275.125],
  [570, 277],
  [576.5, 279],
  [583, 285],
  [580.5, 289],
  [576, 289],
  [583.5, 293],
  [585, 299],
  [581, 303.5],
  [577, 304],
  [568, 281],
  [562, 286],
  [561, 277],
  [566, 268],
  [579, 263],
  [570, 262],
  [574, 258.5],
  [587, 255],
  [573, 253],
  [567, 255],
  [557.5, 260.5],
  [552, 264],
  [551, 256],
  [549, 263],
  [547, 257],
  [544, 264],
  [540, 259.5],
  [531, 256.25],
  [522, 255],
  [515, 262],
  [511.5, 258.5],
  [510, 252],
  [509, 245],
  [506, 242],
  [504, 239],
  [492, 247],
  [494, 231],
  [502, 227],
  [480, 236],
  [476, 220],
  [471, 223],
  [472, 217],
  [467, 221],
  [462, 216],
  [463, 227],
  [455, 215],
  [451, 225],
  [453, 236],
  [445, 228],
  [450, 242],
  [438, 257],
  [413, 251],
  [413, 278],
  [404, 295],
  [399, 265],
  [394, 270],
  [398, 297],
  [396, 307],
  [383, 314],
  [373, 314],
  [371, 323],
  [371, 331],
  [356, 332],
  [350, 340],
  [354, 355],
  [338, 363],
  [346, 366],
  [346, 378],
  [325, 371],
  [329, 381],
  [342, 388],
  [331, 391],
  [341, 400],
  [355, 405],
  [346, 408],
  [337, 402],
  [340, 416],
  [327, 413],
  [337, 425],
  [329, 433],
  [337, 433],
  [337, 441],
  [354, 434],
  [351, 450],
  [355, 473],
  [363, 495],
  [364, 511],
];

const CABEZA = [
  ...SECTION_CABEZA_OREJA_IZQ_TO_OREJA_DER_INFERIOR,
  ...SECTION_CABEZA_OREJA_DER_TO_OREJA_IZQ_SUPERIOR,
].map(([px, py]) => ({ px, py }));

const CUELLO = [...TRAMO_CAMISA_IZQ_CUELLO, ...TRAMO_CUELLO_BARBA, ...TRAMO_CUELLO_CAMISA_DER].map(([px, py]) => ({
  px,
  py,
}));

const SHADOW_NECK_LEFT = [
  ...TRAMO_CAMISA_IZQ_CUELLO.reverse(),
  [523, 987], //
  [511, 957],
  [505.5, 944.5],
  [498, 934],
  [460, 900],
  [450, 873],
  [441, 844],
  [428, 817],
  [414, 791],
  [407, 767],
  [407, 737],
  [405, 715],
].map(([px, py]) => ({
  px,
  py,
}));

const SHADOW_NECK_RIGHT = [
  [629, 715],
  [623, 760],
  [616, 792],
  [610, 824],
  [603, 840],
  [599, 854],
  [599, 865],
  [596, 877],
  [593, 892],
  [592, 917],
  [601, 901],

  ...TRAMO_CUELLO_CAMISA_DER.reverse(),
].map(([px, py]) => ({
  px,
  py,
}));

const CUELLO_BARBA = [
  [429, 736],
  [427, 756],
  [455, 787],
  [479, 810],
  [510, 820],
  [537, 818],
  [553, 805],
  [572, 791],
  [586, 777],
  [595, 760],
  [607, 750],
  [610, 730],
  [596, 741],
  [574, 756],
  [563, 765],
  [548, 773],
  [531, 778],
  [514, 780],
  [500, 779],
  [486, 776],
  [476, 773],
  [465, 767],
  [446, 754],
].map(([px, py]) => ({
  px,
  py,
}));

const CARA_LIGHT_BOTTOM = [
  [507, 511],
  [510, 527],
  [502, 549],
  [492, 576],
  [488, 590],
  [489, 601],
  [499, 609],
  [507, 619],
  [517, 616],
  [527, 611],
  [535, 610],
  [546, 610],
  [553, 606],
  [553, 597],
  [542, 590],
  [537, 593],
  [529, 587],
  [529, 582],
  [523, 576],
  [530, 571],
  [543, 580],
  [554, 586],
  [565, 594],
  [580, 605],
  [596, 626],
  [599, 645],
  [599, 670],
  [610, 658],
  [629, 643],
  [645, 634],
  [653, 625],
  [660, 612],
  [662, 600],
  [664, 581],
  [659, 572],
  [650, 564],
  [644, 556],
  [635, 548],
  [634, 553],
  [621, 553],
  [610, 552],
  [596, 547],
  [583, 547],
  [593, 555],
  [605, 558],
  [613, 562],
  [624, 567],
  [620, 571],
  [604, 575],
  [587, 576],
  [564, 567],
  [552, 559],
  [544, 549],
  [528, 534],
  [527, 517],
  [521, 509],
  [512, 508],
].map(([px, py]) => ({
  px,
  py,
}));
const CARA_LIGHT_TOP = [
  [457, 397],
  [452, 406],
  [442, 410],
  [445, 425],
  [459, 452],
  [476, 466],
  [490, 474],
  [498, 492],
  [532, 490],
  [525, 478],
  [543, 462],
  [568, 458],
  [590, 463],
  [608, 463],
  [627, 468],
  [633, 469],
  [642, 477],
  [649, 477],
  [655, 482],
  [649, 468],
  [647, 446],
  [626, 419],
  [615, 398],
  [590, 379],
  [550, 367],
  [511, 369],
].map(([px, py]) => ({
  px,
  py,
}));
const CARA_LIGHT_LEFT = [
  [409, 598],
  [414, 601],
  [409, 608],
  [409, 617],
  [416, 624],
  [425, 622],
  [434, 609],
  [436, 606],
  [442, 601],
  [442, 596],
  [425, 591],
  [414, 594],
].map(([px, py]) => ({
  px,
  py,
}));
const CUELLO_LIGHT = [
  [510, 829],
  [522, 841],
  [528, 851],
  [540, 858],
  [562, 862],
  [577, 849],
  [596, 821],
  [601, 790],
  [615, 761],
  [623, 749],
  [629, 716],
  [607, 730],
  [603, 746],
  [595, 761],
  [589, 770],
  [581, 783],
  [568, 792],
  [559, 799],
  [544, 805],
  [538, 815],
  [530, 816],
  [523, 816],
].map(([px, py]) => ({
  px,
  py,
}));

const CARA = [...SECTION_CABEZA_BARBA_INFERIOR].map(([px, py]) => ({ px, py }));
const CARA_SUPERIOR = [...SECTION_CARA_SUPERIOR].map(([px, py]) => ({ px, py }));

const BARBA = [
  ...SECTION_CABEZA_BARBA_INFERIOR,
  [655, 652],
  [666, 623],
  [672, 577],
  [659, 615],
  [640, 633.5],
  [617.5, 652.75],
  [597, 668],
  [593, 642],
  [588, 634],
  [549, 616],
  [539, 615],
  [511, 622],
  [495, 622],
  [479, 616],
  [468, 615],
  [456, 620],
  [439, 624],
  [425, 637],
  [419, 648],
  [412, 640],
  [405, 635],
  [398, 628],
  [391, 623],
  [384, 616],
  [379, 609],
  [376, 605],
  [374, 601],
  [372, 596],
  [371, 593],
].map(([px, py]) => ({ px, py }));

const BARBA_SPACE_LEFT = [
  [444, 667],
  [442, 678],
  [443, 687],
  [446, 689],
  [449, 694],
  [452, 700],
  [457, 708],
  [459, 715],
  [464, 711],
  [468, 710],
  [467, 705],
  [472, 701],
  [464, 696],
  [460, 691],
  [456, 686],
  [457, 680],
  [459, 675],
  [448, 668],
].map(([px, py]) => ({ px, py }));

const BARBA_SPACE_RIGHT = [
  [548, 685],
  [551, 690],
  [548, 699],
  [542, 702],
  [537, 707],
  [537, 715],
  [543, 720],
  [549, 717],
  [560, 702],
  [565, 696],
  [572, 694],
  [576, 688],
  [576, 680],
  [573, 675],
  [570, 674],
  [566, 673],
].map(([px, py]) => ({ px, py }));

const BARBA_DARKNESS_BOTTOM = [
  [427, 670],
  [419, 690],
  [423, 723],
  [432, 737],
  [454, 756],
  [471, 767],
  [518, 773],
  [540, 771],
  [584, 745],
  [591, 739],
  [587, 699],
  [577, 696],
  [570, 705],
  [564, 717],
  [564, 739],
  [560, 744],
  [550, 750],
  [536, 751],
  [525, 743],
  [510, 735],
  [502, 735],
  [495, 747],
  [489, 756],
  [481, 754],
  [478, 737],
  [470, 725],
  [463, 724],
  [452, 732],
  [453, 711],
  [446, 698],
  [440, 690],
  [433, 680],
  [431, 675],
].map(([px, py]) => ({ px, py }));

const BARBA_DARKNESS_BARBILLA = [
  [482, 693],
  [479, 698],
  [486, 705],
  [498, 707],
  [506, 706],
  [510, 705],
  [515, 707],
  [524, 707],
  [530, 707],
  [535, 706],
  [536, 697],
  [526, 694],
  [514, 692],
  [509, 690],
  [502, 690],
  [491, 693],
].map(([px, py]) => ({ px, py }));

const SHADOW_CACHETE_LEFT = [
  [444, 597],
  [436, 603],
  [425, 615],
  [416, 622],
  [423, 623],
  [429, 620],
  [437, 614],
  [442, 606],
].map(([px, py]) => ({ px, py }));

const SHADOW_CACHETE_RIGHT = [
  [570, 598],
  [581, 620],
  [597, 631],
  [593, 623],
  [585, 611],
  [577, 603],
  [575, 602],
].map(([px, py]) => ({ px, py }));

const PELO = [].map(([px, py]) => ({ px, py }));

const OREJA_DERECHA = [
  [691, 543], //
  [685, 545],
  [679, 549],
  [675, 564],
  [671, 581],
  [667, 603],
  [666, 623],
  [662, 634],
  [658, 648],
  [655, 652], //
  ...TRAMO_BORDE_DERECHO_OREJA_DER,
].map(([px, py]) => ({ px, py }));

const OREJA_DERECHA_SHADOW1 = [
  [683, 558],
  [683, 569],
  [682, 577],
  [682, 583],
  [683, 588],
  [683, 596],
  [684, 598],
  [686, 591],
  [688, 578],
  [689, 568],
  [690, 561],
  [690, 556],
  [690, 552],
  [686, 553],
  [684, 556],
].map(([px, py]) => ({ px, py }));

const OREJA_DERECHA_SHADOW2 = [
  [668, 603],
  [668, 606],
  [668, 612],
  [668, 613],
  [671, 615],
  [674, 614],
  [678, 608],
  [679, 606],
  [677, 603],
  [674, 600],
  [670, 601],
].map(([px, py]) => ({ px, py }));

const OREJA_IZQ_SHADOW1 = [
  [362, 534],
  [361, 537],
  [361, 541],
  [361, 552],
  [362, 561],
  [363, 574],
  [363, 578],
  [365, 575],
  [365, 565],
  [367, 559],
  [367, 552],
  [366, 548],
  [365, 541],
  [366, 538],
  [366, 535],
  [363, 529],
  [361, 530],
].map(([px, py]) => ({ px, py }));

const OREJA_IZQ = [
  ...TRAMO_OREJA_IZQ_BORDE_LEFT,

  // [369, 612],
  [366, 610],

  [365, 616.5],
  [366, 624.25],
  [371.5, 628.125],
  [374.25, 628.0625],
  [373, 612], //
  [371, 599],
  [371, 588],
  [370, 576],
  [370, 566],
  [370, 555],
  [369, 545],
  [369, 540],
  [370, 530],
  [368, 524], //
].map(([px, py]) => ({ px, py }));

const CEJAS = [].map(([px, py]) => ({ px, py }));
const NARIZ = [
  [512, 508],
  [500, 516],
  [497, 535],
  [479, 565],
  [468, 579],
  [462, 588],
  [460, 604],
  [474, 608],
  [486, 615],
  [500, 621],
  [515, 619],
  [526, 615],
  [534, 611],
  [544, 611],
  [549, 614],
  [554, 610],
  [556, 600],
  [556, 593],
  [547, 587],
  [539, 586],
  [533, 583],
  [526, 573],
  [526, 538],
  [529, 521],
  [528, 513],
  [516, 508],
].map(([px, py]) => ({ px, py }));

export default {
  cabeza: {
    points: CABEZA,
    configs: { backgroundColor: "rgb(83 31 14)", strokeWidth: "0" },
  },
  cuello: {
    points: CUELLO,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
  cuelloShadowLeft: {
    points: SHADOW_NECK_LEFT,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  cuelloShadowRight: {
    points: SHADOW_NECK_RIGHT,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  // cuelloBarba: {
  //   points: CUELLO_BARBA,
  //   configs: { backgroundColor: "rgb(113 64 47)", strokeWidth: "0" },
  // },
  cuelloLight: {
    points: CUELLO_LIGHT,
    configs: { backgroundColor: "rgb(255 209 173)", strokeWidth: "0" },
  },
  cara: {
    points: CARA,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
  caraSuperior: {
    points: CARA_SUPERIOR,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
  caraLightTop: {
    points: CARA_LIGHT_TOP,
    configs: { backgroundColor: "rgb(255 209 173)", strokeWidth: "0" },
  },
  nariz: {
    points: NARIZ,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  caraLightBottom: {
    points: CARA_LIGHT_BOTTOM,
    configs: { backgroundColor: "rgb(255 209 173)", strokeWidth: "0" },
  },
  caraLightLeft: {
    points: CARA_LIGHT_LEFT,
    configs: { backgroundColor: "rgb(255 209 173)", strokeWidth: "0" },
  },
  barba: {
    points: BARBA,
    configs: { backgroundColor: "rgb(83 31 14)", strokeWidth: "0" },
  },
  shadowBarbaLeft: {
    points: BARBA_SPACE_LEFT,
    configs: { backgroundColor: "rgb(113 64 47)", strokeWidth: "0" },
  },
  shadowBarbaRight: {
    points: BARBA_SPACE_RIGHT,
    configs: { backgroundColor: "rgb(113 64 47)", strokeWidth: "0" },
  },
  shadowBarbaBottom: {
    points: BARBA_DARKNESS_BOTTOM,
    configs: { backgroundColor: "rgb(60 23 11)", strokeWidth: "0" },
  },
  shadowBarbaBarbilla: {
    points: BARBA_DARKNESS_BARBILLA,
    configs: { backgroundColor: "rgb(60 23 11)", strokeWidth: "0" },
  },
  shadowCacheteRight: {
    points: SHADOW_CACHETE_RIGHT,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  shadowCacheteLeft: {
    points: SHADOW_CACHETE_LEFT,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  orejaDerecha: {
    points: OREJA_DERECHA,
    configs: { backgroundColor: "rgb(244 189 146)", strokeWidth: "0" },
  },
  orejaIzquierda: {
    points: OREJA_IZQ,
    configs: { backgroundColor: "rgb(208 161 124)", strokeWidth: "0" },
  },
  orejaDerechaShadow1: {
    points: OREJA_DERECHA_SHADOW1,
    configs: { backgroundColor: "rgb(208 161 124)", strokeWidth: "0" },
  },
  orejaDerechaShadow2: {
    points: OREJA_DERECHA_SHADOW2,
    configs: { backgroundColor: "rgb(208 152 109)", strokeWidth: "0" },
  },
  orejaIzqShadow: {
    points: OREJA_IZQ_SHADOW1,
    configs: { backgroundColor: "rgb(172 126 90)", strokeWidth: "0" },
  },
};
