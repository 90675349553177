const shadowLighter = [].map(([px, py]) => ({ px, py }));

const shadowDarker = [].map(([px, py]) => ({ px, py }));

const background = [].map(([px, py]) => ({ px, py }));

const bookHabit = [
  [459, 892],
  [447, 1057],
  [550, 1063],
  [557, 911],
  [554, 904],
  [547, 900],
  [459, 892],
].map(([px, py]) => ({ px, py }));

const bookThinkLikeAMonkPages = [
  [570, 859],
  [570, 863],
  [607, 869],
  [696, 873],
  [696, 864],
  [679, 862],
  [615, 857],
  [590, 855],
  [570, 859],
].map(([px, py]) => ({ px, py }));

const bookThinkLikeAMonk = [
  [569, 865],
  [571, 1068],
  [702, 1074],
  [697, 872],
  [696, 870],
  [619, 868],
  [616, 867],
  [609, 866],
  [608, 864],
  [570, 863],
  [569, 865],
].map(([px, py]) => ({ px, py }));

const bookHappy = [
  [717, 879],
  [718, 1079],
  [890, 1091],
  [882, 895],
  [854, 889],
  [819, 886],
  [770, 882],
  [717, 879],
].map(([px, py]) => ({ px, py }));

const bookHappyPages = [
  [715, 878],
  [718, 879],
  [880, 898],
  [882, 895],
  [883, 890],
  [872, 883],
  [841, 880],
  [781, 879],
  [715, 878],
].map(([px, py]) => ({ px, py }));

const bookHappyLeft = [
  [710, 878],
  [714, 1082],
  [719, 1083],
  [717, 879],
  [710, 878],
].map(([px, py]) => ({ px, py }));

const shelfFront = [
  [436, 1052],
  [439, 1072],
  [899, 1109],
  [899, 1091],
  [436, 1052],
].map(([px, py]) => ({ px, py }));

const shelfFrontTop = [
  [439, 1047],
  [436, 1052],
  [899, 1091],
  [899, 1083],
  [439, 1047],
].map(([px, py]) => ({ px, py }));

const shelfBg = [
  [454, 972],
  [453, 1059],
  [890, 1096],
  [890, 984],
  [454, 972],
].map(([px, py]) => ({ px, py }));

const bookHabitShadowLight = [
  [463, 887],
  [463, 903],
  [457, 1042],
  [572, 1052],
  [572, 896],
  [567, 893],
  [475, 884],
].map(([px, py]) => ({ px, py }));
const bookHabitShadowDark = [
  [459, 891],
  [467, 1056],
  [580, 1065],
  [577, 906],
  [553, 900],
  [474, 890],
].map(([px, py]) => ({ px, py }));

const bookThinkLikeAMonkShadowLight = [
  [590, 854],
  [627, 1068],
  [734, 1077],
  [723, 886],
  [719, 864],
  [717, 861],
  [715, 860],
].map(([px, py]) => ({ px, py }));
const bookThinkLikeAMonkShadowDark = [
  [685, 865],
  [687, 1075],
  [721, 1076],
  [718, 1068],
  [715, 1040],
  [714, 1013],
  [714, 997],
  [714, 984],
  [714, 972],
  [714, 947],
  [713, 930],
  [713, 906],
  [713, 885],
  [713, 879],
  [713, 878],
  [715, 879],
  [715, 877],
  [711, 876],
  [704, 874],
  [703, 873],
  [700, 869],
  [698, 866],
  [698, 862],
  [695, 862],
  [690, 861],
  [686, 862],
].map(([px, py]) => ({ px, py }));

const bookHappyShadowLight = [
  [714, 877],
  [873, 1078],
  [898, 1081],
  [899, 1011],
  [897, 927],
  [897, 889],
  [882, 877],
  [738, 872],
  [728, 870],
  [721, 873],
].map(([px, py]) => ({ px, py }));

const bookHappyShadowDark = [
  [711, 878],
  [883, 1087],
  [899, 1087],
  [898, 1008],
  [890, 895],
  [889, 892],
  [886, 885],
  [878, 882],
  [875, 881],
  [734, 878],
  [713, 877],
].map(([px, py]) => ({ px, py }));

const shelfFrontShadowlight = [
  [544, 1073],
  [568, 1093],
  [898, 1119],
  [897, 1105],
].map(([px, py]) => ({ px, py }));

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "clipped-bottom-level non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.2)",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "clipped-bottom-level non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.4)",
    },
  },
  background: {
    points: background,
    configs: {
      class: "bottom-level",
      backgroundColor: "transparent",
    },
  },
  shelfBg: {
    points: shelfBg,
    configs: {
      class: "bottom-level",
      backgroundColor: "rgb(0 0 0)",
    },
  },
  bookHabitShadowLight: {
    points: bookHabitShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },
  bookHabitShadowDark: {
    points: bookHabitShadowDark,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 50%)",
    },
  },
  bookHabit: {
    points: bookHabit,
    configs: {
      backgroundColor: "rgb(239 199 31)",
    },
  },

  bookThinkLikeAMonkShadowLight: {
    points: bookThinkLikeAMonkShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },

  bookHappyShadowLight: {
    points: bookHappyShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },
  bookHappyShadowDark: {
    points: bookHappyShadowDark,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 50%)",
    },
  },

  bookHappyPages: {
    points: bookHappyPages,
    configs: {
      backgroundColor: "rgb(166 153 129)",
    },
  },
  bookHappyLeft: {
    points: bookHappyLeft,
    configs: {
      backgroundColor: "rgb(146 147 119)",
    },
  },
  bookThinkLikeAMonkShadowDark: {
    points: bookThinkLikeAMonkShadowDark,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 50%)",
    },
  },
  bookThinkLikeAMonkPages: {
    points: bookThinkLikeAMonkPages,
    configs: {
      backgroundColor: "rgb(166 153 129)",
    },
  },
  bookThinkLikeAMonk: {
    points: bookThinkLikeAMonk,
    configs: {
      backgroundColor: "rgb(114 144 167)",
    },
  },
  bookHappy: {
    points: bookHappy,
    configs: {
      backgroundColor: "rgb(182 183 148)",
    },
  },
  shelfFrontTop: {
    points: shelfFrontTop,
    configs: {
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
  },
  shelfFront: {
    points: shelfFront,
    configs: {
      backgroundColor: "rgba(0, 0, 0, 1)",
    },
  },
  shelfFrontShadowlight: {
    points: shelfFrontShadowlight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
  },
};

export const def = {
  bookHabit: {
    points: bookHabit,
    configs: {
      backgroundColor: "rgb(239 199 31)",
    },
  },
  bookThinkLikeAMonk: {
    points: bookThinkLikeAMonk,
    configs: {
      backgroundColor: "rgb(114 144 167)",
    },
  },
  bookHappy: {
    points: bookHappy,
    configs: {
      backgroundColor: "rgb(182 183 148)",
    },
  },
};
