class AnalyticsOptOutManager {
  private readonly urlToSetOptOut = "itsMe";
  private readonly urlToSetOptIn = "itsNotMe";
  private readonly anaylticsExclusionKey = "exclude_from_analytics";
  public isOptedOut: boolean = false;

  constructor() {
    this._setExcludeFromAnalytics();
    this._setIncludeInAnalytics();
    this.isOptedOut = localStorage.getItem(this.anaylticsExclusionKey) === "true";
  }

  private _setExcludeFromAnalytics() {
    this._urlIncludes(this.urlToSetOptOut) && localStorage.setItem(this.anaylticsExclusionKey, "true");
  }

  private _setIncludeInAnalytics() {
    this._urlIncludes(this.urlToSetOptIn) && localStorage.setItem(this.anaylticsExclusionKey, "false");
  }

  private _urlIncludes(value: string) {
    return window.location.search.includes(value);
  }
}

export default new AnalyticsOptOutManager();
