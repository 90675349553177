const [p1, p2, p11, p21] = [
  [449, 653],
  [571, 661],
  [444, 645],
  [575, 647],
];

const TRAMO_LIPS_BOTTOM = [
  p1,
  // [445, 654],
  [448, 657],
  [454, 658],
  [458, 661],
  [471, 663],
  [489, 665],
  [496, 664],
  [500, 665],
  [507, 669],
  [517, 666],
  [525, 666],
  [537, 666],
  [548, 666],
  [556, 666],
  [564, 665],
  [569, 664],
  [572, 665],
  p2,
];
const TRAMO_LIPS_BOTTOM_SONRIENDO = [
  p11,
  // [448, 657],
  // [454, 658],
  // [458, 661],
  [471, 663],
  [489, 667],
  [496, 669],
  [500, 670],
  [507, 671],
  [517, 669],
  [525, 668],
  [537, 668],
  // [548, 666],
  [543, 666], // added sonriendo
  // [556, 666],
  // [564, 665],
  // [569, 664],
  // [572, 665],
  p21,
];
// const TRAMO_LIPS_BOTTOM_SONRIENDO = [
//   p11,
//   // [448, 657],
//   // [454, 658],
//   // [458, 661],
//   [471, 663],
//   [489, 665],
//   [496, 664],
//   [500, 665],
//   [507, 669],
//   [517, 666],
//   [525, 666],
//   [537, 666],
//   // [548, 666],
//   [543, 666], // added sonriendo
//   // [556, 666],
//   // [564, 665],
//   // [569, 664],
//   // [572, 665],
//   p21,
// ];

const TRAMO_LIPS_TOP = [
  p2,
  [565, 660],
  [562, 663],
  [556, 664],
  [543, 664],
  [526, 665],
  [518, 664],
  [512, 664],
  [507, 663],
  [494, 661],
  [488, 661],
  [478, 660],
  [471, 660],
  [464, 659],
  [456, 657],
  [453, 655],
  p1,
];

const TRAMO_LIPS_TOP_SORPRENDIDO = [
  p21,
  // [565, 660],
  // [562, 663],
  // [556, 664],
  [543, 664],
  [526, 665],
  [518, 664],
  [512, 664],
  [507, 663],
  [494, 661],
  [488, 661],
  [478, 660],
  [471, 660],
  // [464, 659],
  // [456, 657],
  // [453, 655],
  p11,
];

const TRAMO_LIPS_TOP_SONRIENDO = [
  p21,
  // [565, 660],
  // [562, 663],
  // [556, 664],
  [543, 659],
  [526, 661],
  [518, 660],
  [512, 662],
  [507, 661],
  [494, 659],
  [488, 657],
  [478, 656],
  [471, 655],
  // [464, 659],
  // [456, 657],
  // [453, 655],
  p11,
];

const TRAMO_LIPS_TOP_SONRIENDO_DIENTES = [
  p21,
  // [565, 660],
  // [562, 663],
  // [556, 664],
  [543, 664],
  [526, 665],
  [518, 664],
  [512, 664],
  [507, 663],
  [494, 661],
  [488, 661],
  [478, 660],
  [471, 660],
  // [464, 659],
  // [456, 657],
  // [453, 655],
  p11,
];

const LIPS_DARKNESS = [...TRAMO_LIPS_BOTTOM, ...TRAMO_LIPS_TOP].map(([px, py]) => ({ px, py }));

const LIPS_DARKNESS_SONRIENDO = [...TRAMO_LIPS_BOTTOM_SONRIENDO, ...TRAMO_LIPS_TOP_SONRIENDO].map(([px, py]) => ({
  px,
  py,
}));

const LABIO_SUPERIOR = [
  ...TRAMO_LIPS_TOP.reverse(),
  [561, 656],
  [551, 653.5],
  [537, 651],
  [521, 651],
  [511.5, 651.5],
  [501, 650],
  [492, 649],
  [481, 648],
  [468, 649],
  [458.5, 650.5],
  [449, 653], //p1
].map(([px, py]) => ({ px, py }));

const LABIO_SUPERIOR_DETAIL_SONRIENDO = [
  [550, 660], // added sonriendo
  p21,
  // [559, 654],
  [551, 649],
  [540, 647],
  [530, 646],
  [521, 645],
  [516, 645],
  [512, 646],
  [509, 647],
  [503, 647],
  [497, 645],
  [490, 645],
  [482, 645],
  [475, 645],
  [466, 645],
  [461, 647],
  // [454, 648],
  // [449, 653], //p1
  p11,
  [457, 653], // added sonriendo
].map(([px, py]) => ({ px, py }));

const LABIO_SUPERIOR_DETAIL = [
  [561, 656],
  [559, 654],
  [551, 649],
  [540, 647],
  [530, 646],
  [521, 645],
  [516, 645],
  [512, 646],
  [509, 647],
  [503, 647],
  [497, 645],
  [490, 645],
  [482, 645],
  [475, 645],
  [466, 645],
  [461, 647],
  [454, 648],
  [449, 653], //p1
].map(([px, py]) => ({ px, py }));
const LABIO_INFERIOR = [
  [446, 657],
  [451, 662],
  [462, 669],
  [468, 673],
  [486, 681],
  [501, 681],
  [517, 683],
  [529, 683],
  [545, 679],
  [556, 673],
  [569, 665],
  ...TRAMO_LIPS_BOTTOM.reverse(),
].map(([px, py]) => ({ px, py }));

const LABIO_INFERIOR_SONRIENDO = [
  // [446, 657],
  // [451, 662],
  [462, 669],
  [468, 673],
  [486, 681],
  [501, 681],
  [517, 683],
  [529, 683],
  [545, 679],
  [556, 673],
  // [569, 665],
  ...TRAMO_LIPS_BOTTOM_SONRIENDO.reverse(),
].map(([px, py]) => ({ px, py }));

const LABIO_INFERIOR_DETAIL = [
  [446, 657],
  [449, 653], //p1
  [457, 673],
  [465, 679],
  [473, 684],
  [481, 687],
  [489, 687],
  [500, 686],
  [510, 687],
  [516, 687],
  [527, 690],
  [536, 690],
  [548, 685],
  [559, 679],
  [564, 675],
  [568, 665],
].map(([px, py]) => ({ px, py }));

const LABIO_INFERIOR_DETAIL_SONRIENDO = [
  p11,
  [449, 653], //p1
  // [457, 673],
  [459, 673],
  [465, 679],
  [473, 684],
  [481, 687],
  [489, 687],
  [500, 686],
  [510, 687],
  [516, 687],
  [527, 690],
  [536, 690],
  [548, 685],
  // [559, 679],
  [557, 679], // added soriendo
  [560, 675], // added sorniendo
  // [564, 675],
  [568, 665],
  p21,
].map(([px, py]) => ({ px, py }));

const dientes = [
  [594, 581],
  [594, 586],
  [598, 590],
  [600, 590],
  [602, 592],
  [605, 591],
  [607, 591],
  [610, 591],
  [612, 590],
  [613, 589],
  [613, 592],
  [616, 592],
  [621, 593],
  [625, 592],
  [630, 593],
  [637, 592],
  [638, 591],
  [638, 590],
  [643, 592],
  [645, 592],
  [647, 591],
  [649, 589],
  [653, 594],
  [655, 593],
  [657, 589],
  [657, 588],
  [661, 591],
  [664, 590],
  [665, 591],
  [667, 589],
  [667, 588],
  [667, 587],
  [670, 587],
  [670, 580],
  [668, 580],
  [666, 580],
  [664, 581],
  [663, 580],
  [661, 579],
  [659, 580],
  [658, 581],
  [657, 579],
  [655, 579],
  [652, 579],
  [651, 579],
  [649, 582],
  [649, 580],
  [646, 579],
  [644, 578],
  [642, 579],
  [640, 581],
  [639, 584],
  [637, 580],
  [637, 579],
  [635, 579],
  [632, 579],
  [629, 580],
  [627, 581],
  [626, 583],
  [625, 585],
  [624, 583],
  [623, 581],
  [623, 580],
  [622, 580],
  [614, 579],
  [613, 583],
  [613, 585],
  [611, 580],
  [610, 579],
  [608, 579],
  [605, 580],
  [605, 584],
  [604, 586],
  [603, 581],
  [601, 580],
  [600, 580],
  [596, 580],
  [594, 581],
].map(([px, py]) => ({ px, py }));

const LABIO_INFERIOR_DETAIL_BRIGHT = [
  [477, 670],
  [484, 673],
  [496, 674],
  [503, 674],
  [507, 674],
  [519, 675],
  [529, 674],
  [535, 672],
  [532, 671],
  [524, 669],
  [518, 669],
  [511, 670],
  [507, 671],
  [498, 669],
  [486, 668],
  [479, 668],
].map(([px, py]) => ({ px, py }));

const LABIO_SUPERIOR_SONRIENDO = [
  ...TRAMO_LIPS_TOP_SONRIENDO.reverse(),
  // [561, 656],
  // [551, 653.5],
  [537, 651],
  [521, 651],
  [511.5, 651.5],
  [501, 650],
  [492, 649],
  [481, 648],
  [468, 649],
  // [458.5, 650.5],
  // [449, 653], //p1
].map(([px, py]) => ({ px, py }));

export default {
  // labioInferiorDetailSonriendo: {
  //   points: LABIO_INFERIOR_DETAIL_SONRIENDO,
  //   configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
  // },
  labioSuperiorDetail: {
    points: LABIO_SUPERIOR_DETAIL,
    configs: { backgroundColor: "rgb(220 147 139)", strokeWidth: "0" },
  },
  labioInferior: {
    points: LABIO_INFERIOR,
    configs: { backgroundColor: "rgb(222 123 114)", strokeWidth: "0" },
  },
  // labioInferiorSonriendo: {
  //   points: LABIO_INFERIOR_SONRIENDO,
  //   configs: { backgroundColor: "rgb(222 123 114)", strokeWidth: "0" },
  // },
  labioSuperior: {
    points: LABIO_SUPERIOR,
    configs: { backgroundColor: "rgb(202 128 121)", strokeWidth: "0" },
  },
  // labioSuperiorDetailSonriendo: {
  //   points: LABIO_SUPERIOR_DETAIL_SONRIENDO,
  //   configs: { backgroundColor: "rgb(220 147 139)", strokeWidth: "0" },
  // },
  // labioSuperiorSonriendo: {
  //   points: LABIO_SUPERIOR_SONRIENDO,
  //   configs: { backgroundColor: "rgb(202 128 121)", strokeWidth: "0" },
  // },
  labioShadow: {
    points: LIPS_DARKNESS,
    configs: { backgroundColor: "#815854", strokeWidth: "0" },
  },
  // labioShadow: {
  //   points: LIPS_DARKNESS_SONRIENDO,
  //   configs: { backgroundColor: "#815854", strokeWidth: "0" },
  // },
  labioInferiorBright: {
    points: LABIO_INFERIOR_DETAIL_BRIGHT,
    configs: { backgroundColor: "rgb(241 133 123)", strokeWidth: "0" },
  },
  // dientes: {
  //   points: dientes,
  //   configs: { backgroundColor: "rgb(241 241 241)", strokeWidth: "0" },
  // },
};
