import React from "react";

export const CraftedMessage = () => (
  <svg
    style={{ position: "absolute", left: "-10px", top: "0px", zIndex: "-1" }}
    width="252"
    height="64"
    viewBox="0 0 252 64"
    fill="none"
  >
    <path
      d="M240.202 54.087C240.393 52.5599 244.014 51.4297 244.251 49.5317C244.501 47.5314 246.861 45.758 247.316 43.7111C248.374 38.9514 250.325 34.0226 250.325 29.061C250.325 24.6182 250.325 20.1753 250.325 15.7325C250.325 12.3616 250.148 6.36913 246.782 4.59731C243.635 2.94119 239.454 1.95411 235.872 1.95411C222.366 1.95411 208.741 1.36344 195.155 1.95411C180.148 2.60661 164.977 2.9664 149.883 2.9664C136.556 2.9664 123.236 3.97869 109.842 3.97869C89.2491 3.97869 68.6565 3.97869 48.0639 3.97869C42.2273 3.97869 34.7146 3.99197 29.1397 6.11575C25.78 7.39562 22.2608 7.5012 19.2698 9.82748C17.0822 11.529 14.8521 13.195 14.4333 16.1262C14.1669 17.9913 13.7869 20.5158 13.168 22.2843C12.42 24.4215 12.9229 26.8005 12.0432 28.7798C11.4849 30.0361 11.4246 32.6045 11.4246 33.9537C11.4246 37.6369 10.9185 41.2945 10.9185 44.9764C10.9185 46.4199 10.9185 47.8633 10.9185 49.3068C10.9185 51.0936 9.83407 50.9229 9.42814 52.3437C9.18651 53.1894 6.49794 55.9768 5.60393 56.8708C4.64861 57.8262 4.01399 58.9669 3.07321 59.9077C1.07437 61.9066 2.07634 62.2282 4.22609 61.5667C6.67642 60.8128 9.87826 59.4178 12.4369 59.1485C13.7132 59.0141 14.7747 58.1996 15.9799 58.1362C18.6045 57.9981 20.9468 56.1608 23.319 55.6336C28.9371 54.3851 35.2671 54.5932 41.0341 54.5932C48.3357 54.5932 55.6373 54.5932 62.9389 54.5932C75.4447 54.5932 87.9136 54.5932 100.366 54.5932C118.29 54.5932 136.358 55.6055 154.326 55.6055C167.835 55.6055 181.328 54.5932 194.818 54.5932C203.666 54.5932 212.514 54.5932 221.362 54.5932C225.027 54.5932 228.692 54.5932 232.357 54.5932C233.682 54.5932 237.584 55.2746 238.178 54.087"
      stroke="grey"
      strokeWidth="1"
      strokeLinecap="round"
      fill="rgb(245, 245, 245)"
    />
  </svg>
);
