const shadowLighter = [
  [483, 540.5],
  [399, 554],
  [495, 548],
  [482, 410],
  [377, 420],
  [483, 540.5],
].map(([px, py]) => ({ px, py }));

const shadowDarker = [
  [392, 550],
  [485, 542],
  [474, 409],
  [382, 419],
  [392, 550],
].map(([px, py]) => ({ px, py }));

const background = [
  [369, 420.5],
  [466, 410.5],
  [478, 544.5],
  [390, 549.5],
  [385, 551.5],
  [369, 420.5],
].map(([px, py]) => ({ px, py }));

const bgFrame = [
  [370, 412],
  [385, 549],
  [478, 543],
  [466, 402],
  [370, 412],
].map(([px, py]) => ({ px, py }));

const bgInner = [
  [383, 423],
  [394, 538],
  [464, 533],
  [454, 416],
  [383, 423],
].map(([px, py]) => ({ px, py }));

const innerShadowLeft = [
  [383, 423],
  [394, 538],
  [398, 538],
  [386, 423],
  [383, 423],
].map(([px, py]) => ({ px, py }));

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "clipped-max non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.2)",
    },
  },
  shadowDarker: {
    points: shadowDarker,
    configs: {
      class: "clipped-max non-stk",
      backgroundColor: "rgba(4, 244, 4, 0.4)",
    },
  },
  // background: {
  //   points: background,
  //   configs: {
  //     class: "max hidden",
  //     backgroundColor: "transparent",
  //   },
  // },
  bgFrame: {
    points: bgFrame,
    configs: {
      backgroundColor: "rgb(35 30 22)",
    },
  },
  bgInner: {
    points: bgInner,
    configs: {
      backgroundColor: "rgb(236 232 224)",
    },
  },
  innerShadowLeft: {
    points: innerShadowLeft,
    configs: {
      class: "non-stk",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
};
