export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const fromSnakeToCapitalFirst = (snakeString: string) => {
  const capitalizeFirstLetter = (input: string): string => {
    if (input.length === 0) {
      return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  return snakeString
    .split("-")
    .map((e, i) => (i === 0 ? capitalizeFirstLetter(e) : e))
    .join(" ");
};
