const background = [
  [401, 584], // mid - level
  [461, 581],
  [473, 589],
  [472, 741],

  [457, 743],
  [447, 759],

  [57, 766],
  [52, 751],
  [49, 752],
  [59, 596],
  [158, 590],
  [172, 603],
  [254, 593],
  [274, 593],
  [278, 589],
  [366, 582],
  [375, 614],
  [377, 588],
  [399, 584], // mid - level
].map(([px, py]) => ({ px, py }));

const shadowLighter = [
  [151, 766],
  [180, 788],
  [584, 783],
  [582, 771],
  [588, 761],
  [527, 630],
  [518, 607],
  [515, 598],
  [512, 590],
  [469, 587],
  [376, 621],
  [184, 605],
  [180, 596],
  [155, 597],
  [151, 766],
].map(([px, py]) => ({ px, py }));

// const shadowDarker = [
//   [471, 736],
//   [519, 736],
//   [518, 724],
//   [527, 717],
//   [488, 575],
//   [383, 593],
//   [387, 627],
//   [173, 606],
//   [168, 586],
//   [62, 599],
//   [94, 741],
//   [471, 736],
// ].map(([px, py]) => ({ px, py }));

const bookSplitShadowLight = [
  [175, 603.5],
  [175, 608.5],
  [254, 605.5],
  [256, 592.5],
  [175, 603.5],
].map(([px, py]) => ({ px, py }));

const bookSurrounddedPagesSide = [
  [254, 649],
  [248, 644],
  [241, 739],
  [245, 739],
  [251, 734],
  [258, 649],
  [254, 649],
].map(([px, py]) => ({ px, py }));
const bookSplitPages = [
  [220, 602.5],
  [240, 736.5],
  [245, 738.5],
  [252, 671.5],
  [259, 600.5],
  [220, 602.5],
].map(([px, py]) => ({ px, py }));

const bookSplitDetailPages = [
  [214, 605.5],
  [219, 608.5],
  [223, 613.5],
  [227, 618.5],
  [230, 624.5],
  [236, 632.5],
  [238, 636.5],
  [223, 604.5],
  [220, 599.5],
  [220, 602.5],
  [218, 603.5],
  [215, 604.5],
  [214, 605.5],
].map(([px, py]) => ({ px, py }));

const bookSurrounddedShadowLight = [
  [275, 591.5],
  [353, 734.5],
  [394, 727.5],
  [376, 614.5],
  [366, 582.5],
  [278, 588.5],
  [275, 591.5],
].map(([px, py]) => ({ px, py }));

const bookSurrounddedShadowDark = [
  [275, 591.5],
  [353, 734.5],
  [394, 727.5],
  [376, 620],
  [366, 590],
  [278, 588.5],
  [275, 591.5],
].map(([px, py]) => ({ px, py }));

const bookSurrounddedPages = [
  [343, 586],
  [356, 734.5],
  [359, 727.5],
  [346, 585.5],
  [343, 586],
].map(([px, py]) => ({ px, py }));
const bookSurroundded = [
  [251, 593.5],
  [260, 741.5],
  [356, 738.5],
  [343, 584],
  [251, 593.5],
].map(([px, py]) => ({ px, py }));

const bookNeverSplit = [
  [171, 604],
  [160, 743],
  [242, 745],
  [243, 684],
  [244, 654],
  [241, 641],
  [237, 634],
  [231, 624],
  [224, 615],
  [220, 609],
  [217, 606],
  [215, 605],
  [218, 603],
  [220, 601],
  [219, 599],
  [217, 599],
  [183, 602],
  [171, 604],
].map(([px, py]) => ({ px, py }));

const bookCultureShadowDark = [
  [159, 591],
  [148, 591],
  [142, 739],
  [171, 738],
  [175, 603],
  [171, 603],
  [159, 591],
].map(([px, py]) => ({ px, py }));

const bookCulture = [
  [59, 596],
  [49, 752],
  [139, 752],
  [148, 590],
  [59, 596],
].map(([px, py]) => ({ px, py }));

const bookCulturePages = [
  [148, 592],
  [140, 740],
  [151, 740],
  [154, 672],
  [155, 636],
  [155, 608],
  [154, 593],
  [148, 592],
].map(([px, py]) => ({ px, py }));

const bookCultureBack = [
  [154, 602],
  [155, 642],
  [154, 669],
  [151, 733],
  [152, 741],
  [153, 740],
  [154, 717],
  [155, 688],
  [156, 668],
  [158, 647],
  [158, 617],
  [158, 602],
  [159, 590],
  [145, 592],
  [145, 592],
  [154, 593],
  [154, 602],
].map(([px, py]) => ({ px, py }));

const bookShelfBack = [
  [60, 695],
  [62, 738],
  [59, 761],
  [448, 755],
  [462, 734],
  [461, 678],
  [60, 695],
].map(([px, py]) => ({ px, py }));

const bookShellFrontSide = [
  [442, 737],
  [446, 759],
  [464, 734],
  [463, 722],
  [448, 749],
  [446, 741],
  [447, 740],
  [446, 734],
  [442, 737],
].map(([px, py]) => ({ px, py }));

const shelfSideDetail = [
  [446, 734],
  [442, 737],
  [444, 743],
  [446, 741],
  [447, 740],
  [446, 734],
].map(([px, py]) => ({ px, py }));

const bookShellFrontTop = [
  [55, 742],
  [49, 747],
  [443, 737],
  [446, 734],
  [55, 742],
].map(([px, py]) => ({ px, py }));

const bookShelfFront = [
  [50, 746],
  [57, 766],
  [446, 759],
  [443, 737],
].map(([px, py]) => ({ px, py }));

const bookMindset = [
  [377, 588],
  [373, 632],
  [371, 687],
  [369, 740],
  [461, 739],
  [462, 654],
  [462, 580],
  [427, 583],
  [403, 584],
  [377, 588],
].map(([px, py]) => ({ px, py }));

const bookMindsetPages = [
  [462, 581],
  [461, 738],
  [473, 740],
  [473, 589],
  [462, 581],
].map(([px, py]) => ({ px, py }));

const bookMindSetShadowLight = [
  [453, 581],
  [454, 735],
  [519, 736],
  [518, 724],
  [527, 717],
  [488, 576],
  [462, 580],
  [453, 581],
].map(([px, py]) => ({ px, py }));

export const mock = {
  bookCulture: {
    points: bookCulture,
    configs: {
      backgroundColor: "rgb(157 184 191)",
    },
  },
  bookSurroundded: {
    points: bookSurroundded,
    configs: {
      backgroundColor: "rgb(213 179 146)",
    },
  },
  bookNeverSplit: {
    points: bookNeverSplit,
    configs: {
      backgroundColor: "rgb(254 218 40)",
    },
  },
  bookMindset: {
    points: bookMindset,
    configs: {
      backgroundColor: "rgb(237 220 190)",
    },
  },
};

export default {
  shadowLighter: {
    points: shadowLighter,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / %15)",
    },
  },
  // shadowDarker: {
  //   points: shadowDarker,
  //   configs: {
  //     class: "shadow-darker clipped-mid-level",
  //     backgroundColor: "rgba(4, 244, 4, 0.4)",
  //   },
  // },
  // background: {
  //   points: background,
  //   configs: {
  //     class: "mid-level",
  //     backgroundColor: "transparent",
  //   },
  // },
  bookShelfBack: {
    points: bookShelfBack,
    configs: {
      class: "mid-level",
      backgroundColor: "black",
    },
  },

  bookCultureShadowDark: {
    points: bookCultureShadowDark,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 70%)",
    },
  },
  bookCultureBack: {
    points: bookCultureBack,
    configs: {
      backgroundColor: "rgb(84 68 40)",
    },
  },
  bookCulturePages: {
    points: bookCulturePages,
    configs: {
      backgroundColor: "rgb(122 89 47)",
    },
  },
  bookCulture: {
    points: bookCulture,
    configs: {
      backgroundColor: "rgb(157 184 191)",
    },
  },
  bookSplitShadowLight: {
    points: bookSplitShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },
  bookSurrounddedPagesSide: {
    points: bookSurrounddedPagesSide,
    configs: {
      backgroundColor: "rgb(78 57 27)",
    },
  },
  bookSplitPages: {
    points: bookSplitPages,
    configs: {
      backgroundColor: "rgb(142 101 52)",
    },
  },

  bookSplitDetailPages: {
    points: bookSplitDetailPages,
    configs: {
      backgroundColor: "rgb(237 220 190)",
    },
  },
  bookSurrounddedShadowLight: {
    points: bookSurrounddedShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },
  bookSurrounddedShadowDark: {
    points: bookSurrounddedShadowDark,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 33%)",
    },
  },
  bookSurrounddedPages: {
    points: bookSurrounddedPages,
    configs: {
      backgroundColor: "rgb(75 52 24)", //"rgb(142 101 52)",
    },
  },
  bookSurroundded: {
    points: bookSurroundded,
    configs: {
      backgroundColor: "rgb(213 179 146)",
    },
  },
  bookNeverSplit: {
    points: bookNeverSplit,
    configs: {
      backgroundColor: "rgb(254 218 40)",
    },
  },
  bookMindSetShadowLight: {
    points: bookMindSetShadowLight,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(0 0 0 / 15%)",
    },
  },
  bookShellFrontSide: {
    points: bookShellFrontSide,
    configs: {
      backgroundColor: "rgb(89 79 63)",
    },
  },
  bookMindsetPages: {
    points: bookMindsetPages,
    configs: {
      backgroundColor: "rgb(166, 153, 129)",
    },
  },
  bookMindset: {
    points: bookMindset,
    configs: {
      backgroundColor: "rgb(237 220 190)",
    },
  },
  shelfSideDetail: {
    points: shelfSideDetail,
    configs: {
      class: "non-stk",
      backgroundColor: "rgb(89 79 63)",
    },
  },
  bookShellFrontTop: {
    points: bookShellFrontTop,
    configs: {
      backgroundColor: "rgb(71 63 50)",
    },
  },
  bookShelfFront: {
    points: bookShelfFront,
    configs: {
      backgroundColor: "rgb(35 30 22)",
    },
  },
};
