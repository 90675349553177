import React, { useState, useEffect } from "react";
import * as Helpers from "./helpers";
import { useLogo } from "./useLogo";
import "./styles.scss";

type Props = {
  className?: string;
  onCLick?: () => void;
};

const CONFIGS = {
  width: 180,
};

export const LogoArrow: React.FC<Props> = ({ className = "", onCLick = () => {} }) => {
  const [elems, setElems] = useState(Helpers.arrowPoints(CONFIGS.width));
  const [rotation, rotate] = useLogo();

  useEffect(() => {
    // console.log("rotation", rotation);
  }, [rotation]);

  return (
    <svg
      className={["designed-arrow", className].join(" ")}
      width={CONFIGS.width / 2}
      viewBox={`0 0 ${CONFIGS.width / 2} ${CONFIGS.width / 2}`}
      onClick={onCLick}
    >
      <g style={{ transform: `translate(0px, ${-(CONFIGS.width / 2) * Math.sin(60)}px)` }}>
        {elems.map((elem, i) => {
          return (
            <polyline
              key={elem.className}
              className={elem.className}
              points={elem.points.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
            />
          );
        })}
      </g>
    </svg>
  );
};
