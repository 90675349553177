const DESK = [
  [0, 877],
  [0, 1200],
  [281, 1200],
  [900, 817],
  [900, 739],
  [652, 688],
  [2, 877],
].map(([px, py]) => ({ px, py }));

const DESK_BOTTOM = [
  [281, 1200],
  [378, 1200],
  [900, 850],
  [900, 817],
  [281, 1200],
].map(([px, py]) => ({ px, py }));

export default {
  desk: {
    points: DESK,
    configs: { backgroundColor: "rgb(210 132 43)" },
  },
  deskBottom: {
    points: DESK_BOTTOM,
    configs: { backgroundColor: "rgb(45 10 4)" },
  },
};
