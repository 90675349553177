import React, { useEffect, useState } from "react";
import LayoutScroll from "./LayoutScroll";
import { ImpSections } from "./LayoutScroll/services/sections";
import * as Configs from "./story_settings";
import SocialMediaFrame from "./LayoutScroll/services/social_media";

type Props = { story: keyof typeof Configs.sectionsData };
const renderAs = undefined; //"post";
const frameImp = new SocialMediaFrame({ width: window.innerWidth, height: window.innerHeight }, renderAs);

const LayoutClient: React.FC<Props> = ({ story }) => {
  const [layoutImps, setLayoutImps] = useState<{ sections: ImpSections | undefined; frame: SocialMediaFrame }>({
    sections: undefined,
    frame: frameImp,
  });

  useEffect(() => {
    window.addEventListener("resize", handleViewportHeightChange);
    return () => {
      window.removeEventListener("resize", handleViewportHeightChange);
    };
  }, []);

  useEffect(() => {
    setLayoutImps({ sections: new ImpSections(Configs.sectionsData[story]), frame: frameImp });
  }, [story]);

  const handleViewportHeightChange = React.useCallback(() => {
    const currentViewportHeight = window.innerHeight;

    if (currentViewportHeight > window.innerHeight) {
      frameImp.refreshValues({ width: window.innerWidth, height: window.innerHeight }, frameImp.renderAs);
      console.log("Frame Changed to: " + frameImp.smHeight + "px");
      setLayoutImps((e) => ({ ...e, frame: frameImp }));
    }
  }, [frameImp]);

  React.useEffect(() => {
    window.addEventListener("resize", handleViewportHeightChange);
    return () => {
      window.removeEventListener("resize", handleViewportHeightChange);
    };
  }, [handleViewportHeightChange]);

  return (
    <>
      {layoutImps.sections && (
        <LayoutScroll
          story={story}
          SectionsImp={layoutImps.sections}
          show={Configs.showStates[story]}
          frameSizes={{
            width: layoutImps.frame.smWidth,
            height: layoutImps.frame.smHeight,
            renderAs: layoutImps.frame.renderAs,
          }}
        />
      )}
    </>
  );
};

export default LayoutClient;
