import { sleep } from "utils";
import * as Configs from "../configs";

// Assuming T is a string or number type
type ProgressesFormatted<T extends string | number> = (scrollValue: number) => { [key in T]: number };

interface ISections<T extends string | number> {
  progresses: (scrollValue: number) => number[];
  progressesFormmatted: ProgressesFormatted<T>;
  current: number;
  runLoadingSection: (sectionIndex: number) => void;
}

type SectionData = {
  name: string;
  scrollConsumed: number;
};

type Section = {
  name: string;
  scrollConsumed: number;
  range: {
    min: number;
    max: number;
  };
};

function generateDataWanted(sectionsData: SectionData[]): (number | number[])[][][] {
  let dataWanted: number[][][] = [];

  let scrollAccumulator = 0;
  sectionsData.forEach((section) => {
    const sectionData: number[][] = [];

    for (let i = 0; i <= section.scrollConsumed; i++) {
      const normalizedScroll = i / section.scrollConsumed;
      sectionData.push([scrollAccumulator + i, normalizedScroll]);
    }

    scrollAccumulator += section.scrollConsumed;
    dataWanted.push(sectionData);
  });

  return dataWanted.map((section, sectionIndex) =>
    section.map(([key, val], i) => {
      const progress = dataWanted.map((e, i) => {
        if (i < sectionIndex) return 1;
        if (i > sectionIndex) return 0;
        return val;
      });
      const current = sectionIndex;

      return [key, progress, current];
    })
  );
}

const TC = Configs.sectionsData.reduce((acc, { scrollConsumed }) => acc + scrollConsumed, 0);

export class ImpSections implements ISections<string> {
  static scrollLayoutData: SectionData[] = Configs.sectionsData.map((e) => ({
    ...e,
    scrollConsumed: (e.scrollConsumed * (TC - window.innerHeight)) / TC,
  })); // the method depends on the layout to do the calculations, of all children sections.
  private readonly data: Section[];
  public readonly totalScrollConsumed: number;
  public current: number;
  public progressCalculated: any; //Array<[number, number[]]>;

  constructor(sectionData: SectionData[]) {
    this.totalScrollConsumed = sectionData.reduce((acc, { scrollConsumed }) => acc + scrollConsumed, 0);
    this.data = this._setData(sectionData);
    this.current = 0;
    this.progressCalculated = generateDataWanted(
      sectionData.map((e) => ({
        ...e,
        scrollConsumed: (e.scrollConsumed * (this.totalScrollConsumed - window.innerHeight)) / this.totalScrollConsumed,
      }))
    ).flat();
  }

  public progressesRefactored = (scrollValue: number) => {
    const data = this.progressCalculated[Math.round(scrollValue)] || [scrollValue, this.data.map((e) => 0), 0];
    const progress = data[1] as number[];
    this.current = data[2] + 1;
    return progress;
  };

  public progresses = (scrollValue: number) => {
    return this.data.map(({ scrollConsumed, range }, index) => {
      if (scrollValue > range.max) {
        return 1;
      } else if (scrollValue < range.min) {
        return 0;
      } else {
        this.current = index + 1;

        // for the last step it is not scrolling the total value, only the 90% consumed, it implies for this step we will do aplly a factor.
        const isLast = this.current === this.data.length;
        if (isLast) {
          return (scrollValue - range.min) / scrollConsumed / 0.9;
        } else {
          return (scrollValue - range.min) / scrollConsumed;
        }
      }
    });
  };

  public progressesFormmatted = (scrollValue: number) => {
    return this.progresses(scrollValue).reduce((acc, progress, i) => {
      return { ...acc, [this.data[i].name]: progress };
    }, {});
  };

  public runLoadingSection = async () => {
    const deltaScroll = 50;
    const deltaTime = 30; //ms

    const toConsume = ImpSections.scrollLayoutData[0].scrollConsumed; //  + ImpSections.scrollLayoutData[1].scrollConsumed;
    const scrollEvent = new Event("scroll");

    window.scrollTo({
      top: toConsume - deltaScroll,
      behavior: "smooth",
    });

    // for (let val = 0; val < toConsume - deltaScroll; val = val + deltaScroll) {
    //   // const aux = () => {
    //   // window.scrollBy(0, deltaScroll);
    //   window.scrollTo(0, val + deltaScroll);
    //   window.dispatchEvent(scrollEvent);
    //   // };
    //   // requestAnimationFrame(aux);
    //   await sleep(deltaTime);
    // }
  };

  public goToSection = (sectionIndex: number, option: "bottom" | "top" = "top") => {
    const groundVAlue = ImpSections.scrollLayoutData[0].scrollConsumed;
    const totalValue = ImpSections.scrollLayoutData[1].scrollConsumed;
    const renderValue = this.data[sectionIndex].range[option === "bottom" ? "max" : "min"] / 10000;
    const magicFactor = option === "bottom" ? 1 : 1.05;
    const scrollToSet = groundVAlue + renderValue * totalValue * magicFactor;

    if (sectionIndex == 0) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, scrollToSet + 10);
    }
  };

  private _setData = (dataInput: SectionData[]) => {
    let acc = 0;
    const response = dataInput.map((sectionData) => {
      const range = {
        min: acc,
        max: acc + sectionData.scrollConsumed,
      };
      acc = acc + sectionData.scrollConsumed;

      return {
        ...sectionData,
        range,
      };
    });
    return response;
  };
}
