import React, { useEffect, useState, useMemo } from "react";
import "./styles.scss";
import { Story } from "./Story";
import { useLanguage } from "services/translate";
import { useNavigate } from "react-router-dom";

type Props = { renderValue: number }; // number 0 - 1.

const BookShelfStory: React.FC<Props> = ({ renderValue }) => {
  const [storyImp, seStoryImp] = useState<Story | undefined>(undefined);
  const navigate = useNavigate();
  const { language } = useLanguage();

  const changePath = (newPath: string) => {
    navigate(language + "/" + newPath);
  };

  useEffect(() => {
    const bgServ = new Story(language, "client-book-shelf");

    seStoryImp(bgServ);
    bgServ.renderSvg();
  }, []);

  useEffect(() => {
    storyImp && changePath(storyImp.currentScene);
  }, [storyImp?.currentScene]);

  useEffect(() => {
    storyImp && storyImp.updateTexts(language);
    storyImp && changePath(storyImp.currentScene);
  }, [language]);

  useEffect(() => {
    storyImp && storyImp.webhookScrolling(renderValue);
  }, [renderValue]);

  return (
    <div className="wrapper-book-shelf">
      <svg
        id="client-book-shelf"
        xmlns="http://www.w3.org/2000/svg"
        className="client-book-shelf"
        preserveAspectRatio="none"
      ></svg>
    </div>
  );
};

export default BookShelfStory;
