import React, { useState, useEffect, useRef } from "react";
import AnimateLogo from "./animations";
import "./styles.scss";
import MyAnalytics from "services/Analytics";

type Props = {
  width?: number;
  renderValue: number; //0-1
  clickIt?: boolean;
  onClick?: () => void;
};

let timeoutId: NodeJS.Timeout;
export const Logo: React.FC<Props> = ({ width = 200, renderValue, onClick = () => {}, clickIt }) => {
  const [animateService, setAnimateService] = useState<AnimateLogo | undefined>(undefined);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;
    setAnimateService(new AnimateLogo(svgRef.current, width));
  }, []);

  useEffect(() => {
    clickIt && _handleOnClick();
  }, [clickIt]);

  useEffect(() => {
    if (renderValue > 0) {
      clearTimeout(timeoutId);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [renderValue]);

  useEffect(() => {
    animateService?.init();
    timeoutId = setTimeout(() => {
      renderValue === 0 && _handleOnClick();
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [animateService]);

  const _handleOnClick = async () => {
    await animateService?.play();
    onClick();
  };

  return (
    <svg
      id="svg-logo"
      ref={svgRef}
      className="logo"
      width={width}
      viewBox={`0 0 ${width} ${width}`}
      preserveAspectRatio="none"
      onClick={() => {
        MyAnalytics.capture("clicked_logo");
        _handleOnClick();
      }}
    ></svg>
  );
};
