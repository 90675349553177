const COMPLETE = [
  [54, 972],
  [52, 1024],
  [115, 1135],
  [122, 1142],
  [131, 1145],
  [156, 1136],
  [593, 941],
  [593, 927],
  [591, 926],
  [590, 921],
  [588, 919],
  [587, 915],
  [584, 913],
  [582, 908],
  [570, 901],
  [568, 898],
  [557, 894],
  [550, 889],
  [550, 887],
  [543, 885],
  [502, 860],
  [500, 858],
  [493, 851],
  [472, 838],
  [467, 836],
  [462, 835],
  [65, 964],
  [57, 968],
  [55, 970],
  [54, 972],
].map(([px, py]) => ({ px, py }));

const TOP = [
  [54, 977],
  [64, 998],
  [68, 1012],
  [71, 1001],
  [120, 1096],
  [125, 1101],
  [129, 1101],
  [134, 1101],
  [139, 1100],
  [583, 913],
  [582, 908],
  [570, 901],
  [568, 898],
  [557, 894],
  [550, 889],
  [550, 887],
  [543, 885],
  [502, 860],
  [500, 858],
  [493, 851],
  [472, 838],
  [467, 836],
  [462, 835],
  [65, 964],
  [57, 968],
  [55, 970],
  [54, 972],
  [54, 977],
].map(([px, py]) => ({ px, py }));

const KEYS_LEFT_SIDE = [
  [87, 986],
  [134, 1073],
  [255, 1026],
  [246, 1018],
  [197, 1037],
  [183, 1019],
  [167, 1025],
  [155, 1008],
  [148, 1011],
  [137, 993],
  [121, 998],
  [110, 979],
  [87, 986],
].map(([px, py]) => ({ px, py }));

const KEYS_RIGHT_SIDE = [
  [431, 944],
  [444, 950],
  [569, 898],
  [489, 850],
  [436, 868],
  [447, 877],
  [487, 863],
  [511, 877],
  [461, 895],
  [480, 905],
  [468, 910],
  [490, 920],
  [431, 944],
].map(([px, py]) => ({ px, py }));

const KEYS_SPACE = [
  [258, 1012],
  [267, 1021],
  [435, 953],
  [423, 946],
  [258, 1012],
].map(([px, py]) => ({ px, py }));

const KEYS_BODY = [
  [121, 975],
  [128, 987],
  [144, 983],
  [156, 1000],
  [162, 998],
  [175, 1014],
  [191, 1009],
  [203, 1022],
  [471, 919],
  [460, 914],
  [451, 910],
  [465, 904],
  [449, 895],
  [497, 876],
  [484, 869],
  [444, 883],
  [439, 879],
  [428, 871],
  [121, 975],
].map(([px, py]) => ({ px, py }));
const KEYS_SCAPE = [
  [72, 959],
  [78, 971],
  [99, 964],
  [92, 953],
].map(([px, py]) => ({ px, py }));

const KEYS_HEADER_LEFT = [
  [136, 939],
  [142, 950],
  [247, 915],
  [239, 906],
].map(([px, py]) => ({ px, py }));

const KEYS_HEADER_RIGHT = [
  [431, 844],
  [441, 853],
  [473, 842],
  [463, 835],
].map(([px, py]) => ({ px, py }));

const KEYS_HEADER_MIDDLE_LEFT = [
  [249, 903],
  [256, 913],
  [346, 884],
  [337, 875],
].map(([px, py]) => ({ px, py }));

const KEYS_HEADER_MIDDLE_RIGHT = [
  [346, 873],
  [356, 881],
  [433, 855],
  [423, 847],
].map(([px, py]) => ({ px, py }));

export default {
  keyboard: {
    points: COMPLETE,
    configs: { backgroundColor: "rgb(25 7 8)" },
  },
  keyboardTop: {
    points: TOP,
    configs: { backgroundColor: "rgb(143 103 60)", filter: "drop-shadow(0px 9px 0px rgba(143, 103, 60, 0.4))" },
  },
  keyboardKeysLS: {
    points: KEYS_LEFT_SIDE,
    configs: { backgroundColor: "rgb(161 119 71)", filter: "drop-shadow(0px 9px 0px rgba(161, 119, 71, 0.4))" },
  },
  keyboardKeysRS: {
    points: KEYS_RIGHT_SIDE,
    configs: { backgroundColor: "rgb(217 175 111)", filter: "drop-shadow(0px 9px 0px rgba(217, 175, 111, 0.4))" },
  },
  keyboardKeysSpace: {
    points: KEYS_SPACE,
    configs: { backgroundColor: "rgb(215 161 91)", filter: "drop-shadow(0px 9px 0px rgba(215, 161, 91, 0.4))" },
  },
  keyboardKeysBody: {
    points: KEYS_BODY,
    configs: { backgroundColor: "rgb(238 199 155)", filter: "drop-shadow(0px 9px 0px rgba(238, 199, 155, 0.4))" },
  },
  keyboardKeysScape: {
    points: KEYS_SCAPE,
    configs: { backgroundColor: "rgb(206 151 82)", filter: "drop-shadow(0px 9px 0px rgba(206, 151, 82, 0.4))" },
  },
  keyboardKeysHL: {
    points: KEYS_HEADER_LEFT,
    configs: { backgroundColor: "rgb(252 216 156)", filter: "drop-shadow(0px 9px 0px rgba(252, 216, 156, 0.4))" },
  },
  keyboardKeysHR: {
    points: KEYS_HEADER_RIGHT,
    configs: { backgroundColor: "transparent", filter: "drop-shadow(0px 9px 0px rgba(214, 166, 100, 0.4))" },
  },
  keyboardKeysHML: {
    points: KEYS_HEADER_MIDDLE_LEFT,
    configs: { backgroundColor: "rgb(214 166 100)", filter: "drop-shadow(0px 9px 0px rgba(214, 166, 100, 0.4))" },
  },
  keyboardKeysHMR: {
    points: KEYS_HEADER_MIDDLE_RIGHT,
    configs: { backgroundColor: "rgb(252 216 156)", filter: "drop-shadow(0px 9px 0px rgba(252, 216, 156, 0.4))" },
  },
};
