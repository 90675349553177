import Img from "assets/defined_fotos/con_diego_1024x1024.jpg";

const NACHO_POINTS = [
  { px: 703, py: 223 },
  { px: 684, py: 219 },
  { px: 658, py: 247 },
  { px: 646, py: 246 },
  { px: 630, py: 262 },
  { px: 617, py: 263 },
  { px: 606, py: 285 },
  { px: 604, py: 302 },
  { px: 587, py: 328 },
  { px: 604, py: 321 },
  { px: 596, py: 343 },
  { px: 607, py: 374 },
  { px: 618, py: 387 },
  { px: 619, py: 414 },
  { px: 617, py: 427 },
  { px: 618, py: 446 },
  { px: 623, py: 500 },
  { px: 627, py: 515 },
  { px: 652, py: 552 },
  { px: 659, py: 597 },
  { px: 651, py: 601 },
  { px: 630, py: 604 },
  { px: 609, py: 611 },
  { px: 607, py: 613 },
  { px: 601, py: 628 },
  { px: 590, py: 635 },
  { px: 582, py: 644 },
  { px: 570, py: 653 },
  { px: 558, py: 663 },
  { px: 545, py: 674 },
  { px: 534, py: 684 },
  { px: 518, py: 695 },
  { px: 497, py: 716 },
  { px: 487, py: 722 },
  { px: 482, py: 744 },
  { px: 463, py: 806 },
  { px: 461, py: 833 },
  { px: 463, py: 862 },
  { px: 463, py: 883 },
  { px: 465, py: 912 },
  { px: 461, py: 931 },
  { px: 466, py: 960 },
  { px: 467, py: 979 },
  { px: 464, py: 992 },
  { px: 465, py: 1007 },
  { px: 464, py: 1025 },
  { px: 1020, py: 1025 },
  { px: 1008, py: 936 },
  { px: 1012, py: 883 },
  { px: 1007, py: 847 },
  { px: 1017, py: 821 },
  { px: 1025, py: 824 },
  { px: 1025, py: 606 },
  { px: 998, py: 591 },
  { px: 984, py: 585 },
  { px: 957, py: 570 },
  { px: 949, py: 570 },
  { px: 943, py: 569 },
  { px: 919, py: 579 },
  { px: 913, py: 577 },
  { px: 905, py: 566 },
  { px: 897, py: 564 },
  { px: 874, py: 568 },
  { px: 865, py: 560 },
  { px: 850, py: 560 },
  { px: 844, py: 558 },
  { px: 841, py: 560 },
  { px: 825, py: 557 },
  { px: 824, py: 558 },
  { px: 824, py: 557 },
  { px: 825, py: 522 },
  { px: 828, py: 509 },
  { px: 833, py: 493 },
  { px: 835, py: 492 },
  { px: 854, py: 492 },
  { px: 859, py: 482 },
  { px: 874, py: 453 },
  { px: 876, py: 432 },
  { px: 876, py: 419 },
  { px: 872, py: 414 },
  { px: 867, py: 411 },
  { px: 868, py: 410 },
  { px: 868, py: 382 },
  { px: 871, py: 371 },
  { px: 872, py: 366 },
  { px: 873, py: 352 },
  { px: 875, py: 342 },
  { px: 873, py: 329 },
  { px: 870, py: 317 },
  { px: 868, py: 308 },
  { px: 866, py: 302 },
  { px: 857, py: 300 },
  { px: 841, py: 282 },
  { px: 826, py: 263 },
  { px: 815, py: 258 },
  { px: 811, py: 254 },
  { px: 798, py: 247 },
  { px: 792, py: 240 },
  { px: 789, py: 234 },
  { px: 769, py: 227 },
  { px: 758, py: 221 },
  { px: 741, py: 214 },
  { px: 738, py: 212 },
  { px: 752, py: 228 },
  { px: 754, py: 235 },
  { px: 730, py: 229 },
  { px: 714, py: 227 },
  { px: 703, py: 223 },
];

const DIEGO_POINTS = [
  { px: 301, py: 223 },
  { px: 286, py: 235 },
  { px: 259, py: 249 },
  { px: 233, py: 256 },
  { px: 222, py: 259 },
  { px: 214, py: 265 },
  { px: 216, py: 281 },
  { px: 217, py: 284 },
  { px: 208, py: 296 },
  { px: 200, py: 299 },
  { px: 199, py: 314 },
  { px: 196, py: 330 },
  { px: 199, py: 339 },
  { px: 195, py: 354 },
  { px: 192, py: 359 },
  { px: 192, py: 374 },
  { px: 195, py: 381 },
  { px: 186, py: 383 },
  { px: 181, py: 387 },
  { px: 186, py: 393 },
  { px: 195, py: 402 },
  { px: 189, py: 407 },
  { px: 178, py: 412 },
  { px: 169, py: 411 },
  { px: 162, py: 413 },
  { px: 176, py: 421 },
  { px: 184, py: 427 },
  { px: 187, py: 436 },
  { px: 191, py: 444 },
  { px: 199, py: 465 },
  { px: 222, py: 480 },
  { px: 227, py: 485 },
  { px: 208, py: 505 },
  { px: 191, py: 515 },
  { px: 183, py: 528 },
  { px: 172, py: 539 },
  { px: 129, py: 537 },
  { px: 106, py: 540 },
  { px: 80, py: 555 },
  { px: 58, py: 566 },
  { px: 42, py: 578 },
  { px: 20, py: 590 },
  { px: -1, py: 600 },
  { px: -1, py: 606 },
  { px: -1, py: 1025 },
  { px: 447, py: 1025 },
  { px: 451, py: 974 },
  { px: 455, py: 947 },
  { px: 458, py: 915 },
  { px: 456, py: 881 },
  { px: 456, py: 848 },
  { px: 454, py: 800 },
  { px: 453, py: 781 },
  { px: 459, py: 755 },
  { px: 462, py: 714 },
  { px: 460, py: 710 },
  { px: 479, py: 706 },
  { px: 481, py: 701 },
  { px: 474, py: 691 },
  { px: 468, py: 687 },
  { px: 465, py: 680 },
  { px: 465, py: 680 },
  { px: 473, py: 634 },
  { px: 470, py: 610 },
  { px: 463, py: 589 },
  { px: 456, py: 579 },
  { px: 449, py: 574 },
  { px: 448, py: 574 },
  { px: 470, py: 539 },
  { px: 478, py: 530 },
  { px: 484, py: 517 },
  { px: 487, py: 492 },
  { px: 486, py: 483 },
  { px: 491, py: 470 },
  { px: 495, py: 457 },
  { px: 511, py: 456 },
  { px: 518, py: 454 },
  { px: 538, py: 451 },
  { px: 557, py: 435 },
  { px: 569, py: 413 },
  { px: 557, py: 387 },
  { px: 557, py: 361 },
  { px: 547, py: 340 },
  { px: 538, py: 315 },
  { px: 524, py: 284 },
  { px: 516, py: 275 },
  { px: 509, py: 266 },
  { px: 503, py: 249 },
  { px: 492, py: 230 },
  { px: 472, py: 223 },
  { px: 454, py: 215 },
  { px: 440, py: 214 },
  { px: 410, py: 206 },
  { px: 364, py: 208 },
  { px: 315, py: 217 },
  { px: 301, py: 223 },
];


const dientesBottom = [[604,597],[604,601],[608,602],[617,605],[626,606],[654,605],[653,599],[648,600],[645,595],[642,597],[635,595],[633,597],[626,595],[618,594],[611,594],[610,596],[610,594],[607,594]].map(([px, py]) => ({ px, py }));


const backgroundBoca = [[582,577.75],[582,581.75],[588,586.75],[598,594.75],[608,598.75],[615,600.75],[624,601.75],[635,601.75],[644,600.75],[654,596.75],[664,592.75],[669,589.75],[679,581.75],[681,581.75],[681,579.75],[681,576.75],[677,578.75],[671,577.75],[667,577.75],[663,578.75],[655,578.75],[649,578.75],[641,577.75],[637,577.75],[630,578.75],[624,578.75],[617,578.75],[612,578.75],[604,578.75],[598,578.75],[594,578.75],[590,578.75],[587,577.75],[585,576.75],[583,576.75]].map(([px, py]) => ({ px, py }));



const dientesTop = [
  [594, 581],
  [594, 586],
  [598, 590],
  [600, 590],
  [602, 592],
  [605, 591],
  [607, 591],
  [610, 591],
  [612, 590],
  [613, 589],
  [613, 592],
  [616, 592],
  [621, 593],
  [625, 592],
  [630, 593],
  [637, 592],
  [638, 591],
  [638, 590],
  [643, 592],
  [645, 592],
  [647, 591],
  [649, 589],
  [653, 594],
  [655, 593],
  [657, 589],
  [657, 588],
  [661, 591],
  [664, 590],
  [665, 591],
  [667, 589],
  [667, 588],
  [667, 587],
  [670, 587],
  [670, 580],
  [668, 580],
  [666, 580],
  [664, 581],
  [663, 580],
  [661, 579],
  [659, 580],
  [658, 581],
  [657, 579],
  [655, 579],
  [652, 579],
  [651, 579],
  [649, 582],
  [649, 580],
  [646, 579],
  [644, 578],
  [642, 579],
  [640, 581],
  [639, 584],
  [637, 580],
  [637, 579],
  [635, 579],
  [632, 579],
  [629, 580],
  [627, 581],
  [626, 583],
  [625, 585],
  [624, 583],
  [623, 581],
  [623, 580],
  [622, 580],
  [614, 579],
  [613, 583],
  [613, 585],
  [611, 580],
  [610, 579],
  [608, 579],
  [605, 580],
  [605, 584],
  [604, 586],
  [603, 581],
  [601, 580],
  [600, 580],
  [596, 580],
  [594, 581],
].map(([px, py]) => ({ px, py }));


const encias = [[593,576],[595,584],[667,585],[669,582],[669,577]].map(([px, py]) => ({ px, py }));

const lengua = [[601,587.25],[601,595.25],[609,601.25],[649,600.25],[653,598.25],[656,588.25]].map(([px, py]) => ({ px, py }));
const labiosExteriores = [[585,583.25],[589,592.25],[593,600.25],[602,606.25],[607,610.25],[612,611.25],[618,612.25],[637,613.25],[642,613.25],[648,611.25],[664,601.25],[673,590.25],[678,583.25],[669,576.25],[662,574.25],[654,572.25],[647,572.25],[637,571.25],[634,572.25],[629,572.25],[623,574.25],[617,574.25],[610,573.25],[605,572.25],[599,572.25],[593,573.25],[590,574.25]].map(([px, py]) => ({ px, py }));

const OBJECT_DEFINED = {
  nacho: {
    points: NACHO_POINTS,
  },
  diego: {
    points: DIEGO_POINTS,
  },
};

const DATA = {
  image: Img,
  textElements: {},
  objectsDefined: OBJECT_DEFINED,
  svgObjects: {
    nacho: {
      // nacho: {
      //   points: NACHO_POINTS,
      //   configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
      // },
      // diego: {
      //   points: DIEGO_POINTS,
      //   configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
      // },
    },

    labiosExteriores: {
      labiosExteriores:  {
        points: labiosExteriores,
        configs: { backgroundColor: "rgb(222 123 114)", strokeWidth: "0" },
      },
    },
    backgroundBoca: {
      backgroundBoca:  {
        points: backgroundBoca,
        // configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
        configs: { backgroundColor: "transparent", strokeWidth: "0" },
      },
    },
    encias: {
      encias:  {
        points: encias,
        configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
        // configs: { back⁄groundColor: "transparent", strokeWidth: "0" },
      },
    },
    lengua: {
      lengua:  {
        points: lengua,
        configs: { backgroundColor: "rgb(189 139 99)", strokeWidth: "0" },
        // configs: { back⁄groundColor: "transparent", strokeWidth: "0" },
      },
    },
    dientesTop: {
      dientesTop:  {
          points: dientesTop,
          configs: { backgroundColor: "rgb(241 241 241)", strokeWidth: "0" },
        },
    },
    dientesBottom: {
      dientesBottom:  {
          points: dientesBottom,
          configs: { backgroundColor: "rgb(241 241 241)", strokeWidth: "0" },
        },
    }
  },
};

export default DATA;
