import * as d3 from "d3";
import { ImageElement } from "./ImageElement";
import { TextElement } from "./TextElement";
import * as Types from "./types";
import * as Helpers from "./helpers";
import "./styles.scss";

export { TextElement };

export default class BgClient<T extends keyof any, TT extends keyof any, TTT extends keyof any> {
  readonly svg: d3.Selection<HTMLElement | null, unknown, null, undefined>;
  readonly imageBase: d3.Selection<HTMLElement | null, unknown, null, undefined>;
  public readonly elements: Record<T, ImageElement>;
  public readonly textElements: Record<TTT, TextElement>;
  public readonly svgObjects: Record<TT, Types.ElementData>;

  constructor(readonly data: Types.ImageData<T, TT, TTT>, idSvg: string = "SVG-background") {
    const element = document.getElementById(idSvg);
    this.svg = d3.select(element);
    this.imageBase = this.svg.select(".img-base:first-of-type");

    const newObj = Object.entries(data.objectsDefined).map(([key, value]) => [
      key,
      new ImageElement(this, key, value as Types.ElementData),
    ]);

    const newObj2 = Object.entries(data.textElements).map(([key, value]) => [
      key,
      new TextElement(this, key, value as Types.ElementData),
    ]);

    this.elements = Object.fromEntries(newObj);
    this.textElements = Object.fromEntries(newObj2);
    this.svgObjects = data.svgObjects;

    this.reset();
  }

  public removeSvgHtmlElements = () => {
    const eleemnts = this.svg.selectAll("*");
    console.log("BgClient<T -> publicremoveSvgHtmlElements -> eleemnts", eleemnts);

    try {
      var svgId = "#svg-aboutme";
      console.log("Before removal:", d3.select(svgId).node());

      // Select the SVG element by its ID and remove all its children
      d3.select(svgId).selectAll("*").remove();

      console.log("After removal:", d3.select(svgId).node());
    } catch (error) {
      console.log("BgClient<T -> publicremoveSvgHtmlElements -> error", error);
    }
  };

  public turnObjectsOn = (color: string = "black") => {
    const imageElements = Object.values(this.elements) as ImageElement[];
    imageElements.map((imageElement) => imageElement.paintBackground());
  };

  public drawOpacityLayer = (pathToTakeOut: string) => {
    this.svg
      .append("path")
      .attr("id", "opacity-layer")
      .attr("d", `M0 0 H1000 V1300 H0 Z ${pathToTakeOut}`)
      .attr("fill", "url(#lgrad-sections__ideas__opacity-layer)")
      .transition()
      .duration(100)
      .ease(d3.easeLinear);
  };

  public ZoomIn = (x: number, y: number, scale: number) => {
    this.svg
      // .select("g:first-child")
      .transition()
      .duration(1500)
      .ease(d3.easeLinear)
      .attr("transform", `scale(${scale}) translate(${x}, ${y}) `);

    this.svg.select("g:first-child").classed("zoomed-in", true);
  };

  public ZoomInOld = () => {
    this.svg.transition().duration(500).ease(d3.easeLinear).attr("transform", "translate(10, 120) scale(1.4)");
  };

  public ZoomOut = () => {
    this.svg
      .select("g:first-child")
      .transition()
      .duration(500)
      .ease(d3.easeLinear)
      .attr("transform", "translate(0, 0) scale(1)");

    this.svg.select("g:first-child").classed("zoomed-in", false);
  };

  public opacityBaseImage = (opacity: number) => {
    this.imageBase.style("opacity", opacity);
  };

  public removeOpacityLayer = () => d3.select(`#opacity-layer`).remove();

  /**
   * if considerImage is true -> it takes for the viewport the points of the svg elements and only render the elements defined.
   */
  public useElements = (svgElements: TT[], elements: T[], considerImage?: boolean) => {
    const allPointsSvgElements = svgElements.reduce((points: any, svgElement) => {
      return [
        ...points,
        Object.values(this.svgObjects[svgElement])
          .filter((e) => e !== undefined)
          .map((e) => {
            return e.points;
          })
          .flat(),
      ];
    }, []) as Types.Point[];

    const allPointsElements = elements.reduce((points: any, element) => {
      return [...points, this.elements[element].points];
    }, []) as Types.Point[];

    const allPoints = [...allPointsSvgElements, ...allPointsElements].flat();

    const { minX, minY, maxX, maxY } = Helpers.calculateMinAndMax(allPoints);

    const newViewBox = `${minX} ${minY} ${maxX - minX} ${maxY - minY}`;
    const className = "svg-image-element";

    this.svg.attr("viewBox", newViewBox);

    !considerImage &&
      svgElements.forEach((element) => {
        const theGroup = this.svg.append("g");
        theGroup.attr("class", `${className} ${String(element)}`);
        // theGroup.attr("style", `transform: translate(${-minX}px, ${-minY}px);`);

        Object.entries(this.svgObjects[element]).forEach(([key, value]) => {
          const { backgroundColor, strokeWidth, filter } = value.configs;
          theGroup
            .append("g")
            .attr("class", `g-${key}`)
            .attr("clip-path", `url(#clip-${key})`)
            .append("path")
            .attr("class", `${key}`)
            .attr("d", Helpers.hpPointsToPath(value.points))
            .attr("fill", backgroundColor)
            .attr("opacity", 1)
            .attr("stroke-width", strokeWidth ?? 1)
            .attr("filter", filter ?? "none")
            .attr("stroke", "rgb(0 0 0 / 50%)");
        });
      });

    elements.forEach((element) => {
      const theGroup = this.svg.append("g");
      theGroup.attr("class", `${className} ${String(element)}`);
      const imgElement = this.elements[element];
      theGroup
        .append("g")
        .attr("class", `g-${String(element)}`)
        .attr("clip-path", `url(#clip-${String(element)})`)
        .append("path")
        .attr("class", `${imgElement.refId}`)
        .attr("d", Helpers.hpPointsToPath(imgElement.points))
        .attr("fill", imgElement.configs.backgroundColor)
        .attr("opacity", 1)
        .attr("stroke-width", 1)
        .attr("stroke", "rgb(0 0 0 / 50%)");
    });
  };

  public toComic = () => {
    Object.entries(this.svgObjects).forEach(([elementGroup, elements]) => {
      const theGroup = this.svg.append("g");
      theGroup.attr("class", `${elementGroup}-svg-element`);

      const data = Object.entries(elements as { [element: string]: Types.ElementData }).map(
        ([key, { points, configs }]) => ({
          configs,
          points: points.map((e) => [e.px, e.py]),
          className: key,
        })
      );

      theGroup
        .selectAll("polyline")
        .data(data)
        .enter()
        .append("polyline")
        .attr("class", ({ className }) => className)
        .attr("fill", ({ configs }) => configs.backgroundColor || "red")
        .attr("points", ({ points }) => points.join(" "))
        .attr("filter", ({ configs }) => configs.filter || "none")
        .attr("stroke-width", ({ configs }) => configs.strokeWidth || 0)
        .attr("stroke", ({ configs }) => configs.stroke || "rgba(0,0,0,0.3");
      // .attr("fill", "transparent")
      // .attr("points", ({ points }) => points.join(" "))
      // .attr("filter", ({ configs }) => configs.filter || "none")
      // .attr("stroke-width", 1)
      // .attr("stroke", "rgba(4,244,4,1");
    });
  };

  private reset = () => {
    this.svg.selectAll("path").remove();
    this.svg.selectAll("line").remove();
    this.svg.selectAll("use").remove();
    this.svg.selectAll("text").remove();
    this.svg.selectAll("rect").remove();

    // console.log("ACA, this.svg: ", this.svg);

    // try {
    //   var svgId = "#svg-aboutme";
    //   console.log("Before removal:", d3.select(svgId).node());

    //   // Select the SVG element by its ID and remove all its children
    //   d3.select(svgId).selectAll("*").remove();

    //   console.log("After removal:", d3.select(svgId).node());
    // } catch (error) {
    //   console.log("BgClient<T -> publicremoveSvgHtmlElements -> error", error);
    // }
  };
}
