const BACKEND_ENDPOINTS = {
  localhost: "http://localhost:4000/",
  // prod: "https://bk2q28yenb.execute-api.eu-central-1.amazonaws.com/dev",
  prod: "https://vr9xl1ypl9.execute-api.eu-central-1.amazonaws.com/",
};

const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
const API_EDNPOINT = isLocalhost ? BACKEND_ENDPOINTS.localhost : BACKEND_ENDPOINTS.prod;

export default {
  apiEndpoint: API_EDNPOINT,
  isItLocalhost: isLocalhost,
  isProduction: !isLocalhost,
  postHog: {
    instanceId: isLocalhost ? "" : "phc_9TWlS76dYs2aLAApbUS4C9nbGgtgJ6aMU3CW2MufpUf",
    api_host: "https://app.posthog.com",
  },
};
