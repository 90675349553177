export const LEATHER = [
  [311, 1016],
  [309, 1027],
  [301, 1032],
  [356.5, 1122.5],
  [358, 1127],
  [356, 1134],
  [349, 1137],
  [60, 1200],
  [900, 1200],
  [900, 1056],
  [804, 1051],
  [803, 1058],
  [800, 1066],
  [804, 1068],
  [839, 1181],
  [838, 1192],
  [832, 1197],
  [439.5, 1158],
  [433, 1155],
  [430, 1146],
  [480, 1037],
  [479, 1029],
].map(([px, py]) => ({ px, py }));

const LEATHER_CONFIGS = {
  backgroundColor: "#532413",
  strokeWidth: "0",
};

const DATA = {
  leather: {
    points: LEATHER,
    configs: LEATHER_CONFIGS,
  },
};

export default DATA;
