import posthog from "posthog-js";
import Configs from "configs";
import AnalyticsOptOutManager from "./AnalyticsOptOutManager";

type PostHogCustomEvents =
  | "clicked_chat_bubble"
  | "clicked_chat_start_again"
  | "clicked_love"
  | "clicked_sidebar_item"
  | "change_language"
  | "contact_form_sent"
  | "clicked_logo";

type PostHogEvents = "$page_view" | PostHogCustomEvents;

interface IMyAnalytics {
  init: () => void;
  capture: (eventName: PostHogEvents, params?: Record<string, string | boolean | number>) => void;
}

class MyAnalytics implements IMyAnalytics {
  private posthog: typeof posthog;
  private excludeAnalytics: boolean = false;

  constructor() {
    this.posthog = posthog;
    this.excludeAnalytics = AnalyticsOptOutManager.isOptedOut;
  }

  init = () => this._initPostHog();

  capture: IMyAnalytics["capture"] = (eventName, params = {}) => {
    this.posthog.capture(eventName, { ...params, itsMe: this.excludeAnalytics });
  };

  _initPostHog() {
    // if (!this.excludeAnalytics) {
    posthog.init(Configs.postHog.instanceId, {
      api_host: Configs.postHog.api_host,
    });
    // } else {
    //   console.log("Analytics excluded for this device.");
    // }
  }
}

export default new MyAnalytics();
