const FOTO_BORDER = [
  [644, 631],
  [647, 778],
  [803, 781],
  [798, 629],
  [644, 631],
].map(([px, py]) => ({ px, py }));

const FOTO_BORDER_INSIDE = [
  [666, 652],
  [671, 754],
  [780, 756],
  [773, 654],
  [666, 652],
].map(([px, py]) => ({ px, py }));

const SCREEN = [
  [682, 667],
  [685, 742],
  [763, 740],
  [759, 668],
  [682, 667],
].map(([px, py]) => ({ px, py }));

const SCREEN_CONFIG = {
  paddingLeft: 20,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 22,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 28,
      fontSize: 16,
      color: "white",
      heightLine: 20,
    },
  },
  p1: SCREEN[0], // Top left corner
  p2: SCREEN[1], // Bottom left corner
  p3: SCREEN[3], // Top right corner
  p4: SCREEN[2], // Bottom right corner
};

export default {
  fotoBorder: {
    points: FOTO_BORDER,
    configs: { backgroundColor: "#271400", filter: " blur(2px)" },
  },
  fotoBorderInside: {
    points: FOTO_BORDER_INSIDE,
    configs: { backgroundColor: "#c6c6c6c9", filter: " blur(1.5px)" },
  },
  fotoScreen: {
    points: SCREEN,
    configs: {
      ...SCREEN_CONFIG,
      backgroundColor: "#7c806d",
      filter: "drop-shadow(1px 1px 0 rgba(0,0,0,.3)) blur(1px)",
    },
  },
};
