const DESK = [
  [0, 743],
  [0, 1200],
  [900, 1200],
  [900, 747],
].map(([px, py]) => ({ px, py }));

export default {
  desk: {
    points: DESK,
    configs: { backgroundColor: "#7e480f" },
  },
};
