import React, { useEffect } from "react";
import Layout from "./layout";
import { AppContextProvider, LanguageProvider } from "./AppContext";
import BubbleChat from "./components/bubbleChat";
import CharacterProfile from "./components/CharacterProfile";
import MyAnalytics from "services/Analytics";

function App() {
  useEffect(() => {
    const { protocol, hostname } = window.location;
    // const pathname = window.location.pathname;
    // const href = window.location.href;
    const productionHost = "https://www.ig-ruiz.com";
    const allowedURLS = ["http://localhost", productionHost];

    if (!allowedURLS.includes(protocol + "//" + hostname)) {
      console.log("this route is not allowed, you should be redirected to " + productionHost);
      window.location.href = productionHost;
    }

    MyAnalytics.init();
  }, []);

  return (
    <AppContextProvider>
      <LanguageProvider>
        <div className="App">
          <Layout />
          {/* <BubbleChat /> */}
          {/* <CharacterProfile type="mirando-frente-serio" position="center" size="full" />
        <CharacterProfile type="mirando-frente-sonriendo" position="bottom-center" />
        <CharacterProfile type="pensando" position="bottom-left" size="small" />
        <CharacterProfile type="observando-izquierda" position="bottom-right" /> */}
        </div>
      </LanguageProvider>
    </AppContextProvider>
  );
}

export default App;
