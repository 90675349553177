import Img from "assets/defined_fotos/new_layout_3_screens.jpeg";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */

import MonitorData from "./monitor";
import ComputerData from "./computer";
import IpadData from "./ipad";
import LeatherData from "./leather";
import PuzzleData from "./puzzle";
import WallData from "./wall";

const NEW_YORK_TIMES_FRAME = [
  {
    px: 116,
    py: 170,
  },
  {
    px: 116,
    py: 170,
  },
  {
    px: 127,
    py: 312,
  },
  {
    px: 320,
    py: 298,
  },
  {
    px: 314,
    py: 157,
  },
  {
    px: 116,
    py: 170,
  },
];

const LIBERTY_FRAME = [
  {
    px: 132,
    py: 344,
  },
  {
    px: 126,
    py: 635,
  },
  {
    px: 168,
    py: 636,
  },
  {
    px: 147,
    py: 444,
  },
  {
    px: 299,
    py: 435,
  },
  {
    px: 299,
    py: 432,
  },
  {
    px: 294,
    py: 428,
  },
  {
    px: 294,
    py: 417,
  },
  {
    px: 298,
    py: 413,
  },
  {
    px: 301,
    py: 413,
  },
  {
    px: 305,
    py: 411,
  },
  {
    px: 303,
    py: 336,
  },
  {
    px: 132,
    py: 344,
  },
];

const NYT_CONFIGS = {
  backgroundColor: "#ffe9d1",
};

const OBJECTS_DEFINED = {
  ...MonitorData,
  ...ComputerData,
  ...IpadData,
  ...LeatherData,
  ...PuzzleData,
  ...WallData,
  // newYorkTimesFrame: {
  //   points: NEW_YORK_TIMES_FRAME,
  //   configs: NYT_CONFIGS,
  // },
  // libertyFrame: {
  //   points: LIBERTY_FRAME,
  // },
};

const { computer, monitor, puzzle, ipad } = OBJECTS_DEFINED;

const TEXT_ELEMENTS = {
  computer,
  monitor,
  puzzle,
  ipad,
};

const DATA = {
  image: Img,
  objectsDefined: OBJECTS_DEFINED,
  textElements: TEXT_ELEMENTS,
  svgObjects: {
    monitor: {
      ...MonitorData,
    },
    computer: {
      ...ComputerData,
    },
    ipad: {
      ...IpadData,
    },
    desk: {
      ...LeatherData,
    },
  },
};

export default DATA;
