import { Point } from "./types";

export const hpPointsToPath = (points: Point[]) => `M${points.reduce((acc, { px, py }) => `${acc}${px},${py} `, "")}z`;

export const hpSplitTextIntoLines = (text: string, maxLength: number) => {
  const words = text.split(" ");
  const lines = [];

  const wordIsEmptyLine = text.includes("EMPTY_LINE");

  if (wordIsEmptyLine) {
    const isThereAMultiple = text.includes("x");
    if (isThereAMultiple) {
      const multiple = parseInt(text.split("x")[1]);
      for (let index = 0; index < multiple; index++) {
        lines.push("EMPTY_LINE");
      }
      return lines;
    }
  }

  let currentLine = "";

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (currentLine.length + word.length <= maxLength) {
      currentLine += word + " ";
    } else {
      lines.push(currentLine.trim());
      currentLine = word + " ";
    }
  }

  // Add the last line
  if (currentLine.trim().length > 0) {
    lines.push(currentLine.trim());
  }

  return lines;
};

export const calculateMinAndMax = (points: Point[]) => {
  const { minX, maxX, minY, maxY } = points.reduce(
    (acc, current) => ({
      minX: Math.min(acc.minX, current.px),
      maxX: Math.max(acc.maxX, current.px),
      minY: Math.min(acc.minY, current.py),
      maxY: Math.max(acc.maxY, current.py),
    }),
    { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity }
  );

  return { minX, maxX, minY, maxY };
};
