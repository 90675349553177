/**
 * StoryHighOrderData
 * it defines the data needed for the data structure, in order to be able to render the story.
 * all content, animations and styles use this data.
 */

export const STORY_STRUCTURE_DATA = {
  scenes: ["introduction", "about-me", "my-skills", "contact"] as const,
  storyMoments: [
    "rendering-main-words",
    "rendering-monitor",
    "rendering-monitor-2",
    "rendering-text-and-monitor",
    "rendering-end",
    "rendering-end-intro",
  ] as const,
  textElements: [
    "monitor-tab-main",
    "monitor-screen",
    "background",
    "monitor-tab-left",
    "monitor-tab-middle",
    "ipad",
    "computer",
    "monitor-tab-right-small",
    "monitor-tab-right",
  ] as const,
};
