const PAGE1 = [
  [460, 827],
  [391, 1072],
  [637, 1107],
  [731, 1112],
  [900, 1120],
  [900, 847],
  [460, 827],
].map(([px, py]) => ({ px, py }));

const PAGE2 = [
  [401, 871],
  [393, 925],
  [375, 1181],
  [637.5, 1178],
  [900, 1165],
  [900, 1120],
  [829, 857],
  [401, 871],
].map(([px, py]) => ({ px, py }));

const PAGE3 = [
  [496, 774],
  [450, 1035],
  [899, 1040],
  [900, 943],
  [812, 776],
  [496, 774],
].map(([px, py]) => ({ px, py }));

export default {
  papersPage3: {
    points: PAGE3,
    configs: { backgroundColor: "#e9e9e9", filter: "drop-shadow(90px -29px 0 rgba(0,0,0,.3)) blur(1px)" },
  },
  papersPage2: {
    points: PAGE2,

    configs: { backgroundColor: "#ebdfd7", filter: "drop-shadow(-3px 7px 0 rgba(0,0,0,.3))" }, // "rgb(249 204 163)" },
  },
  papersPage1: {
    points: PAGE1,
    // configs: { backgroundColor: "rgb(249 204 163)" },
    configs: { backgroundColor: "whitesmoke", filter: "drop-shadow(-3px 7px 0 rgba(0,0,0,.3))" }, //"rgb(255 211 151)" },
  },
};
