import React from "react";
import * as Helpers from "../helpers";
import "./styles.scss";

type Props = {
  className?: string;
  onClick?: () => void;
};

const CONFIGS = {
  width: 100,
};

const SwapSVGElements = Helpers.SwapArrowPoints(CONFIGS.width);
const TextPathPoints = SwapSVGElements[0].points.slice(0, 3).reverse();
// const TextPathScroll = `M${TextPathPoints.reduce((acc, [px, py]) => `${acc}${px},${py} `, "")}z`;
// const TextPathDown = `M${TextPathPoints.reduce((acc, [px, py]) => `${acc}${px},${py} `, "")}z`;

const SwapArrow: React.FC<Props> = ({ className = "", onClick = () => {} }) => {
  return (
    <svg
      className={["swap-down-svg-wrapper", className].join(" ")}
      width={CONFIGS.width}
      viewBox={`0 0 ${CONFIGS.width} ${CONFIGS.width}`}
      onClick={onClick}
    >
      {/* <g style={{ transform: `translate(${CONFIGS.width / 2}px, ${CONFIGS.width / 1.5}px) rotate(60deg)` }}>
        {SwapSVGElements.map((elem, i) => {
          return (
            <polyline
              key={elem.className}
              className={elem.className}
              points={elem.points.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
            />
          );
        })}
      </g>
      <g style={{ transform: `translate(${CONFIGS.width / 2}px, ${CONFIGS.width / 2}px) rotate(60deg)` }}>
        {SwapSVGElements.map((elem, i) => {
          return (
            <polyline
              key={elem.className}
              className={elem.className}
              points={elem.points.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
            />
          );
        })}
      </g> */}
      {/* <g style={{ transform: `translate(${CONFIGS.width / 2}px, ${CONFIGS.width / 3}px) rotate(60deg)` }}> */}
      <g style={{ transform: `scale(2.5) translate(26px, 20px) rotate(60deg)` }}>
        {SwapSVGElements.map((elem, i) => {
          return (
            <polyline
              key={elem.className}
              className={elem.className}
              points={elem.points.reduce((acc, [px, py]) => `${acc} ${px},${py}`, "")}
            />
          );
        })}
      </g>
      {/* 
      <path d={TextPathScroll} id="textPathScroll" />
      <path d={TextPathDown} id="textPathDown" />
      <text
        style={{
          transform: "translate(125px, 60px) rotate(-120deg)",
          fontSize: "24px",
        }}
      >
        <textPath href="#textPathScroll" startOffset="70px" method="stretch" spacing="auto">
          Scroll
        </textPath>
        <textPath href="#textPathDown" startOffset="70px" method="stretch" spacing="auto">
          Down
        </textPath>
      </text> */}
    </svg>
  );
};

export default SwapArrow;
