import React from "react";
import { Navigate } from "react-router-dom";
import * as Pages from "./pages";

// const HOME_ELEMENT = <Pages.Home />;
const NOT_MATCH_ROUTE = { path: "*", element: <Pages.NotFound /> };

const ROUTES_COMPONENTS_CLIENT = [
  // { path: "", element: HOME_ELEMENT },
  // { path: "about-me", element: <Pages.AboutMe /> },
  // { path: "journey", element: <Pages.Journey /> },
  // { path: "ideas", element: <Pages.Ideas /> },
  // { path: "news", element: <Pages.News /> },
] as const;

const ROUTES_COMPONENTS_ADMIN = [
  // { path: "admin", element: <Pages.AdminPages.ImageFactory /> },
  // { path: "admin/image-factory", element: <Pages.AdminPages.ImageFactory /> },
] as const;

export const ROUTES_COMPONENTS = [...ROUTES_COMPONENTS_CLIENT, ...ROUTES_COMPONENTS_ADMIN] as const;

/** LOCALS_ROUTES_COMPONENTS
 * it builds the routes based on the languages defined.
 */
const LOCALS_ROUTES_COMPONENTS = [
  // ...VALID_LANGUAGES.map((lang) => ({ path: `${lang}`, element: HOME_ELEMENT })),
  ...ROUTES_COMPONENTS.reduce((prev: any, value) => {
    // return [...prev, ...VALID_LANGUAGES.map((lang) => ({ ...value, path: `${lang}/${value.path}` }))];
  }, []),
];

export const RoutesAll = (currentLanguage: string) => {
  const NavigatedRoutes = [
    ...ROUTES_COMPONENTS.map(({ path }) => ({
      path: path,
      element: <Navigate replace to={`/${currentLanguage}/${path}`} />,
    })),
  ];

  return [...NavigatedRoutes, ...LOCALS_ROUTES_COMPONENTS, ...[NOT_MATCH_ROUTE]];
};

export const VALID_ROUTES_NAMES = ROUTES_COMPONENTS.map(({ path }) => path);

export const pathMatchToCurrent = (pathname: string, pathToCompare: (typeof VALID_ROUTES_NAMES)[number]) => {
  const lastPartOfTheUrl = pathname.split("/").reverse()[0];
  return lastPartOfTheUrl === pathToCompare;
};

export const isItAdminRoute = (pathname: string) => pathname.includes("admin");
export const isItInstagramRoute = (pathname: string) => pathname.includes("instagram/post/loading");
export const isItClaudeRoute = (pathname: string) => pathname.includes("claude-3");
