import { ActionOptions } from "../Actions/types";
import { ConversationData } from "../types";

const finishConversation = (hearAbout: string = "ti"): ConversationData => ({
  keyWord: "ending-branch",
  messages: [
    { text: "¡Perfecto! 👌" },
    { text: "Me pondré en contacto contigo lo antes posible." },
    { text: `Ya estoy deseando saber más sobre ${hearAbout}` },
    { text: "Te invito a seguir navegando por mi sitio web 🙂." },
  ],
  action: undefined,
});

const emailActionDefault = (hearAbout: string) => ({
  type: "input-email" as const,
  conversation: finishConversation(hearAbout),
});

const MISSING_CONVERSATION = {
  keyWord: "missing-conversation",
  messages: [
    { text: "Lo siento, aún no he completado este contenido." },
    { text: "Voy a notificar a Ignacio que has seleccionado esta opción." },
    { text: "¿Te gustaría proporcionarme tu correo electrónico para que podamos contactarte❓" },
  ],
  action: emailActionDefault("la oportunidad de colaborar 💪"),
};

const EMAIL_DETECTED_CONVERSATION = (
  email: string,
  emailQuestionConversation: ConversationData,
  conversationForward: ConversationData
  
): ConversationData => ({
  keyWord: "email-detected",
  messages: [
    ...emailQuestionConversation.messages,
    { text: "Oh, pero espera." },
    { text: "Veo que ya has ingresado tu correo electrónico 😅." },
    { text: `¿Debería contactarte a ${email}❓` },
  ],
  action: {
    type: "confirm-email-action",
    options: [
      {
        keyWord: "confirm-email",
        text: "¡Sí!",
        conversation: conversationForward,
      },
      {
        keyWord: "reject-confirm-email",
        text: "No, me gustaría ingresar otro.",
        conversation: {
          keyWord: "reject-confirm-email",
          messages: [
            { text: "Está bien, no hay problema 🙂." },
            { text: "Aquí vamos, simplemente ingrésalo:" },
          ],
          action: {
            type: "input-email-reentered",
            conversation: conversationForward,
          },
        },
      },
    ],
  },
});

const BACK_TO_MESSAGE_MESSAGES = [
  { text: "Entiendo, no hay problema, podemos volver a esto más tarde 🙂." },
];

const CHAT_DATA_FAKE = {
  keyWord: "welcome",
  messages: [
    { text: "¡Hola! 👋 ¡Qué bueno tenerte aquí!❗️" },
    { text: "Este es un chat básico que hice para comunicarme contigo." },
    { text: "¿En qué estás interesado❓" },
  ],
};

const ActionOptionCollaboration: ActionOptions =  {
  keyWord: "collaboration",
  text: "Estoy buscando colaboración.",
  conversation: {
    keyWord: "collaboration",
    messages: [
      { text: "¡Eso es genial!❗️" },
      { text: "¿Puedo preguntarte qué tipo de colaboración estás buscando❓" },
    ],
    action: {
      type: "options",
      options: [
        {
          keyWord: "unicorn",
          text: "Tengo la próxima idea de unicornio 🦄.",
          conversation: {
            keyWord: "unicorn",
            messages: [
              { text: "Oh wow. Eso suena increíble." },
              { text: "Me encantaría saber más al respecto." },
              { text: "¿Te importaría dejarme tu correo electrónico❓" },
              { text: "Así puedo contactarte." },
            ],
            action: emailActionDefault("la idea de 🦄💡❗️"),
          },
        },
        {
          keyWord: "services",
          text: "Creo que te interesarían mis servicios 📈.",
          conversation: {
            keyWord: "services",
            messages: [
              { text: "😮" },
              { text: "Me alegra escuchar eso, probablemente tengas razón❗️" },
              { text: "Me pregunto qué tipo de servicios estás ofreciendo.. 🤔" },
              { text: "¿Es uno de estos❓" },
            ],
            action: {
              type: "options",
              options: [
                {
                  keyWord: "marketing",
                  text: "Marketing",
                  conversation:{ 
                    keyWord: "marketing",
                    messages: [
                      { text: "El marketing es muy importante para cualquier negocio." },
                      { text: "Me intriga, ¿qué tipo de marketing ofreces❓" },
                    ],
                    action: {
                      type: "options",
                      options: [
                        {
                          keyWord: "marketing-digital",
                          text: "Marketing digital",
                          conversation: 
                            {
                              keyWord: "marketing-digital",
                              messages: [
                                { text: "Genial, hay mucho potencial en ideas de marketing digital." },
                                { text: "Me encantaría saber más al respecto." },
                              ],
                              action: emailActionDefault("la idea de marketing digital 💡"),
                            }
                        },
                        {
                          keyWord: "marketing-traditional",
                          text: "Marketing tradicional",
                          conversation: {
                              keyWord: "marketing-traditional",
                              messages: [
                                { text: "Elección interesante." },
                                { text: "¿Te importaría contarme más al respecto❓" },
                              ],
                              action: emailActionDefault("la idea de marketing tradicional 💡"),
                            },
                        },
                        {
                          keyWord: "marketing-other",
                          text: "Otro",
                          conversation:
                            {
                              keyWord: "marketing-other",
                              messages: [
                                { text: "Me encantaría saber qué tipo sería." },
                                { text: "Me gustan especialmente estos, porque mejorarían este 🤖 en futuras actualizaciones 👌." },
                                { text: "¿Cómo te gustaría colaborar❓" },
                              ],
                              action: emailActionDefault("la oportunidad de colaborar 💪"),
                            },
                        },
                      ],
                    },
                  },
                },
                {
                  keyWord: "creator",
                  text: "Creador de contenido",
                  conversation: {
                      keyWord: "creator",
                      messages: [
                        { text: "La creación de contenido es genial en la actualidad 🚀." },
                        { text: "¿Ves algún potencial en esto❓" },
                        { text: "Me encantaría saber más al respecto." },
                        { text: "¿Te gustaría proporcionarme tu correo electrónico❓" },
                      ],
                      action: emailActionDefault("la idea de creación de contenido 💡"),
                    },
                },
                {
                  keyWord: "outsourcing",
                  text: "Externalización",
                  conversation: 
                    {
                      keyWord: "outsourcing",
                      messages: [
                        { text: "La externalización es una excelente manera de mejorar la productividad." },
                        { text: "Y es una excelente manera de colaborar con otros." },
                        { text: "¿Crees que podríamos colaborar❓" },
                        { text: "Me encantaría hacer una llamada para discutirlo." },
                        { text: "¿Te gustaría proporcionarme tu correo electrónico❓" },
                      ],
                      action: emailActionDefault("la oportunidad de colaborar 💪"),
                    },
            
                },
              ],
            },
          },
        },
        {
          keyWord: "colaboration-other",
          text: "Otro tipo de colaboración",
          conversation: {
            keyWord: "colaboration-other",
            messages: [
              { text: "Me encantaría saber qué tipo sería." },
              { text: "Me gustan especialmente estos, porque mejorarían este 🤖 en futuras actualizaciones 👌." },
              { text: "¿Cómo te gustaría colaborar❓" },
            ],
            action: {
              type: "input-text",
              conversation: {
                keyWord: "colaboration-other-input-text",
                messages: [
                  { text: "¡Gracias! Anotado 🗒." },
                  { text: "Voy a notificar a Ignacio al respecto." },
                  { text: "¿Te gustaría proporcionarme tu correo electrónico para que podamos contactarte❓" },
                  { text: "De lo contrario, una vez que Ignacio responda, aparecerá en este chat." },
                ],
                action: emailActionDefault("la oportunidad de colaborar 💪"),
              },
            },
          },
        },
      ],
    },
  },
} 

const ActionOptionAboutMe: ActionOptions = {
  keyWord: "more-about-me",
  text: "Más sobre ti",
  conversation: 
    {
      keyWord: "more-about-me",
      messages: [
        { text: "Me alegra que estés interesado en saber más sobre mí." },
        { text: "Soy un desarrollador de software y también me apasiona la tecnología." },
        { text: "Siempre estoy buscando nuevos desafíos y oportunidades." },
        { text: "Te invito a seguir navegando por mi sitio web." },
      ],
      action: undefined,
    },
  
}

const ActionOptionFeedback: ActionOptions =  {
  keyWord: "feedback",
  text: "Quiero darte algunos comentarios.",
  conversation: 
    {
      keyWord: "feedback",
      messages: [
        { text: "Me alegra escuchar eso." },
        { text: "Siempre estoy buscando comentarios para mejorar." },
        { text: "¿Qué tipo de comentarios quieres darme❓" },
      ],
      action: {
        type: "options",
        options: [
          {
            keyWord: "feedback-bug",
            text: "Encontré un error",
            conversation: {
              keyWord: "feedback-bug",
              messages: [
                { text: "Lamento escuchar eso." },
                { text: "Voy a notificar a Ignacio al respecto." },
                { text: "¿Te gustaría proporcionarme tu correo electrónico para que podamos contactarte❓" },
                { text: "De lo contrario, una vez que Ignacio responda, aparecerá en este chat." },
              ],
              action: emailActionDefault("el error 🐞"),
            },
          },
          {
            keyWord: "feedback-improvement",
            text: "Tengo una sugerencia de mejora",
            conversation: {
              keyWord: "feedback-improvement",
              messages: [
                { text: "Me alegra escuchar eso." },
                { text: "Siempre estoy buscando comentarios para mejorar." },
                { text: "Me encantaría saber más al respecto." },
                { text: "¿Te importaría dejarme tu correo electrónico❓" },
                { text: "Así puedo contactarte." },
              ],
              action: emailActionDefault("la sugerencia de mejora 💡"),
            },
          },
          {
            keyWord: "feedback-other",
            text: "Otro",
            conversation: {
              keyWord: "feedback-other",
              messages: [
                { text: "Me encantaría saber qué tipo sería." },
                { text: "Me gustan especialmente estos, porque mejorarían este 🤖 en futuras actualizaciones 👌." },
                { text: "¿Cómo te gustaría colaborar❓" },
              ],
              action: emailActionDefault("la oportunidad de colaborar 💪"),
            },
          },
        ],
      },
    },
}

const ActionOptionHireMe: ActionOptions = {
  keyWord: "hire-me",
  text: "Quiero trabajar contigo",
  conversation: {
    keyWord: "hire-me",
    messages: [
      { text: "¡Eso es increíble! ❗️ Me alegra escuchar eso." },
      { text: "Siempre estoy buscando nuevos desafíos y oportunidades." },
      { text: "Me encantaría saber más al respecto." },
      { text: "¿Te importaría dejarme tu correo electrónico❓" },
      { text: "Así puedo contactarte." },
    ],
    action: emailActionDefault("la oportunidad de colaborar 💪"),
  }
}

const CHAT_DATA: ConversationData = {
  keyWord: "welcome",
  messages: [
    { text: "¡Hola! 👋 ¡Qué bueno tenerte aquí!❗️" },
    { text: "Este es un chat básico que hice para comunicarme contigo." },
    { text: "¿En qué estás interesado❓" },
  ],
  action: {
    type: "options",
    options: [
      ActionOptionCollaboration,
      ActionOptionAboutMe,
      ActionOptionFeedback,
      ActionOptionHireMe
    ],
  },
}

const spanishData ={
  CHAT_DATA,
  CHAT_DATA_FAKE,
  EMAIL_DETECTED_CONVERSATION,
  BACK_TO_MESSAGE_MESSAGES,
}

export default spanishData;
