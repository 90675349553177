const BORDER = [
  [105, 0],
  [0, 27],
  [0, 382],
  [18, 463],
  [189, 462],
  [348, 466],
  [520, 480],
  [677, 497],
  [900, 530],
  [900, 0],
].map(([px, py]) => ({ px, py }));

const SCREEN = [
  [0, 46],
  [0, 329],
  [30, 439],
  [186, 439],
  [339, 446],
  [518, 456],
  [777, 482],
  [900, 497],
  [900, 0],
  [184, 0],
].map(([px, py]) => ({ px, py }));

const ARM = [
  [778, 513],
  [900, 619],
  [900, 531],
  [778, 513],
].map(([px, py]) => ({ px, py }));

const SCREEN_CONFIG = {
  paddingLeft: 30,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 44,
      color: "white",
      heightLine: 64,
    },
  },
  p1: SCREEN[0], // Top left corner
  p2: SCREEN[1], // Bottom left corner
  p3: SCREEN[SCREEN.length - 2], // Top right corner
  p4: SCREEN[SCREEN.length - 3], // Bottom right corner
};

export default {
  arm: {
    points: ARM,
    configs: { backgroundColor: "black", filter: "blur(1px)" },
  },
  border: {
    points: BORDER,
    configs: { backgroundColor: "#a49c97" },
  },
  monitor: {
    points: SCREEN,
    configs: { ...SCREEN_CONFIG, backgroundColor: "rgb(51 51 51)", filter: "drop-shadow(-2px 4px 0 rgba(0,0,0,.3))" },
  },
};
