const monitorRaw = [
  [89, 236],
  [150, 563],
  [403, 527],
  [621, 497],
  [878, 463],
  [857, 137],
  [704, 165],
  [585, 186],
  [466, 203],
  [324, 219],
  [177, 231],
  [89, 236],
];

const MONITOR = monitorRaw.map(([px, py]) => ({ px, py }));

const MONITOR_BORDE = [
  [80, 229],
  [143, 567],
  [145, 581],
  [381, 547],
  [510, 529],
  [636, 512],
  [887, 481],
  [886, 466],
  [876, 299],
  [871, 215.5],
  [864, 128],
  [703, 158],
  [584, 178],
  [467, 195],
  [324, 211],
  [177, 223],
  [80, 229],
  ...monitorRaw.reverse(),
  [80, 229],
].map(([px, py]) => ({ px, py }));

const VENTILADOR = [
  [116, 426],
  [110, 439],
  [105, 452],
  [99, 464],
  [91, 477],
  [88, 483],
  [86, 486],
  [85, 489],
  [80, 490],
  [76, 494],
  [74, 498],
  [75, 504], //

  [80, 497], //
  [92, 484],
  [94, 480],
  [98, 474],
  [103, 465],
  [108, 454],
  [110, 450],
  [113, 441],
  [118, 431],
].map(([px, py]) => ({ px, py }));

const VENTILATOR_WINDS = [
  [78, 506],
  [74, 508],
  [67, 512],
  [61, 515],
  [53, 519],
  [48.5, 522.5],
  [46.25, 525.75],
  [46, 529],
  [47, 532],
  [51, 537],
  [55, 540],
  [60, 542],
  [63.5, 542],
  [67, 540],
  [71, 535],
  [77, 526],
  [79, 523],
  [81, 520],
  [83, 518],
  [85, 517],
  [89, 514],
  [93, 513],
  [95, 511],
  [97, 508],
  [98, 507],
  [101, 506],
  [104, 504],
  [108, 502],
  [114, 500],
  [120, 497],
  [125, 494],
  [129, 490],
  [130, 486],
  [129, 482],
  [127, 479],
  [122, 476],
  [117, 476],
  [112.5, 477.5],
  [109, 479],
  [104.5, 482],
  [102, 485],
  [97, 490],
  [94, 493],
  [92, 495],
  [91, 496],
  [88, 499],
  [84, 502],
  [82, 504],
  [80, 505],
].map(([px, py]) => ({ px, py }));

const VENTILATOR_PERILLA = [
  [91, 496],
  [85, 497],
  [82, 498],
  [79, 502],
  [78, 504],
  [77.5, 509.5],
  [79.25, 513.75],
  [82.125, 516.375],
  [86, 518],
  [89, 518],
  [93, 518],
  [97, 516],
  [100, 513],
  [101, 509],
  [101, 506],
  [100, 502],
  [95.5, 498],
  [91, 496],
].map(([px, py]) => ({ px, py }));

const VENTILATOR_BEHIND = [
  [94, 493],
  [90, 491],
  [86, 491],
  [83, 492],
  [80, 494],
  [78, 497],
  [77, 500],
  [78, 504],
  [79, 502],
  [82, 498],
  [85, 497],
  [91, 496],
  [91, 496],
  [92, 497],
  [98, 500],
  [94, 493],
].map(([px, py]) => ({ px, py }));

const VENTILATOR_DOT = [
  [89, 506],
  [88, 506],
  [86, 508],
  [86, 510],
  [87, 511],
  [89, 512],
  [91, 511],
  [92, 510],
  [92, 508],
  [90, 506],
  [89, 506],
].map(([px, py]) => ({ px, py }));

const VENTILATOR_CABLE = [
  [117, 424],
  [113, 433],
  [104, 454],
  [94, 472],
  [88, 481],
  [86, 486],
  [86, 490],
  [89, 490],
  [91, 484],
  [97, 475],
  [103, 465],
  [110, 450],
  [114, 440],
  [118, 431],
  [117, 424],
].map(([px, py]) => ({ px, py }));

const MONITOR_MAIN = [
  [385, 234],
  [417, 515],
  [615, 485],
  [869, 450],
  [850, 162],
  [693, 191],
  [500, 222],
  [385, 234],
].map(([px, py]) => ({ px, py }));

const MONITOR_FOTO = [
  [131, 315],
  [174, 575],
  [411, 540],
  [271, 308],
  [131, 320],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_MIRO = [
  [280, 282],
  [321, 538],
  [552, 505],
  [524, 254], // p3
  [352, 276],
  [351, 268],
  [299, 273],
  [299.5, 280.5],
  [280, 282],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_MIRO_DETAIL_TOP = [
  [280, 282],
  [299.5, 280.5],
  [299, 273],
  [351, 268],
  [352, 276],
  [524, 254],
  [523, 244],
  [441, 255],
  [364, 264.5],
  [279, 273],
  [280, 282],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_MIRO_GREEN = [
  [294, 275],
  [292, 275],
  [292, 277],
  [294, 277],
  [294, 276],
  [294, 275],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_NOTION = [
  [459, 234],
  [488, 504],
  [728, 469],
  [706, 195],
  [459, 234],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_GOOGLE = [
  [596, 202],
  [618, 469],
  [873, 438],
  [854, 156],
  [596, 202],
].map(([px, py]) => ({ px, py }));

const MONITOR_TAB_MUSICA = [
  [709, 354],
  [722, 483],
  [876, 463],
  [868, 329],
  [709, 354],
].map(([px, py]) => ({ px, py }));

const MONITOR_BORDER_BOTTOM = [
  [143, 567],
  [145, 581],
  [381, 547],
  [510, 529],
  [636, 512],
  [887, 481],
  [886, 466],
  [700, 490],
  [533, 512],
  [316, 543],
].map(([px, py]) => ({ px, py }));

const DETAIL_REFLECT_LEFT = [
  [427, 200],
  [422, 200],
  [341, 209],
  [245, 218],
  [269, 220],
  [297, 219],
  [320, 218],
  [332, 217],
  [392, 211],
  [428, 207],
  [427, 200],
].map(([px, py]) => ({ px, py }));

const DETAIL_REFLECT_RIGHT = [
  [462, 196],
  [464, 195],
  [517, 189],
  [568, 181],
  [622, 172],
  [644, 168],
  [607, 180],
  [587, 184],
  [525, 194],
  [480, 200],
  [463, 203],
].map(([px, py]) => ({ px, py }));

const SMALL_CONFIGS = {
  paddingLeft: 10,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 22,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 25,
      fontSize: 14,
      color: "white",
      heightLine: 22,
    },
  },
  // backgroundColor: "black",
};
const TAB_NOTION_CONFIGS = {
  paddingLeft: 20,
  paddingTop: 20,
  fonts: {
    h1: {
      characterPerLine: 20, //26, //44,
      fontSize: 22,
      color: "#f5c51f",
      heightLine: 22, // 80, //40
    },
    p: {
      characterPerLine: 21,
      fontSize: 18,
      color: "white",
      heightLine: 22,
    },
  },
  // backgroundColor: "black",
};

const MONITOR_TAB_MIRO_CONFIG = {
  ...SMALL_CONFIGS,
  backgroundColor: "rgb(194 190 183)", // 0.6 * "#cfcac1" or rgb(156 154 148) a bit darker.,

  p1: MONITOR_TAB_MIRO_DETAIL_TOP[0], // Top left corner
  p2: MONITOR_TAB_MIRO[1], // Bottom left corner
  p3: MONITOR_TAB_MIRO_DETAIL_TOP[4], // Top right corner
  p4: MONITOR_TAB_MIRO[2], // Bottom right corner
};

const MONITOR_MAIN_CONFIG = {
  paddingLeft: 20,
  paddingTop: 40,
  fonts: {
    h1: {
      characterPerLine: 26, //26, //44,
      fontSize: 32,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 35,
      fontSize: 24,
      color: "black",
      heightLine: 34,
    },
  },
  backgroundColor: "whitesmoke",
  p1: MONITOR_MAIN[0], // Top left corner
  p2: MONITOR_MAIN[1], // Bottom left corner
  p3: { px: 850, py: 162 }, //MONITOR_MAIN[MONITOR_MAIN.length - 5], // Top right corner
  p4: { px: 869, py: 450 }, //MONITOR_MAIN[MONITOR_MAIN.length - 3], // Bottom right corner
};

const MONITOR_TAB_NOTION_CONFIG = {
  ...TAB_NOTION_CONFIGS,
  backgroundColor: "rgb(164 162 158)",
  p1: MONITOR_TAB_NOTION[0], // Top left corner
  p2: MONITOR_TAB_NOTION[1], // Bottom left corner
  p3: MONITOR_TAB_NOTION[MONITOR_TAB_NOTION.length - 2], // Top right corner
  p4: MONITOR_TAB_NOTION[MONITOR_TAB_NOTION.length - 3], // Bottom right corner
};

const MONITOR_TAB_GOOGLE_CONFIG = {
  ...SMALL_CONFIGS,
  backgroundColor: "rgb(102 101 104)",
  p1: MONITOR_TAB_GOOGLE[0], // Top left corner
  p2: MONITOR_TAB_GOOGLE[1], // Bottom left corner
  p3: MONITOR_TAB_GOOGLE[MONITOR_TAB_GOOGLE.length - 2], // Top right corner
  p4: MONITOR_TAB_GOOGLE[MONITOR_TAB_GOOGLE.length - 3], // Bottom right corner
};

const MONITOR_FOTO_CONFIG = {
  ...SMALL_CONFIGS,
  backgroundColor: "black",
  p1: MONITOR_FOTO[0], // Top left corner
  p2: MONITOR_FOTO[1], // Bottom left corner
  p3: MONITOR_FOTO[3], // Top right corner
  p4: MONITOR_FOTO[2], // Bottom right corner
};

const MONITOR_TAB_MUSIC_CONFIG = {
  ...SMALL_CONFIGS,
  backgroundColor: "rgb(144 142 147)",
  p1: MONITOR_TAB_MUSICA[0], // Top left corner
  p2: MONITOR_TAB_MUSICA[1], // Bottom left corner
  p3: MONITOR_TAB_MUSICA[MONITOR_TAB_MUSICA.length - 2], // Top right corner
  p4: MONITOR_TAB_MUSICA[MONITOR_TAB_MUSICA.length - 3], // Bottom right corner
};

const MONITOR_CONFIGS = {
  paddingLeft: 30,
  paddingTop: 30,
  fonts: {
    h1: {
      characterPerLine: 30, //26, //44,
      fontSize: 42,
      color: "#f5c51f",
      heightLine: 45, // 80, //40
    },
    p: {
      characterPerLine: 20,
      fontSize: 36,
      color: "black",
      heightLine: 42,
    },
  },
  backgroundColor: "rgb(51 51 51)", // 0.8 opacity of black.
  p1: MONITOR[0], // Top left corner
  p2: MONITOR[1], // Bottom left corner
  p3: MONITOR[MONITOR.length - 2], // Top right corner
  p4: { px: 883, py: 465 }, //MONITOR[MONITOR.length - 3], // Top right corner
};

const DATA = {
  monitorBorde: {
    points: MONITOR_BORDE,
    configs: { backgroundColor: "black", filter: "drop-shadow(-2px 0px 0 rgba(0,0,0,.4))" },
  },
  monitor: {
    points: MONITOR,
    configs: MONITOR_CONFIGS,
  },
  monitorDetailReflectLeft: {
    points: DETAIL_REFLECT_LEFT,
    configs: { backgroundColor: "rgb(207 199 165)" }, // pure reflect color rgb(252 240 192), for effects.
  },
  monitorDetailReflectRight: {
    points: DETAIL_REFLECT_RIGHT,
    configs: { backgroundColor: "rgb(207 199 165)" },
  },
  ventilatorCable: {
    points: VENTILATOR_CABLE,
    configs: { backgroundColor: "#4b4144" },
  },
  ventiladorBehind: {
    points: VENTILATOR_BEHIND,
    configs: { backgroundColor: "#3f3532" },
  },
  ventiladorWinds: {
    points: VENTILATOR_WINDS,
    configs: { backgroundColor: "#c6b99e" },
  },
  ventiladorPerilla: {
    points: VENTILATOR_PERILLA,
    configs: { backgroundColor: "#bcbfae" },
  },
  ventiladorDot: {
    points: VENTILATOR_DOT,
    configs: { backgroundColor: "#8e8f81" },
  },
  monitorFOTO: {
    points: MONITOR_FOTO,
    configs: MONITOR_FOTO_CONFIG,
  },
  monitorTabGoogle: {
    points: MONITOR_TAB_GOOGLE,
    configs: MONITOR_TAB_GOOGLE_CONFIG,
  },
  monitorTabMusic: {
    points: MONITOR_TAB_MUSICA,
    configs: MONITOR_TAB_MUSIC_CONFIG,
  },
  monitorTabNotion: {
    points: MONITOR_TAB_NOTION,
    configs: MONITOR_TAB_NOTION_CONFIG,
  },
  monitorTabMiro: {
    points: MONITOR_TAB_MIRO,
    configs: MONITOR_TAB_MIRO_CONFIG,
  },
  monitorTabMiroDetailTop: {
    points: MONITOR_TAB_MIRO_DETAIL_TOP,
    configs: { backgroundColor: "#a49c97" },
  },
  monitorMain: {
    points: MONITOR_MAIN,
    configs: MONITOR_MAIN_CONFIG,
  },
  monitorBorderBottom: {
    points: MONITOR_BORDER_BOTTOM,
    configs: { backgroundColor: "#a49c97" },
  },
  minLeftPoint: {
    points: [
      [36, 236],
      [36, 236],
    ].map(([px, py]) => ({ px, py })),
    configs: { backgroundColor: "transparent" },
  },
};

// const TextAnimatedObjects = {
//   monitorTabGoogle: DATA.monitorTabGoogle,
//   monitorTabMusic: DATA.monitorTabMusic,
//   monitorTabNotion: DATA.monitorTabNotion,
//   monitorTabMiro: DATA.monitorTabMiro,
//   monitorMain: DATA.monitorMain,
// };

// const ShapesObjects = {
//   monitorTabGoogle: DATA.monitorTabGoogle,
//   monitorTabMusic: DATA.monitorTabMusic,
//   monitorTabNotion: DATA.monitorTabNotion,
//   monitorTabMiro: DATA.monitorTabMiro,
//   monitorMain: DATA.monitorMain,
// };

// const DATA_DEFINED = {
//   shapes: {
//     monitor: {
//       ...DATA.monitorBorde,
//       ...DATA.monitorBorderBottom,
//       ...DATA.monitorDetailReflectLeft,
//       ...DATA.monitorDetailReflectRight,
//       ...DATA.monitorFOTO,
//       ...DATA.monitorMain,
//       ...DATA.monitorBorderBottom,
//       ...DATA.monitorBorderBottom,
//       ...DATA.monitorBorderBottom,
//     },
//   },
// };

export default DATA;
