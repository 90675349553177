import { ActionOptions } from "../Actions/types";
import { ConversationData } from "../types";

const finishConversation = (hearAbout: string = "you"): ConversationData => ({
  keyWord: "ending-branch",
  messages: [
    { text: "Perfekt❗️" },
    { text: "Ich werde mich so schnell wie möglich bei Ihnen melden." },
    { text: `Ich freue mich schon darauf, mehr über ${hearAbout} zu erfahren` },
    { text: "Ich lade Sie jetzt ein, meine Website weiter zu durchsuchen 🙂." },
  ],
  action: undefined,
});

const emailActionDefault = (hearAbout: string) => ({
  type: "input-email" as const,
  conversation: finishConversation(hearAbout),
});

const MISSING_CONVERSATION = {
  keyWord: "missing-conversation",
  messages: [
    { text: "Es tut mir leid, ich habe diesen Inhalt noch nicht fertiggestellt." },
    { text: "Ich werde Ignacio benachrichtigen, dass Sie diese Option ausgewählt haben." },
    { text: "Möchten Sie mir Ihre E-Mail-Adresse geben, damit wir Sie kontaktieren können❓" },
  ],
  action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
};

const EMAIL_DETECTED_CONVERSATION = (
  email: string,
  emailQuestionConversation: ConversationData,
  conversationForward: ConversationData
): ConversationData => ({
  keyWord: "email-detected",
  messages: [
    ...emailQuestionConversation.messages,
    { text: "Aber Moment mal." },
    { text: "Ich sehe, Sie haben bereits Ihre E-Mail-Adresse eingegeben 😅." },
    { text: `Soll ich Sie unter ${email} kontaktieren❓` },
  ],
  action: {
    type: "confirm-email-action",
    options: [
      {
        keyWord: "confirm-email",
        text: "Ja!",
        conversation: conversationForward,
      },
      {
        keyWord: "reject-confirm-email",
        text: "Nein, ich möchte eine andere eingeben.",
        conversation: {
          keyWord: "reject-confirm-email",
          messages: [
            { text: "Alles klar, das ist kein Problem 🙂." },
            { text: "Hier gehen wir, geben Sie sie einfach ein:" },
          ],
          action: {
            type: "input-email-reentered",
            conversation: conversationForward,
          },
        },
      },
    ],
  },
});

const BACK_TO_MESSAGE_MESSAGES = [
  { text: "Ich verstehe, das ist kein Problem, wir können später darauf zurückkommen 🙂." },
];

const CHAT_DATA_FAKE = {
  keyWord: "welcome",
  messages: [
    { text: "Hallo 👋, schön, Sie zu haben❗️" },
    { text: "Dies ist ein grundlegender Chat, den ich erstellt habe, um mit Ihnen zu kommunizieren." },
    { text: "Wofür interessieren Sie sich❓" },
  ],
};

const ActionOptionCollaboration: ActionOptions = {
  keyWord: "collaboration",
  text: "Ich suche nach Zusammenarbeit.",
  conversation: {
    keyWord: "collaboration",
    messages: [
      { text: "Das ist großartig❗️" },
      { text: "Darf ich fragen, nach welcher Art von Zusammenarbeit Sie suchen❓" },
    ],
    action: {
      type: "options",
      options: [
        {
          keyWord: "unicorn",
          text: "Ich habe die nächste Einhorn-Idee 🦄.",
          conversation: {
            keyWord: "unicorn",
            messages: [
              { text: "Oh wow. Das klingt fantastisch." },
              { text: "Ich würde gerne mehr darüber erfahren." },
              { text: "Könnten Sie mir Ihre E-Mail-Adresse hinterlassen❓" },
              { text: "Dann kann ich Sie kontaktieren." },
            ],
            action: emailActionDefault("die 🦄💡❗️"),
          },
        },
        {
          keyWord: "services",
          text: "Ich denke, Sie könnten an meinen Dienstleistungen interessiert sein 📈.",
          conversation: {
            keyWord: "services",
            messages: [
              { text: "😮" },
              { text: "Schön, das zu hören, Sie haben wahrscheinlich recht❗️" },
              { text: "Ich frage mich, welche Art von Dienstleistungen Sie anbieten.. 🤔" },
              { text: "Ist es eine dieser Optionen❓" },
            ],
            action: {
              type: "options",
              options: [
                {
                  keyWord: "marketing",
                  text: "Marketing",
                  conversation: {
                    keyWord: "marketing",
                    messages: [
                      { text: "Marketing ist für jedes Unternehmen sehr wichtig." },
                      { text: "Ich bin neugierig, welche Art von Marketing bieten Sie an❓" },
                    ],
                    action: {
                      type: "options",
                      options: [
                        {
                          keyWord: "marketing-digital",
                          text: "Digitales Marketing",
                          conversation: {
                            keyWord: "marketing-digital",
                            messages: [
                              { text: "Fantastisch, es gibt viel Potenzial für Ideen im Bereich digitales Marketing." },
                              { text: "Ich würde gerne mehr darüber erfahren." },
                            ],
                            action: emailActionDefault("die Idee für digitales Marketing 💡"),
                          },
                        },
                        {
                          keyWord: "marketing-traditional",
                          text: "Traditionelles Marketing",
                          conversation: {
                            keyWord: "marketing-traditional",
                            messages: [
                              { text: "Interessante Wahl." },
                              { text: "Können Sie mir mehr darüber erzählen❓" },
                            ],
                            action: emailActionDefault("die Idee für traditionelles Marketing 💡"),
                          },
                        },
                        {
                          keyWord: "marketing-other",
                          text: "Andere",
                          conversation: {
                            keyWord: "marketing-other",
                            messages: [
                              { text: "Ich würde gerne hören, um welche Art von Marketing es sich handelt." },
                              { text: "Ich mag diese am meisten, weil sie diese 🤖 in zukünftigen Upgrades 👌 verbessern würden." },
                              { text: "Wie möchten Sie zusammenarbeiten❓" },
                            ],
                            action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  keyWord: "creator",
                  text: "Content Creator",
                  conversation: {
                    keyWord: "creator",
                    messages: [
                      { text: "Content-Erstellung ist heutzutage großartig 🚀." },
                      { text: "Sehen Sie Potenzial darin❓" },
                      { text: "Ich würde gerne mehr darüber erfahren." },
                      { text: "Möchten Sie mir Ihre E-Mail-Adresse geben❓" },
                    ],
                    action: emailActionDefault("die Idee für Content-Erstellung 💡"),
                  },
                },
                {
                  keyWord: "outsourcing",
                  text: "Outsourcing",
                  conversation: {
                    keyWord: "outsourcing",
                    messages: [
                      { text: "Outsourcing ist eine großartige Möglichkeit, die Produktivität zu steigern." },
                      { text: "Und es ist eine großartige Möglichkeit, mit anderen zusammenzuarbeiten." },
                      { text: "Glauben Sie, dass wir zusammenarbeiten könnten❓" },
                      { text: "Ich würde gerne einen Anruf machen, um es zu besprechen." },
                      { text: "Möchten Sie mir Ihre E-Mail-Adresse geben❓" },
                    ],
                    action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
                  },
                },
              ],
            },
          },
        },
        {
          keyWord: "colaboration-other",
          text: "Andere Art der Zusammenarbeit",
          conversation: {
            keyWord: "colaboration-other",
            messages: [
              { text: "Ich würde gerne hören, um welche Art von Zusammenarbeit es sich handelt." },
              { text: "Ich mag diese am meisten, weil sie diese 🤖 in zukünftigen Upgrades 👌 verbessern würden." },
              { text: "Wie möchten Sie zusammenarbeiten❓" },
            ],
            action: {
              type: "input-text",
              conversation: {
                keyWord: "colaboration-other-input-text",
                messages: [
                  { text: "Danke❗️, notiert 🗒." },
                  { text: "Ich werde Ignacio darüber informieren." },
                  { text: "Möchten Sie mir Ihre E-Mail-Adresse geben, damit wir Sie kontaktieren können❓" },
                  { text: "Andernfalls wird es in diesem Chat angezeigt, sobald Ignacio antwortet." },
                ],
                action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
              },
            },
          },
        },
      ],
    },
  },
};

const ActionOptionAboutMe: ActionOptions = {
  keyWord: "more-about-me",
  text: "Mehr über Sie",
  conversation: {
    keyWord: "more-about-me",
    messages: [
      { text: "Ich freue mich, dass Sie mehr über mich erfahren möchten." },
      { text: "Ich bin ein Softwareentwickler und leidenschaftlich für Technologie." },
      { text: "Ich bin immer auf der Suche nach neuen Herausforderungen und Möglichkeiten." },
      { text: "Ich lade Sie ein, meine Website weiter zu durchsuchen." },
    ],
    action: undefined,
  },
};

const ActionOptionFeedback: ActionOptions = {
  keyWord: "feedback",
  text: "Ich möchte Ihnen Feedback geben.",
  conversation: {
    keyWord: "feedback",
    messages: [
      { text: "Es freut mich zu hören." },
      { text: "Ich bin immer auf der Suche nach Feedback, um mich zu verbessern." },
      { text: "Welche Art von Feedback möchten Sie mir geben❓" },
    ],
    action: {
      type: "options",
      options: [
        {
          keyWord: "feedback-bug",
          text: "Ich habe einen Fehler gefunden",
          conversation: {
            keyWord: "feedback-bug",
            messages: [
              { text: "Es tut mir leid, das zu hören." },
              { text: "Ich werde Ignacio darüber informieren." },
              { text: "Möchten Sie mir Ihre E-Mail-Adresse geben, damit wir Sie kontaktieren können❓" },
              { text: "Andernfalls wird es in diesem Chat angezeigt, sobald Ignacio antwortet." },
            ],
            action: emailActionDefault("den Fehler 🐞"),
          },
        },
        {
          keyWord: "feedback-improvement",
          text: "Ich habe einen Verbesserungsvorschlag",
          conversation: {
            keyWord: "feedback-improvement",
            messages: [
              { text: "Es freut mich zu hören." },
              { text: "Ich bin immer auf der Suche nach Feedback, um mich zu verbessern." },
              { text: "Ich würde gerne mehr darüber erfahren." },
              { text: "Könnten Sie mir Ihre E-Mail-Adresse hinterlassen❓" },
              { text: "Dann kann ich Sie kontaktieren." },
            ],
            action: emailActionDefault("den Verbesserungsvorschlag 💡"),
          },
        },
        {
          keyWord: "feedback-other",
          text: "Anderes",
          conversation: {
            keyWord: "feedback-other",
            messages: [
              { text: "Ich würde gerne hören, um welche Art es sich handelt." },
              { text: "Ich mag diese am meisten, weil sie diese 🤖 in zukünftigen Upgrades 👌 verbessern würden." },
              { text: "Wie möchten Sie zusammenarbeiten❓" },
            ],
            action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
          },
        },
      ],
    },
  },
};

const ActionOptionHireMe: ActionOptions = {
  keyWord: "hire-me",
  text: "Ich möchte mit Ihnen arbeiten",
  conversation: {
    keyWord: "hire-me",
    messages: [
      { text: "Das ist großartig❗️, Ich freue mich das zu hören." },
      { text: "Ich bin immer auf der Suche nach neuen Herausforderungen und Möglichkeiten." },
      { text: "Ich würde gerne mehr darüber erfahren." },
      { text: "Könnten Sie mir Ihre E-Mail-Adresse hinterlassen❓" },
      { text: "Dann kann ich Sie kontaktieren." },
    ],
    action: emailActionDefault("die Möglichkeit zur Zusammenarbeit 💪"),
  },
};

const CHAT_DATA: ConversationData = {
  keyWord: "welcome",
  messages: [
    { text: "Hallo 👋, schön, dass Sie hier sind❗️" },
    { text: "Dies ist ein einfacher Chat, den ich erstellt habe, um mit Ihnen zu kommunizieren." },
    { text: "Wofür interessieren Sie sich❓" },
  ],
  action: {
    type: "options",
    options: [
      ActionOptionCollaboration,
      ActionOptionAboutMe,
      ActionOptionFeedback,
      ActionOptionHireMe,
    ],
  },
};

const englishData = {
  CHAT_DATA,
  CHAT_DATA_FAKE,
  EMAIL_DETECTED_CONVERSATION,
  BACK_TO_MESSAGE_MESSAGES,
};

export default englishData;
