import { ChatActionOptionsProps, ChatActionInputProps } from "./types";

export const ID_IDENTIFIER = "option-id-";

export const effectHideNotSelectedOptions = (indexSelected: number, options: ChatActionOptionsProps["options"]) => {
  const eleSelected = document.getElementById(`${ID_IDENTIFIER}${options[indexSelected].keyWord}`) as HTMLElement;
  const heightToTranslate = `${-1 * (eleSelected.offsetHeight + 10)}px`;

  options.forEach(({ keyWord }, i) => {
    if (indexSelected === i) return;
    const elem = document.getElementById(`${ID_IDENTIFIER}${keyWord}`) as HTMLElement;

    elem.style.zIndex = "-1";
    if (i < indexSelected) {
      elem.style.marginBottom = heightToTranslate;
    } else {
      elem.style.marginTop = heightToTranslate;
    }
  });

  return new Promise((r) => setTimeout(r, 500));
};

const validateInputValue = (value: string, type: ChatActionInputProps["type"]) => {
  switch (type) {
    case "input-email":
    case "input-email-reentered":
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return pattern.test(value);
    case "input-text":
      return value.trim() !== "";
    default:
      return false;
  }
};

const placeholders = (type: ChatActionInputProps["type"]) => {
  switch (type) {
    case "input-email":
      return "Enter email..";
    case "input-text":
      return "Ask me something..";
    default:
      return "";
  }
};

export const ActionInputHelpers = {
  validateInputValue,
  placeholders,
};
