const COMPLETE = [
  [288, 946],
  [279, 945],
  [270, 945],
  [252, 946.5],
  [241, 949],
  [219, 956],
  [205.75, 963],
  [189.5, 973],
  [179, 981],
  [170.875, 989.375],
  [164.75, 997.75],
  [158.5, 1010.5],
  [156, 1028],
  [168, 1090],
  [185, 1178],
  [190, 1189],
  [193, 1195],
  [196, 1199],
  [322, 1199],
  [332, 1190],
  [341, 1178],
  [349, 1160],
  [352, 1150],
  [354, 1142],
  [354, 1124],
  [344, 995],
  [342, 982],
  [339, 972],
  [326, 959],
  [307, 950],
  [296, 947],
].map(([px, py]) => ({ px, py }));

const TAP = [
  [288, 946],
  [279, 945],
  [270, 945],
  [252, 946.5],
  [241, 949],
  [219, 956],
  [205.75, 963],
  [189.5, 973],
  [179, 981],
  [170.875, 989.375],
  [164.75, 997.75],
  [158.5, 1010.5],
  [156, 1028],
  [161, 1044],
  [172, 1054],
  [182, 1061],
  [195, 1066],
  [211, 1070],
  [233, 1070],
  [246, 1069],
  [264, 1065],
  [291, 1055],
  [314, 1040],
  [330, 1026],
  [339, 1011],
  [342, 1002],
  [344, 995],
  [342, 982],
  [339, 972],
  [326, 959],
  [307, 950],
  [296, 947],
  [288, 946],
].map(([px, py]) => ({ px, py }));

const TAP_2 = [].map(([px, py]) => ({ px, py }));

const TAP_INNER_TOP = [
  [285, 949],
  [278, 948],
  [269, 948],
  [264, 949],
  [254, 950],
  [245, 952],
  [232, 956],
  [221, 960],
  [207, 967],
  [195, 975],
  [185, 984],
  [172, 1000],
  [167, 1013],
  [165, 1020],
  [164, 1027],
  [165, 1033],
  [169, 1040],
  [173, 1046],
  [177, 1050],
  [183, 1054],

  [183, 1053],
  [183, 1052],
  [183, 1048],
  [184, 1043],
  [186, 1036],
  [190, 1030],
  [199, 1017],
  [208, 1005],
  [214, 997],
  [223, 988],
  [234, 980],
  [242, 974],
  [252, 968],
  [260, 963],
  [269, 957],
  [279, 952],

  [285, 949],
].map(([px, py]) => ({ px, py }));

const TAP_INNER_BOTTOM = [
  [279, 952],
  [269, 957],
  [260, 963],
  [252, 968],
  [242, 974],
  [234, 980],
  [223, 988],
  [214, 997],
  [208, 1005],
  [199, 1017],
  [190, 1030],
  [186, 1036],
  [184, 1043],
  [183, 1048],
  [183, 1052],
  [183, 1053],
  [183, 1054],
  [187, 1056],
  [194, 1059],
  [200, 1061],
  [210, 1063],
  [222, 1064],
  [242, 1063],
  [264, 1058],
  [287, 1050],
  [307, 1038],
  [319, 1028],
  [328.5, 1016],
  [334, 1005],
  [336, 997],
  [336, 989],
  [335, 981.5],
  [332, 974],
  [327.5, 967.5],
  [321, 961],
  [310, 955],
  [299, 951],
  [291, 949],
  [285, 949],
  [279, 952],
].map(([px, py]) => ({ px, py }));

const DETAIL_LIGHT_FRONT = [
  [344, 995],
  [342, 1002],
  [339, 1011],
  [330, 1026],
  [314, 1040],
  [291, 1055],
  [264, 1065],
  [246, 1069],
  [233, 1070],
  [228, 1078],
  [236, 1086],
  [236, 1090],
  [250, 1096],
  [257, 1101],
  [267, 1098],
  [274, 1091],
  [281, 1087],
  [299, 1078],
  [304, 1071],
  [321, 1058],
  [326, 1046],
  [337, 1034],
  [342, 1024],
  [344, 1015],
  [345, 1011],
  [344, 995],
].map(([px, py]) => ({ px, py }));

const L = [
  [202, 1123],
  [207, 1151],
  [216, 1154],
  [216, 1150],
  [210, 1149],
  [206, 1125],
  [202, 1123],
].map(([px, py]) => ({ px, py }));

const O_OUT = [
  [226, 1127],
  [222, 1128],
  [219, 1131],
  [218, 1136],
  [218, 1141],
  [220, 1147],
  [222, 1151],
  [225, 1154],
  [229, 1156],
  [233, 1157],
  [238, 1155],
  [240, 1152],
  [241, 1149],
  [241.5, 1144.5],
  [240, 1139],
  [237, 1133],
  [234, 1130],
  [231, 1128],
  [227, 1127],
].map(([px, py]) => ({ px, py }));

const O_IN = [
  [227, 1132],
  [223, 1133],
  [222, 1138],
  [222, 1143],
  [225, 1149],
  [228, 1152],
  [231, 1153],
  [234, 1152],
  [237, 1150],
  [237, 1145],
  [236, 1140],
  [234, 1136],
  [231, 1133],
  [227, 1132],
].map(([px, py]) => ({ px, py }));

const V = [
  [242, 1128],
  [257, 1157],
  [262, 1126],
  [257, 1127],
  [255, 1142],
  [247, 1128],
  [242, 1128],
].map(([px, py]) => ({ px, py }));

const E = [
  [270, 1124],
  [274, 1151],
  [288, 1147],
  [287, 1143],
  [279, 1146],
  [277, 1137],
  [284, 1135],
  [284, 1131],
  [276, 1133],
  [275, 1126],
  [284, 1123],
  [284, 1119],
  [270, 1124],
].map(([px, py]) => ({ px, py }));

export default {
  complete: {
    points: COMPLETE,
    configs: { backgroundColor: "rgb(161 83 62)", strokeWidth: "0" },
  },
  tap: {
    points: TAP,
    configs: { backgroundColor: "rgb(255 168 93)", strokeWidth: "0" },
  },
  tap2: {
    points: TAP_2,
    configs: { backgroundColor: "transparent" },
  },
  tapInnerTop: {
    points: TAP_INNER_TOP,
    configs: { backgroundColor: "rgb(151 72 51)", strokeWidth: "0" },
  },
  tapInnerBottom: {
    points: TAP_INNER_BOTTOM,
    configs: { backgroundColor: "#873a26", strokeWidth: "0" },
  },
  cupDetailFront: {
    points: DETAIL_LIGHT_FRONT,
    configs: { backgroundColor: "rgb(152 95 66)", strokeWidth: "0" },
  },
  l: {
    points: L,
    configs: { backgroundColor: "rgb(203 190 173)", strokeWidth: "0" },
  },
  oOut: {
    points: O_OUT,
    configs: { backgroundColor: "rgb(203 190 173)", strokeWidth: "0" },
  },
  oIn: {
    points: O_IN,
    configs: { backgroundColor: "rgb(161 83 62)", strokeWidth: "0" },
  },
  v: {
    points: V,
    configs: { backgroundColor: "rgb(203 190 173)", strokeWidth: "0" },
  },
  e: {
    points: E,
    configs: { backgroundColor: "rgb(203 190 173)", strokeWidth: "0" },
  },
};
