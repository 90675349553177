import React from "react";

export const MessageCorner = () => (
  <svg
    style={{ position: "absolute", left: "40px", top: "10px", zIndex: "-1" }}
    width="24"
    height="35"
    viewBox="0 0 24 35"
    fill="none"
  >
    <path
      d="M21.4515 1.83795C21.4515 3.29983 19.8729 5.42912 18.6677 6.39326C15.2955 9.09104 10.9243 13.5862 6.26713 13.9854C5.25712 14.072 1.12622 15.3953 1.93678 15.6163C4.23181 16.2423 6.49288 17.4343 8.5729 18.5689C11.5441 20.1895 14.9905 22.7088 17.4023 25.1206C18.5957 26.314 19.3944 28.1315 20.6923 29.1698C21.3404 29.6883 22.9699 34.8195 22.9699 33.2189C22.9699 29.6009 22.9699 25.9829 22.9699 22.3649C22.9699 16.1952 22.4638 -2.30725 22.4638 3.86253"
      stroke="#e8e8e8"
      strokeWidth="1"
      strokeLinecap="round"
      fill="rgb(245, 245, 245)"
    />
  </svg>
);
