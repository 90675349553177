const LAMP_BAR = [
  [228, 190],
  [228, 199],
  [230, 206],
  [232, 210],
  [657, 158],
  [659, 153],
  [658, 148],
  [657, 143],
  [655, 140],
  [228, 190],
].map(([px, py]) => ({ px, py }));

const DETAIL_LEFT = [
  [226, 190],
  [226, 199],
  [228, 206],
  [230, 210],
  [232, 213],
  [234, 213],
  [232, 210],
  [230, 206],
  [228, 199],
  [228, 190],
  [226, 190],
].map(([px, py]) => ({ px, py }));

const DETAIL_RIGHT = [
  [655, 140],
  [657, 143],
  [658, 148],
  [659, 153],
  [657, 158],
  [653, 162],
  [654, 162],
  [659, 158],
  [661, 153],
  [660, 148],
  [659, 143],
  [657, 140],
  [655, 140],
].map(([px, py]) => ({ px, py }));

const LAMP_LIGHT = [
  [232, 210],
  [234, 213],
  [653, 162],
  [657, 158],
  [232, 210],
].map(([px, py]) => ({ px, py }));

const LAMP_ANKER_BOTTOM = [
  [425, 190],
  [422, 196],
  [422, 200],
  [427, 199],
  [428, 208],
  [463, 204],
  [462, 196],
  [464, 195],
  [465, 185],
  [425, 190],
].map(([px, py]) => ({ px, py }));

const LAMP_ANKER_TOP = [
  [423, 165],
  [422, 169],
  [423, 169],
  [426, 183],
  [466, 178],
  [465, 170],
  [464, 166],
  [462, 161],
  [423, 165],
].map(([px, py]) => ({ px, py }));

const DATA = {
  lampBar: {
    points: LAMP_BAR,
    configs: { backgroundColor: "#a49c97", filter: "drop-shadow(2px 29px 33px rgb(249, 229, 148));" },
  },
  lampDetailLeft: {
    points: DETAIL_LEFT,
    configs: { backgroundColor: "black" },
  },
  lampDetailRight: {
    points: DETAIL_RIGHT,
    configs: { backgroundColor: "black" },
  },
  lampLight: {
    points: LAMP_LIGHT,
    configs: { backgroundColor: "#f9e594", filter: "drop-shadow(3px 15px 9 rgb(249, 229, 148))" },
  },
  lampAnkerBottom: {
    points: LAMP_ANKER_BOTTOM,
    configs: { backgroundColor: "#3f4038" },
  },
  lampAnkerTop: {
    points: LAMP_ANKER_TOP,
    configs: { backgroundColor: "#6b615e", filter: "drop-shadow(-1px 1px 0 rgba(0,0,0,.3))" },
  },
};

export default DATA;
