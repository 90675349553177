import Img from "assets/defined_fotos/desktop_mobile.jpeg";
/**
 * All of the bellow object were define in a viewBox="0 0 900 1200"
 * IMPORTANT: the point should be define in anti horario way. Otherways the substraction of the area will not work.
 */

const MONITOR = [
  { px: 1, py: 114 },
  { px: 1, py: 660 },
  { px: 225, py: 632 },
  { px: 397, py: 621 },
  { px: 669, py: 620 },
  { px: 899, py: 634 },
  { px: 899, py: 97 },
  { px: 484, py: 101 },
  { px: 416, py: 103 },
  { px: 221, py: 105 },
  { px: 3, py: 117 },
];

const MONITOR_CHILDREN = {
  foto: [
    { px: 1, py: 234 },
    { px: 1, py: 496 },
    { px: 192, py: 480 },
    { px: 146, py: 226 },
    { px: 1, py: 234 },
  ],
  body: [
    { px: 144, py: 169 },
    { px: 221, py: 609 },
    { px: 497, py: 594 },
    { px: 791, py: 599 },
    { px: 873, py: 601 },
    { px: 883, py: 157 },
    { px: 144, py: 169 },
  ],
};

const BOOKS_CHILDREN = {
  author: [
    { px: 586, py: 991 },
    { px: 589, py: 1007 },
    { px: 657, py: 1003 },
    { px: 654, py: 986 },
    { px: 586, py: 991 },
  ],
  title: [
    { px: 589, py: 1016 },
    { px: 605, py: 1100 },
    { px: 809, py: 1081 },
    { px: 800, py: 1043 },
    { px: 753, py: 1043 },
    { px: 742, py: 1015 },
    { px: 589, py: 1016 },
  ],
  subtitle: [
    { px: 607, py: 1098 },
    { px: 610, py: 1116 },
    { px: 817, py: 1098 },
    { px: 813, py: 1090 },
    { px: 607, py: 1098 },
  ],
};

const COMPUTER_CHILDREN = {
  left: [
    { px: 109, py: 847 },
    { px: 125, py: 1080 },
    { px: 329, py: 1054 },
    { px: 311, py: 827 },
    { px: 109, py: 847 },
  ],
  right: [
    { px: 311, py: 825 },
    { px: 330, py: 1054 },
    { px: 487, py: 1034 },
    { px: 467, py: 808 },
    { px: 311, py: 825 },
  ],
};

const FOTO = [
  { px: 526, py: 1008 },
  { px: 564, py: 1200 },
  { px: 865, py: 1199 },
  { px: 843, py: 1129 },
  { px: 861, py: 1132 },
  { px: 850, py: 1100 },
  { px: 832, py: 1090 },
  { px: 823, py: 1063 },
  { px: 848, py: 1051 },
  { px: 832, py: 1018 },
  { px: 812, py: 1026 },
  { px: 789, py: 965 },
  { px: 571, py: 978 },
  { px: 548, py: 981 },
  { px: 543, py: 992 },
  { px: 531, py: 997 },
  { px: 526, py: 1008 },
];

const COMPUTER = [
  { px: 108, py: 843 },
  { px: 122, py: 1082 },
  { px: 487, py: 1037 },
  { px: 467, py: 806 },
  { px: 108, py: 843 },
];

const MONITOR_BODY_CONFIGS = {
  paddingLeft: 30,
  paddingTop: 80,
  fonts: {
    h1: {
      characterPerLine: 22, //26, //44,
      fontSize: 54,
      color: "#f5c51f",
      heightLine: 60, // 80, //40
    },
    p: {
      characterPerLine: 44,
      fontSize: 28,
      color: "black",
      heightLine: 34,
    },
  },
  backgroundColor: "white",
  p1: MONITOR_CHILDREN.body[0],
  p2: MONITOR_CHILDREN.body[1],
  p3: MONITOR_CHILDREN.body[MONITOR_CHILDREN.body.length - 2],
  p4: MONITOR_CHILDREN.body[MONITOR_CHILDREN.body.length - 3],
};

const COMPUTER_CONFIGS = {
  paddingLeft: 10,
  paddingTop: 15,
  fonts: {
    h1: {
      characterPerLine: 28,
      fontSize: 20,
      color: "white",
      heightLine: 22,
    },
    p: {
      characterPerLine: 28,
      fontSize: 20,
      color: "white",
      heightLine: 22,
    },
  },
  backgroundColor: "black",
  p1: COMPUTER[0],
  p2: COMPUTER[1],
  p3: COMPUTER[COMPUTER.length - 2],
  p4: COMPUTER[COMPUTER.length - 3],
};

export const OBJECT_DEFINED = {
  monitor: {
    points: MONITOR,
    childElements: {
      monitorFoto: {
        points: MONITOR_CHILDREN.foto,
      },
      monitorBody: {
        points: MONITOR_CHILDREN.body,
        configs: MONITOR_BODY_CONFIGS,
      },
    },
  },
  computer: {
    points: COMPUTER,
    configs: COMPUTER_CONFIGS,
    childElements: {
      computerLeft: {
        points: COMPUTER_CHILDREN.left,
      },
      computerRight: {
        points: COMPUTER_CHILDREN.right,
      },
    },
  },
  foto: {
    points: FOTO,
    childElements: {
      booksAuthor: {
        points: BOOKS_CHILDREN.author,
      },
      booksTitle: {
        points: BOOKS_CHILDREN.title,
      },
      booksSubtitle: {
        points: BOOKS_CHILDREN.subtitle,
      },
    },
  },
};

const DATA = {
  image: Img,
  objectsDefined: OBJECT_DEFINED,
  svgObjects: {
    monitor: {
      monitor: OBJECT_DEFINED.monitor,
    },
  },
};

export default DATA;
