import Img from "assets/defined_fotos/bg_rf_900x1200.jpg";

const RF_EXTENDED = [
  {
    px: 186,
    py: -1,
  },
  {
    px: 170,
    py: 192,
  },
  {
    px: 381,
    py: 634,
  },
  {
    px: 375,
    py: 754,
  },
  {
    px: 453,
    py: 883,
  },
  {
    px: 492,
    py: 948,
  },
  {
    px: 577,
    py: 945,
  },
  {
    px: 691,
    py: 686,
  },
  {
    px: 711,
    py: 250,
  },
  {
    px: 811,
    py: -1,
  },
  {
    px: 186,
    py: -1,
  },
];

const SKY_RF = [
  {
    px: 196,
    py: 1,
  },
  {
    px: 194,
    py: 20,
  },
  {
    px: 206,
    py: 49,
  },
  {
    px: 201,
    py: 93.5,
  },
  {
    px: 196,
    py: 138,
  },
  {
    px: 207.5,
    py: 162,
  },
  {
    px: 209.6875,
    py: 170.625,
  },
  {
    px: 224.78125,
    py: 203.4375,
  },
  {
    px: 233.328125,
    py: 218.84375,
  },
  {
    px: 234.6015625,
    py: 226.546875,
  },
  {
    px: 248.875,
    py: 258.25,
  },
  {
    px: 256.5625,
    py: 272.875,
  },
  {
    px: 257.40625,
    py: 279.1875,
  },
  {
    px: 268.25,
    py: 303.5,
  },
  {
    px: 271.09375,
    py: 306.3125,
  },
  {
    px: 274.515625,
    py: 315.71875,
  },
  {
    px: 276.2265625,
    py: 321.421875,
  },
  {
    px: 278.08203125,
    py: 323.2734375,
  },
  {
    px: 282.009765625,
    py: 331.19921875,
  },
  {
    px: 280.9736328125,
    py: 335.162109375,
  },
  {
    px: 284.45556640625,
    py: 335.1435546875,
  },
  {
    px: 289.196533203125,
    py: 344.13427734375,
  },
  {
    px: 287.9375,
    py: 347.125,
  },
  {
    px: 298.78125,
    py: 370.9375,
  },
  {
    px: 301.203125,
    py: 373.84375,
  },
  {
    px: 304.625,
    py: 382.75,
  },
  {
    px: 314.21875,
    py: 405.0625,
  },
  {
    px: 325.8125,
    py: 431.375,
  },
  {
    px: 347,
    py: 480,
  },
  {
    px: 347,
    py: 450,
  },
  {
    px: 351,
    py: 447,
  },
  {
    px: 352,
    py: 393,
  },
  {
    px: 354,
    py: 389,
  },
  {
    px: 356,
    py: 294,
  },
  {
    px: 361,
    py: 293,
  },
  {
    px: 360.5,
    py: 275,
  },
  {
    px: 360.25,
    py: 264,
  },
  {
    px: 363,
    py: 258,
  },
  {
    px: 367.5,
    py: 257,
  },
  {
    px: 373.25,
    py: 257,
  },
  {
    px: 377,
    py: 256,
  },
  {
    px: 378,
    py: 237,
  },
  {
    px: 380,
    py: 231.5,
  },
  {
    px: 386,
    py: 231,
  },
  {
    px: 385,
    py: 226,
  },
  {
    px: 388,
    py: 224,
  },
  {
    px: 390.5,
    py: 226,
  },
  {
    px: 391.25,
    py: 230.5,
  },
  {
    px: 399.125,
    py: 231.25,
  },
  {
    px: 401,
    py: 238,
  },
  {
    px: 401,
    py: 246,
  },
  {
    px: 402,
    py: 251,
  },
  {
    px: 402.5,
    py: 254.5,
  },
  {
    px: 406.75,
    py: 255.25,
  },
  {
    px: 409.875,
    py: 255.625,
  },
  {
    px: 414.4375,
    py: 253.8125,
  },
  {
    px: 418,
    py: 255,
  },
  {
    px: 428,
    py: 304,
  },
  {
    px: 429.5,
    py: 309,
  },
  {
    px: 435.25,
    py: 308.5,
  },
  {
    px: 436.125,
    py: 312.25,
  },
  {
    px: 439,
    py: 312,
  },
  {
    px: 449,
    py: 373,
  },
  {
    px: 452,
    py: 537,
  },
  {
    px: 476,
    py: 536,
  },
  {
    px: 485,
    py: 596,
  },
  {
    px: 488,
    py: 682,
  },
  {
    px: 493,
    py: 685,
  },
  {
    px: 493.75,
    py: 712.75,
  },
  {
    px: 496.625,
    py: 728.625,
  },
  {
    px: 497.0625,
    py: 736.5625,
  },
  {
    px: 501.28125,
    py: 738.53125,
  },
  {
    px: 502.390625,
    py: 744.515625,
  },
  {
    px: 502.9453125,
    py: 753.5078125,
  },
  {
    px: 511.5,
    py: 754.5,
  },
  {
    px: 512.75,
    py: 776.25,
  },
  {
    px: 518.875,
    py: 785.125,
  },
  {
    px: 519,
    py: 808,
  },
  {
    px: 521,
    py: 840,
  },
  {
    px: 522.5,
    py: 856,
  },
  {
    px: 525,
    py: 869,
  },
  {
    px: 527.5,
    py: 877.25,
  },
  {
    px: 537,
    py: 877.5,
  },
  {
    px: 538.5,
    py: 863.75,
  },
  {
    px: 540.75,
    py: 847.875,
  },
  {
    px: 545,
    py: 838,
  },
  {
    px: 539,
    py: 704,
  },
  {
    px: 548,
    py: 703,
  },
  {
    px: 546,
    py: 643,
  },
  {
    px: 546,
    py: 597,
  },
  {
    px: 583,
    py: 595,
  },
  {
    px: 572,
    py: 418,
  },
  {
    px: 577,
    py: 329,
  },
  {
    px: 584.5,
    py: 329.5,
  },
  {
    px: 587.25,
    py: 330.75,
  },
  {
    px: 589.625,
    py: 330.375,
  },
  {
    px: 590.8125,
    py: 328.1875,
  },
  {
    px: 594,
    py: 330,
  },
  {
    px: 604,
    py: 219,
  },
  {
    px: 611,
    py: 219,
  },
  {
    px: 612.5,
    py: 222,
  },
  {
    px: 618.25,
    py: 221.5,
  },
  {
    px: 620.125,
    py: 218.25,
  },
  {
    px: 623.0625,
    py: 219.625,
  },
  {
    px: 624.296875,
    py: 220.96875,
  },
  {
    px: 628.9140625,
    py: 220.640625,
  },
  {
    px: 630.22265625,
    py: 217.4765625,
  },
  {
    px: 635.876953125,
    py: 216.89453125,
  },
  {
    px: 639.7041015625,
    py: 220.603515625,
  },
  {
    px: 641.53125,
    py: 220.3125,
  },
  {
    px: 644.765625,
    py: 218.65625,
  },
  {
    px: 645.3828125,
    py: 215.828125,
  },
  {
    px: 649.69140625,
    py: 217.4140625,
  },
  {
    px: 649.845703125,
    py: 219.20703125,
  },
  {
    px: 653.9228515625,
    py: 219.103515625,
  },
  {
    px: 656.96142578125,
    py: 216.0517578125,
  },
  {
    px: 659,
    py: 215,
  },
  {
    px: 658.5,
    py: 194,
  },
  {
    px: 654.25,
    py: 192.5,
  },
  {
    px: 654.125,
    py: 172.75,
  },
  {
    px: 657.0625,
    py: 171.875,
  },
  {
    px: 658.53125,
    py: 163.4375,
  },
  {
    px: 658.265625,
    py: 159.21875,
  },
  {
    px: 655.1328125,
    py: 158.109375,
  },
  {
    px: 655.56640625,
    py: 141.5546875,
  },
  {
    px: 656,
    py: 129,
  },
  {
    px: 666,
    py: 128,
  },
  {
    px: 666,
    py: 136.5,
  },
  {
    px: 664,
    py: 144.75,
  },
  {
    px: 666,
    py: 159,
  },
  {
    px: 669,
    py: 162,
  },
  {
    px: 670.5,
    py: 166.5,
  },
  {
    px: 671.25,
    py: 168.75,
  },
  {
    px: 676,
    py: 175,
  },
  {
    px: 676.5,
    py: 165.5,
  },
  {
    px: 674.75,
    py: 164.75,
  },
  {
    px: 675.875,
    py: 162.375,
  },
  {
    px: 678.4375,
    py: 162.1875,
  },
  {
    px: 677.71875,
    py: 157.09375,
  },
  {
    px: 679.359375,
    py: 159.546875,
  },
  {
    px: 682.1796875,
    py: 160.7734375,
  },
  {
    px: 682.58984375,
    py: 164.38671875,
  },
  {
    px: 681.794921875,
    py: 165.193359375,
  },
  {
    px: 682.3974609375,
    py: 168.5966796875,
  },
  {
    px: 685,
    py: 168,
  },
  {
    px: 681.5,
    py: 140.5,
  },
  {
    px: 678.75,
    py: 138.75,
  },
  {
    px: 678.375,
    py: 125.875,
  },
  {
    px: 679.1875,
    py: 121.4375,
  },
  {
    px: 681.59375,
    py: 120.21875,
  },
  {
    px: 682,
    py: 92,
  },
  {
    px: 681,
    py: 83,
  },
  {
    px: 678.5,
    py: 81.5,
  },
  {
    px: 681.25,
    py: 76.75,
  },
  {
    px: 679.625,
    py: 75.375,
  },
  {
    px: 682,
    py: 68,
  },
  {
    px: 686.25,
    py: 70.75,
  },
  {
    px: 686.375,
    py: 76.125,
  },
  {
    px: 689.5,
    py: 79.5,
  },
  {
    px: 687.75,
    py: 84.25,
  },
  {
    px: 688.5625,
    py: 89.4375,
  },
  {
    px: 695.375,
    py: 112.625,
  },
  {
    px: 698.1875,
    py: 113.8125,
  },
  {
    px: 704,
    py: 127,
  },
  {
    px: 704.5,
    py: 132,
  },
  {
    px: 702.75,
    py: 133.5,
  },
  {
    px: 707,
    py: 163,
  },
  {
    px: 710,
    py: 167,
  },
  {
    px: 711.5,
    py: 165,
  },
  {
    px: 709.25,
    py: 164,
  },
  {
    px: 710.125,
    py: 161.5,
  },
  {
    px: 710.5625,
    py: 156.25,
  },
  {
    px: 713.78125,
    py: 154.625,
  },
  {
    px: 715.390625,
    py: 159.8125,
  },
  {
    px: 716.1953125,
    py: 165.40625,
  },
  {
    px: 719,
    py: 171,
  },
  {
    px: 719,
    py: 112,
  },
  {
    px: 714,
    py: 103,
  },
  {
    px: 713.5,
    py: 97.5,
  },
  {
    px: 718.25,
    py: 93.75,
  },
  {
    px: 714.625,
    py: 93.875,
  },
  {
    px: 713.8125,
    py: 89.9375,
  },
  {
    px: 717.40625,
    py: 86.96875,
  },
  {
    px: 718,
    py: 83,
  },
  {
    px: 725,
    py: 88.5,
  },
  {
    px: 726.5,
    py: 95.25,
  },
  {
    px: 729.25,
    py: 98.625,
  },
  {
    px: 728.625,
    py: 104.3125,
  },
  {
    px: 725.3125,
    py: 105.15625,
  },
  {
    px: 728.65625,
    py: 107.578125,
  },
  {
    px: 726.328125,
    py: 108.7890625,
  },
  {
    px: 732,
    py: 128,
  },
  {
    px: 739,
    py: 151,
  },
  {
    px: 742,
    py: 154,
  },
  {
    px: 752,
    py: 184,
  },
  {
    px: 745.5,
    py: 140,
  },
  {
    px: 742.9375,
    py: 139.5,
  },
  {
    px: 742.65625,
    py: 133.25,
  },
  {
    px: 743.515625,
    py: 128.125,
  },
  {
    px: 741.375,
    py: 108,
  },
  {
    px: 737.25,
    py: 78,
  },
  {
    px: 725,
    py: 16,
  },
  {
    px: 717,
    py: 15,
  },
  {
    px: 720,
    py: 0,
  },
  {
    px: 196,
    py: 1,
  },
];

const windows = [
  {
    px: 113,
    py: 156,
  },
  {
    px: 10,
    py: 864,
  },
  {
    px: 114,
    py: 896,
  },
  {
    px: 336,
    py: 875,
  },
  {
    px: 369,
    py: 602,
  },
  {
    px: 113,
    py: 156,
  },
];

const SKY_INNER = [
  {
    px: 220,
    py: 27,
  },
  {
    px: 217,
    py: 148,
  },
  {
    px: 325,
    py: 400,
  },
  {
    px: 352,
    py: 229,
  },
  {
    px: 388,
    py: 202,
  },
  {
    px: 436,
    py: 218,
  },
  {
    px: 456,
    py: 275,
  },
  {
    px: 498,
    py: 498,
  },
  {
    px: 499,
    py: 668,
  },
  {
    px: 520,
    py: 790,
  },
  {
    px: 531,
    py: 787,
  },
  {
    px: 528,
    py: 698,
  },
  {
    px: 540,
    py: 686,
  },
  {
    px: 541,
    py: 583,
  },
  {
    px: 563,
    py: 549,
  },
  {
    px: 565,
    py: 307,
  },
  {
    px: 605,
    py: 191,
  },
  {
    px: 679,
    py: 61,
  },
  {
    px: 710,
    py: 50,
  },
  {
    px: 716,
    py: 14,
  },
  {
    px: 220,
    py: 27,
  },
];

const BOTTOM_BACKGROUND_AI_A = [
  {
    px: 1,
    py: 900,
  },
  {
    px: 1,
    py: 1028,
  },
  {
    px: 111,
    py: 997,
  },
  {
    px: 105,
    py: 894,
  },
  {
    px: 1,
    py: 900,
  },
];

const BOTTOM_BACKGROUND_AI_B = [
  {
    px: 483,
    py: 900,
  },
  {
    px: 484,
    py: 910,
  },
  {
    px: 540,
    py: 899,
  },
  {
    px: 483,
    py: 900,
  },
];

const BOTTOM_BACKGROUND_AI_C = [
  {
    px: 600,
    py: 900,
  },
  {
    px: 611,
    py: 910.4545288085938,
  },
  {
    px: 635,
    py: 972.4545288085938,
  },
  {
    px: 789,
    py: 964.4545288085938,
  },
  {
    px: 812,
    py: 1027.4545288085938,
  },
  {
    px: 833,
    py: 1018.4545288085938,
  },
  {
    px: 849,
    py: 1051.4545288085938,
  },
  {
    px: 824,
    py: 1063.4545288085938,
  },
  {
    px: 831,
    py: 1090.4545288085938,
  },
  {
    px: 852,
    py: 1103.4545288085938,
  },
  {
    px: 861,
    py: 1133.4545288085938,
  },
  {
    px: 844,
    py: 1130.4545288085938,
  },
  {
    px: 865,
    py: 1200,
  },
  {
    px: 900,
    py: 1200,
  },
  {
    px: 900,
    py: 900,
  },
  {
    px: 770,
    py: 900,
  },
  {
    px: 758,
    py: 893,
  },
  {
    px: 716,
    py: 890.5,
  },
  {
    px: 658,
    py: 892.25,
  },
  {
    px: 629,
    py: 892.125,
  },
  {
    px: 614.5,
    py: 892.0625,
  },
  {
    px: 607.25,
    py: 893.03125,
  },
  {
    px: 591.625,
    py: 895.515625,
  },
  {
    px: 594.8125,
    py: 897.7578125,
  },
  {
    px: 600,
    py: 900,
  },
];

const BASE_ROCKEFELLER = [
  {
    px: 266,
    py: 618,
  },
  {
    px: 152,
    py: 691,
  },
  {
    px: 55,
    py: 779,
  },
  {
    px: 1,
    py: 845,
  },
  {
    px: 1,
    py: 1199,
  },
  {
    px: 899,
    py: 1199,
  },
  {
    px: 899,
    py: 874,
  },
  {
    px: 722,
    py: 686,
  },
  {
    px: 623,
    py: 635,
  },
  {
    px: 511,
    py: 596,
  },
  {
    px: 419,
    py: 587,
  },
  {
    px: 400,
    py: 587,
  },
  {
    px: 266,
    py: 618,
  },
];

const AKY_CORNER = [
  {
    px: 305,
    py: 1,
  },
  {
    px: 870,
    py: 213,
  },
  {
    px: 870,
    py: 1,
  },
  {
    px: 305,
    py: 1,
  },
];

const OBJECT_DEFINED = {
  base_rockefeller: {
    points: BASE_ROCKEFELLER,
  },
  sky_inner: {
    points: SKY_INNER,
  },
  bottom_background_ai_b: {
    points: BOTTOM_BACKGROUND_AI_B,
  },
  bottom_background_ai_a: {
    points: BOTTOM_BACKGROUND_AI_A,
  },
  bottom_background_ai_c: {
    points: BOTTOM_BACKGROUND_AI_C,
  },
  sky_RF: {
    points: SKY_RF,
  },
};

const DATA = {
  image: Img,
  objectsDefined: OBJECT_DEFINED,
};

export default DATA;
